import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { FaRegHandshake } from "react-icons/fa";
import {
  FiArrowLeft,
  FiEdit,
  FiGlobe,
  FiInfo,
  FiMail,
  FiPhone,
  FiUser,
} from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { IoDocumentTextOutline } from "react-icons/io5";
import { BsBuilding } from "react-icons/bs";
import _ from "lodash";
import { UNAUTHORIZED } from "../../../../../constants";
import ContactDetails from "../../../../../component/form/contact_details";
import {
  actionGetClientDetails,
  ACTION_MASTER_CLIENT_DETAILS_SUCCESS,
} from "./action";
import {
  actionGetLeadMeetingsDetail,
  ACTION_GET_CLIENT_MEETINGS_DETAIL_SUCCESS,
} from "./action";
import {
  generateInitialLettersString,
  PRINT,
  verifyPermission,
} from "../../../../../utils";
import TabLeadProposalsView from "./tabs/proposals";
import TabLeadMeetingView from "./tabs/meetings";

const LeadView = (props) => {
  const [clientDetails, setClientDetails] = useState(null);

  const [contactDetails, setContactDetails] = useState([]);
  const [showContactDetails, setShowContactDetails] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);

  const initialRender = () => {
    if (props.location.state !== undefined || props.location.state != null) {
      props.actionGetClientDetails({ id: props?.location?.state?.client_id });
      props.actionGetLeadMeetingsDetail({
        client_id: props?.location?.state?.client_id,
      });
    } else {
      props.history.goBack();
    }
  };
  useEffect(initialRender, []);

  const onClientDetailUpdate = () => {
    const { ClientDetails } = props;
    var ClientDetailsResponse = Object.assign({}, ClientDetails);
    if (ClientDetailsResponse.result === true) {
      setClientDetails(ClientDetailsResponse.response);
      setContactDetails(ClientDetailsResponse.response.contact_details);
      PRINT("Client Details", JSON.stringify(ClientDetailsResponse.response));
    } else {
      switch (ClientDetailsResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onClientDetailUpdate, [props.ClientDetails]);

  const renderContactDetails = () => {
    var arrContactDetails = [];
    if (contactDetails != null && contactDetails.length > 0) {
      contactDetails.forEach((contact, indexContact) => {
        arrContactDetails.push(
          <div
            class={`col-12 px-0 mb-1 border-bottom ${
              contact.contact_name === null ? "hidden" : ""
            } `}
          >
            <div className="float-left mb-half">
              <div className="d-flex flex-row">
                <div className="flex-column mr-half">
                  {/* <FaUserCircle size={50} className="blue-grey" /> */}
                  <div className="d-flex bg-circle bg-blue-grey bg-lighten-4 justify-content-center align-items-center text-black text-bold-600 font-medium-2 mr-1">
                    {generateInitialLettersString(contact.contact_name)}
                  </div>
                </div>
                <div className="flex-column">
                  <p class="mb-0 latest-update-item-name text-bold-600 text-primary font-medium-1">
                    {contact.contact_name}
                  </p>
                  <div class="font-small-3 text-bold-700">
                    {contact.contact_designation}
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <div
                      className={`flex-column ${
                        contact.contact_phone !== null ? "" : "hidden"
                      }`}
                    >
                      <FiPhone size={12} className="mr-half primary" />
                    </div>
                    <div
                      className={`flex-column mr-1 ${
                        contact.contact_phone !== null ? "" : "hidden"
                      }`}
                    >
                      <div class="font-small-3 secondary">
                        {contact.contact_phone}
                      </div>
                    </div>
                    <div
                      className={`flex-column ${
                        contact.contact_email !== null ? "" : "hidden"
                      }`}
                    >
                      <FiMail size={12} className="mr-half primary" />
                    </div>
                    <div
                      className={`flex-column ${
                        contact.contact_email !== null ? "" : "hidden"
                      }`}
                    >
                      <div class="d-inline font-small-3 secondary">
                        {contact.contact_email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider"></div>
          </div>
        );
      });
    }
    return arrContactDetails;
  };

  const renderTab = () => {
    switch (selectedTab) {
      case 1:
        return (
          <TabLeadProposalsView
            {...props}
            clientId={props?.location?.state?.client_id}
            tab={selectedTab}
          />
        );
      case 2:
        return (
          <TabLeadMeetingView
            {...props}
            clientId={props?.location?.state?.client_id}
            tab={selectedTab}
          />
        );
      default:
        return <></>;
    }
  };
  useEffect(renderTab, [selectedTab]);

  return (
    <>
      <section>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-3 border-right">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <span
                    className="primary cursor-pointer"
                    onClick={() => {
                      props.history.goBack();
                    }}
                  >
                    <FiArrowLeft size={16} /> Back
                  </span>
                  {verifyPermission("UPDATE_LEADS") ? (
                    <FiEdit
                      size={16}
                      className="float-right"
                      cursor="pointer"
                      onClick={() => {
                        props.history.push({
                          pathname: "/leads/edit",
                          state: {
                            client_id: props?.location?.state?.client_id,
                            type: "Lead",
                            visible:
                              clientDetails !== null &&
                              (clientDetails.created_by === null ||
                                Number(clientDetails.created_by) ===
                                  Number(props.auth.getUser().user_id))
                                ? true
                                : false,
                          },
                        });
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-center w-100 mt-2">
                  <div className="flex-column text-center">
                    <div className="d-flex justify-content-center mb-1">
                      <div
                        className="d-flex justify-content-center align-items-center rounded-circle bg-blue-grey bg-lighten-4 "
                        style={{ height: 80, width: 80 }}
                      >
                        <BsBuilding
                          size={30}
                          className="blue-grey bg-lighten-2"
                        />
                      </div>
                    </div>
                    <h4 className="font-medium-2 text-bold-600">
                      {clientDetails != null &&
                      clientDetails.company_name != null
                        ? clientDetails.company_name
                        : "-"}
                    </h4>
                    <h4
                      className="font-medium-1 text-bold-500 text-primary cursor-pointer"
                      onClick={() => {
                        props.history.push({
                          pathname: "/client/group-level-view",
                          state: {
                            client_group_id: clientDetails?.client_group_id,
                          },
                        });
                      }}
                    >
                      {clientDetails != null &&
                      clientDetails.company_group_name != null
                        ? clientDetails.company_group_name
                        : "-"}
                    </h4>
                    <div className="d-flex flex-row justify-content-center w-100 mt-1">
                      <button
                        className={`btn btn-md btn-success font-small-3 text-bold-600 px-2 round ${
                          clientDetails != null &&
                          clientDetails.category != null
                            ? ""
                            : "hidden"
                        }`}
                      >
                        {clientDetails != null && clientDetails.category != null
                          ? _.capitalize(clientDetails.category)
                          : "-"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center mt-2 mb-0">
                  <div className="col-12 col-md-6 col-lg-2">
                    <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                      <a
                        className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                        href={`${
                          clientDetails != null && clientDetails.mobile != null
                            ? `tel:` + clientDetails.mobile
                            : "#"
                        }`}
                      >
                        <FiPhone size={12} className="my-auto" />
                      </a>
                    </p>
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p className="font-small-3 text-bold-600 mb-half">
                      {clientDetails != null && clientDetails.mobile != null
                        ? clientDetails.mobile
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="row align-items-center mt-half">
                  <div className="col-12 col-md-6 col-lg-2">
                    <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                      <a
                        className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                        href={`${
                          clientDetails != null && clientDetails.email != null
                            ? `mailto:` + clientDetails.email
                            : "#"
                        }`}
                      >
                        <FiMail size={12} className="my-auto" />
                      </a>
                    </p>
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p className="font-small-3 text-bold-600 mb-half">
                      {clientDetails != null && clientDetails.email != null
                        ? clientDetails.email
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="row mt-half">
                  <div className="col-12 col-md-6 col-lg-2">
                    <a
                      className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                      target="_blank"
                      href={`${
                        clientDetails != null && clientDetails.address != null
                          ? `https://maps.google.com/maps?q=` +
                            clientDetails.address
                          : "#"
                      }`}
                    >
                      <GoLocation size={12} className="my-auto" />
                    </a>
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p className="font-small-3 text-bold-600 mb-half">
                      {clientDetails != null && clientDetails.address != null
                        ? clientDetails.address
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="row align-items-center mt-half">
                  <div className="col-12 col-md-6 col-lg-2">
                    <p className="font-small-3 text-bold-600 blue-grey lighten-1 mb-half">
                      <a
                        className="btn btn-float btn-primary text-white btn-social-icon btn-xs rounded-circle"
                        target="_blank"
                        onClick={() => {
                          if (clientDetails.website.includes("http")) {
                            window.open(clientDetails.website, "_blank");
                          } else {
                            window.open(
                              "http://" + clientDetails.website,
                              "_blank"
                            );
                          }
                        }}
                      >
                        <FiGlobe size={12} className="my-auto" />
                      </a>
                    </p>
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p className="font-small-3 text-bold-600 mb-half">
                      {clientDetails != null && clientDetails.website != null
                        ? clientDetails.website
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9 mt-2 mt-md-0 mt-lg-0 px-0">
                <ul
                  className="nav nav-tabs nav-underline border-bottom"
                  role="tablist"
                  style={{ backgroundColor: "transparent" }}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link d-flex align-items-center justify-content-center active"
                      id="contacts-tab"
                      data-toggle="tab"
                      href="#contacts"
                      aria-controls="contacts"
                      role="tab"
                      aria-selected="false"
                      onClick={(event) => {
                        setSelectedTab(0);
                      }}
                    >
                      <BsBuilding size={16} />
                      <span className="d-none d-sm-block">
                        &nbsp;Client Profile
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link d-flex align-items-center justify-content-center"
                      id="policies-tab"
                      data-toggle="tab"
                      href="#policies"
                      aria-controls="policies"
                      role="tab"
                      aria-selected="false"
                      onClick={(event) => {
                        setSelectedTab(1);
                      }}
                    >
                      <IoDocumentTextOutline size={16} />
                      <span className="d-none d-sm-block">
                        &nbsp;Opportunities
                      </span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link d-flex align-items-center justify-content-center"
                      id="meetings-tab"
                      data-toggle="tab"
                      href="#meetings"
                      aria-controls="meetings"
                      role="tab"
                      aria-selected="false"
                      onClick={(event) => {
                        setSelectedTab(2);
                      }}
                    >
                      <FaRegHandshake size={16} />
                      <span className="d-none d-sm-block">&nbsp;Meetings</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  {renderTab()}
                  {selectedTab === 0 ? (
                    <div
                      className="tab-pane active"
                      id="contacts"
                      aria-labelledby="contacts-tab"
                      role="tabpanel"
                    >
                      <div className="card">
                        <div className="card-body">
                          <form noValidate autoComplete="Off">
                            <div className="form-body">
                              <div className="row mb-1">
                                <div className="col-12 col-md-12 col-lg-12">
                                  <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                        <div>
                                          <FiInfo size={16} />
                                          &nbsp;Basic Information
                                        </div>
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        State
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.state != null
                                          ? clientDetails.state
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        City
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.city_name != null
                                          ? clientDetails.city_name
                                          : "-"}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row pt-1">
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Pincode
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.pincode != null
                                          ? clientDetails.pincode
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Email
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.email != null
                                          ? clientDetails.email
                                          : "-"}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row pt-1">
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Mobile
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.mobile != null
                                          ? clientDetails.mobile
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Landline No
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.landline != null
                                          ? clientDetails.landline
                                          : "-"}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row pt-1">
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Pan No
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.pan != null
                                          ? clientDetails.pan
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Industrial Segment
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.industrial_segment != null
                                          ? clientDetails.industrial_segment
                                          : "-"}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row pt-1">
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-1">
                                        Activity
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-1">
                                        {clientDetails != null &&
                                        clientDetails.industrial_segment_activity_name !=
                                          null
                                          ? clientDetails.industrial_segment_activity_name
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-1">
                                        Website
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-1">
                                        {clientDetails != null &&
                                        clientDetails.website != null
                                          ? clientDetails.website
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-1">
                                        Form Of Organization
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-1">
                                        {clientDetails != null &&
                                        clientDetails.form_organization != null
                                          ? clientDetails.form_organization
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-1">
                                        Multi Branch
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-1">
                                        {clientDetails != null &&
                                        clientDetails.multi_branch != null
                                          ? clientDetails.multi_branch
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Lead Source
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.lead_source != null
                                          ? clientDetails.lead_source
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Date of Birth
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails?.contact_dob != null
                                          ? clientDetails?.contact_dob
                                          : "-"}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row pt-2">
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                        <div>
                                          <FiUser size={16} />
                                          &nbsp;Primary Contact
                                        </div>
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row pt-1">
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Conatct Name
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.primary_contact != null
                                          ? clientDetails.primary_contact
                                              .contact_name
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Contact Designation
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.primary_contact != null
                                          ? clientDetails.primary_contact
                                              .contact_designation
                                          : "-"}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row pt-1">
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Conatct Phone
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.primary_contact != null
                                          ? clientDetails.primary_contact
                                              .contact_phone
                                          : "-"}
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-2 col-md-2 d-flex align-items-center">
                                      <h4 className="text-uppercase font-small-3 mb-0">
                                        Conatct Email ID
                                      </h4>
                                    </div>
                                    <div className="col-12 col-lg-4 col-md-4 d-flex align-items-center">
                                      <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {clientDetails != null &&
                                        clientDetails.primary_contact != null
                                          ? clientDetails.primary_contact
                                              .contact_email
                                          : "-"}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row pt-2">
                                    <div className="col-12 col-md-12 col-lg-12">
                                      <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                        <div>
                                          <FiPhone size={16} />
                                          &nbsp;Contact Details
                                        </div>
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="row mx-0">
                                    {contactDetails.length > 0 ? (
                                      renderContactDetails()
                                    ) : (
                                      <h6 className="text-bold-500 text-center">
                                        No Contact Details
                                      </h6>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactDetails
        show={showContactDetails}
        handleClose={(newContact) => {
          if (newContact != null) {
            var arrContacts = Object.assign([], contactDetails);
            arrContacts.push(newContact);
            setContactDetails(arrContacts);
          }
          setShowContactDetails(false);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  var ClientDetails = null;
  var GetLeadMeetingsDetail = null;

  if (
    state.LeadViewReducer != null &&
    Object.keys(state.LeadViewReducer).length > 0 &&
    state.LeadViewReducer.constructor === Object
  ) {
    switch (state.LeadViewReducer.tag) {
      case ACTION_MASTER_CLIENT_DETAILS_SUCCESS:
        ClientDetails = Object.assign({}, state.LeadViewReducer.data);
        delete state.LeadViewReducer;
        return { ClientDetails };
      case ACTION_GET_CLIENT_MEETINGS_DETAIL_SUCCESS:
        GetLeadMeetingsDetail = Object.assign({}, state.LeadViewReducer.data);
        delete state.LeadViewReducer;
        return { GetLeadMeetingsDetail };
      default:
    }
  }
  return {
    ClientDetails,
    GetLeadMeetingsDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionGetClientDetails,
      actionGetLeadMeetingsDetail,
    },
    dispatch
  );
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(LeadView));
