import React, { useEffect, useState } from 'react';
import ScreenTitle from '../../../../../../component/screen_title';
import { FiPlus, FiUser, FiPhone, FiShield, FiEdit, FiEye } from 'react-icons/fi';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateBox } from 'devextreme-react/date-box';
import ErrorLabel from "../../../../../../component/form/error_label";
import { useForm, Controller } from "react-hook-form";
import AddNewCompany from '../../../../../../component/form/new_customer';
import { withAuth } from 'react-auth-guard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dateFormat from 'dateformat';
import $ from "jquery";
import Select from 'react-select';
import { ERROR, UNAUTHORIZED, characterLimit, PRIMARY_COLOR, MEETING_FILE_SIZE, MEETING_FILE_ERROR_MESSAGE, customerMinCharacters, customerMeetingCharacterLimit } from '../../../../../../constants';
import { checkFileExtension, PRICE_FORMAT } from '../../../../../../utils';
import { getAccompainedByIds } from '../../../../../../utils';
import Swal from 'sweetalert2';
import moment from 'moment';
import { getData, KEY_TOAST_MESSAGE, KEY_USER_TOKEN, setData } from '../../../../../../utils/preferences';
import ContactDetails from '../../../../../../component/form/contact_details';
import PolicyDetailModal from '../../../../../../component/form/policy_details';
import ProposalDetailModal from '../../../../../../component/popups/proposal_details';
import { actionCustomerMeetingContact, ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS } from "./action";
import { actionCustomerMeetingPolicy, ACTION_CUSTOMER_MEETING_POLICY_SUCCESS } from "./action";
import { actionCustomerMeetingAddContact, ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS } from './action';
import { actionEditPolicy, ACTION_EDIT_POLICY_SUCCESS } from './action';
import { actionCustomerMeetingSave, ACTION_CUSTOMER_MEETING_SAVE_SUCCESS } from './action';
import { actionMeetingType, ACTION_API_MEETING_TYPE_SUCCESS } from './action';
import { actionPreviousMeeting, ACTION_API_PREVIOUS_MEETING_SUCCESS } from './action';
import { actionBusinessType, ACTION_API_BUSINESS_TYPE_SUCCESS } from './action';
import { actionSelectCustomer, ACTION_SELECT_CUSTOMER_SUCCESS } from './action';
import { actionAccompainedBy, ACTION_API_ACCOMPAINED_BY_SUCCESS } from './action';
import { actionAddPolicy, ACTION_ADDPOLICY_SUCCESS } from './action';
import { actionMasterInsuranceCompanyName, ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS } from './action';
import { actionCustomerMeetingNext, ACTION_CUSTOMER_MEETING_NEXT_SUCCESS } from './action';
import DataGrid, {
    Button,
    Column,
} from "devextreme-react/data-grid";
import _ from 'lodash';
import { toast } from 'react-toastify';

const CustomerMeetingAdd = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue, watch, getValues } = useForm()

    var meetingAgendaWatcher = watch('agenda', '');
    var meetingLocationWatcher = watch('location', '');

    const [meetingType, setMeetingType] = useState();

    const [businessType, setBusinessType] = useState();

    // Store All Customer & Lead Name
    const [customer, setCustomer] = useState();

    const [newCustomerId, setNewCustomerId] = useState(null)

    const [previousMeeting, setPreviousMeeting] = useState();
    const [accompainedBy, setAccompainedBy] = useState();

    const [addNewCompany, setAddNewCompany] = useState(false);

    const [customerCompanyId, setCustomerCompanyId] = useState(null);
    const [selectedCompanyContacts, setSelectedCompanyContacts] = useState([])

    const [customerPolicyId, setCustomerPolicyId] = useState(0)
    const [selectedCompanyPolicy, setSelectedCompanyPolicy] = useState([])
    const [editPolicyIndex, setEditPolicyIndex] = useState(-1)
    const [additionalLeadChecks, setAdditionalLeadChecks] = useState();
    const [encodedData, setEncodedData] = useState();
    const [clientSelectedType, setSelectedClientType]= useState();
    const [clientId, setClientId]= useState();
    const [contactDetails, setContactDetails] = useState([]);
    const [policyDetails, setPolicyDetails] = useState([]);
    const [assignRMId, setAssignRMId] = useState(null);
    const [showContactDetails, setShowContactDetails] = useState(false);
    const [showPolicyDetails, setShowPolicyDetails] = useState(false);
    const [showProposalDetails, setShowProposalDetails] = useState(false);
    const [checkBoxValue, setCheckBoxValue] = useState({ checkBoxValue: null });
    const [meetingTypeId, setMeetingTypeId] = useState(null);
    const [businessTypeId, setBusinessTypeId] = useState(null);
    const [details, setDetails] = useState(null);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [convertedToCustomer, setConvertedToCustomer] = useState(false);
    const [token, setToken] = useState(() => {
        const tokenString = getData(KEY_USER_TOKEN);
        return tokenString ? JSON.parse(tokenString) : null;
    });
    const validExt = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xlsx', 'xls', 'ppt', 'pptx'];

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "renewal_status", caption: "Relevant" },
        { dataField: "policy_type", caption: "Policy Type" },
        { dataField: "department_name", caption: "Department" },
        { dataField: "insurer_name", caption: "Insurance Company" },
        { dataField: "lead_expiry_date", caption: "Next Renewal on" },
        { dataField: "brokerage_amnt", caption: "Brokerage Amount" },
        { dataField: "expected_premium", caption: "Expected Premium" },
        { dataField: "probability", caption: "Probability(%)" },
        { dataField: "status", caption: "Status" },
    ]);

    var meetingTypeWatcher = watch('meeting_type', '')

    const [nextMeeting, setNextMeeting] = useState(false);
    const [status, setStatus] = useState('close');
    const [clientType, setClientType] = useState('lead');
    const [masterInsuranceCompanyName, setMasterInsuranceCompanyName] = useState([]);
    const [existingDate, setExistingDate] = useState(false);
    var watchMeetInsurerCompany = watch('meet_insurer_company');
    var watchDecisionMakerStatus = watch('decision_maker_status');
    var watchMinutesOfMeeting = watch('minutes_of_meeting', '');

    const onValueChanged = (args) => {
        policyDetails.forEach((item) => {
            if (item.renewal_status === "active") {
                setCheckBoxValue({ checkBoxValue: args.value });
            }
        })
    }

    const initialRender = () => {
        props.actionMeetingType({});
        props.actionBusinessType({});
        props.actionAccompainedBy({});
        props.actionMasterInsuranceCompanyName();
    }
    useEffect(initialRender, []);

    // Call On Page Load To Get The Meeting Types **Meeting With**
    const onMeetingTypeUpdate = () => {
        const { MeetingType } = props;
        var MeetingTypeResponse = Object.assign({}, MeetingType);
        if (MeetingTypeResponse.result === true) {
            setMeetingType(MeetingTypeResponse.response);
        } else {
            switch (MeetingTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onMeetingTypeUpdate, [props.MeetingType]);

    // Call On Change of Meeting With To Get The Customer List **Select Customer**
    const onSelectCustomerUpdate = () => {
        const { Customers } = props;
        var CustomersResponse = Object.assign({}, Customers);
        if (CustomersResponse.result === true) {
            setCustomer(CustomersResponse.response);
        } else {
            switch (CustomersResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onSelectCustomerUpdate, [props.Customers]);

    // Call On Page Load To Get Business Types **Meeting Type**
    const onBusinessTypeUpdate = () => {
        const { BusinessType } = props;
        var BusinessTypeResponse = Object.assign({}, BusinessType);
        if (BusinessTypeResponse.result === true) {
            setBusinessType(BusinessTypeResponse.response);
        } else {
            switch (BusinessTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onBusinessTypeUpdate, [props.BusinessType]);

    useEffect(() => {
        if (customer != null && customer.length > 0) {
            if (newCustomerId != null && newCustomerId > 0) {
                setTimeout(() => {
                    setValue('client_id', newCustomerId)
                })
            }
            if (customerCompanyId > 0) {
                setTimeout(() => {
                    setValue('client_id', customerCompanyId)
                }, 500);
            }
            if (customerPolicyId > 0) {
                setTimeout(() => {
                    setValue('client_id', customerPolicyId)
                }, 500);
            }
        }
    }, customer);

    // Call When Select Customer Value Change To Get Previous Meetings **Previous Meeting**
    const onPreviousMeetingUpdate = () => {
        const { PreviousMeeting } = props;
        var PreviousMeetingResponse = Object.assign({}, PreviousMeeting);
        if (PreviousMeetingResponse.result === true) {
            setPreviousMeeting(PreviousMeetingResponse.response);
        } else {
            switch (PreviousMeetingResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onPreviousMeetingUpdate, [props.PreviousMeeting]);

    // Call On Page Load To Get The Accompained By
    const onAccompainedByUpdate = () => {
        const { AccompainedBy } = props;
        var AccompainedByResponse = Object.assign({}, AccompainedBy);
        if (AccompainedByResponse.result === true) {
            setAccompainedBy(AccompainedByResponse.response);
        } else {
            switch (AccompainedByResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onAccompainedByUpdate, [props.AccompainedBy]);

    // Call When Select Customer Value Change and Setup the Contacts Under **Contact Details** Section
    // NEED TO CHECK
    const onCompanyContactsUpdate = () => {
        const { MeetingContact } = props
        var MeetingContactsResponse = Object.assign({}, MeetingContact)
        if (MeetingContactsResponse.result === true) {
            var contacts = MeetingContactsResponse.response != null && MeetingContactsResponse.response.length > 0 ? Object.assign([], MeetingContactsResponse.response) : []
            var selectedContacts = []
            if (contacts.length === 1) {
                contacts.forEach((contact, index) => {
                    selectedContacts.push(contact?.id)
                });
                setSelectedCompanyContacts(selectedContacts)
            } else {
                setSelectedCompanyContacts([])
            }
            setContactDetails(MeetingContactsResponse.response)
        } else {
            switch (MeetingContactsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    setContactDetails([])
                    setSelectedCompanyContacts([])
                    break;
                default:
            }
        }
    }
    useEffect(onCompanyContactsUpdate, [props.MeetingContact])

    // Call When Select Customer Value Change and Setup the Proposal Under **Proposal Details** Section
    const onCompanyPolicyUpdate = () => {
        const { MeetingPolicy } = props
        var MeetingPolicyResponse = Object.assign({}, MeetingPolicy)
        if (MeetingPolicyResponse.result === true) {
            setPolicyDetails(MeetingPolicyResponse.response)
            setColumns([
                { dataField: "Action", caption: "Action", type: "button" },
                { dataField: "renewal_status", caption: "Relevant" },
                { dataField: "policy_type", caption: "Policy Type" },
                { dataField: "department_name", caption: "Department" },
                { dataField: "insurer_name", caption: "Insurance Company" },
                { dataField: "lead_expiry_date", caption: "Next Renewal on" },
                { dataField: "brokerage_amnt", caption: "Brokerage Amount" },
                { dataField: "expected_premium", caption: "Expected Premium" },
                { dataField: "probability", caption: "Probability(%)" },
                { dataField: "status", caption: "Status" },
            ]);
        } else {
            switch (MeetingPolicyResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    setPolicyDetails([])
                    setSelectedCompanyPolicy([])
                    break;
                default:
            }
        }
    }
    useEffect(onCompanyPolicyUpdate, [props.MeetingPolicy])

    // Call When New Policy Added
    const onAddPolicyUpdate = () => {

        const { AddPolicy } = props;
        if (AddPolicy != null) {
            var AddPolicyResponse = Object.assign({}, AddPolicy);
            if (AddPolicyResponse.result === true) {
                props.actionCustomerMeetingPolicy({ client_id: customerPolicyId });
                var newPolicy = Object.assign({}, AddPolicyResponse.response);
                var arrPolicies = Object.assign([], policyDetails);
                arrPolicies.push(newPolicy);
                var arrSelectedCompanyPolicy = Object.assign([], selectedCompanyPolicy);
                arrSelectedCompanyPolicy.push(newPolicy?.id);
                setPolicyDetails(arrPolicies);
                setSelectedCompanyPolicy(arrSelectedCompanyPolicy);
                setClientId(AddPolicyResponse?.response?.client_id)
                setSelectedClientType( _.capitalize(AddPolicyResponse?.response?.client_type))
                
                if (typeof AddPolicyResponse?.response?.encoded_data === 'boolean' && AddPolicyResponse?.response?.encoded_data === false) {
                    setAdditionalLeadChecks(!AddPolicyResponse?.response?.encoded_data);
                }else{
                    setEncodedData(AddPolicyResponse?.response?.encoded_data)
                }
            } else {
                switch (AddPolicyResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onAddPolicyUpdate, [props.AddPolicy]);

    const onEditPolicyUpdate = () => {

        const { EditPolicy } = props;
        if (EditPolicy != null) {
            var EditPolicyResponse = Object.assign({}, EditPolicy);
            if (EditPolicyResponse.result === true) {
                props.actionCustomerMeetingPolicy({ client_id: customerPolicyId });
                var newPolicy = Object.assign({}, EditPolicyResponse.response);
                var arrPolicies = Object.assign([], policyDetails);
                arrPolicies[editPolicyIndex] = newPolicy
                var arrSelectedCompanyPolicy = Object.assign([], selectedCompanyPolicy);
                if (!arrSelectedCompanyPolicy.includes(newPolicy?.id)) {
                    arrSelectedCompanyPolicy.push(newPolicy?.id);
                }
                setEditPolicyIndex(-1)
                setPolicyDetails(arrPolicies);
                setSelectedCompanyPolicy(arrSelectedCompanyPolicy);
                setClientId(EditPolicyResponse?.response?.client_id)
                setSelectedClientType( _.capitalize(EditPolicyResponse?.response?.client_type))
                
                if (typeof EditPolicyResponse?.response?.encoded_data === 'boolean' && EditPolicyResponse?.response?.encoded_data === false) {
                    setAdditionalLeadChecks(!EditPolicyResponse?.response?.encoded_data);
                }else{
                    setEncodedData(EditPolicyResponse?.response?.encoded_data)
                }
            } else {
                switch (EditPolicyResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onEditPolicyUpdate, [props.EditPolicy]);

    useEffect(()=>{
        if(encodedData && token){   
              const url = `https://pincerp.staypinc.com/login/${token}lead${encodedData}`;
              window.open(url);
            // window.location.href = url;
        }
      },[encodedData])

      useEffect(()=>{
        if(additionalLeadChecks){   
           if(clientSelectedType=='Lead') {
            props.history.push({
                pathname: '/leads/edit',
                state: {
                    additionalCheck: additionalLeadChecks,
                    type: clientSelectedType,
                    client_id: clientId,
                    visible:true
                }
            })
           }else{
            props.history.push({
                pathname: '/client/edit',
                state: {
                    additionalCheck: additionalLeadChecks,
                    type: clientSelectedType,
                    client_id: clientId,
                    visible:true
                }
            })
           }
           
        }
      },[additionalLeadChecks])

    const onNewContactUpdate = () => {

        const { NewContact } = props;

        if (NewContact != null) {
            var NewContactResponse = Object.assign({}, NewContact);
            if (NewContactResponse.result === true) {
                var newContact = Object.assign({}, NewContactResponse.response);
                var arrContacts = Object.assign([], contactDetails);
                arrContacts.push(newContact);
                var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                arrSelectedCompanyContact.push(newContact?.id);
                setSelectedCompanyContacts(arrSelectedCompanyContact);
                setContactDetails(arrContacts);
            } else {
                switch (NewContactResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onNewContactUpdate, [props.NewContact]);

    const onMasterInsuranceCompanyNameUpdate = () => {
        const { MasterInsuranceCompanyName } = props;
        var MasterInsuranceCompanyNameResponse = Object.assign({}, MasterInsuranceCompanyName);
        if (MasterInsuranceCompanyNameResponse.result === true) {
            setMasterInsuranceCompanyName(MasterInsuranceCompanyNameResponse.response);
        } else {
            switch (MasterInsuranceCompanyNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(MasterInsuranceCompanyNameResponse.message);
                    break;
                default:
            }
        }
    }
    useEffect(onMasterInsuranceCompanyNameUpdate, [props.MasterInsuranceCompanyName]);

    const onCustomerMeetingNextUpdate = () => {
        const { CustomerMeetingNext } = props;
        var CustomerMeetingNextResponse = Object.assign({}, CustomerMeetingNext);
        if (CustomerMeetingNextResponse.result === true) {
            $("#add_customer-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            toast.success("Next Customer Meeting Saved Successfully");
            // setData(KEY_TOAST_MESSAGE, "Next Customer Meeting Saved Successfully");
            window.location.replace('/meetings/customer-meetings');
        } else {
            switch (CustomerMeetingNextResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                default:
            }
        }
    }
    useEffect(onCustomerMeetingNextUpdate, [props.CustomerMeetingNext]);

    const onCustomerMeetingSaveUpdate = () => {
        const { CustomerMeetingSave } = props;
        var CustomerMeetingSaveResponse = Object.assign({}, CustomerMeetingSave);
        $("#add_customer").prop("disabled", false);
        if (CustomerMeetingSaveResponse.result === true) {
            if (nextMeeting === true) {
                var data = {
                    meeting_type: meetingTypeId,
                    business_type: businessTypeId,
                    client_id: customerCompanyId,
                    prev_meeting: CustomerMeetingSaveResponse.response?.id,
                    start_date_time: getValues('start_date_time') + ' ' + getValues('next_from_time'),
                    end_date_time: getValues('start_date_time') + ' ' + getValues('next_to_time'),
                    created_by: props?.auth?.getUser()?.id,
                }
                props.actionCustomerMeetingNext(data);
            } else {
                $("#add_customer-loader").addClass("hidden");
                // $("#progress-loader").addClass("hidden");
                toast.success("Customer Meeting Saved Successfully");
                // setData(KEY_TOAST_MESSAGE, "Customer Meeting Saved Successfully");
                window.location.replace('/meetings/customer-meetings');
            }
        } else {
            $("#add_customer-loader").addClass("hidden");
            // $("#progress-loader").addClass("hidden");
            switch (CustomerMeetingSaveResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    toast.success("Your Login session has expired, Please try to Login again!");
                    break;
                case ERROR:
                    toast.error(CustomerMeetingSaveResponse.message);
                    break;
                default:
            }
        }
    }
    useEffect(onCustomerMeetingSaveUpdate, [props.CustomerMeetingSave]);

    const renderRenewalDate = (cell) => {
        if (cell.data.lead_expiry_date != null && cell.data.lead_expiry_date != "0000-00-00 00:00:00") {
            return <div>{moment(cell.data.lead_expiry_date).format('MMM, DD YYYY')}</div>
        }
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm float-left"
                                    onClick={() => {
                                        setEditPolicyIndex(indexColumn);
                                        setShowProposalDetails(true);
                                    }}
                                >
                                    <FiEye size={16} className="primary" />
                                </button>
                            )}
                        />
                        <Button
                            render={(params) => (
                                <button
                                    className="btn btn-icon btn-sm"
                                    onClick={() => {
                                        setEditPolicyIndex(indexColumn);
                                        setShowPolicyDetails(true);
                                    }}
                                >
                                    <FiEdit size={16} className="primary" />
                                </button>
                            )}
                        />

                    </Column>
                );
            } else if (objColumn.dataField === "lead_expiry_date") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderRenewalDate}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const onSubmit = (data) => {
        const { from_time, to_time } = data;
        const fromTimeDate = new Date(`2000-01-01T${from_time}`);
        const toTimeDate = new Date(`2000-01-01T${to_time}`);
        if (selectedCompanyContacts.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'Please select at least one Contact',
                icon: 'error'
            }).then(success => {

            })
        } else {
            if (fromTimeDate >= toTimeDate) {
                Swal.fire({
                    title: 'Error',
                    text: 'From Time cannot be greater than or equal to To Time',
                    icon: 'error'
                }).then(success => {

                })
            } else {
                data.created_by = props.auth.getUser()?.user_id;
                data.start_date_time = data.start_date + ' ' + data.from_time;
                data.end_date_time = data.start_date + ' ' + data.to_time;
                data.accompained_by = JSON.stringify(getAccompainedByIds(data.accompained_by));
                data.contact_ids = JSON.stringify(selectedCompanyContacts);
                data.policies_ids = JSON.stringify(selectedCompanyPolicy);
                if (existingDate === true) {
                    data.status = status
                } else {
                    data.status = "open"
                }
                // data.insurer_id = watchMeetInsurerCompany !== undefined && Number(watchMeetInsurerCompany) === 1 ? data.insurer_id : null
                // data.decision_maker = watchDecisionMakerStatus !== undefined && Number(watchDecisionMakerStatus) === 1 ? data.decision_maker : null
                var files = [];
                if (uploadDocument != null) {
                    files.push({ 'name': 'upload_document', 'value': uploadDocument });
                }
                delete data.from_time;
                delete data.to_time;
                delete data.start_date;
                delete data.upload_document;

                if (data.converted_to === 'client') {
                    if (details != null) {
                        data.client_details = details;
                        $("#add_customer-loader").removeClass("hidden");
                        $("#add_customer").prop("disabled", true);
                        if(data?.prev_meeting ==null ||data?.prev_meeting ==undefined){
                            delete data?.prev_meeting
                        }
                        props.actionCustomerMeetingSave(data, files);
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: 'Converted To Customer Failed'
                        })
                    }
                }
                $("#add_customer-loader").removeClass("hidden");
                $("#add_customer").prop("disabled", true);
                // $("#progress-loader").removeClass("hidden");
                if(data?.prev_meeting ==null ||data?.prev_meeting ==undefined){
                    delete data?.prev_meeting
                }
                props.actionCustomerMeetingSave(data, files);
            }
        }
    }

    return (
        <>
            <ScreenTitle title="Add Customer Meeting" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Meeting Information
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Meeting With&nbsp;<span className="danger">*</span></label>

                                                    <Controller
                                                        name="meeting_type"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={meetingType}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Meeting With"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {

                                                                        // Reset Customer 
                                                                        setCustomerCompanyId(null);
                                                                        setCustomerPolicyId(0);
                                                                        setCustomer([]);
                                                                        // EOC : Reset Customer 

                                                                        field.onChange(event.itemData.value);
                                                                        setMeetingTypeId(event.itemData.value);
                                                                        // Reset Prev Meeting
                                                                        setValue('prev_meeting', null)

                                                                        if (event.itemData.value === 'lead') {

                                                                            props.actionSelectCustomer({ client_type: 'lead' });

                                                                            $("#customer-field").removeClass("col-12 col-md-6 col-lg-6");
                                                                            $("#customer-field").addClass("col-12 col-md-4 col-lg-4");
                                                                            $("#customer-new-button").removeClass("hidden");

                                                                        } else {

                                                                            props.actionSelectCustomer({ client_type: 'client' });

                                                                            $("#customer-field").addClass("col-12 col-md-6 col-lg-6");
                                                                            $("#customer-field").removeClass("col-12 col-md-4 col-lg-4");
                                                                            $("#customer-new-button").addClass("hidden");

                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Meeting With is required'
                                                        }}
                                                    />
                                                    {errors.meeting_type && <ErrorLabel message={errors.meeting_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6" id="customer-field">
                                                <div className="form-group">
                                                    <label className="label-control">Select Customer&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_id"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={customer}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                allowFiltering={true}
                                                                filterType={"Contains"}
                                                                placeholder="Select Customer"
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData?.id);
                                                                        setValue('prev_meeting', null)
                                                                        setCustomerCompanyId(event.itemData?.id)
                                                                        setAssignRMId(event.itemData?.created_by)
                                                                        setCustomerPolicyId(event.itemData?.id)
                                                                        props.actionPreviousMeeting({ client_id: event.itemData?.id });
                                                                        props.actionCustomerMeetingContact({ client_id: event.itemData?.id })
                                                                        props.actionCustomerMeetingPolicy({ client_id: event.itemData?.id })
                                                                    }

                                                                }}

                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Customer is required'
                                                        }}
                                                    />
                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-2 col-lg-2 hidden" id="customer-new-button">
                                                <div className="form-group">
                                                    <label></label>
                                                    <button
                                                        type="button"
                                                        className="btn btn-block btn-primary shadow round text-uppercase font-small-2"
                                                        onClick={(event) => {
                                                            setConvertedToCustomer(false)
                                                            setAddNewCompany(true);
                                                        }}
                                                    > Add New Company</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* {
                                                meetingTypeWatcher === 'client' ? */}
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Meeting Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="business_type"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                dataSource={businessType}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                allowFiltering={true}
                                                                placeholder="Select Meeting Type"
                                                                filterType='contains'
                                                                value={field.value}
                                                                change={(event) => {
                                                                    if (event.itemData.value != null) {
                                                                        setValue('prev_meeting', null)
                                                                        field.onChange(event.itemData.value);
                                                                        setBusinessTypeId(event.itemData.value);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Meeting Type is required'
                                                        }}
                                                    />
                                                    {errors.business_type && <ErrorLabel message={errors.business_type?.message} />}
                                                </div>
                                            </div>
                                            {/* :
                                                    <></>
                                            } */}

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Previous Meeting</label>
                                                    <Controller
                                                        name="prev_meeting"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                dataSource={previousMeeting}
                                                                fields={{ text: 'title', value: 'id' }}
                                                                allowFiltering={false}
                                                                placeholder="Select Previous Meeting"
                                                                value={field.value}
                                                                change={(event) => field.onChange(event.itemData?.id)}
                                                            />
                                                        }
                                                    />
                                                    {errors.prev_meeting && <ErrorLabel message={errors.prev_meeting?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="start_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                min={moment(new Date()).subtract(60, 'days').calendar()}
                                                                displayFormat="dd/MM/yyyy"
                                                                acceptCustomValue={false}
                                                                showClearButton={true}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    if (moment(value).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                                                                        setExistingDate(true);
                                                                    } else {
                                                                        setExistingDate(false);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Start Date is required'
                                                        }}
                                                    />
                                                    {errors.start_date && <ErrorLabel message={errors.start_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">From Time</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        defaultValue={moment().format('HH:mm')}
                                                        id="from_time"
                                                        name="from_time"
                                                        placeholder="--:-- --"
                                                        {...register('from_time', { required: 'From Time is required' })}
                                                    />
                                                    {errors.from_time && <ErrorLabel message={errors.from_time?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                                <div className="form-group">
                                                    <label className="label-control">To Time</label>
                                                    <input
                                                        type="time"
                                                        className="form-control"
                                                        id="to_time"
                                                        name="to_time"
                                                        defaultValue={moment().add(1, 'hours').format('HH:mm')}
                                                        placeholder="--:-- --"
                                                        {...register('to_time', { required: 'To Time is required' })}
                                                    />
                                                    {errors.to_time && <ErrorLabel message={errors.to_time?.message} />}
                                                </div>
                                            </div>

                                            {/* call when select customer value **Contact Details** */}
                                            {
                                                customerCompanyId > 0 ?
                                                    [<div className="col-12 col-md-12 col-lg-12">
                                                        <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                            <span><FiPhone size={16} />&nbsp;Contact Details</span>
                                                            <button
                                                                type="button"
                                                                className="btn"
                                                                onClick={() => {
                                                                    setShowContactDetails(true)
                                                                }}
                                                            >
                                                                <FiPlus
                                                                    cursor="pointer"
                                                                    size={20}
                                                                />
                                                            </button>
                                                        </h4>
                                                    </div>,
                                                    <div className="col-12 col-md-12 col-lg-12 mb-1">
                                                        {
                                                            contactDetails != null && contactDetails.length > 0 ?
                                                                <table className="table table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>Contact Name</th>
                                                                            <th>Contact Designation</th>
                                                                            <th>Contact Phone</th>
                                                                            <th>Contact Email</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            contactDetails.map((contact, indexContact) => (
                                                                                <tr key={contact?.id}>
                                                                                    <th>
                                                                                        <input
                                                                                            type='checkbox'
                                                                                            id={`${contact?.id}`}
                                                                                            name=""
                                                                                            defaultChecked={selectedCompanyContacts.includes(contact?.id) ? true : false}
                                                                                            checked={selectedCompanyContacts.includes(contact?.id) ? true : false}
                                                                                            onChange={(event) => {
                                                                                                var arrSelectedCompanyContact = Object.assign([], selectedCompanyContacts);
                                                                                                if (!arrSelectedCompanyContact.includes(contact?.id)) {
                                                                                                    arrSelectedCompanyContact.push(contact?.id)
                                                                                                    setSelectedCompanyContacts(arrSelectedCompanyContact)
                                                                                                } else {
                                                                                                    var contactIndex = arrSelectedCompanyContact.findIndex((objContact, index) => objContact == contact?.id);
                                                                                                    if (contactIndex >= 0) {
                                                                                                        arrSelectedCompanyContact.splice(contactIndex, 1);
                                                                                                        setSelectedCompanyContacts(arrSelectedCompanyContact);
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                    </th>
                                                                                    <td>{contact.contact_name}</td>
                                                                                    <td>{contact.contact_designation}</td>
                                                                                    <td>{contact.contact_phone}</td>
                                                                                    <td>{contact.contact_email}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <></>
                                                        }
                                                        <ErrorLabel message={customerCompanyId > 0 && contactDetails.length === 0 ? 'No contacts found' : ''} />
                                                    </div>
                                                    ]
                                                    :
                                                    <></>
                                            }

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Meeting Agenda&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="agenda"
                                                        name="agenda"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Meeting Agenda"
                                                        {...register('agenda', { required: 'Meeting Agenda is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{meetingAgendaWatcher.length}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.agenda?.type === 'required' && <ErrorLabel message={errors.agenda?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Meeting Location&nbsp;<span className="danger">*</span></label>
                                                    <textarea
                                                        className="form-control"
                                                        id="location"
                                                        name="location"
                                                        rows={5}
                                                        maxLength={characterLimit}
                                                        placeholder="Enter Meeting Location"
                                                        {...register('location', { required: 'Meeting Location is required' })}
                                                    />
                                                    <div className="d-flex flex-row justify-content-between w-100">
                                                        <div className="flex-column"><small className="text-muted">{`Max ${characterLimit} characters`}</small></div>
                                                        <div className="flex-column"><small>{meetingLocationWatcher.length}/{characterLimit}</small></div>
                                                    </div>
                                                    {errors.location && <ErrorLabel message={errors.location?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <div className="form-group">
                                                    <label className="label-control">Accompanied By</label>
                                                    <Controller
                                                        control={control}
                                                        defaultValue=""
                                                        name="accompained_by"
                                                        render={({ field }) => {
                                                            return (
                                                                <Select
                                                                    {...field}
                                                                    placeholder="Select"
                                                                    menuPlacement={"top"}
                                                                    isMulti={true}
                                                                    hideSelectedOptions={false}
                                                                    maxMenuHeight={200}
                                                                    name="name"
                                                                    options={accompainedBy}
                                                                    value={field.value}
                                                                    onChange={(selectedOptions) => {
                                                                        field.onChange(selectedOptions);
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {errors.accompained_by && <ErrorLabel message={errors.accompained_by?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <fieldset class="form-group">
                                                        <label for="basicInputFile">Upload Document</label>
                                                        <div class="custom-file">
                                                            <input type="file"
                                                                class="custom-file-input"
                                                                id="upload_document"
                                                                name="upload_document"
                                                                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xlsx, .xls, .ppt, .pptx"
                                                                onChange={(event) => {
                                                                    if (
                                                                        event.target.files
                                                                    ) {

                                                                        var fileSize = event.target.files[0]?.size;

                                                                        var ext = (event.target.files[0]?.name).split('.').pop();

                                                                        if (checkFileExtension(ext, validExt)) {

                                                                            if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                                setUploadDocument(event.target.files[0]);
                                                                            } else {
                                                                                Swal.fire({
                                                                                    title: 'Error',
                                                                                    icon: 'error',
                                                                                    html: MEETING_FILE_ERROR_MESSAGE,
                                                                                    confirmButtonColor: PRIMARY_COLOR
                                                                                }).then((result) => {

                                                                                });
                                                                            }
                                                                        } else {

                                                                            Swal.fire({
                                                                                title: 'Upload Error',
                                                                                text: 'You can only upload jpg, jpeg, png, pdf, doc, docx, xlsx, xls, ppt & pptx files',
                                                                                icon: 'error'
                                                                            }).then(success => {

                                                                            })

                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                            <label class="custom-file-label" for="upload_document">
                                                                {
                                                                    uploadDocument != null ?
                                                                        uploadDocument.name : "Choose file"
                                                                }
                                                            </label>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            {
                                                existingDate === true ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Status</label>
                                                            <Controller
                                                                name="status"
                                                                control={control}

                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        dataSource={
                                                                            [
                                                                                {
                                                                                    name: 'None of Above',
                                                                                    id: 'open'
                                                                                },
                                                                                {
                                                                                    name: 'Cancel',
                                                                                    id: 'cancel'
                                                                                },
                                                                                {
                                                                                    name: 'Close',
                                                                                    id: 'close'
                                                                                }
                                                                            ]
                                                                        }
                                                                        fields={{ text: 'name', value: 'id' }}
                                                                        placeholder="Select status"
                                                                        value={status}
                                                                        enabled={false}
                                                                        change={(event) => {

                                                                            if (event.itemData != null) {

                                                                                field.onChange(event.itemData?.id)
                                                                                setStatus(event.itemData?.id)

                                                                                if (event.itemData?.id === 'cancel') {
                                                                                    setNextMeeting(false)
                                                                                }

                                                                                if (event.itemData?.id !== 'close') {
                                                                                    setValue('minutes_of_meeting', '');
                                                                                    setValue('remark', '');
                                                                                    // setValue('decision_maker_status', '');
                                                                                    // setValue('meet_insurer_company', '');
                                                                                    // setValue('decision_maker', '');
                                                                                    // setValue('insurer_id', null);
                                                                                    // setValue('is_pinc_policy', '');
                                                                                    setValue('converted_to', '');
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                            {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }

                                            {/* call when status close **Status** */}
                                            {
                                                existingDate === true && status === 'close' ?
                                                    [<div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control ">Minutes Of Meeting&nbsp;<span className="danger">*</span></label>
                                                            <textarea
                                                                className="form-control"
                                                                id="minutes_of_meeting"
                                                                name="minutes_of_meeting"
                                                                placeholder="Enter Minutes Of Meeting"
                                                                maxLength={characterLimit}
                                                                rows={5}
                                                                {...register('minutes_of_meeting', { required: 'Minutes of meeting is required', minLength: customerMinCharacters })}
                                                            />
                                                            <div className="d-flex flex-row justify-content-between w-100">
                                                                <div className="flex-column"><small className="text-muted">{`Min ${customerMeetingCharacterLimit} characters  (Minutes Visible to Customer)`}</small></div>
                                                                <div className="flex-column"><small>{watchMinutesOfMeeting != null && watchMinutesOfMeeting != undefined ? watchMinutesOfMeeting?.length : 0}/{characterLimit}</small></div>
                                                            </div>
                                                            {errors.minutes_of_meeting?.type === 'required' && <ErrorLabel message={errors.minutes_of_meeting?.message} />}
                                                            {errors.minutes_of_meeting?.type === 'minLength' && <ErrorLabel message={'Minimum Length 50 is required'} />}
                                                        </div>
                                                    </div>,
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control ">Remarks</label>
                                                            <textarea
                                                                className="form-control"
                                                                id="remark"
                                                                name="remark"
                                                                placeholder="Enter Remarks"
                                                                rows={5}
                                                                {...register('remark')}
                                                            />
                                                            {errors.remark && <ErrorLabel message={errors.remark?.message} />}
                                                        </div>
                                                    </div>,
                                                    // <div className="col-12 col-md-12 col-lg-12 mb-1">
                                                    //     <label>Whether met with Insurance Company ?</label>
                                                    //     <div className="row mt-half mb-half">
                                                    //         <div className="radio ml-1 mr-1">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={1}
                                                    //                     name="meet_insurer_company"
                                                    //                     {...register('meet_insurer_company')}
                                                    //                 />
                                                    //                 Yes
                                                    //             </label>
                                                    //         </div>
                                                    //         <div className="radio">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={0}
                                                    //                     name="meet_insurer_company"
                                                    //                     {...register('meet_insurer_company')}
                                                    //                 />
                                                    //                 No
                                                    //             </label>
                                                    //         </div>
                                                    //     </div>
                                                    // </div>,
                                                    // <>
                                                    //     {
                                                    //         watchMeetInsurerCompany !== undefined && Number(watchMeetInsurerCompany) === 1 ?
                                                    //             <div className="col-12 col-md-6 col-lg-6">
                                                    //                 <div className="form-group">
                                                    //                     <label className="label-control">Insurance Company</label>
                                                    //                     <Controller
                                                    //                         name="insurer_id"
                                                    //                         control={control}

                                                    //                         render={({ field }) =>
                                                    //                             <DropDownListComponent
                                                    //                                 {...field}
                                                    //                                 dataSource={masterInsuranceCompanyName}
                                                    //                                 fields={{ text: 'name', value: 'id' }}
                                                    //                                 allowFiltering={true}
                                                    //                                 placeholder="Select Insurance Company"
                                                    //                                 value={field.value}
                                                    //                                 change={(event) => {
                                                    //                                     if (event.itemData != null) {
                                                    //                                         field.onChange(event.itemData.id)
                                                    //                                     }
                                                    //                                 }}
                                                    //                             />
                                                    //                         }
                                                    //                     />
                                                    //                     {errors.insurer_id && <ErrorLabel message={errors.insurer_id?.message} />}
                                                    //                 </div>
                                                    //             </div>
                                                    //             :
                                                    //             <></>
                                                    //     }
                                                    // </>
                                                    //     ,
                                                    // <>
                                                    //     {
                                                    //         watchMeetInsurerCompany !== undefined && Number(watchMeetInsurerCompany) === 1 ?
                                                    //             <div className="col-12 col-md-6 col-lg-6">
                                                    //                 <div className="form-group">
                                                    //                     <label className="label-control ">Quote</label>
                                                    //                     <input
                                                    //                         className="form-control"
                                                    //                         id="quote"
                                                    //                         name="quote"
                                                    //                         placeholder="Enter quote"
                                                    //                         {...register('quote')}
                                                    //                     />
                                                    //                     {errors.quote && <ErrorLabel message={errors.quote?.message} />}
                                                    //                 </div>
                                                    //             </div>
                                                    //             :
                                                    //             <></>
                                                    //     }
                                                    // </>
                                                    //     ,
                                                    // <div className="col-12 col-md-6 col-lg-6">
                                                    //     <label>Whether RM has met decision maker ?</label>
                                                    //     <div className="row mt-half mb-half">
                                                    //         <div className="radio ml-1 mr-1">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={1}
                                                    //                     name="decision_maker_status"
                                                    //                     {...register('decision_maker_status')}
                                                    //                 />Yes </label>
                                                    //         </div>
                                                    //         <div className="radio">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={0}
                                                    //                     name="decision_maker_status"
                                                    //                     {...register('decision_maker_status')}
                                                    //                 />No</label>
                                                    //         </div>
                                                    //     </div>
                                                    // </div>
                                                    //     ,
                                                    // <div className="col-12 col-md-6 col-lg-6">
                                                    //     {
                                                    //         watchDecisionMakerStatus !== undefined && Number(watchDecisionMakerStatus) === 1 ?
                                                    //             <div className="form-group">
                                                    //                 <label className="label-control">Decision Maker Name</label>
                                                    //                 <Controller
                                                    //                     name="decision_maker"
                                                    //                     control={control}

                                                    //                     render={({ field }) =>
                                                    //                         <DropDownListComponent
                                                    //                             {...field}
                                                    //                             dataSource={contactDetails}
                                                    //                             fields={{ text: 'title', value: 'id' }}
                                                    //                             allowFiltering={true}
                                                    //                             placeholder="Select Decision Maker Name"
                                                    //                             value={field.value}
                                                    //                             change={(event) => {
                                                    //                                 if (event.itemData.id != null && event.itemData.id != undefined) {
                                                    //                                     field.onChange(event.itemData.id)
                                                    //                                 }
                                                    //                             }
                                                    //                             }
                                                    //                         />
                                                    //                     }
                                                    //                 />
                                                    //                 {errors.decision_maker && <ErrorLabel message={errors.decision_maker?.message} />}
                                                    //             </div>
                                                    //             :
                                                    //             <></>
                                                    //     }
                                                    // </div>
                                                    //     ,

                                                    <>
                                                        {
                                                            clientType === 'lead' & meetingTypeId === 'lead' ?
                                                                <div className="col-12 col-md-6 col-lg-6">
                                                                    <div className="form-group">
                                                                        <label className="label-control">Converted To</label>
                                                                        <Controller
                                                                            name="converted_to"
                                                                            control={control}
                                                                            render={({ field }) =>
                                                                                <DropDownListComponent
                                                                                    {...field}
                                                                                    dataSource={[
                                                                                        {
                                                                                            name: 'None of Above',
                                                                                            id: 'None of Above'
                                                                                        },
                                                                                        {
                                                                                            name: 'Customer',
                                                                                            id: 'client'
                                                                                        },
                                                                                        {
                                                                                            name: 'Lead',
                                                                                            id: 'lead'
                                                                                        }
                                                                                    ]}
                                                                                    fields={{ text: 'name', value: 'id' }}
                                                                                    placeholder="Select Converted To"
                                                                                    value={field.value}
                                                                                    change={(event) => {
                                                                                        if (event.itemData != null) {
                                                                                            setConvertedToCustomer(true)
                                                                                            if (event.itemData?.id === 'client') {
                                                                                                setAddNewCompany(true);
                                                                                                // setMeetingTypeId(event.itemData.id);
                                                                                            }
                                                                                            field.onChange(event.itemData?.id)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            }
                                                                        />
                                                                        {errors.converted_to && <ErrorLabel message={errors.converted_to?.message} />}
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </>
                                                        ,
                                                    // <div className="col-12 col-md-6 col-lg-6">
                                                    //     <label>Is Policy placed with PINC ?</label>
                                                    //     <div className="row mt-half mb-half">
                                                    //         <div className="radio ml-1 mr-1">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={1}
                                                    //                     name="is_pinc_policy"
                                                    //                     {...register('is_pinc_policy')}
                                                    //                 />
                                                    //                 Yes
                                                    //             </label>
                                                    //         </div>
                                                    //         <div className="radio">
                                                    //             <label>
                                                    //                 <input
                                                    //                     type="radio"
                                                    //                     className="status_group mr-half"
                                                    //                     value={0}
                                                    //                     name="is_pinc_policy"
                                                    //                     {...register('is_pinc_policy')}
                                                    //                 />
                                                    //                 No
                                                    //             </label>
                                                    //         </div>
                                                    //     </div>
                                                    // </div>,
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="form-group">
                                                            <div class="custom-control custom-checkbox">
                                                                <input
                                                                    type="checkbox"
                                                                    class="custom-control-input"
                                                                    name="next_meeting"
                                                                    id="next_meeting"
                                                                    {...register('next_meeting')}
                                                                    onChange={(event) => { setNextMeeting(event.target.checked); }}
                                                                />
                                                                {errors.next_meeting && <ErrorLabel message={errors.next_meeting?.message} />}
                                                                <label class="custom-control-label" for="next_meeting">Create Next Meeting</label>
                                                            </div>
                                                        </div>
                                                    </div>]
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>

                                    {/* call when **Create Next Meeting** checkBox click */}
                                    {
                                        nextMeeting ?
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12">
                                                    <h6 className="mb-2 font-medium-1 text-bold-600">Next Meeting Details</h6>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">Start Date&nbsp;<span className="danger">*</span></label>
                                                        <Controller
                                                            name="start_date_time"
                                                            className="form-control"
                                                            control={control}

                                                            render={({ field }) =>
                                                                <DateBox
                                                                    defaultValue={new Date()}
                                                                    calendarOptions={{
                                                                        showTodayButton: true,
                                                                    }}
                                                                    stylingMode="underlined"
                                                                    className="form-control"
                                                                    pickerType="calendar"
                                                                    type="date"
                                                                    displayFormat="dd/MM/yyyy"
                                                                    min={dateFormat(new Date)}
                                                                    showClearButton={true}
                                                                    acceptCustomValue={false}
                                                                    value={field.value}
                                                                    onValueChange={(value) => {
                                                                        field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                    }}
                                                                />
                                                            }
                                                            rules={{
                                                                required: 'Start Date is required'
                                                            }}
                                                        />
                                                        {errors.start_date_time && <ErrorLabel message={errors.start_date_time?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">From Time</label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            id="next_from_time"
                                                            name="next_from_time"
                                                            placeholder="--:-- --"
                                                            {...register('next_from_time', { required: 'From Time is required' })}
                                                        />
                                                        {errors.next_from_time && <ErrorLabel message={errors.next_from_time?.message} />}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg">
                                                    <div className="form-group">
                                                        <label className="label-control">To Time</label>
                                                        <input
                                                            type="time"
                                                            className="form-control"
                                                            id="next_to_time"
                                                            name="next_to_time"
                                                            placeholder="--:-- --"
                                                            {...register('next_to_time', { required: 'To Time is required' })}
                                                        />
                                                        {errors.next_to_time && <ErrorLabel message={errors.next_to_time?.message} />}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <></>
                                    }

                                    {/* call when select customer value **Proposal Details** */}
                                    {
                                        customerPolicyId > 0 ?
                                            [<div className="col-12 col-md-12 col-lg-12 px-0">
                                                <h4 className="d-flex align-items-center justify-content-between form-section mb-2 black">
                                                    <span><FiShield size={16} />&nbsp;Opportunity Details</span>
                                                    <button
                                                        type="button"
                                                        className="btn"
                                                        onClick={() => {
                                                            setEditPolicyIndex(-1)
                                                            setShowPolicyDetails(true)
                                                        }}
                                                    >
                                                        <FiPlus
                                                            cursor="pointer"
                                                            size={20}
                                                        />
                                                    </button>
                                                </h4>
                                            </div>,
                                            <div className="col-12 col-md-12 col-lg-12 mb-1 px-0">
                                                {
                                                    policyDetails != null && policyDetails.length > 0 ?
                                                        <>
                                                            <table className="table table-striped table-bordered table-responsive">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Action</th>
                                                                        <th>Relevant</th>
                                                                        <th>Opportunity Type</th>
                                                                        <th>Policy Type</th>
                                                                        <th>Department</th>
                                                                        <th>Intermediary Type</th>
                                                                        <th>Intermediary Details/Broker Name</th>
                                                                        <th>Insurance Company</th>
                                                                        <th>Next Renewal on</th>
                                                                        {/* <th>Brokerage Amount</th> */}
                                                                        <th>Expected Premium</th>
                                                                        <th>Probability(%)</th>
                                                                        <th>Status</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        policyDetails.map((policy, indexPolicy) => (
                                                                            <tr key={policy?.id}>
                                                                                <td>
                                                                                    <button
                                                                                        type='button'
                                                                                        className='btn btn-icon'
                                                                                        onClick={() => {
                                                                                            setEditPolicyIndex(indexPolicy)
                                                                                            setShowProposalDetails(true);
                                                                                        }}
                                                                                    >
                                                                                        <FiEye size={16} className="primary" />
                                                                                    </button>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-icon"
                                                                                        onClick={() => {
                                                                                            setEditPolicyIndex(indexPolicy)
                                                                                            setShowPolicyDetails(true)
                                                                                        }}
                                                                                    >
                                                                                        <FiEdit size={16} className="primary" />
                                                                                    </button>
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type='checkbox'
                                                                                        id={`${policy?.id}`}
                                                                                        name=""
                                                                                        defaultChecked={selectedCompanyPolicy.includes(policy?.id) ? true : false}
                                                                                        checked={selectedCompanyPolicy.includes(policy?.id) ? true : false}
                                                                                        onChange={(event) => {
                                                                                            var arrSelectedCompanyPolicy = Object.assign([], selectedCompanyPolicy)
                                                                                            if (event.target.checked) {
                                                                                                if (!arrSelectedCompanyPolicy.includes(policy?.id)) {
                                                                                                    arrSelectedCompanyPolicy.push(policy?.id)
                                                                                                    setSelectedCompanyPolicy(arrSelectedCompanyPolicy)
                                                                                                }
                                                                                            } else {
                                                                                                var indexPolicy = arrSelectedCompanyPolicy.find((objPolicy, index) => objPolicy?.id === policy?.id)
                                                                                                if (indexPolicy != null || indexPolicy !== undefined) {
                                                                                                    arrSelectedCompanyPolicy.push(policy?.id)
                                                                                                    setSelectedCompanyPolicy(arrSelectedCompanyPolicy)
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </td>
                                                                                <td>{policy.oppurtunity_type}</td>
                                                                                <td>{policy.policy_type}</td>
                                                                                <td>{policy.department_name}</td>
                                                                                <td>{policy.intermediary_type}</td>
                                                                                <td>{policy.intermediary_value}</td>
                                                                                <td>{policy.insurer_name}</td>
                                                                                <td>{(policy.lead_expiry_date != null && policy.lead_expiry_date != '0000-00-00 00:00:00') ? moment(policy.lead_expiry_date).format('MMM, DD YYYY') : ''}</td>
                                                                                {/* <td>{policy.brokerage_amnt != null ? <span>&#8377;&nbsp;{PRICE_FORMAT(policy.brokerage_amnt)}</span> : ''}</td> */}
                                                                                <td>{policy.expected_premium != null ? <span>&#8377;&nbsp;{PRICE_FORMAT(policy.expected_premium)}</span> : ''}</td>
                                                                                <td>{policy.probability}</td>
                                                                                <td>{policy.status}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            {/* <DataGrid
                                                                dataSource={policyDetails}
                                                                showBorders={true}
                                                                onToolbarPreparing={(e) => {
                                                                    e.toolbarOptions.items.unshift({
                                                                        location: 'before'

                                                                    })
                                                                }}
                                                            >
                                                                <CheckBox value={checkBoxValue.checkBoxValue} onValueChanged={onValueChanged} />
                                                                {renderColumns()}
                                                            </DataGrid> */}
                                                        </>
                                                        :
                                                        <></>
                                                }
                                                <ErrorLabel message={customerPolicyId > 0 && policyDetails.length === 0 ? 'No Opportunity found' : ''} />
                                            </div>
                                            ]
                                            :
                                            <></>
                                    }
                                    <div className="form-actions border-0 float-none">
                                        <button
                                            id="add_customer"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >
                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="add_customer-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        > Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <AddNewCompany
                    show={addNewCompany}
                    createClient={meetingTypeId != 'lead' ? true : false}
                    clientId={newCustomerId != null ? newCustomerId : customerCompanyId != null ? customerCompanyId : null}
                    convertedToCustomer={convertedToCustomer}
                    handleClose={(newCompany) => {
                        try {
                            if (newCompany != null) {
                                var newCompanyDetail = Object.assign([], customer);
                                var temp = [...newCompanyDetail, { id: newCompany?.id, name: newCompany.name }];
                                setCustomer(temp);
                                setValue('client_id', newCompany?.id);
                                setNewCustomerId(newCompany?.id);
                                setDetails(newCompany)
                            }
                        } catch (error) {
                            console.log(error);
                        }
                        setAddNewCompany(false);
                    }
                    }
                />
                <ContactDetails
                    show={showContactDetails}
                    handleClose={(newContact) => {
                        if (newContact != null) {
                            props.actionCustomerMeetingAddContact({
                                client_id: customerCompanyId,
                                client_type: "client",
                                contact_designation: newContact.contact_designation,
                                contact_email: newContact.contact_email,
                                contact_name: newContact.contact_name,
                                contact_phone: newContact.contact_phone,
                            });
                        }
                        setShowContactDetails(false)
                    }}
                />
                <PolicyDetailModal
                    show={showPolicyDetails}
                    assignRMId={assignRMId}
                    handleClose={(newPolicy, stateRfq) => {
                        if (newPolicy != null) {
                            if (newPolicy.hasOwnProperty('id')) {
                                props.actionEditPolicy({
                                    id: newPolicy?.id,
                                    client_id: customerCompanyId,
                                    department_id: newPolicy.department_id,
                                    insurer_id: newPolicy.insurer_id,
                                    brokerage_amnt: newPolicy.brokerage_amnt,
                                    product_specialist_id: newPolicy.product_specialist_id,
                                    expected_premium: newPolicy.expected_premium,
                                    expiring_date: newPolicy.expiring_date,
                                    probability: newPolicy.probability,
                                    policy_type_id: newPolicy.policy_type_id,
                                    client_remark: newPolicy.client_remark,
                                    is_product_specialist_required: newPolicy.is_product_specialist_required,
                                    opportunity_questions: newPolicy.opportunity_questions,
                                    oppurtunity_type: newPolicy.oppurtunity_type,
                                    intermediary_type: newPolicy.intermediary_type,
                                    intermediary_value: newPolicy.intermediary_value,
                                    posted_from: "pincnetwork",
                                    ...(stateRfq === true && { send_for_rfq: true }),
                                    broker_ids:newPolicy.broker_ids,
                                    sub_product:  newPolicy.sub_product ,
                                    line_of_business : newPolicy?.line_of_business,
                                    employer_employee_relationship:newPolicy.employer_employee_relationship,
                                    no_of_employees:newPolicy.no_of_employees,
                                    no_of_dependants:newPolicy.no_of_dependants,
                                    total_lives:newPolicy.total_lives,
                                    policy_for_short_period :newPolicy.oppurtunity_type != 'Fresh' ? newPolicy?.policy_for_short_period: '',
                                    start_date :newPolicy?.start_date!=null && newPolicy.oppurtunity_type != 'Fresh' ? newPolicy?.start_date:null,    
                                    insurer_ids: newPolicy.insurer_ids,
                                })
                            } else {
                                props.actionAddPolicy({
                                    id: newPolicy?.id,
                                    client_id: customerCompanyId,
                                    department_id: newPolicy.department_id,
                                    insurer_id: newPolicy.insurer_id,
                                    brokerage_amnt: newPolicy.brokerage_amnt,
                                    product_specialist_id: newPolicy.product_specialist_id,
                                    broker: newPolicy.broker,
                                    expected_premium: newPolicy.expected_premium,
                                    expiring_date: newPolicy.expiring_date,
                                    probability: newPolicy.probability,
                                    policy_type_id: newPolicy.policy_type_id,
                                    client_remark: newPolicy.client_remark,
                                    is_product_specialist_required: newPolicy.is_product_specialist_required,
                                    opportunity_questions: newPolicy.opportunity_questions,
                                    oppurtunity_type: newPolicy.oppurtunity_type,
                                    intermediary_type: newPolicy.intermediary_type,
                                    intermediary_value: newPolicy.intermediary_value,
                                    posted_from: "pincnetwork",
                                    ...(stateRfq === true && { send_for_rfq: true }),
                                    broker_ids:newPolicy.broker_ids,
                                    sub_product:  newPolicy.sub_product ,
                                    line_of_business : newPolicy?.line_of_business,
                                    employer_employee_relationship:newPolicy.employer_employee_relationship,
                                    no_of_employees:newPolicy.no_of_employees,
                                    no_of_dependants:newPolicy.no_of_dependants,
                                    total_lives:newPolicy.total_lives,
                                    policy_for_short_period :newPolicy.oppurtunity_type != 'Fresh' ? newPolicy?.policy_for_short_period: '',
                                    start_date :newPolicy?.start_date!=null && newPolicy.oppurtunity_type != 'Fresh' ? newPolicy?.start_date:null,     
                                    insurer_ids: newPolicy.insurer_ids,
                                })
                            }
                        }
                        setShowPolicyDetails(false)
                    }}
                    data={editPolicyIndex > -1 ? policyDetails[editPolicyIndex] : null}
                />
                <ProposalDetailModal
                    {...props}
                    show={showProposalDetails}
                    proposalDetails={editPolicyIndex > -1 ? policyDetails[editPolicyIndex] : null}
                    handleClose={() => {
                        setShowProposalDetails(false);
                    }}
                />
            </section>
        </>
    )
}
const mapStateToProps = (state) => {

    var MeetingType = null;
    var Customers = null;
    var BusinessType = null;
    var PreviousMeeting = null;
    var AccompainedBy = null;
    var ClientDetails = null;
    var MeetingContact = null;
    var MeetingPolicy = null;
    var AddPolicy = null;
    var EditPolicy = null;
    var NewContact = null;
    var MasterInsuranceCompanyName = null;
    var CustomerMeetingSave = null;
    var CustomerMeetingNext = null;

    if (
        state.AddCustomerMeetingReducer != null &&
        Object.keys(state.AddCustomerMeetingReducer).length > 0 &&
        state.AddCustomerMeetingReducer.constructor === Object
    ) {
        switch (state.AddCustomerMeetingReducer.tag) {
            case ACTION_API_MEETING_TYPE_SUCCESS:
                MeetingType = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { MeetingType };
            case ACTION_SELECT_CUSTOMER_SUCCESS:
                Customers = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { Customers };
            case ACTION_API_BUSINESS_TYPE_SUCCESS:
                BusinessType = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { BusinessType };
            case ACTION_API_PREVIOUS_MEETING_SUCCESS:
                PreviousMeeting = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { PreviousMeeting };
            case ACTION_API_ACCOMPAINED_BY_SUCCESS:
                AccompainedBy = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { AccompainedBy };
            case ACTION_CUSTOMER_MEETING_CONTACT_SUCCESS:
                MeetingContact = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { MeetingContact };
            case ACTION_CUSTOMER_MEETING_POLICY_SUCCESS:
                MeetingPolicy = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { MeetingPolicy };
            case ACTION_ADDPOLICY_SUCCESS:
                AddPolicy = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { AddPolicy };
            case ACTION_EDIT_POLICY_SUCCESS:
                EditPolicy = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { EditPolicy };
            case ACTION_CUSTOMER_MEETING_ADD_CONTACT_SUCCESS:
                NewContact = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { NewContact }
            case ACTION_MASTER_INSURANCE_COMPANY_NAME_SUCCESS:
                MasterInsuranceCompanyName = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { MasterInsuranceCompanyName }
            case ACTION_CUSTOMER_MEETING_SAVE_SUCCESS:
                CustomerMeetingSave = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { CustomerMeetingSave }
            case ACTION_CUSTOMER_MEETING_NEXT_SUCCESS:
                CustomerMeetingNext = Object.assign({}, state.AddCustomerMeetingReducer.data);
                delete state.AddCustomerMeetingReducer;
                return { CustomerMeetingNext }
            default:
        }
    }
    return {
        MeetingType,
        Customers,
        BusinessType,
        PreviousMeeting,
        AccompainedBy,
        ClientDetails,
        MeetingContact,
        MeetingPolicy,
        AddPolicy,
        EditPolicy,
        NewContact,
        MasterInsuranceCompanyName,
        CustomerMeetingSave,
        CustomerMeetingNext
    };
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionMeetingType,
        actionSelectCustomer,
        actionBusinessType,
        actionPreviousMeeting,
        actionAccompainedBy,
        actionCustomerMeetingContact,
        actionCustomerMeetingPolicy,
        actionAddPolicy,
        actionEditPolicy,
        actionCustomerMeetingAddContact,
        actionMasterInsuranceCompanyName,
        actionCustomerMeetingSave,
        actionCustomerMeetingNext
    }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(CustomerMeetingAdd))
