import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import { useForm, Controller } from "react-hook-form";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dateFormat from "dateformat";
import { UNAUTHORIZED } from "../../../../../constants";
import $ from "jquery";
import _ from "lodash";
import { getMonthNumber } from "../../../../../utils";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { actionTravelCalendar, ACTION_CALENDAR_TRAVEL_SUCCESS } from "./action";
import { actionTravelDelete, ACTION_TRAVEL_DELETE_SUCCESS } from "./action";
import {
  actionTravelRICalendarList,
  ACTION_TRAVEL_RI_CALENDAR_LIST_SUCCESS,
} from "./action";
import {
  actionTravelRICalendarEvent,
  ACTION_TRAVEL_RI_CALENDAR_EVENT_SUCCESS,
} from "./action";
import {
  actionTravelDirectCalendarList,
  ACTION_TRAVEL_DIRECT_CALENDAR_LIST_SUCCESS,
} from "./action";
import {
  actionTravelDirectCalendarEvent,
  ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_SUCCESS,
} from "./action";
import {
  actionTravelTabPermissions,
  ACTION_TRAVEL_TAB_PERMISSION_SUCCESS,
} from "./action";

import DataGrid, {
  Button,
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";

const TravelCalendarView = props => {
  const { match } = props;
  const { control } = useForm({});

  const date = dateFormat(new Date(), {
    month: "long",
    year: "numeric",
    day: "numeric",
  });

  const [listView, setListView] = useState(false);
  const [travelCalendar, setTravelCalendar] = useState([]);
  const [rICalenderCount, setRICalenderCount] = useState(0);
  const [rICalender, setRICalender] = useState(true);
  const [directCalenderCount, setDirectCalenderCount] = useState(0);
  const [directCalender, setDirectCalender] = useState(false);
  const [rIEvents, setRIEvents] = useState([]);
  const [directEvents, setDirectEvents] = useState([]);
  const [rIEventDetails, setRIEventDetails] = useState([]);
  const [directEventDetails, setDirectEventDetails] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedRIDate, setSelectedRIDate] = useState(null);
  const [timeGridDay, setTimeGridDay] = useState(false);
  const [selectedButton, setSelectedButton] = useState("RI_CALENDER");
  const [tabPermission, setTabPermission] = useState();

  const initialRender = () => {
    $("#progress-loader").removeClass("hidden");
    props.actionTravelTabPermissions();
    if (props.location?.state?.from === "listView") {
      setDirectCalender(false);
      setListView(true);
      setRICalender(false);
      props.actionTravelCalendar();
    } else {
      setRICalender(true);
      setSelectedDate(dateFormat("yyyy-mm-dd"));
      setSelectedButton("RI_CALENDER");
      props.actionTravelRICalendarEvent({
        type: "reinsurance",
        date: dateFormat("yyyy-mm-dd"),
      });
    }

    setTimeout(() => {
      var strDate = $(".fc-toolbar-title").html();
      var arrDate = _.split(strDate, " ");
      props.actionTravelRICalendarList({
        type: "reinsurance",
        month: getMonthNumber(arrDate[0], "string"),
        year: arrDate[1],
      });
    }, 1200);

    $(document).on("click", ".fc-next-button", function () {
      var strDate = $(".fc-toolbar-title").html();
      var arrDate = _.split(strDate, " ");
      setTimeGridDay(false);
      if ($("#selectedButton").val() === "RI_CALENDER") {
        props.actionTravelRICalendarList({
          type: "reinsurance",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      } else {
        props.actionTravelDirectCalendarList({
          type: "others",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      }
    });

    $(document).on("click", ".fc-button-group", function () {
      var strDate = $(".fc-toolbar-title").html();
      var arrDate = _.split(strDate, " ");
      setTimeGridDay(false);
      if ($("#selectedButton").val() === "RI_CALENDER") {
        setTimeout(() => {
          var strDate = $(".fc-toolbar-title").html();
          var arrDate = _.split(strDate, " ");
          props.actionTravelRICalendarList({
            type: "reinsurance",
            month: getMonthNumber(arrDate[0], "string"),
            year: arrDate[1],
          });
        }, 1200);
      } else {
        setTimeout(() => {
          props.actionTravelDirectCalendarList({
            type: "others",
            month: getMonthNumber(arrDate[0], "string"),
            year: arrDate[1],
          });
        }, 1200);
      }
    });

    $(document).on("click", ".fc-prev-button", function () {
      var strDate = $(".fc-toolbar-title").html();
      var arrDate = _.split(strDate, " ");
      setTimeGridDay(false);
      if ($("#selectedButton").val() === "RI_CALENDER") {
        props.actionTravelRICalendarList({
          type: "reinsurance",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      } else {
        props.actionTravelDirectCalendarList({
          type: "others",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      }
    });

    $(document).on("click", ".fc-timeGridWeek-button", function () {
      var strDate = $(".fc-toolbar-title").html();
      var arrDate = _.split(strDate, " ");
      setTimeGridDay(false);
      if ($("#selectedButton").val() === "RI_CALENDER") {
        props.actionTravelRICalendarList({
          type: "reinsurance",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      } else {
        props.actionTravelDirectCalendarList({
          type: "others",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      }
    });

    $(document).on("click", ".fc-dayGridMonth-button", function () {
      setTimeGridDay(false);
    });

    $(document).on("click", ".fc-timeGridDay-button", function () {
      var strDate = $(".fc-toolbar-title").html();
      var arrDate = _.split(strDate, " ");
      setTimeGridDay(true);
      if ($("#selectedButton").val() === "RI_CALENDER") {
        props.actionTravelRICalendarList({
          type: "reinsurance",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      } else {
        props.actionTravelDirectCalendarList({
          type: "others",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      }
    });
  };
  useEffect(initialRender, []);

  const [columns, setColumns] = useState([
    { dataField: "Action", caption: "Action", type: "button" },
    {
      dataField: "travel_rm_details.full_name",
      caption: "Name",
      visible: true,
    },
    { dataField: "travel_to", caption: "Travel To", visible: true },
    { dataField: "start_date", caption: "Start Date", visible: true },
    { dataField: "end_date", caption: "End Date", visible: true },
    { dataField: "accompanied_by", caption: "Accompanied By", visible: true },
    { dataField: "status", caption: "Status", visible: true },
  ]);

  const onTravelTabPermissionUpdate = () => {
    const { TravelTabPermission } = props;
    var TravelTabPermissionResponse = Object.assign({}, TravelTabPermission);
    if (TravelTabPermissionResponse.result === true) {
      $("#progress-loader").addClass("hidden");

      setTabPermission(TravelTabPermissionResponse.response);
    } else {
      $("#progress-loader").addClass("hidden");
      switch (TravelTabPermissionResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onTravelTabPermissionUpdate, [props.TravelTabPermission]);

  useEffect(() => {
    if (
      tabPermission &&
      tabPermission?.ri_calendar != 1 &&
      tabPermission?.direct_calendar != 1
    ) {
      setListView(true);
      setRICalender(false);
      setDirectCalender(false);
      props.actionTravelCalendar();
    } else if (
      tabPermission &&
      tabPermission?.direct_calendar &&
      !tabPermission?.ri_calendar
    ) {
      $("#progress-loader").removeClass("hidden");
      setDirectCalender(true);
      setListView(false);
      setRICalender(false);
      setRIEvents([]);
      setRIEventDetails([]);
      setTimeGridDay(false);
      setSelectedButton("DIRECT_CALENDER");
      setSelectedRIDate(dateFormat("yyyy-mm-dd"));
      setTimeout(() => {
        props.actionTravelDirectCalendarEvent({
          type: "others",
          date: dateFormat("yyyy-mm-dd"),
        });
        var strDate = $(".fc-toolbar-title").html();
        var arrDate = _.split(strDate, " ");
        props.actionTravelDirectCalendarList({
          type: "others",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      }, 1200);

      $(document).on("click", ".fc-prev-button", function () {
        var strDate = $(".fc-toolbar-title").html();
        var arrDate = _.split(strDate, " ");
        props.actionTravelDirectCalendarList({
          type: "others",
          month: getMonthNumber(arrDate[0], "string"),
          year: arrDate[1],
        });
      });
    }
  }, [tabPermission]);

  const onTravelCalendarRIEventUpdate = () => {
    const { TravelCalendarRIEvent } = props;
    var TravelCalendarRIEventResponse = Object.assign(
      {},
      TravelCalendarRIEvent
    );
    if (TravelCalendarRIEventResponse.result === true) {
      $("#progress-loader").addClass("hidden");
      // alert(rIEventDetails);
      setRIEventDetails(TravelCalendarRIEventResponse.response);
    } else {
      $("#progress-loader").addClass("hidden");
      switch (TravelCalendarRIEventResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onTravelCalendarRIEventUpdate, [props.TravelCalendarRIEvent]);

  const onTravelCalendarDirectEventUpdate = () => {
    const { TravelCalendarDirectEvent } = props;
    var TravelCalendarDirectEventResponse = Object.assign(
      {},
      TravelCalendarDirectEvent
    );
    if (TravelCalendarDirectEventResponse.result === true) {
      $("#progress-loader").addClass("hidden");
      setDirectEventDetails(TravelCalendarDirectEventResponse.response);
    } else {
      $("#progress-loader").addClass("hidden");
      switch (TravelCalendarDirectEventResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onTravelCalendarDirectEventUpdate, [
    props.TravelCalendarDirectEvent,
  ]);

  const onTravelCalendarRIListUpdate = () => {
    const { TravelCalendarRIList } = props;
    var TravelCalendarRIListResponse = Object.assign({}, TravelCalendarRIList);
    if (TravelCalendarRIListResponse.result === true) {
      $("#progress-loader").addClass("hidden");
      const formattedEvents = TravelCalendarRIListResponse.response.map(
        event => ({
          ...event,
          start: new Date(event.event_date).toISOString(),
          end: new Date(event.event_date).toISOString(),
        })
      );
      setRIEvents(formattedEvents);
      console.log("Netra", JSON.stringify(formattedEvents))
      setRICalenderCount(TravelCalendarRIListResponse.response.length);
      setDirectCalenderCount(0);
    } else {
      $("#progress-loader").addClass("hidden");
      switch (TravelCalendarRIListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onTravelCalendarRIListUpdate, [props.TravelCalendarRIList]);

  const onTravelCalendarDirectListUpdate = () => {
    const { TravelCalendarDirectList } = props;
    var TravelCalendarDirectListResponse = Object.assign(
      {},
      TravelCalendarDirectList
    );
    if (TravelCalendarDirectListResponse.result === true) {
      $("#progress-loader").addClass("hidden");
      const formattedEvents = TravelCalendarDirectListResponse.response.map(
        event => ({
          ...event,
          start: new Date(event.event_date).toISOString(),
          end: new Date(event.event_date).toISOString(),
        })
      );
      setDirectEvents(formattedEvents);
      setDirectCalenderCount(TravelCalendarDirectListResponse.response.length);
      setRICalenderCount(0);
    } else {
      $("#progress-loader").addClass("hidden");
      switch (TravelCalendarDirectListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onTravelCalendarDirectListUpdate, [props.TravelCalendarDirectList]);

  const onTravelCalendarUpdate = () => {
    const { TravelCalendar } = props;
    var TravelCalendarResponse = Object.assign({}, TravelCalendar);
    if (TravelCalendarResponse.result === true) {
      $("#progress-loader").addClass("hidden");
      const customListData = TravelCalendarResponse.response.map(item => ({
        ...item,
        accompanied_by: item.travel_accompanieds
          ? item.travel_accompanieds
              .map(nameObj => nameObj.travel_accompanied_details?.full_name)
              .join(", ")
          : "",
      }));
      setTravelCalendar(customListData);
    } else {
      $("#progress-loader").addClass("hidden");
      switch (TravelCalendarResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onTravelCalendarUpdate, [props.TravelCalendar]);

  const onTravelDeleteUpdate = () => {
    const { TravelDelete } = props;
    var TravelDeleteResponse = Object.assign({}, TravelDelete);
    if (TravelDeleteResponse.result === true) {
      $("#progress-loader").addClass("hidden");
      toast.success(TravelDeleteResponse.message);
      props.actionTravelCalendar();
    } else {
      $("#progress-loader").addClass("hidden");
      switch (TravelDeleteResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onTravelDeleteUpdate, [props.TravelDelete]);

  useEffect(() => {
    if (timeGridDay === true) {
      if (selectedRIDate != null && directCalender === true) {
        setSelectedRIDate(new Date());
      } else if (selectedDate != null && rICalender === true) {
        setSelectedDate(new Date());
      }
    }
  }, [timeGridDay]);

  const renderHeaderButtonUI = () => {
    return (
      <>
        <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
          <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
            {tabPermission?.ri_calendar == 1 ? (
              <li class="nav-item">
                <button
                  onClick={() => {
                    $("#progress-loader").removeClass("hidden");
                    setRICalender(true);
                    setListView(false);
                    setDirectCalender(false);
                    setTimeGridDay(false);
                    setDirectEvents([]);
                    setDirectEventDetails([]);
                    setSelectedButton("RI_CALENDER");
                    setSelectedDate(dateFormat("yyyy-mm-dd"));
                    setTimeout(() => {
                      props.actionTravelRICalendarEvent({
                        type: "reinsurance",
                        date: dateFormat("yyyy-mm-dd"),
                      });
                      var strDate = $(".fc-toolbar-title").html();
                      var arrDate = _.split(strDate, " ");
                      props.actionTravelRICalendarList({
                        type: "reinsurance",
                        month: getMonthNumber(arrDate[0], "string"),
                        year: arrDate[1],
                      });
                    }, 1200);

                    $(document).on("click", ".fc-prev-button", function () {
                      var strDate = $(".fc-toolbar-title").html();
                      var arrDate = _.split(strDate, " ");
                      props.actionTravelRICalendarList({
                        type: "reinsurance",
                        month: getMonthNumber(arrDate[0], "string"),
                        year: arrDate[1],
                      });
                    });
                  }}
                  className={`btn ${
                    rICalender === true ? "btn-primary" : "btn-light"
                  } btn-lead btn-md px-1 font-small-3 shadow round mr-1`}
                >
                  RI Calender{" "}
                  {rICalenderCount > 0 && rICalender === true
                    ? `(` + rICalenderCount + `)`
                    : ``}
                </button>
              </li>
            ) : (
              <></>
            )}
            {tabPermission?.direct_calendar == 1 ? (
              <li class="nav-item mr-1">
                <button
                  onClick={() => {
                    $("#progress-loader").removeClass("hidden");
                    setDirectCalender(true);
                    setListView(false);
                    setRICalender(false);
                    setRIEvents([]);
                    setRIEventDetails([]);
                    setTimeGridDay(false);
                    setSelectedButton("DIRECT_CALENDER");
                    setSelectedRIDate(dateFormat("yyyy-mm-dd"));
                    setTimeout(() => {
                      props.actionTravelDirectCalendarEvent({
                        type: "others",
                        date: dateFormat("yyyy-mm-dd"),
                      });
                      var strDate = $(".fc-toolbar-title").html();
                      var arrDate = _.split(strDate, " ");
                      props.actionTravelDirectCalendarList({
                        type: "others",
                        month: getMonthNumber(arrDate[0], "string"),
                        year: arrDate[1],
                      });
                    }, 1200);

                    $(document).on("click", ".fc-prev-button", function () {
                      var strDate = $(".fc-toolbar-title").html();
                      var arrDate = _.split(strDate, " ");
                      props.actionTravelDirectCalendarList({
                        type: "others",
                        month: getMonthNumber(arrDate[0], "string"),
                        year: arrDate[1],
                      });
                    });
                  }}
                  className={`btn ${
                    directCalender === true ? "btn-primary" : "btn-light"
                  } btn-lead btn-md px-1 font-small-3 shadow round `}
                >
                  Direct Calender{" "}
                  <span className="mr-half">
                    {directCalenderCount > 0 && directCalender === true
                      ? `(` + directCalenderCount + `)`
                      : ``}
                  </span>
                </button>
              </li>
            ) : (
              <></>
            )}
            {tabPermission?.list_view == 1 ? (
              <li class="nav-item">
                <button
                  onClick={() => {
                    $("#progress-loader").removeClass("hidden");
                    setListView(true);
                    setRICalender(false);
                    setDirectCalender(false);
                    props.actionTravelCalendar();
                  }}
                  className={`btn ${
                    listView === true ? "btn-primary" : "btn-light"
                  } btn-lead btn-md px-1 font-small-3 shadow round mr-1`}
                >
                  List View{" "}
                  {travelCalendar.length && listView === true
                    ? `(` + travelCalendar.length + `)`
                    : ``}
                </button>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>
        {tabPermission?.list_view == 1 ? (
          <div className="text-right col-12 col-md-6 col-lg-6">
            <button
              className="btn btn-primary btn-md shadow round mr-1"
              onClick={() => {
                props.history.push(`${match.url}/update`);
              }}
            >
              Add Travel
            </button>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

    // const renderEventContent = ({ event }) => {
    //   const endDate = moment(event.extendedProps.end_date).format("YYYY-MM-DD");
    //   const startDate = moment(event.extendedProps.start_date).format("YYYY-MM-DD");
    //   const calendarDate = moment(event.start).format("YYYY-MM-DD");
    
    //   // Check if the end_date or start_date matches the current calendar date
    //   const isMatchingStartAndEndDate = startDate === calendarDate && endDate === calendarDate;
    //   const isMatchingEndDate = endDate === calendarDate;
    //   const isMatchingStartDate = startDate === calendarDate;
    
    //   // Function to get initials from full name
    //   const getInitials = (fullName) => {
    //     if (!fullName) return "NN"; // Default to "NN" if no name is provided
    //     return fullName
    //       .split(" ") // Split the name by spaces
    //       .map((name) => name.charAt(0)) // Take the first letter of each part
    //       .join(""); // Join the letters together
    //   };
    
    //   return (
    //     <>
    //       {isMatchingStartAndEndDate && (
    //         <div
    //           className="event-content-same"
    //           style={{
    //             borderRadius: "50%",
    //             backgroundColor: "#60B3DD", // Color for the same start and end date
    //             width: "25px",
    //             height: "25px",
    //             marginRight: "3px",
    //             marginLeft: "4px",
    //             border: "2px solid #1878A8",
    //             display: "inline-block",
    //             lineHeight: "21px",
    //             textAlign: "center",
    //             color: "#FFFFFF", // Text color
    //             fontWeight: "bold",
    //             fontSize: "9px",
    //           }}
    //         >
    //           {getInitials(event.extendedProps.travel_rm_details?.full_name)}
    //         </div>
    //       )}
    //       {isMatchingEndDate && !isMatchingStartDate && (
    //         <div
    //           className="event-content-end"
    //           style={{
    //             borderRadius: "50%",
    //             backgroundColor: "#FD3C3C", // Different color for end date
    //             width: "25px",
    //             height: "25px",
    //             marginRight: "3px",
    //             marginLeft: "4px",
    //             border: "2px solid #D61F1F",
    //             lineHeight: "21px",
    //             display: "inline-block",
    //             color:"#fff",
    //             textAlign: "center",
    //             fontSize: "9px",
    //           }}
    //         >
    //           {getInitials(event.extendedProps.travel_rm_details?.full_name)}
    //         </div>
    //       )}
    //       {isMatchingStartDate && !isMatchingEndDate && (
    //         <div
    //           className="event-content-start"
    //           style={{
    //             borderRadius: "50%",
    //             backgroundColor: "#36A53A", // Different color for start date
    //             width: "25px",
    //             height: "25px",
    //             marginRight: "3px",
    //             marginLeft: "4px",
    //             border: "2px solid #31CB36",
    //             display: "inline-block",
    //             lineHeight: "21px",
    //             color:"#fff",
    //             textAlign: "center",
    //             fontSize: "9px",
    //           }}
    //         >
    //           {getInitials(event.extendedProps.travel_rm_details?.full_name)}
    //         </div>
    //       )}
    //       {/* {!isMatchingEndDate && !isMatchingStartDate && (
    //         <div
    //           className="event-content-none"
    //           style={{
    //             borderRadius: "50%",
    //             backgroundColor: "#E3066F",
    //             width: "8px",
    //             height: "8px",
    //             marginLeft: "3px",
    //           }}
    //         >
             
    //         </div>
         
    //       )} */}
    //     </>
    //   );
    // };
  
    const renderEventContent = ({ event }) => {
      const fullName = event.extendedProps.travel_rm_details?.full_name?.trim(); // Trim to remove any leading/trailing spaces
    
// Debugging: Log the full name
console.log("Full Name:", fullName);

      // Function to get initials from full name
      const getInitials = (name) => {
        if (!name) return "NN"; // Default to "NN" if no name is provided
        return name
          .split(" ") // Split the name by spaces
          .map((namePart) => namePart.charAt(0)) // Take the first letter of each part
          .join(""); // Join the letters together
      };
    
      // Function to get background color based on the full name
      const getBackgroundColor = (name) => {
        if (!name) return { backgroundColor: "#F5FFEF", borderColor: "#E3066F" , color: "#E3066F" }; // Default color if no name is provided
        
        switch (name.toLowerCase()) { // Convert the name to lowercase for case-insensitive comparison
          case "akshay j kamath": //akshay kamath
          return { backgroundColor: "#8AE757", borderColor: "#21A735", color: "#21A735" }; // Green for Akshay Kamath
          case "nagarajan giri shankar": //giri shankar  
          return { backgroundColor: "#C170FF", borderColor: "#380EB4", color: "#380EB4" }; // Blue for Giri Shankar
          case "harish nambissan": //harish nambissan
          return { backgroundColor: "#E3066F", borderColor: "#930D4C", color: "#FFF" }; // Pink for Harish Nambissan
          case "vir gandhi": //vir gandhi
          return { backgroundColor: "#75DDF4", borderColor: "#0B7FAA", color: "#0B7FAA" }; // Light Blue for Yogeesh Kumar
          case "prathamesh gangnaik": //prathmesh gangnaik
          return { backgroundColor: "#FAB92D", borderColor: "#B47F0E", color: "#FFF" }; // Mustard-dark for Prathamesh Gangnaik
          default:
            return { backgroundColor: "#F5FFEF", borderColor: "#E3066F", color: "#E3066F" }; // Default color
        }
      };
    
      const initials = getInitials(fullName);
      const { backgroundColor, borderColor, color } = getBackgroundColor(fullName);
    console.log("background", backgroundColor)
      return (
        <>
          <div
            className="event-content-same"
            style={{
              borderRadius: "50%",
              backgroundColor: backgroundColor,
              width: "30px",
              height: "30px",
              marginRight: "4px",
              marginLeft: "0px",
              border: `2px solid ${borderColor}`, // Apply border color
              display: "inline-block",
              lineHeight: "27px",
              textAlign: "center",
              color: `${color}`, // Text color
              fontWeight: "bold",
              fontSize: "9px",
            }}
            title={fullName}
          >
            {initials}
          </div>
        </>
      );
    };
    
    
    
    

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      if (objColumn.dataField === "Action") {
        arrColumns.push(
          <Column dataField="action" type="buttons">
            {/* {verifyPermission("EDIT_TRAVEL") ? ( */}
            <Button
              render={params => (
                <button
                  className="btn btn-icon btn-sm"
                  onClick={() => {
                    props.history.push({
                      pathname: `${match.url}/update`,
                      state: { data: params.data },
                    });
                  }}
                >
                  <FiEdit size={16} className="primary" />
                </button>
              )}
            />
            {/* ) : (
              <></>
            )} */}
            <Button
              render={params => (
                <button
                  className="btn btn-icon btn-sm"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      text: "Are you sure, you want to delete?",
                      cancelButtonText: "Cancel",
                      showCancelButton: true,
                    }).then(({ isConfirmed }) => {
                      if (isConfirmed) {
                        props.actionTravelDelete({ id: params?.data?.id });
                      }
                    });
                  }}
                >
                  <FiTrash2 size={16} className="primary" />
                </button>
              )}
            />
          </Column>
        );
      } else {
        arrColumns.push(
          <Column
            dataField={objColumn.dataField}
            caption={objColumn.caption}
            visible={objColumn.visible}
          ></Column>
        );
      }
    });
    return arrColumns;
  };
  return (
    <>
      <div className="content-header row mb-1">{renderHeaderButtonUI()}</div>
      <div className="card">
        <div className="card-body">
          <div class="row">
            <input type="hidden" id="selectedButton" value={selectedButton} />
            {rICalender === true && tabPermission?.ri_calendar == 1 ? (
              <div className="col-12 col-md-8 col-lg-9 px-0">
                <Controller
                  name="date"
                  control={control}
                  defaultValue={date}
                  weekends={false}
                  render={({ field }) => (
                    <FullCalendar
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      headerToolbar={{
                        left: "prev,today,next",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                      }}
                      dayMaxEventRows={20}
                      dayMaxEvents={5}
                      initialView="dayGridMonth"
                      editable={true}
                      selectable={true}
                      selectMirror={true}
                      eventDisplay={"list-item"}
                      eventContent={renderEventContent}
                      events={rIEvents}
                      dateClick={data => {
                        setRIEventDetails([]);
                        setSelectedDate(data.dateStr);
                        props.actionTravelRICalendarEvent({
                          date: data.dateStr,
                          type: "reinsurance",
                        });
                      }}
                    />
                  )}
                />
              </div>
            ) : (
              <></>
            )}
            {directCalender === true && tabPermission?.direct_calendar == 1 ? (
              <div className="col-12 col-md-8 col-lg-9 px-0">
                <Controller
                  name="date"
                  control={control}
                  defaultValue={date}
                  weekends={false}
                  render={({ field }) => (
                    <FullCalendar
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      headerToolbar={{
                        left: "prev,today,next",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                      }}
                      dayMaxEventRows={20}
                      dayMaxEvents={5}
                      initialView="dayGridMonth"
                      editable={true}
                      selectable={true}
                      selectMirror={true}
                      eventDisplay={"list-item"}
                      eventContent={renderEventContent}
                      events={directEvents}
                      dateClick={data => {
                        setDirectEventDetails([]);
                        setSelectedRIDate(data.dateStr);
                        props.actionTravelDirectCalendarEvent({
                          date: data.dateStr,
                          type: "others",
                        });
                      }}
                    />
                  )}
                />
              </div>
            ) : (
              <></>
            )}

            <div className="col-12 col-md-4 col-lg-3">
              {selectedDate != null &&
              rICalender === true &&
              tabPermission?.ri_calendar == 1 ? (
                <div className="d-flex flex-row w-100 justify-content-center">
                  <div className="flex-column">
                    <div className="d-flex justify-content-center">
                      <h1 className="font-large-5 text-bold-700 my-0 mr-half">
                        {dateFormat(selectedDate, "dd")}
                      </h1>
                    </div>
                    <div className="d-flex justify-content-center">
                      <h1 className="font-large-1 text-bold-500 my-0">
                        {dateFormat(selectedDate, "dddd")}
                      </h1>
                    </div>

                    <div className="d-flex justify-content-center">
                      <h6 className="font-medium-1 text-bold-300 my-0 mt-1">
                        {dateFormat(selectedDate, "mmmm,yyyy")}
                      </h6>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {selectedRIDate != null &&
              directCalender === true &&
              tabPermission?.direct_calendar == 1 ? (
                <div className="d-flex flex-row w-100 justify-content-center">
                  <div className="flex-column">
                    <div className="d-flex justify-content-center">
                      <h1 className="font-large-5 text-bold-700 my-0 mr-half">
                        {dateFormat(selectedRIDate, "dd")}
                      </h1>
                    </div>
                    <div className="d-flex justify-content-center">
                      <h1 className="font-large-1 text-bold-500 my-0">
                        {dateFormat(selectedRIDate, "dddd")}
                      </h1>
                    </div>

                    <div className="d-flex justify-content-center">
                      <h6 className="font-medium-1 text-bold-300 my-0 mt-1">
                        {dateFormat(selectedRIDate, "mmmm,yyyy")}
                      </h6>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div class="">
                <div
                  id="accordionWrap1"
                  role="tablist"
                  aria-multiselectable="true"
                  class="mt-1"
                >
                  {!listView &&
                  rIEventDetails != null &&
                  rIEventDetails.length > 0 &&
                  rICalender == true &&
                  tabPermission?.ri_calendar == 1 ? (
                    rIEventDetails.map((eventData, index) => (
                      <div
                        key={index}
                        className="card mb-1"
                        style={{ borderRadius: "10px", overflow: "hidden" }}
                      >
                        <div
                          className="card-header border-bottom py-1"
                          style={{
                            backgroundColor: "#FADADD",
                            padding: "1rem",
                          }}
                        >
                            <div
                              className="d-flex flex-column align-items-end"
                              style={{ fontSize: "10px", fontWeight: "normal" }}
                            >
                              <div>
                                STATUS:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {eventData?.status
                                    ? eventData.status.toUpperCase()
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          <div className="">
                            <div className=" d-flex">
                              <div className="my-auto"
                                style={{
                                  height: 10,
                                  width: 10,
                                  backgroundColor: "#E3066F",
                                  borderRadius: "50%",
                                  marginRight: 5,
                                }}
                              ></div>
                              <div className="my-auto"
                                style={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                {eventData?.travel_rm_details?.full_name
                                  ? eventData.travel_rm_details.full_name
                                  : "-"}
                              </div>
                            </div>
                          
                          </div>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ marginTop: "0.5rem" }}
                          >
                            <div
                              style={{
                                fontSize: "13px",
                                color: "#E3066F",
                                fontWeight: "bold",
                              }}
                            >
                              {eventData?.travel_to ? eventData.travel_to : "-"}
                            </div>
                            <div
                              style={{ fontSize: "13px", fontWeight: "normal" }}
                            >
                              {eventData?.start_date
                                ? moment(eventData.start_date)
                                    .format("DD-MMM")
                                    .toUpperCase()
                                : "-"}{" "}
                              to{" "}
                              {eventData?.end_date
                                ? moment(eventData.end_date)
                                    .format("DD-MMM")
                                    .toUpperCase()
                                : "-"}
                            </div>
                          </div>
                          <div
                            style={{ marginTop: "0.5rem", fontSize: "13px" }}
                          >
                            <span style={{ textDecoration: "underline" }}>
                              Accompanied By:
                            </span>
                            &nbsp;
                            <span style={{ fontWeight: "bold" }}>
                              {eventData?.travel_accompanieds
                                ?.map(
                                  accompanied =>
                                    accompanied.travel_accompanied_details
                                      .full_name
                                )
                                .join(", ") || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                  {!listView &&
                  directEventDetails != null &&
                  directEventDetails.length > 0 &&
                  directCalender === true &&
                  tabPermission?.direct_calendar == 1 ? (
                    directEventDetails.map((eventData, index) => (
                      <div
                        key={index}
                        className="card mb-1"
                        style={{ borderRadius: "10px", overflow: "hidden" }}
                      >
                        <div
                          className="card-header border-bottom py-1"
                          style={{
                            backgroundColor: "#FADADD",
                            padding: "1rem",
                          }}
                        >
                             <div
                              className="d-flex flex-column align-items-end"
                              style={{ fontSize: "10px", fontWeight: "normal" }}
                            >
                              <div>
                                STATUS:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {eventData?.status
                                    ? eventData.status.toUpperCase()
                                    : "-"}
                                </span>
                              </div>
                            </div>
                          <div className="">
                            <div className="d-flex flex-row align-items-center">
                              <div
                                style={{
                                  height: 12,
                                  width: 12,
                                  backgroundColor: "#E3066F",
                                  borderRadius: "50%",
                                  marginRight: 8,
                                }}
                              ></div>
                              <div
                                style={{ fontWeight: "bold", fontSize: "12px" }}
                              >
                                {eventData?.travel_rm_details?.full_name
                                  ? eventData.travel_rm_details.full_name
                                  : "-"}
                              </div>
                            </div>
                         
                          </div>
                          <div
                            className="d-flex justify-content-between align-items-center"
                            style={{ marginTop: "0.5rem" }}
                          >
                            <div
                              style={{
                                fontSize: "13px",
                                color: "#E3066F",
                                fontWeight: "bold",
                              }}
                            >
                              {eventData?.travel_to ? eventData.travel_to : "-"}
                            </div>
                            <div
                              style={{ fontSize: "13px", fontWeight: "normal" }}
                            >
                              {eventData?.start_date
                                ? moment(eventData.start_date)
                                    .format("DD-MMM")
                                    .toUpperCase()
                                : "-"}{" "}
                              to{" "}
                              {eventData?.end_date
                                ? moment(eventData.end_date)
                                    .format("DD-MMM")
                                    .toUpperCase()
                                : "-"}
                            </div>
                          </div>
                          <div
                            style={{ marginTop: "0.5rem", fontSize: "13px" }}
                          >
                            <span style={{ textDecoration: "underline" }}>
                              Accompanied By:
                            </span>
                            &nbsp;
                            <span style={{ fontWeight: "bold" }}>
                              {eventData?.travel_accompanieds
                                ?.map(
                                  accompanied =>
                                    accompanied.travel_accompanied_details
                                      .full_name
                                )
                                .join(", ") || "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {listView && tabPermission?.list_view == 1 ? (
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <DataGrid
                        dataSource={travelCalendar}
                        showBorders={true}
                        columnAutoWidth={true}
                        export={{
                          fileName: "Travel List",
                          enabled: true,
                          allowExportSelectedData: false,
                          excelFilterEnabled: true,
                        }}
                      >
                        <SearchPanel visible={true} />
                        <FilterRow visible={true} />
                        <HeaderFilter visible={true} />
                        {renderColumns()}
                        <Pager
                          visible={true}
                          showPageSizeSelector={false}
                          showNavigationButtons={true}
                          allowedPageSizes={[4, 6, 10]}
                        />
                        <Paging
                          defaultPageIndex={0}
                          defaultPageSize={10}
                          enabled={true}
                        />
                      </DataGrid>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => {
  var TravelCalendar = null;
  var TravelDelete = null;
  var TravelCalendarRIList = null;
  var TravelCalendarRIEvent = null;
  var TravelCalendarDirectList = null;
  var TravelCalendarDirectEvent = null;
  var TravelTabPermission = null;

  if (
    state.CalendarTravelReducer != null &&
    Object.keys(state.CalendarTravelReducer).length > 0 &&
    state.CalendarTravelReducer.constructor === Object
  ) {
    switch (state.CalendarTravelReducer.tag) {
      case ACTION_CALENDAR_TRAVEL_SUCCESS:
        TravelCalendar = Object.assign({}, state.CalendarTravelReducer.data);
        delete state.CalendarTravelReducer;
        return { TravelCalendar };
      case ACTION_TRAVEL_DELETE_SUCCESS:
        TravelDelete = Object.assign({}, state.CalendarTravelReducer.data);
        delete state.CalendarTravelReducer;
        return { TravelDelete };
      case ACTION_TRAVEL_RI_CALENDAR_LIST_SUCCESS:
        TravelCalendarRIList = Object.assign(
          {},
          state.CalendarTravelReducer.data
        );
        delete state.CalendarTravelReducer;
        return { TravelCalendarRIList };
      case ACTION_TRAVEL_RI_CALENDAR_EVENT_SUCCESS:
        TravelCalendarRIEvent = Object.assign(
          {},
          state.CalendarTravelReducer.data
        );
        delete state.CalendarTravelReducer;
        return { TravelCalendarRIEvent };
      case ACTION_TRAVEL_DIRECT_CALENDAR_LIST_SUCCESS:
        TravelCalendarDirectList = Object.assign(
          {},
          state.CalendarTravelReducer.data
        );
        delete state.CalendarTravelReducer;
        return { TravelCalendarDirectList };
      case ACTION_TRAVEL_DIRECT_CALENDAR_EVENT_SUCCESS:
        TravelCalendarDirectEvent = Object.assign(
          {},
          state.CalendarTravelReducer.data
        );
        delete state.CalendarTravelReducer;
        return { TravelCalendarDirectEvent };
      case ACTION_TRAVEL_TAB_PERMISSION_SUCCESS:
        TravelTabPermission = Object.assign(
          {},
          state.CalendarTravelReducer.data
        );
        delete state.CalendarTravelReducer;
        return { TravelTabPermission };
      default:
    }
  }
  return {
    TravelCalendar,
    TravelDelete,
    TravelCalendarRIList,
    TravelCalendarRIEvent,
    TravelCalendarDirectList,
    TravelCalendarDirectEvent,
    TravelTabPermission,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      actionTravelCalendar,
      actionTravelDelete,
      actionTravelRICalendarList,
      actionTravelRICalendarEvent,
      actionTravelDirectCalendarList,
      actionTravelDirectCalendarEvent,
      actionTravelTabPermissions,
    },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(TravelCalendarView)
);
