import React, { useEffect, useState } from "react";
import ScreenTitle from '../../../../../../component/screen_title';
import { ACTION_RISK_MANAGEMENT_VIEW_SUCCESS, actionViewRiskManagmentRequest } from "../../action";
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import _ from 'lodash';
import moment from "moment";
import RequestHistory from '../../../../../../component/widgets/request_history';
import DocumentIframe from "../../../../../../component/popups/document_iframe";
import { downloadFile } from "../../../../../../utils";

const RiskManagementView = (props) => {
    const [riskManagementDetail, setRiskManagementDetail] = useState(null);
    const [requestHistory, setRequestHistory] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        props.actionViewRiskManagmentRequest({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    const onRequestViewUpdate = () => {
        const { RequestView } = props;
        var RequestViewResponse = Object.assign({}, RequestView);
        if (RequestViewResponse.result === true) {
            setRiskManagementDetail(RequestViewResponse.response);
            if (RequestViewResponse.response.request_risk_status.length > 0) {
                setRequestHistory(RequestViewResponse.response.request_risk_status);
            }
        } else {
            switch (RequestViewResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRequestViewUpdate, [props.RequestView]);

    return (<>
        <ScreenTitle title="View Risk Request" />
        <section>
            <div className="card">
                <div className="card-body">
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Created Date</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.created_at != null ?
                                        moment(riskManagementDetail.created_at).format("DD/MM/YYYY") : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Client Type</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.client_type != null ?
                                        _.capitalize(riskManagementDetail.client_type).replace('_', ' ') : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Client Name</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.client_name != null ?
                                        riskManagementDetail.client_name : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Location Address</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.address != null ?
                                        riskManagementDetail.address : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Occupancy</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.occupancy != null && riskManagementDetail.occupancy != 'undefined' ?
                                        riskManagementDetail.occupancy : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Sum Insured</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.sum_insured != null ?
                                        riskManagementDetail.sum_insured : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Type of Inspection</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.inspection_type != null ?
                                        riskManagementDetail.inspection_type : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    {
                        riskManagementDetail != null && riskManagementDetail.inspection_type != null && riskManagementDetail.inspection_type === "Others" ?
                            <div className="row pt-2">
                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                    <h4 className="text-uppercase font-small-3 mb-0">Description</h4>
                                </div>
                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                    <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {
                                            riskManagementDetail != null && riskManagementDetail.description != null ?
                                                riskManagementDetail.description : '-'
                                        }
                                    </h4>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Date of Inspection</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.inspection_date != null ?
                                        moment(riskManagementDetail.inspection_date).format("DD/MM/YYYY") : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Assign Engineer</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.assign_engineer != null ?
                                        riskManagementDetail.assign_engineer : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">Remark</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.remarks != null && riskManagementDetail.remarks != 'undefined' ?
                                        riskManagementDetail.remarks : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3">
                            <h4 className="text-uppercase font-small-3 mb-0">Document</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3">
                            {
                                (riskManagementDetail != null && riskManagementDetail.risk_management_document != null) ?
                                    // <div>
                                    //     <a target="_blank" href={riskManagementDetail.risk_management_document} download="Meeting Document">View Document</a>
                                    // </div>

                                    <>
                                        <a className="primary"
                                            onClick={() => {
                                                if (riskManagementDetail.risk_management_document?.split('.').pop() == 'pdf' ||
                                                    riskManagementDetail.risk_management_document?.split('.').pop() == 'PDF' ||
                                                    riskManagementDetail.risk_management_document?.split('.').pop() == 'png' ||
                                                    riskManagementDetail.risk_management_document?.split('.').pop() == 'jpg' ||
                                                    riskManagementDetail.risk_management_document?.split('.').pop() == 'jpeg' ||
                                                    riskManagementDetail.risk_management_document?.split('.').pop() == 'gif'
                                                ) {
                                                    setSelectedDocumentIndex(riskManagementDetail.risk_management_document)
                                                    setShowDocumentDetails(true);
                                                    setExtension(riskManagementDetail.risk_management_document?.split('.').pop())
                                                    setDocumentDetails(riskManagementDetail)
                                                } else {
                                                    downloadFile(riskManagementDetail.risk_management_document);
                                                }
                                            }}
                                        >
                                            View Document
                                        </a>
                                        <a id="downloadLink" className="d-none"></a>
                                    </>
                                    :
                                    <h4 className="font-medium-1 text-bold-700 mb-0">-</h4>
                            }
                        </div>
                    </div>
                    {
                        riskManagementDetail != null && riskManagementDetail.status != null ?
                            <div className="row pt-2">
                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                    <h4 className="text-uppercase font-small-3 mb-0">Approved By</h4>
                                </div>
                                <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                                    <h4 className="font-medium-1 text-bold-700 mb-0">
                                        {
                                            riskManagementDetail != null && riskManagementDetail.approved_by != null && riskManagementDetail?.status != 'pending' ?
                                                riskManagementDetail.approved_by : '-'
                                        }
                                    </h4>
                                </div>
                            </div>
                            :
                            <></>
                    }
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0"> Actual Date of Inspection</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.actual_date_inspection != null ?
                                        moment(riskManagementDetail.actual_date_inspection).format("DD/MM/YYYY") : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="text-uppercase font-small-3 mb-0">
                                Financial Year</h4>
                        </div>
                        <div className="col-12 col-lg-3 col-md-3 d-flex align-items-center">
                            <h4 className="font-medium-1 text-bold-700 mb-0">
                                {
                                    riskManagementDetail != null && riskManagementDetail.financial_year != null ?
                                        riskManagementDetail.financial_year : '-'
                                }
                            </h4>
                        </div>
                    </div>
                    {
                        requestHistory != null ?
                            <RequestHistory
                                history={requestHistory}
                            />
                            :
                            <></>
                    }
                    <div className="form-actions border-0 float-none">
                        <button
                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
                            onClick={(event) => {

                                event.preventDefault();
                                if (props?.location?.state?.type === 'RISK_APPROVE_AM_TO_DM' || props?.location?.state?.type === 'RISK_CREATOR_NOTIFICATION' || props?.location?.state?.type === 'RISK_REJECT_AM_TO_DM') {
                                    props.history.push({
                                        pathname: '/notification'
                                    })
                                } else {
                                    props.history.goBack();
                                }
                            }}
                        >Back</button>

                    </div>
                </div>
            </div>
        </section>
        <DocumentIframe
            {...props}
            show={showDocumentDetails}
            documentDetails={selectedDocumentIndex}
            detailsOfDocument={documentDetails}
            extension={extension}
            handleClose={() => {
                setShowDocumentDetails(false);
            }}
        />
    </>)
}

const mapStateToProps = (state) => {
    var RequestView = null;

    if (
        state.viewRiskManagementReducer != null &&
        Object.keys(state.viewRiskManagementReducer).length > 0 &&
        state.viewRiskManagementReducer.constructor === Object
    ) {
        switch (state.viewRiskManagementReducer.tag) {

            case ACTION_RISK_MANAGEMENT_VIEW_SUCCESS:
                RequestView = Object.assign({}, state.viewRiskManagementReducer.data);
                delete state.viewRiskManagementReducer;
                return { RequestView };
            default:
        }
    }
    return {
        RequestView,

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewRiskManagmentRequest
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskManagementView))