import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateBox } from "devextreme-react/date-box";
import { TagBox } from "devextreme-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ERROR, UNAUTHORIZED } from "../../../constants";
import dateFormat from "dateformat";
import {
  actionDocumentUpload,
  ACTION_DOCUMENT_UPLOAD_SUCCESS,

} from "./action";
import {
  actionProposalView,
  ACTION_PROPOSAL_VIEW_SUCCESS
} from './action';
import { toast } from "react-toastify";

const OpportunityDocumentsModel =
  props => {
    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
      setValue,
      reset,
      getValues,
      watch,
    } = useForm({
      defaultValues: {
        client_remark: "",
        department_name: "",
        opportunityQuestion: [],
        product_specialist_id: 0,
      },
    });

    const [uploadDocument, setUploadDocument] = useState(null);


    const initialRender = () => {
      $("#save-policy-details").prop("disabled", false);
      console.log("===Oprprrr", props?.data)
      if (props.show === true) {
        // props.actionPolicyGroup({ status: "ACTIVE", orderBy: "name" });

        $("#root").addClass("modal-open");
        $("#root").append('<div class="modal-backdrop fade show"></div>');
        if (props.data !== null && props.data !== undefined) {

          $("#opportunity-docs-form-loader").addClass("hidden");


        }
      } else {
        $("#opportunity-docs-form-loader").addClass("hidden");
        $("#root").removeClass("modal-open");
        $(".modal-backdrop").remove();
      }

    };
    useEffect(initialRender, [props.show]);



    const onDocumentGetUpdate = () => {
      const { DocumentUpload } = props;
      $("#save-policy-details").prop("disabled", false);
      if (DocumentUpload != null) {
        if (DocumentUpload.result === true) {
          toast.success('Document added successfully')
          $("#opportunity-docs-form-loader").addClass("hidden");

          setUploadDocument(null)
          setValue('document_type', null)
          if (props.refetch) {
            props.refetch();
          }
        } else {
          toast.error(`Failed to upload document`);
          $("#opportunity-docs-form-loader").addClass("hidden");
        }
      }
    }
    useEffect(onDocumentGetUpdate, [props.DocumentUpload]);




    const onSubmit = data => {
      const submitObj = {
        client_id: props?.data?.client_id,
        lead_policy_id: props?.data?.id,
        document_type: data?.document_type
        // document_type: 'rfq_policy'
      }

      var files = [];
      if (uploadDocument != null) {
        files.push({ 'name': 'documents', 'value': uploadDocument });
        $("#opportunity-docs-form-loader").removeClass("hidden");
        $("#opportunity-docs-form-loader").prop("disabled", true);
        props.actionDocumentUpload(submitObj, files);
      } else {
        $('#error-pdf-doc-not-found').removeClass('d-none')
      }
      delete data.upload_document;

      // }
      $("#opportunity-docs-form-loader").removeClass("hidden");
      $("#save-policy-details").prop("disabled", true);
      //s props.handleClose(newPolicy, stateRfq);
      setValue("department_name", "");
    };

    return (
      <div
        className="modal fade show"
        id="default"
        role="dialog"
        aria-labelledby="myModalLabel1"
        aria-hidden="true"
        style={{ display: `${props.show === true ? "block" : "none"}` }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="media-middle mt-1">
                <h4 className="modal-title" id="myModalLabel1">
                  Document Upload
                </h4>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props.handleClose();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ overflow: "visible" }}>
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                  <form
                    noValidate
                    autoComplete="Off"
                    onSubmit={handleSubmit(onSubmit)}
                  >

                    <div className="row">
                      {/* <div className="col-md-12 border-bottom mb-2">
                      <h5>Opportunity Details</h5>
                    </div> */}
                      <div className="col-md-12 border-bottom mb-2">
                        <div className="row">
                          {/* <div className="col-md-3">
                      <h6 >Company Name</h6>
                      <h5>ABCD</h5>
                      </div> */}
                          <div className="col-md-3">
                            <h6 >Opportunity Id</h6>
                            <h5>{props?.data?.id}</h5>
                          </div>
                          <div className="col-md-3">
                            <h6>Opportunity Type</h6>
                            <h5>{props?.data?.oppurtunity_type}</h5>
                          </div>
                          {/* <div className="col-md-3">
                      <h6>Policy Type</h6>
                      <h5>{props?.data?.policy_type}</h5>
                      </div> */}
                        </div>
                      </div>
                      {/* <div className="col-md-12 border-bottom mb-2">
                      <h5>Document Upload</h5>
                    </div> */}

                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Document Type
                            <span className="danger">*</span>
                          </label>
                          <Controller
                            name="document_type"
                            className="form-control"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={[
                                  {
                                    value: "rfq_policy",
                                    title: "Policy Document",
                                  },
                                  {
                                    value: "rfq_claim",
                                    title: "Claim Document",
                                  },
                                  {
                                    value: "rfq_other",
                                    title: "Other Document",
                                  },
                                ]}
                                fields={{ text: "title", value: "value" }}
                                allowFiltering={false}
                                placeholder="Select Document Type"
                                value={field.value}
                              />
                            )}
                            rules={{
                              required: "Document Type is required",
                            }}
                          />
                          {errors.meeting_type && (
                            <ErrorLabel message={errors.meeting_type?.message} />
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-md-4 col-lg-8">
                        <p>
                          Upload File
                          <span className="danger">*</span>
                        </p>
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="upload_document"
                            name="upload_document"
                            onChange={(event) => {
                              if (
                                event.target.files
                              ) {
                                setUploadDocument(event.target.files[0]);
                              }
                            }}
                          />
                          <label class="custom-file-label card-text-esc" for="upload_document">
                            {
                              uploadDocument != null ?
                                uploadDocument.name?.length < 45 ? uploadDocument.name : `${uploadDocument.name?.substring(0, 45)}...` : "Choose file"
                            }
                          </label>
                        </div>
                      </div>

                    </div>

                    <div className="form-actions border-0 mt-0 pt-0 float-none">
                      <button
                        id="save-policy-details"
                        className="btn btn-primary mr-1 shadow round text-uppercase"
                        type="submit"
                        onClick={() => {
                          // setStateRfq(false);

                        }}
                      >
                        <div className="d-flex align-items-center">
                          Upload
                          <span
                            id="opportunity-docs-form-loader"
                            className="spinner-border spinner-border-sm ml-half hidden"
                          ></span>
                        </div>
                      </button>

                      <button
                        className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                        onClick={event => {
                          event.preventDefault();
                          props.handleClose();
                          setValue("department_name", "");
                          // setStateRfq(false);
                        }}
                      >
                        Close
                      </button>
                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
const mapStateToProps = state => {
  var DocumentUpload = null;


  if (
    state.OpportunityUploadDocumentReducer != null &&
    Object.keys(state.OpportunityUploadDocumentReducer).length > 0 &&
    state.OpportunityUploadDocumentReducer.constructor === Object
  ) {
    switch (state.OpportunityUploadDocumentReducer.tag) {
      case ACTION_DOCUMENT_UPLOAD_SUCCESS:
        DocumentUpload = Object.assign(
          {},
          state.OpportunityUploadDocumentReducer.data
        );
        delete state.OpportunityUploadDocumentReducer;
        return { DocumentUpload };
      default:
    }
  }
  return {
    DocumentUpload,

  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      actionDocumentUpload,
    },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(OpportunityDocumentsModel)
);
