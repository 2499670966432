import React, { useEffect, useState } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Controller, useForm } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import ScreenTitle from '../../../../../../component/screen_title';
import { DateBox } from "devextreme-react";
import dateFormat from "dateformat";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from 'jquery';
import { actionWellnessManagementClientType, ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_SUCCESS } from "./action";
import { actionWellnessManagementClientName, ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_SUCCESS } from "./action";
import { actionWellnessManagementAssignRm, ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS } from "./action";
import { actionWellnessManagementCustomerType, ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_SUCCESS } from "./action";
import { actionWellnessManagementRequestAdd, ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_SUCCESS } from "./action";
import { KEY_TOAST_MESSAGE, setData, KEY_USER, getData } from "../../../../../../utils/preferences";

const WellnessManagementAdd = (props) => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({
        defaultValues: {
            assign_rm: 0
        }
    });
    const [clientType, setClientType] = useState([]);
    const [clientName, setClientName] = useState([]);
    const [assignRm, setAssignRm] = useState([]);
    const [departments, setDepartments] = useState([]);

    const initialRender = () => {
        var userDetail = getData(KEY_USER);
        if (userDetail != null) {
            var userObj = JSON.parse(userDetail)
            setDepartments(userObj.departments)
            if (userObj.departments.includes('Wellness')) {
                props.actionWellnessManagementAssignRm();
            }
        }
        props.actionWellnessManagementClientType();
    }
    useEffect(initialRender, []);

    const onClientTypeUpdate = () => {
        const { ClientType } = props;
        var ClientTypeResponse = Object.assign({}, ClientType);
        if (ClientTypeResponse.result === true) {
            setClientType(ClientTypeResponse.response);
        } else {
            switch (ClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setClientType([]);
                    break;
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    const onClientNameUpdate = () => {
        const { ClientName } = props;
        var ClientNameResponse = Object.assign({}, ClientName);
        if (ClientNameResponse.result === true) {
            setClientName(ClientNameResponse.response);
        } else {
            switch (ClientNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setClientName([]);
                    break;
                default:
            }
        }
    }
    useEffect(onClientNameUpdate, [props.ClientName]);

    const onCustomerTypeUpdate = () => {
        const { CustomerType } = props;
        var CustomerTypeResponse = Object.assign({}, CustomerType);
        if (CustomerTypeResponse.result === true) {
            setClientName(CustomerTypeResponse.response);
        } else {
            switch (CustomerTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setClientName([]);
                    break;
                default:
            }
        }
    }
    useEffect(onCustomerTypeUpdate, [props.CustomerType]);

    const onAssignRmUpdate = () => {
        const { AssignRm } = props;
        var AssignRmResponse = Object.assign({}, AssignRm);
        if (AssignRmResponse.result === true) {
            var arrAssignRM = Object.assign([], AssignRmResponse.response);
            var user = {
                user_id: 0,
                full_name: "None"
            };
            arrAssignRM.unshift(user);
            setAssignRm(arrAssignRM);
        } else {
            switch (AssignRmResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setAssignRm([]);
                    break;
                default:
            }
        }
    }
    useEffect(onAssignRmUpdate, [props.AssignRm]);

    const onRequestAddUpdate = () => {
        const { RequestAdd } = props;
        var RequestAddResponse = Object.assign({}, RequestAdd);
        $("#save_wellness_management").prop("disabled", false);
        if (RequestAddResponse.result === true) {
            $("#wellness-management-form-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Wellness Request added successfully");
            props.history.goBack();
        } else {
            $("#wellness-management-form-loader").addClass("hidden");
            switch (RequestAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRequestAddUpdate, [props.RequestAdd]);

    const onSubmit = (data) => {
        $("#wellness-management-form-loader").removeClass("hidden");
        $("#save_wellness_management").prop("disabled", true);
        if (departments != null && departments.length > 0 && departments.includes('Wellness')) {
            data.assign_rm = data.assign_rm != null && data.assign_rm != 0 ? data.assign_rm : null;
        } else {
            delete data.assign_rm;
        }
        props.actionWellnessManagementRequestAdd(data);
    }

    return (
        <>
            <ScreenTitle title="Add Wellness Request" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        if (departments != null && departments.length > 0 && departments.includes('Wellness')) {
                                                                            props.actionWellnessManagementCustomerType({ client_type: event?.itemData?.value });
                                                                        } else {
                                                                            props.actionWellnessManagementClientName({ client_type: event?.itemData?.value });
                                                                        }

                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client Type is required'
                                                        }}
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_id"
                                                                name="client_id"
                                                                dataSource={clientName}
                                                                allowFiltering={true}
                                                                placeholder="Select Client Name"
                                                                value={field.value}
                                                                sortOrder="Ascending"
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client Name is required'
                                                        }}
                                                    />

                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Activity</label>
                                                    <Controller
                                                        name="activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="activity_date"
                                                                name="activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.activity_date && <ErrorLabel message={errors.activity_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remarks</label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks "
                                                        name="remarks"
                                                        type="text"
                                                        maxLength={200}
                                                        placeholder="Enter Remarks"
                                                        {...register('remarks', {
                                                            maxLength:
                                                            {
                                                                value: 200,
                                                                message: "Please enter 200 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.remarks?.type === 'required' && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                            {
                                                departments != null && departments.length > 0 && departments.includes('Wellness') ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Assign RM</label>
                                                            <Controller
                                                                name="assign_rm"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="assign_rm"
                                                                        name="assign_rm"
                                                                        dataSource={assignRm}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Assign RM"
                                                                        value={field.value}
                                                                        fields={{ text: 'full_name', value: 'user_id' }}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.user_id)
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            />

                                                            {errors.assign_rm && <ErrorLabel message={errors.assign_rm?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save_wellness_management"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="wellness-management-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var ClientType = null;
    var ClientName = null;
    var AssignRm = null;
    var CustomerType = null;
    var RequestAdd = null;

    if (
        state.addWellnessManagementReducer != null &&
        Object.keys(state.addWellnessManagementReducer).length > 0 &&
        state.addWellnessManagementReducer.constructor === Object
    ) {
        switch (state.addWellnessManagementReducer.tag) {
            case ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_SUCCESS:
                ClientType = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { ClientType };
            case ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_SUCCESS:
                ClientName = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { ClientName };
            case ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS:
                AssignRm = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { AssignRm };
            case ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_SUCCESS:
                CustomerType = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { CustomerType };
            case ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_SUCCESS:
                RequestAdd = Object.assign({}, state.addWellnessManagementReducer.data);
                delete state.addWellnessManagementReducer;
                return { RequestAdd };
            default:
        }
    }
    return {
        ClientType,
        ClientName,
        AssignRm,
        CustomerType,
        RequestAdd
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionWellnessManagementClientType,
        actionWellnessManagementClientName,
        actionWellnessManagementAssignRm,
        actionWellnessManagementCustomerType,
        actionWellnessManagementRequestAdd
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessManagementAdd))
