import React, { useEffect } from "react";
import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi';
import ErrorLabel from "../../../../component/form/error_label";
import { useForm } from "react-hook-form";
import { actionNewPassword, ACTION_NEW_PASSWORD_SUCCESS } from "./action"
import $ from 'jquery';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PRIMARY_COLOR, UNAUTHORIZED } from "../../../../constants";
import Swal from "sweetalert2";
const NewPassword = (props) => {

    const { match } = props;
    const { register, handleSubmit, formState: { errors }, getValues } = useForm({});

    const OnNewPasswordSuccess = () => {
        const { NewPassword } = props;
        var NewPasswordResponse = Object.assign({}, NewPassword);
        if (NewPasswordResponse.result === true) {
            Swal.fire({
                title: 'Success',
                icon: 'success',
                text: NewPasswordResponse.message,
                confirmButtonColor: PRIMARY_COLOR
            }).then((result) => {
                props.history.replace("/login");
            });

        } else {
            switch (NewPasswordResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(OnNewPasswordSuccess, [props.NewPassword]);


    const onSubmit = (data) => {
        data.email = props.location.state.email;
        props.actionNewPassword(data);
    }

    return (
        <>
            <div className="container login-container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 order-2 order-md-1 order-lg-1 mb-3 mb-md-0 mb-lg-0">
                        <div className="d-none d-md-flex d-lg-flex flex-row mb-2">
                            <div className="flex-column">
                                <img src={'/assets/images/app-logo.jpg'} style={{ height: 30, objectFit: 'cover' }} alt="" />
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-2">
                            <div className="flex-column">
                                <h1 className="text-bold-500 text-black">
                                    <span className="d-block">Networking &amp; customer</span>
                                    <span className="d-block">management made easier,</span>
                                    <span className="d-block">just for you!!</span>
                                </h1>
                            </div>
                        </div>
                        <div className="d-flex flex-row mt-2">
                            <div className="flex-column">
                                <p className="font-small-4 text-black lh-1">
                                    <span className="d-block text-justify">an interactive platform for PINC employees to help manage work on the go;</span>
                                    <span className="d-block text-justify">Log in meetings and leads with clients. Share news. Congratulate co-worker on</span>
                                    <span className="d-block text-justify">achievements and interact with co-workers by using the dashboard.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 order-1 order-md-2 order-lg-2 mb-3 mb-md-0 mb-lg-0">
                        <div className="d-flex d-md-none d-lg-none flex-row mb-2 justify-content-center justify-content-lg-start justify-content-md-start">
                            <img src={'/assets/images/app-logo.jpg'} style={{ height: 30, objectFit: 'cover' }} alt="" />
                        </div>
                        <div className="card shadow rounded p-4 p-md-5 mb-0">
                            <div className="card-title text-center text-black" style={{ fontWeight: 600 }}>Set New Password</div>
                            <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <label>Password<span className="text-danger">*</span></label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="form-control"
                                        {...register('password', {
                                            required: 'Password is required',
                                            minLength: {
                                                value: 8,
                                                message: 'Please enter minimum 8 character'
                                            },
                                            validate: (value) => {
                                                if (value.search(/[0-9]/) == -1) {
                                                    return "Atlease 1 numeric value is required"
                                                }
                                                if (value.search(/[a-z]/) == -1) {
                                                    return "Atlease 1 small letter is required"
                                                }
                                                if (value.search(/[A-Z]/) == -1) {
                                                    return "Atlease 1 Capital letter is required"
                                                }
                                                if (value.search(/[#$^+=!*()@%&]/) == -1) {
                                                    return "Atlease 1 Special Symbol is required"
                                                }
                                            }
                                        })}
                                    />
                                    {errors.password && <ErrorLabel message={errors.password?.message} />}
                                    <div className="form-control-position">
                                        <FiLock size={24} className="primary" />
                                    </div>
                                    <div className="form-control-position" style={{ position: 'absolute', right: 0 }}>
                                        <FiEye size={18} className="primary hidden" id="show_password" onClick={() => {
                                            $("#password").attr("type", "password");
                                            $("#show_password").addClass("hidden");
                                            $("#hide_password").removeClass("hidden");
                                        }} />
                                        <FiEyeOff size={18} className="primary" id="hide_password" onClick={() => {
                                            $("#password").attr("type", "text");
                                            $("#hide_password").addClass("hidden");
                                            $("#show_password").removeClass("hidden");
                                        }} />
                                    </div>
                                </fieldset>
                                <fieldset className="form-group position-relative has-icon-left">
                                    <label>Confirm Password<span className="text-danger">*</span></label>
                                    <input
                                        type="password"
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        className="form-control"
                                        {...register('password_confirmation', {
                                            required: 'Confirm Password is required',
                                            minLength: {
                                                value: 8,
                                                message: 'Please enter minimum 8 character'
                                            },
                                            validate: (value) => {
                                                if (value.search(/[0-9]/) == -1) {
                                                    return "Atlease 1 numeric value is required"
                                                }
                                                if (value.search(/[a-z]/) == -1) {
                                                    return "Atlease 1 small letter is required"
                                                }
                                                if (value.search(/[A-Z]/) == -1) {
                                                    return "Atlease 1 Capital letter is required"
                                                }
                                                if (value.search(/[#$^+=!*()@%&]/) == -1) {
                                                    return "Atlease 1 Special Symbol is required"
                                                }
                                            },
                                            validate: (value) => {
                                                return getValues("password") === value || "Password do not match"
                                            }
                                        })}
                                    />
                                    {errors.password_confirmation && <ErrorLabel message={errors.password_confirmation?.message} />}
                                    <div className="form-control-position">
                                        <FiLock size={24} className="primary" />
                                    </div>
                                    <div className="form-control-position" style={{ position: 'absolute', right: 0 }}>
                                        <FiEye size={18} className="primary hidden" id="show_confirm_password" onClick={() => {
                                            $("#password_confirmation").attr("type", "password");
                                            $("#show_confirm_password").addClass("hidden");
                                            $("#hide_confirm_password").removeClass("hidden");
                                        }} />
                                        <FiEyeOff size={18} className="primary" id="hide_confirm_password" onClick={() => {
                                            $("#password_confirmation").attr("type", "text");
                                            $("#hide_confirm_password").addClass("hidden");
                                            $("#show_confirm_password").removeClass("hidden");
                                        }} />
                                    </div>
                                </fieldset>
                                <div className="d-flex flex-row justify-content-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-block"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    var NewPassword = null;
    if (
        state.NewPasswordReducer != null &&
        Object.keys(state.NewPasswordReducer).length > 0 &&
        state.NewPasswordReducer.constructor === Object
    ) {
        switch (state.NewPasswordReducer.tag) {
            case ACTION_NEW_PASSWORD_SUCCESS:
                NewPassword = Object.assign({}, state.NewPasswordReducer.data);
                delete state.NewPasswordReducer;
                return { NewPassword };
            default:
        }
    }
    return {
        NewPassword
    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionNewPassword }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(NewPassword));