import createReducer from "../../../reducers/createReducer";
import { ACTION_DOCUMENT_UPLOAD_SUCCESS,ACTION_PROPOSAL_VIEW_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
};

const OpportunityUploadDocumentReducer = createReducer(INITIAL_STATE, {
    [ACTION_DOCUMENT_UPLOAD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_DOCUMENT_UPLOAD_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_PROPOSAL_VIEW_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_PROPOSAL_VIEW_SUCCESS,
                loading: false,
            }
        );
    },
   
});
export default OpportunityUploadDocumentReducer;    