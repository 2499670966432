import React, { useEffect, useState } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Controller, useForm } from "react-hook-form";
import ErrorLabel from "../../../../../../component/form/error_label";
import ScreenTitle from '../../../../../../component/screen_title';
import { checkFileExtension } from '../../../../../../utils';
import { ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED, characterLimit } from '../../../../../../constants';
import dateFormat from "dateformat";
import Swal from 'sweetalert2';
import { DateBox } from 'devextreme-react/date-box';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { ACTION_RISK_MANAGEMENT_CLIENT_TYPE_SUCCESS, actionRiskManagementClientType } from "./action";
import { ACTION_RISK_MANAGEMENT_CLIENT_NAME_SUCCESS, actionRiskManagementClientName } from "./action";
import { ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_SUCCESS, actionRiskManagementTypeOfInspection } from "./action";
import { ACTION_ASSIGN_ENGINEER_SUCCESS, actionMasterAssignEngineer } from "./action";
import { ACTION_RISK_MASTER_CUSTOMER_TYPE_SUCCESS, actionMasterCustomerType } from "./action";
import { ACTION_RISK_MANAGEMENT_REQUEST_ADD_SUCCESS, actionRiskManagementRequestAdd } from "./action";
import { KEY_TOAST_MESSAGE, setData, getData, KEY_USER } from '../../../../../../utils/preferences';
import { bindActionCreators } from "redux";
import $ from 'jquery';
import { toast } from "react-toastify";

const RiskManagementAdd = (props) => {
    const { register, handleSubmit, control, formState: { errors }, watch } = useForm({
        defaultValues: {
            assign_engineer: 0
        }
    });

    const [selectTypeOfInspection, setSelectTypeOfInspection] = useState(null)
    const [riskDocument, setRiskDocument] = useState(null);
    const validExt = ['doc', 'docx', 'pptx', 'ppt', 'xlsx', 'xls', 'pdf', 'zip'];
    const [clientType, setClientType] = useState([]);
    const [clientName, setClientName] = useState([]);
    const [typeOfInspection, setTypeOfInspection] = useState([]);
    const [masterAssignEngineer, setMasterAssignEngineer] = useState([]);
    const [departments, setDepartments] = useState([]);

    const initialRender = () => {
        var userDetail = getData(KEY_USER);
        if (userDetail != null) {
            var userObj = JSON.parse(userDetail)
            setDepartments(userObj.departments)
            if (userObj.departments.includes('Risk Management')) {
                props.actionMasterAssignEngineer()
            }
        }
        props.actionRiskManagementClientType();
        props.actionRiskManagementTypeOfInspection();
    }
    useEffect(initialRender, []);

    const onClientTypeUpdate = () => {
        const { ClientType } = props;
        var ClientTypeResponse = Object.assign({}, ClientType);
        if (ClientTypeResponse.result === true) {
            setClientType(ClientTypeResponse.response);
        } else {
            switch (ClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setClientType([]);
                    break;
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    const onClientNameUpdate = () => {
        const { ClientName } = props;
        var ClientNameResponse = Object.assign({}, ClientName);
        if (ClientNameResponse.result === true) {
            setClientName(ClientNameResponse.response);
        } else {
            switch (ClientNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setClientName([]);
                    break;
                default:
            }
        }
    }
    useEffect(onClientNameUpdate, [props.ClientName]);

    const onMasterCustomerTypeUpdate = () => {
        const { MasterCustomerType } = props;
        var MasterCustomerTypeResponse = Object.assign({}, MasterCustomerType);
        if (MasterCustomerTypeResponse.result === true) {
            setClientName(MasterCustomerTypeResponse.response);
        } else {
            switch (MasterCustomerTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setClientName([]);
                    break;
                default:
            }
        }
    }
    useEffect(onMasterCustomerTypeUpdate, [props.MasterCustomerType]);

    const onTypeOfInspectionUpdate = () => {
        const { TypeOfInspection } = props;
        var TypeOfInspectionResponse = Object.assign({}, TypeOfInspection);
        if (TypeOfInspectionResponse.result === true) {
            setTypeOfInspection(TypeOfInspectionResponse.response);
        } else {
            switch (TypeOfInspectionResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setTypeOfInspection([]);
                default:
            }
        }
    }
    useEffect(onTypeOfInspectionUpdate, [props.TypeOfInspection]);

    const onMasterAssignEngineerUpdate = () => {
        const { MasterAssignEngineer } = props;
        var MasterAssignEngineerResponse = Object.assign({}, MasterAssignEngineer);
        if (MasterAssignEngineerResponse.result === true) {
            var arrAssignEngineer = Object.assign([], MasterAssignEngineerResponse.response);
            var user = {
                user_id: 0,
                full_name: "None"
            };
            arrAssignEngineer.unshift(user);
            setMasterAssignEngineer(arrAssignEngineer);
        } else {
            switch (MasterAssignEngineerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    setMasterAssignEngineer([]);
                default:
            }
        }
    }
    useEffect(onMasterAssignEngineerUpdate, [props.MasterAssignEngineer]);

    const onRequestAddUpdate = () => {
        const { RequestAdd } = props;
        var RequestAddResponse = Object.assign({}, RequestAdd);
        $("#save_risk_management").prop("disabled", false);
        if (RequestAddResponse.result === true) {
            $("#risk-management-form-loader").addClass("hidden");
            props.history.goBack();
            setData(KEY_TOAST_MESSAGE, "Request added successfully");
        } else {
            $("#risk-management-form-loader").addClass("hidden");
            switch (RequestAddResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    toast.error(RequestAddResponse.message);
                default:
            }
        }
    }
    useEffect(onRequestAddUpdate, [props.RequestAdd]);

    const onSubmit = (data) => {

        var files = [];
        if (riskDocument != null) {
            files.push({ 'name': 'upload_document', 'value': riskDocument });
        }

        delete data.upload_document;
        data.client_id = Number(data.client_id);
        if (departments != null && departments.length > 0 && departments.includes('Risk Management')) {
            data.assign_engineer = data.assign_engineer != null && data.assign_engineer != 0 ? data.assign_engineer : '';
        } else {
            delete data.assign_engineer;
        }

        $("#risk-management-form-loader").removeClass("hidden");
        $("#save_risk_management").prop("disabled", true);
        props.actionRiskManagementRequestAdd(data, files);
    }

    return (
        <>
            <ScreenTitle title="Add Risk Request" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        if (departments != null && departments.length > 0 && departments.includes('Risk Management')) {
                                                                            props.actionMasterCustomerType({ client_type: event?.itemData?.value });
                                                                        } else {
                                                                            props.actionRiskManagementClientName({ client_type: event?.itemData?.value });
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client Type is required'
                                                        }}
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="client_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_id"
                                                                name="client_id"
                                                                dataSource={clientName}
                                                                allowFiltering={true}
                                                                placeholder="Select Client Name"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Client Name is required'
                                                        }}
                                                    />

                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Occupancy</label>
                                                    <input
                                                        className="form-control"
                                                        id="occupancy"
                                                        name="occupancy"
                                                        type="text"
                                                        placeholder="Enter occupancy"
                                                        {...register('occupancy')}
                                                    />
                                                    {errors.occupancy?.type && <ErrorLabel message={errors.occupancy?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Sum Insured </label>
                                                    <input
                                                        className="form-control"
                                                        id="sum_insured"
                                                        name="sum_insured"
                                                        type="text"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        placeholder="Enter sum insured"
                                                        {...register('sum_insured',
                                                            {
                                                                pattern: {
                                                                    value: /^[0-9]+$/,
                                                                    message: 'Please enter valid value'
                                                                }
                                                            }
                                                        )}
                                                    />
                                                    {errors.sum_insured?.type && <ErrorLabel message={errors.sum_insured?.message} />}
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Inspection&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="inspection_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="inspection_id"
                                                                name="inspection_id"
                                                                dataSource={typeOfInspection}
                                                                allowFiltering={false}
                                                                placeholder="Select Type of Inspection"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectTypeOfInspection(event.itemData.name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: 'Type of Inspection is required'
                                                        }}
                                                    />
                                                    {errors.inspection_id && <ErrorLabel message={errors.inspection_id?.message} />}
                                                </div>
                                            </div>
                                            {
                                                selectTypeOfInspection === 'Others' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="description"
                                                                name="description"
                                                                type="text"
                                                                placeholder="Enter description"
                                                                {...register('description', {
                                                                    required: selectTypeOfInspection === "Others" ? "Description is required" : false
                                                                })}
                                                            />
                                                            {errors.description?.type && <ErrorLabel message={errors.description?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Document</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="upload_document"
                                                            accept=".doc,.docx,.pptx,.ppt,.xlsx,.xls,.pdf,.zip"
                                                            name="upload_document"
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0].size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setRiskDocument(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    }
                                                                    else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload Word, PPT, Excel, PDF & ZIP files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="upload_document">
                                                            {
                                                                riskDocument != null ?
                                                                    riskDocument.name : "Choose file"
                                                            }
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Inspection</label>
                                                    <Controller
                                                        name="inspection_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="inspection_date"
                                                                name="inspection_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.inspection_date && <ErrorLabel message={errors.inspection_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remarks</label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks "
                                                        name="remarks"
                                                        type="text"
                                                        maxLength={200}
                                                        placeholder="Enter Remarks"
                                                        {...register('remarks', {
                                                            maxLength:
                                                            {
                                                                value: 200,
                                                                message: "Please enter 200 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.remarks?.type === 'required' && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                            {
                                                departments != null && departments.length > 0 && departments.includes('Risk Management') ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Assign Engineer</label>
                                                            <Controller
                                                                name="assign_engineer"
                                                                control={control}
                                                                render={({ field }) =>
                                                                    <DropDownListComponent
                                                                        {...field}
                                                                        id="assign_engineer"
                                                                        name="assign_engineer"
                                                                        dataSource={masterAssignEngineer}
                                                                        allowFiltering={false}
                                                                        placeholder="Select Assign Engineer"
                                                                        value={field.value}
                                                                        fields={{ text: 'full_name', value: 'user_id' }}
                                                                        change={(event) => {
                                                                            if (event.itemData != null) {
                                                                                field.onChange(event.itemData.user_id)
                                                                            }
                                                                        }}
                                                                    />
                                                                }
                                                            />

                                                            {errors.assign_engineer && <ErrorLabel message={errors.assign_engineer?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                            }


                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="save_risk_management"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="risk-management-form-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var ClientType = null;
    var ClientName = null;
    var TypeOfInspection = null;
    var MasterAssignEngineer = null;
    var MasterCustomerType = null;
    var RequestAdd = null;

    if (
        state.addRiskManagementReducer != null &&
        Object.keys(state.addRiskManagementReducer).length > 0 &&
        state.addRiskManagementReducer.constructor === Object
    ) {
        switch (state.addRiskManagementReducer.tag) {
            case ACTION_RISK_MANAGEMENT_CLIENT_TYPE_SUCCESS:
                ClientType = Object.assign({}, state.addRiskManagementReducer.data);
                delete state.addRiskManagementReducer;
                return { ClientType };
            case ACTION_RISK_MANAGEMENT_CLIENT_NAME_SUCCESS:
                ClientName = Object.assign({}, state.addRiskManagementReducer.data);
                delete state.addRiskManagementReducer;
                return { ClientName };
            case ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_SUCCESS:
                TypeOfInspection = Object.assign({}, state.addRiskManagementReducer.data);
                delete state.addRiskManagementReducer;
                return { TypeOfInspection };
            case ACTION_ASSIGN_ENGINEER_SUCCESS:
                MasterAssignEngineer = Object.assign({}, state.addRiskManagementReducer.data);
                delete state.addRiskManagementReducer;
                return { MasterAssignEngineer };
            case ACTION_RISK_MASTER_CUSTOMER_TYPE_SUCCESS:
                MasterCustomerType = Object.assign({}, state.addRiskManagementReducer.data);
                delete state.addRiskManagementReducer;
                return { MasterCustomerType };
            case ACTION_RISK_MANAGEMENT_REQUEST_ADD_SUCCESS:
                RequestAdd = Object.assign({}, state.addRiskManagementReducer.data);
                delete state.addRiskManagementReducer;
                return { RequestAdd };
            default:
        }
    }
    return {
        ClientType,
        ClientName,
        TypeOfInspection,
        MasterAssignEngineer,
        MasterCustomerType,
        RequestAdd

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionRiskManagementClientType,
        actionRiskManagementClientName,
        actionRiskManagementTypeOfInspection,
        actionMasterAssignEngineer,
        actionMasterCustomerType,
        actionRiskManagementRequestAdd
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskManagementAdd))