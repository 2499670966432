import Api from "../../../../../../api";
import { API_RISK_MANAGEMENT_CLIENT_TYPE, API_RISK_SELECT_CUSTOMER, API_ASSIGN_RM, API_RISK_MASTER_CUSTOMER_TYPE, API_WELLNESS_MANAGEMENT_REQUEST_ADD } from "../../../../../../api/constants";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";

export const ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_LOADING = 'ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_LOADING';
export const ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_SUCCESS = 'ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_SUCCESS';

export const ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_LOADING = 'ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_LOADING';
export const ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_SUCCESS = 'ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_SUCCESS';

export const ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_LOADING = 'ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_LOADING';
export const ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS = 'ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS';

export const ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_LOADING = 'ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_LOADING';
export const ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_SUCCESS = 'ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_SUCCESS';

export const ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_LOADING = 'ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_LOADING';
export const ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_SUCCESS = 'ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_SUCCESS';


export function actionWellnessManagementClientType() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_CLIENT_TYPE).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WELLNESS_MANAGEMENT_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionWellnessManagementAssignRm() {
    return (dispatch, getState) =>
        Api.get(API_ASSIGN_RM).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WELLNESS_MANAGEMENT_ASSIGN_RM_LOADING, { loading: false, data }));
        });
}
export function actionWellnessManagementCustomerType(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_MASTER_CUSTOMER_TYPE, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WELLNESS_MANAGEMENT_CUSTOMER_TYPE_LOADING, { loading: false, data }));
        });
}
export function actionWellnessManagementClientName(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_SELECT_CUSTOMER, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WELLNESS_MANAGEMENT_CLIENT_NAME_LOADING, { loading: false, data }));
        });
}
export function actionWellnessManagementRequestAdd(params) {
    return (dispatch, getState) =>
        Api.post(API_WELLNESS_MANAGEMENT_REQUEST_ADD, params).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_WELLNESS_MANAGEMENT_REQUEST_ADD_LOADING, { loading: false, data }));
        });
}

