import Api from "../../../../../../api";
import { fetchSuccess, loading } from "../../../../../../utils/action.util";
import { API_RISK_MANAGEMENT_CLIENT_TYPE } from "../../../../../../api/constants";
import { API_RISK_SELECT_CUSTOMER } from "../../../../../../api/constants";
import { API_RISK_MANAGEMENT_TYPE_OF_INSPECTION } from "../../../../../../api/constants";
import { API_RISK_MANAGEMENT_FINANCIAL_YEAR } from "../../../../../../api/constants";
import { API_RISK_MANAGEMENT_REQUEST_VIEW } from "../../../../../../api/constants";
import { API_RISK_MANAGEMENT_EDIT } from "../../../../../../api/constants";
import { PRINT } from "../../../../../../utils";

export const ACTION_RISK_APPROVAL_CLIENT_TYPE_LOADING = 'ACTION_RISK_APPROVAL_CLIENT_TYPE_LOADING';
export const ACTION_RISK_APPROVAL_CLIENT_TYPE_SUCCESS = 'ACTION_RISK_APPROVAL_CLIENT_TYPE_SUCCESS';

export const ACTION_RISK_APPROVAL_CLIENT_NAME_LOADING = 'ACTION_RISK_APPROVAL_CLIENT_NAME_LOADING';
export const ACTION_RISK_APPROVAL_CLIENT_NAME_SUCCESS = 'ACTION_RISK_APPROVAL_CLIENT_NAME_SUCCESS';

export const ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_LOADING = 'ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_LOADING';
export const ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_SUCCESS = 'ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_SUCCESS';

export const ACTION_RISK_APPROVAL_FINANCIAL_YEAR_LOADING = 'ACTION_RISK_APPROVAL_FINANCIAL_YEAR_LOADING';
export const ACTION_RISK_APPROVAL_FINANCIAL_YEAR_SUCCESS = 'ACTION_RISK_APPROVAL_FINANCIAL_YEAR_SUCCESS';

export const ACTION_RISK_APPROVAL_VIEW_LOADING = 'ACTION_RISK_APPROVAL_VIEW_LOADING';
export const ACTION_RISK_APPROVAL_VIEW_SUCCESS = 'ACTION_RISK_APPROVAL_VIEW_SUCCESS';

export const ACTION_RISK_APPROVAL_EDIT_LOADING = 'ACTION_RISK_APPROVAL_EDIT_LOADING';
export const ACTION_RISK_APPROVAL_EDIT_SUCCESS = 'ACTION_RISK_APPROVAL_EDIT_SUCCESS';

export function actionRiskManagementClientType() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_CLIENT_TYPE).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RISK_APPROVAL_CLIENT_TYPE_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_APPROVAL_CLIENT_TYPE_LOADING, { loading: false, data }));
        });
}

export function actionRiskManagementClientName(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_SELECT_CUSTOMER, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RISK_APPROVAL_CLIENT_NAME_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_APPROVAL_CLIENT_NAME_LOADING, { loading: false, data }));
        });
}

export function actionRiskManagementTypeOfInspection() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_TYPE_OF_INSPECTION).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_LOADING, { loading: false, data }));
        });
}

export function actionRiskManagementFinancialYear() {
    return (dispatch, getState) =>
        Api.get(API_RISK_MANAGEMENT_FINANCIAL_YEAR).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RISK_APPROVAL_FINANCIAL_YEAR_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_APPROVAL_FINANCIAL_YEAR_LOADING, { loading: false, data }));
        });
}

export function actionViewRiskManagmentApproval(params) {
    return (dispatch, getState) =>
        Api.post(API_RISK_MANAGEMENT_REQUEST_VIEW, params).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RISK_APPROVAL_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_APPROVAL_VIEW_LOADING, { loading: false, data }));
        });
}

export function actionEditRiskManagmentApproval(params, files) {
    return (dispatch, getState) =>
        Api.multipart(API_RISK_MANAGEMENT_EDIT, params, files).then((res) => {
            const data = res;
            PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_RISK_APPROVAL_EDIT_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_RISK_APPROVAL_EDIT_LOADING, { loading: false, data }));
        });
}