import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withAuth } from 'react-auth-guard';
import { useForm, Controller } from "react-hook-form";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import dateFormat from "dateformat";
import { actionCalenderEvents, ACTION_CALENDER_EVENT_SUCCESS } from "./action";
import { actionCalenderEventDetails, ACTION_CALENDER_EVENT_DETAILS_SUCCESS } from "./action";
import { actionMyTeamVertical, ACTION_MY_TEAM_VERTICAL_SUCCESS } from "./action";
import { actionCalenderTeamEvents, ACTION_CALENDER_TEAM_EVENT_SUCCESS } from "./action";
import { actionCalenderTeamEventDetails, ACTION_CALENDER_TEAM_EVENT_DETAILS_SUCCESS } from "./action";
import { actionCalenderGETEventDetails, ACTION_CALENDER_GET_EVENT_DETAILS_SUCCESS } from "./action";
import { ERROR, EVENT_TYPE, UNAUTHORIZED } from '../../../../../constants';
import $ from "jquery";
import _ from "lodash";
import { getMonthNumber, verifyPermission } from '../../../../../utils';
import MyTeamCalendar from './my_team';
import { FiFilter } from "react-icons/fi";

var selectedTeamMembers = []

const HolidayCalendar = (props) => {

    const { control } = useForm({});

    const date = dateFormat(new Date(), {
        month: 'long',
        year: 'numeric',
        day: 'numeric'
    });

    const [myTeamVertical, setMyTeamVertical] = useState([]);
    const [myTeamMembers, setMyTeamMembers] = useState([]);
    const [myCalenderCount, setMyCalenderCount] = useState(0);
    const [myCalender, setMyCalender] = useState(false);
    const [myTeamCalenderCount, setMyTeamCalenderCount] = useState(0);
    const [myTeamCalender, setMyTeamCalender] = useState(false);
    const [events, setEvents] = useState([]);
    const [teamEvents, setTeamEvents] = useState([]);
    const [eventDetails, setEventDetails] = useState([]);
    const [teamEventDetails, setTeamEventDetails] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTeamDate, setSelectedTeamDate] = useState(null);
    const [showRMName, setShowRMName] = useState(false);
    const [userIds, setUserIds] = useState([]);
    const [timeGridDay, setTimeGridDay] = useState(false);
    const [showTeamCalendar, setShowTeamCalendar] = useState(true);
    const [selectedButton, setSelectedButton] = useState('MY_CALENDER')
    const [selectedEvent, setSelectedEvent] = useState([])

    const initialRender = () => {

        if (props.location?.state?.from === 'dashboard') {
            setMyTeamCalender(true)
            setSelectedTeamDate(dateFormat("yyyy-mm-dd"));
            setSelectedButton('MY_TEAM_CALENDER');
        } else {
            setMyCalender(true);
            setSelectedDate(dateFormat("yyyy-mm-dd"));
            setSelectedButton('MY_CALENDER')
            props.actionCalenderEventDetails({ 'date': dateFormat("yyyy-mm-dd") });
        }

        props.actionMyTeamVertical({ user_id: props.auth.getUser().user_id });


        setTimeout(() => {
            var strDate = $(".fc-toolbar-title").html();
            var arrDate = _.split(strDate, " ");
            props.actionCalenderEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1] });
        }, 1200)

        $(document).on("click", ".fc-next-button", function () {
            var strDate = $(".fc-toolbar-title").html();
            var arrDate = _.split(strDate, " ");
            setTimeGridDay(false);
            if ($("#selectedButton").val() === 'MY_CALENDER') {
                props.actionCalenderEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1] });
            } else {
                props.actionCalenderTeamEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1], 'user_ids': selectedTeamMembers });
            }
        })

        $(document).on("click", ".fc-button-group", function () {
            var strDate = $(".fc-toolbar-title").html();
            var arrDate = _.split(strDate, " ");
            setTimeGridDay(false);
            if ($("#selectedButton").val() === 'MY_CALENDER') {
                setTimeout(() => {
                    var strDate = $(".fc-toolbar-title").html();
                    var arrDate = _.split(strDate, " ");
                    props.actionCalenderEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1] });
                }, 1200)
            } else {
                setTimeout(() => {
                    props.actionCalenderTeamEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1], 'user_ids': userIds != null && userIds.length > 0 ? userIds : selectedTeamMembers });
                }, 3000)
            }
        })

        $(document).on("click", ".fc-prev-button", function () {
            var strDate = $(".fc-toolbar-title").html();
            var arrDate = _.split(strDate, " ");
            setTimeGridDay(false);
            if ($("#selectedButton").val() === 'MY_CALENDER') {
                props.actionCalenderEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1] });
            } else {
                props.actionCalenderTeamEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1], 'user_ids': selectedTeamMembers });
            }
        })

        $(document).on("click", ".fc-timeGridWeek-button", function () {
            var strDate = $(".fc-toolbar-title").html();
            var arrDate = _.split(strDate, " ");
            setTimeGridDay(false);
            if ($("#selectedButton").val() === 'MY_CALENDER') {
                props.actionCalenderEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1] });
            } else {
                props.actionCalenderTeamEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1], 'user_ids': selectedTeamMembers });
            }
        })

        $(document).on("click", ".fc-dayGridMonth-button", function () {
            setTimeGridDay(false);
        })

        $(document).on("click", ".fc-timeGridDay-button", function () {
            var strDate = $(".fc-toolbar-title").html();
            var arrDate = _.split(strDate, " ");
            setTimeGridDay(true);
            if ($("#selectedButton").val() === 'MY_CALENDER') {
                props.actionCalenderEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1] });
            } else {
                props.actionCalenderTeamEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1], 'user_ids': selectedTeamMembers });
            }
        })
    }
    useEffect(initialRender, []);

    const onCalenderEventsUpdate = () => {
        const { Events } = props;
        var EventsResponse = Object.assign({}, Events);
        if (EventsResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setShowTeamCalendar(true)
            setEvents(EventsResponse.response);
            setMyCalenderCount(EventsResponse.response.length);
            setMyTeamCalenderCount(0);
        } else {
            $("#progress-loader").addClass("hidden");
            setShowTeamCalendar(true)
            switch (EventsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onCalenderEventsUpdate, [props.Events]);

    const onMyTeamVerticalUpdate = () => {
        const { MyTeamVertical } = props;
        var MyTeamVerticalResponse = Object.assign({}, MyTeamVertical);
        if (MyTeamVerticalResponse.result === true) {
            setShowTeamCalendar(true)
            var myTeam = []
            var myTeamMember = []
            MyTeamVerticalResponse.response.map((item) => {
                if (item.id != props.auth.getUser().user_id) {
                    myTeam.push(item);
                    myTeamMember.push(item.id);
                    setMyTeamVertical(myTeam);
                    setMyTeamMembers(myTeamMember);

                }
            })
            selectedTeamMembers = myTeamMember
            if (props.location?.state?.from === 'dashboard') {
                setUserIds(myTeamMember);
            }
        } else {
            setShowTeamCalendar(true)
            switch (MyTeamVerticalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onMyTeamVerticalUpdate, [props.MyTeamVertical]);

    const onCalenderEventDetailsUpdate = () => {
        const { EventDetails } = props;
        var EventDetailsResponse = Object.assign({}, EventDetails);
        if (EventDetailsResponse.result === true) {
            setEventDetails(EventDetailsResponse.response);
        } else {
            switch (EventDetailsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                default:
            }
        }
    }
    useEffect(onCalenderEventDetailsUpdate, [props.EventDetails]);

    const onTeamEventsUpdate = () => {
        const { TeamEvents } = props;
        var TeamEventsResponse = Object.assign({}, TeamEvents);
        if (TeamEventsResponse.result === true) {
            setTeamEvents(TeamEventsResponse.response);
            setMyTeamCalenderCount(TeamEventsResponse.response.length);
            setMyCalenderCount(0);
            setShowTeamCalendar(false)
            setShowRMName(false);
            // setShowTeamCalendar(true)
        } else {
            setShowTeamCalendar(false)
            setShowRMName(false);
            // setShowTeamCalendar(true)
            switch (TeamEventsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    break;
                default:
            }
        }
    }
    useEffect(onTeamEventsUpdate, [props.TeamEvents]);

    const onTeamEventDetailsUpdate = () => {
        const { TeamEventDetails } = props;
        var TeamEventDetailsResponse = Object.assign({}, TeamEventDetails);
        if (TeamEventDetailsResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setTeamEventDetails(TeamEventDetailsResponse.response);
            setShowTeamCalendar(false)
            setShowRMName(false);
        } else {
            $("#progress-loader").addClass("hidden");
            setShowTeamCalendar(false)
            setShowRMName(false);
            switch (TeamEventDetailsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setTeamEventDetails([]);
                    break;
                default:
            }
        }
    }
    useEffect(onTeamEventDetailsUpdate, [props.TeamEventDetails]);

    useEffect(() => {
        if (selectedEvent != null && selectedEvent.length > 0) {
            let arrEvents = []
            arrEvents = Object.assign([], selectedEvent)
            if (selectedButton === 'MY_CALENDER') {
                setEventDetails(arrEvents)
            } else {
                setTeamEventDetails(arrEvents)
            }
        }
    }, [selectedEvent])

    useEffect(() => {
        if (timeGridDay === true) {
            if (selectedTeamDate != null && myTeamCalender === true) {
                setSelectedTeamDate(new Date())
            }
            else if (selectedDate != null && myCalender === true) {
                setSelectedDate(new Date())
            }
        }
    }, [timeGridDay])

    const onEventGETDetailsUpdate = () => {
        const { SingleEventDetail } = props;
        var EventDetailsResponse = Object.assign({}, SingleEventDetail);
        if (EventDetailsResponse.result === true) {
            setSelectedEvent([EventDetailsResponse.response])
        } else {
            switch (EventDetailsResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout();
                    break;
                case ERROR:
                    setEventDetails([])
                    setTeamEventDetails([]);
                    break;
                default:
            }
        }
    }
    useEffect(onEventGETDetailsUpdate, [props.SingleEventDetail]);

    /**
     * UseEffect for My team Calender selection(UserIds sent to api else myTeamMemebers)
     */
    useEffect(() => {
        if (userIds != null && userIds.length > 0) {
            props.actionCalenderTeamEventDetails({ 'date': selectedTeamDate, 'user_ids': userIds });
            setSelectedTeamDate(selectedTeamDate);
            setTimeout(() => {
                var strDate = $(".fc-toolbar-title").html();
                var arrDate = _.split(strDate, " ");
                props.actionCalenderTeamEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1], 'user_ids': userIds });
            }, 1200)
        }
    }, [userIds])

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        <li class="nav-item">
                            <button
                                onClick={() => {
                                    setMyCalender(true);
                                    setShowRMName(false);
                                    setMyTeamCalender(false);
                                    setShowTeamCalendar(false)
                                    setUserIds([]);
                                    setTimeGridDay(false);
                                    setTeamEvents([]);
                                    setTeamEventDetails([]);
                                    setSelectedButton('MY_CALENDER')
                                    setSelectedDate(dateFormat("yyyy-mm-dd"));
                                    setTimeout(() => {
                                        var strDate = $(".fc-toolbar-title").html();
                                        var arrDate = _.split(strDate, " ");
                                        props.actionCalenderEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1] });
                                    }, 1200)

                                    $(document).on("click", ".fc-prev-button", function () {
                                        var strDate = $(".fc-toolbar-title").html();
                                        var arrDate = _.split(strDate, " ");
                                        props.actionCalenderEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1] });
                                    })
                                }}

                                className={`btn ${myCalenderCount > 0 && myCalender === true ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                My Calender {myCalenderCount > 0 && myCalender === true ? `(` + myCalenderCount + `)` : ``}
                            </button>
                        </li>
                        {
                            verifyPermission("SHOW_TEAM") ?
                                <li class="nav-item ml-1">
                                    <button
                                        onClick={() => {
                                            setMyTeamCalender(true);
                                            setMyCalender(false);
                                            setEvents([]);
                                            setEventDetails([]);
                                            setTimeGridDay(false);
                                            setSelectedButton('MY_TEAM_CALENDER');
                                            setSelectedTeamDate(dateFormat("yyyy-mm-dd"));
                                            if (showTeamCalendar != true) {
                                                if (showRMName === true) {
                                                    setShowRMName(false);
                                                } else {
                                                    setShowRMName(true);
                                                }
                                            }
                                            if (myTeamMembers != null && myTeamMembers.length > 0 && showTeamCalendar === true) {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionCalenderTeamEventDetails({ 'date': dateFormat("yyyy-mm-dd"), 'user_ids': myTeamMembers });

                                                setTimeout(() => {
                                                    var strDate = $(".fc-toolbar-title").html();
                                                    var arrDate = _.split(strDate, " ");
                                                    props.actionCalenderTeamEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1], 'user_ids': myTeamMembers });
                                                }, 1200)

                                                if (myTeamCalender === true) {
                                                    $(document).on("click", ".fc-timeGridWeek-button", function () {
                                                        var strDate = $(".fc-toolbar-title").html();
                                                        var arrDate = _.split(strDate, " ");
                                                        props.actionCalenderTeamEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[1], 'user_ids': myTeamMembers });
                                                    })

                                                    $(document).on("click", ".fc-timeGridDay-button", function () {
                                                        var strDate = $(".fc-toolbar-title").html();
                                                        var arrDate = _.split(strDate, " ");
                                                        props.actionCalenderTeamEvents({ 'month': getMonthNumber(arrDate[0], "string"), 'year': arrDate[2], 'user_ids': myTeamMembers });
                                                    })
                                                }
                                            }

                                        }}
                                        className={`btn ${myTeamCalenderCount > 0 && myTeamCalender === true ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        Team Calender <span className='mr-half'>{myTeamCalenderCount > 0 && myTeamCalender === true ? `(` + myTeamCalenderCount + `)` : ``}</span>
                                        <FiFilter size={16} />
                                    </button>
                                </li>
                                :
                                <></>
                        }
                    </ul>
                </div>
            </>
        )
    }

    const renderEventContent = (eventInfo) => {
        return (
            <>
                <div className="bg-circle" style={{
                    borderRadius: '50%',
                    backgroundColor: eventInfo.borderColor,
                    width: '8px',
                    height: '8px',
                    marginRight: '3px',
                    display: 'inline-block'
                }}>&nbsp;</div>
            </>
        )
    }

    const renderTeamEventContent = (eventInfo) => {
        return (
            <>
                <div className="bg-circle" style={{
                    borderRadius: '50%',
                    backgroundColor: eventInfo.borderColor,
                    width: '8px',
                    height: '8px',
                    marginRight: '3px',
                    display: 'inline-block'
                }}>&nbsp;</div>
            </>
        )
    }
    return (
        <>
            <div className="content-header row mb-1">
                {renderHeaderButtonUI()}
            </div>
            <div className="card">
                <div className="card-body">
                    <div class="row">
                        <input type='hidden' id='selectedButton' value={selectedButton} />
                        {
                            myCalender === true ?
                                <div className="col-12 col-md-8 col-lg-8">
                                    <Controller
                                        name="date"
                                        control={control}
                                        defaultValue={date}
                                        weekends={false}
                                        render={({ field }) =>
                                            <FullCalendar
                                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                headerToolbar={{
                                                    left: 'prev,today,next',
                                                    center: 'title',
                                                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                                                }}

                                                dayMaxEventRows={10}
                                                dayMaxEvents={20}
                                                initialView='dayGridMonth'

                                                editable={true}
                                                selectable={true}
                                                selectMirror={true}
                                                eventDisplay={'list-item'}
                                                eventContent={renderEventContent}
                                                events={events}
                                                dateClick={(data) => {
                                                    setEventDetails([]);
                                                    setSelectedDate(data.dateStr)
                                                    props.actionCalenderEventDetails({ 'date': data.dateStr });
                                                }}
                                                eventClick={(info) => {
                                                    setEventDetails([]);
                                                    setTeamEventDetails([]);
                                                    setSelectedDate(info.event.extendedProps.event_date);
                                                    setSelectedTeamDate(info.event.extendedProps.event_date);
                                                    props.actionCalenderGETEventDetails({ 'date': info.event.extendedProps.event_date, event_id: info.event.id, event_type: EVENT_TYPE[info.event.extendedProps.type] })
                                                }}
                                            />
                                        }
                                    />
                                </div>
                                :
                                <></>
                        }
                        {
                            myTeamCalender === true ?
                                <div className="col-12 col-md-8 col-lg-8">
                                    <Controller
                                        name="date"
                                        control={control}
                                        defaultValue={date}
                                        weekends={false}
                                        render={({ field }) =>
                                            <FullCalendar
                                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                headerToolbar={{
                                                    left: 'prev,today,next',
                                                    center: 'title',
                                                    right: 'dayGridMonth,timeGridWeek,timeGridDay',
                                                }}

                                                dayMaxEventRows={10}
                                                dayMaxEvents={20}
                                                initialView='dayGridMonth'

                                                editable={true}
                                                selectable={true}
                                                selectMirror={true}
                                                eventDisplay={'list-item'}
                                                eventContent={renderTeamEventContent}
                                                events={teamEvents}
                                                dateClick={(data) => {
                                                    setTeamEventDetails([]);
                                                    setSelectedTeamDate(data.dateStr);
                                                    props.actionCalenderTeamEventDetails({ 'date': data.dateStr, 'user_ids': userIds != null && userIds.length > 0 ? userIds : myTeamMembers });
                                                }}
                                                eventClick={(info) => {
                                                    setEventDetails([]);
                                                    setTeamEventDetails([]);
                                                    setSelectedTeamDate(info.event.extendedProps.event_date);
                                                    props.actionCalenderGETEventDetails({ 'date': info.event.extendedProps.event_date, event_id: info.event.id, event_type: EVENT_TYPE[info.event.extendedProps.type] })
                                                    // props.actionCalenderTeamEventDetails({ 'date': info.event.extendedProps.event_date, 'user_ids': userIds != null && userIds.length > 0 ? userIds : myTeamMembers });
                                                }}
                                            />
                                        }
                                    />
                                </div>
                                :
                                <></>
                        }

                        <div className="col-12 col-md-4 col-lg-4">
                            {
                                selectedDate != null && myCalender === true ?
                                    <div className="d-flex flex-row w-100 justify-content-center">
                                        <div className="flex-column">
                                            <div className="d-flex justify-content-center">
                                                <h1 className="font-large-5 text-bold-700 my-0 mr-half">
                                                    {
                                                        dateFormat(selectedDate, 'dd')
                                                    }
                                                </h1>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <h1 className="font-large-1 text-bold-500 my-0">
                                                    {
                                                        dateFormat(selectedDate, 'dddd')
                                                    }
                                                </h1>
                                            </div>

                                            <div className="d-flex justify-content-center">
                                                <h6 className="font-medium-1 text-bold-300 my-0 mt-1">
                                                    {
                                                        dateFormat(selectedDate, 'mmmm,yyyy')
                                                    }
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            {
                                selectedTeamDate != null && myTeamCalender === true ?
                                    <div className="d-flex flex-row w-100 justify-content-center">
                                        <div className="flex-column">
                                            <div className="d-flex justify-content-center">
                                                <h1 className="font-large-5 text-bold-700 my-0 mr-half">
                                                    {
                                                        dateFormat(selectedTeamDate, 'dd')
                                                    }
                                                </h1>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <h1 className="font-large-1 text-bold-500 my-0">
                                                    {
                                                        dateFormat(selectedTeamDate, 'dddd')
                                                    }
                                                </h1>
                                            </div>

                                            <div className="d-flex justify-content-center">
                                                <h6 className="font-medium-1 text-bold-300 my-0 mt-1">
                                                    {
                                                        dateFormat(selectedTeamDate, 'mmmm,yyyy')
                                                    }
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            <div class="">
                                <div id="accordionWrap1" role="tablist" aria-multiselectable="true" class="mt-1">
                                    {
                                        eventDetails != null && eventDetails.length > 0 ?
                                            eventDetails.map((eventData, index) =>
                                                <>

                                                    <div class="card accordion collapse-icon accordion-icon-rotate mb-1">
                                                        <div
                                                            id={`heading1${index}`}
                                                            class="card-header border-bottom py-1 bg-blue-grey bg-lighten-4"
                                                            data-toggle="collapse"
                                                            href={`#accordion1${index}`}
                                                            aria-expanded={``}
                                                            aria-controls={`#accordion1${index}`}
                                                        >
                                                            <a class="card-title lead">
                                                                <div class="d-flex flex-row">
                                                                    <div class="flex-column">
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            <div className="flex-column" style={{ height: 10, width: 10, backgroundColor: eventData.borderColor, borderRadius: 50 }}></div>
                                                                            <div className="ml-half flex-column">
                                                                                {
                                                                                    eventData.type === 'meeting' ?
                                                                                        eventData.meeting_type :
                                                                                        eventData.type
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            eventData.type === 'meeting' ?
                                                                                <p class="font-small-2 mb-0">Status : <span class="text-bold-600">Open</span> ({eventData.start_time} - {eventData.end_time})</p>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            eventData.type === 'Training' ?
                                                                                <p class="font-small-2 mb-0">({eventData?.start_time} - {eventData?.end_time})</p>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div
                                                            id={`accordion1${index}`}
                                                            role="tabpanel"
                                                            data-parent="#accordionWrap1"
                                                            aria-labelledby={`heading1${index}`}
                                                            class={`collapse`}
                                                        >
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        {/* meeting */}
                                                                        {
                                                                            eventData.type === 'meeting' ?
                                                                                <>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0 text-bold-600">{eventData.start_time} - {eventData.end_time}</p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Agenda : <span class="text-bold-600">{eventData.title}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Created By : <span class="text-bold-600">{eventData.name}</span></p>
                                                                                    </div>
                                                                                    {
                                                                                        eventData.company_name != null ?
                                                                                            <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                                <p class="mb-0">Customer Name : <span class="text-bold-600">{eventData.company_name}</span></p>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        eventData.insurer_company_name != null ?
                                                                                            <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                                <p class="mb-0">Company Name : <span class="text-bold-600">{eventData.insurer_company_name}</span></p>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        eventData.accompained_by != null ?
                                                                                            <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                                <p class="mb-0">Accompained By : <span class="text-bold-600">{eventData.accompained_by}</span></p>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }

                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {/* training */}
                                                                        {
                                                                            eventData.type === 'Training' ?
                                                                                <>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Title : <span class="text-bold-600">{eventData.title}</span></p>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        {/* holiday */}
                                                                        {
                                                                            eventData.type === 'Holiday / Special' ?
                                                                                <>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0"><span class="text-bold-600 text-capitalize">{eventData.title}</span></p>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {/* leave: leave type,from date, to date, reason */}
                                                                        {
                                                                            eventData.type === 'leave' ?
                                                                                <>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Leave Type : <span class="text-bold-600">{eventData.title}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">From Date : <span class="text-bold-600">{dateFormat(eventData.from_date, "dd/mm/yyyy")}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">To Date : <span class="text-bold-600">{dateFormat(eventData.to_date, "dd/mm/yyyy")}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Reason : <span class="text-bold-600">{eventData.reason}</span></p>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            :
                                            <></>
                                    }
                                    {
                                        teamEventDetails != null && teamEventDetails.length > 0 && myTeamCalender === true ?
                                            teamEventDetails.map((eventData, index) =>
                                                <>

                                                    <div class="card accordion collapse-icon accordion-icon-rotate mb-1">
                                                        <div
                                                            id={`heading1${index}`}
                                                            class="card-header border-bottom py-1 bg-blue-grey bg-lighten-4"
                                                            data-toggle="collapse"
                                                            href={`#accordion1${index}`}
                                                            aria-expanded={``}
                                                            aria-controls={`#accordion1${index}`}
                                                        >
                                                            <a class="card-title lead">
                                                                <div class="d-flex flex-row">
                                                                    <div class="flex-column">
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            <div className="flex-column" style={{ height: 10, width: 10, backgroundColor: eventData.borderColor, borderRadius: 50 }}></div>
                                                                            <div className="ml-half flex-column">
                                                                                {
                                                                                    eventData.type === 'meeting' ?
                                                                                        eventData.meeting_type :
                                                                                        eventData.type
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            eventData.type === 'meeting' ?
                                                                                <p class="font-small-2 mb-0">Status : <span class="text-bold-600">Open</span> ({eventData.start_time} - {eventData.end_time})</p>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            eventData.type === 'leave' ?
                                                                                <span class="text-bold-600 font-small-2 mb-0">{eventData.name}</span>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {
                                                                            eventData.type === 'Training' ?
                                                                                <div>
                                                                                    <span class="text-bold-600 font-small-2 mt-1">{eventData.name}</span>
                                                                                    <p class="font-small-2 mb-0">({eventData?.start_time} - {eventData?.end_time})</p>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div
                                                            id={`accordion1${index}`}
                                                            role="tabpanel"
                                                            data-parent="#accordionWrap1"
                                                            aria-labelledby={`heading1${index}`}
                                                            class={`collapse`}
                                                        >
                                                            <div class="card-content">
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        {/* meeting */}
                                                                        {
                                                                            eventData.type === 'meeting' ?
                                                                                <>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0 text-bold-600">{eventData.start_time} - {eventData.end_time}</p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Agenda : <span class="text-bold-600">{eventData.title}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Created By : <span class="text-bold-600">{eventData.name}</span></p>
                                                                                    </div>
                                                                                    {
                                                                                        eventData.company_name != null ?
                                                                                            <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                                <p class="mb-0">Customer Name : <span class="text-bold-600">{eventData.company_name}</span></p>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        eventData.insurer_company_name != null ?
                                                                                            <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                                <p class="mb-0">Company Name : <span class="text-bold-600">{eventData.insurer_company_name}</span></p>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }
                                                                                    {
                                                                                        eventData.accompained_by != null ?
                                                                                            <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                                <p class="mb-0">Accompained By : <span class="text-bold-600">{eventData.accompained_by}</span></p>
                                                                                            </div>
                                                                                            :
                                                                                            <></>
                                                                                    }

                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        {/* training */}
                                                                        {
                                                                            eventData.type === 'Training' ?
                                                                                <>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Title : <span class="text-bold-600">{eventData.title}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Training Mode : <span class="text-bold-600">{eventData.training_mode}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Training Type : <span class="text-bold-600">{eventData.training_type}</span></p>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }

                                                                        {/* holiday */}
                                                                        {
                                                                            eventData.type === 'Holiday / Special' ?
                                                                                <>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0"><span class="text-bold-600 text-capitalize">{eventData.title}</span></p>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                        {/* leave: leave type,from date, to date, reason */}
                                                                        {
                                                                            eventData.type === 'leave' ?
                                                                                <>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Leave Type : <span class="text-bold-600">{eventData.title}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">From Date : <span class="text-bold-600">{dateFormat(eventData.from_date, "dd/mm/yyyy")}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">To Date : <span class="text-bold-600">{dateFormat(eventData.to_date, "dd/mm/yyyy")}</span></p>
                                                                                    </div>
                                                                                    <div class="col-md-12 col-md-12 col-lg-12 mb-half">
                                                                                        <p class="mb-0">Reason : <span class="text-bold-600">{eventData.reason}</span></p>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MyTeamCalendar
                {...props}
                show={showRMName}
                myTeamVertical={myTeamVertical}
                myTeamMembers={userIds != null && userIds.length > 0 ? userIds : myTeamMembers}
                handleClose={(teamMember) => {
                    if (teamMember != null) {
                        selectedTeamMembers = teamMember
                        setUserIds(teamMember);
                    } else {

                        setUserIds([]);
                    }
                    setShowRMName(false);
                }}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    var Events = null;
    var EventDetails = null;
    var MyTeamVertical = null;
    var TeamEvents = null;
    var TeamEventDetails = null;
    var SingleEventDetail = null;

    if (
        state.CalenderReducer != null &&
        Object.keys(state.CalenderReducer).length > 0 &&
        state.CalenderReducer.constructor === Object
    ) {
        switch (state.CalenderReducer.tag) {
            case ACTION_CALENDER_EVENT_SUCCESS:
                Events = Object.assign({}, state.CalenderReducer.data);
                delete state.CalenderReducer
                return { Events };
            case ACTION_CALENDER_EVENT_DETAILS_SUCCESS:
                EventDetails = Object.assign({}, state.CalenderReducer.data);
                delete state.CalenderReducer
                return { EventDetails };
            case ACTION_MY_TEAM_VERTICAL_SUCCESS:
                MyTeamVertical = Object.assign({}, state.CalenderReducer.data);
                delete state.CalenderReducer
                return { MyTeamVertical };
            case ACTION_CALENDER_TEAM_EVENT_SUCCESS:
                TeamEvents = Object.assign({}, state.CalenderReducer.data);
                delete state.CalenderReducer
                return { TeamEvents };
            case ACTION_CALENDER_TEAM_EVENT_DETAILS_SUCCESS:
                TeamEventDetails = Object.assign({}, state.CalenderReducer.data);
                delete state.CalenderReducer
                return { TeamEventDetails };
            case ACTION_CALENDER_GET_EVENT_DETAILS_SUCCESS:
                SingleEventDetail = Object.assign({}, state.CalenderReducer.data);
                delete state.CalenderReducer
                return { SingleEventDetail };
            default:
        }
    }

    return {
        Events,
        EventDetails,
        MyTeamVertical,
        TeamEvents,
        TeamEventDetails,
        SingleEventDetail
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionCalenderEvents, actionCalenderEventDetails, actionMyTeamVertical, actionCalenderTeamEvents, actionCalenderTeamEventDetails, actionCalenderGETEventDetails }, dispatch)
}

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(HolidayCalendar))