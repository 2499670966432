import React, { useEffect, useState } from 'react';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { RiLayoutGridFill } from "react-icons/ri";
import { FiEye, FiEdit } from 'react-icons/fi';
import { BsTable } from 'react-icons/bs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withAuth } from 'react-auth-guard';
import { ERROR, UNAUTHORIZED } from '../../../../../constants';
import $ from "jquery";
import { verifyPermission } from '../../../../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeData, getData, KEY_TOAST_MESSAGE } from '../../../../../utils/preferences';
import TeamMembers from '../../../../../component/popups/team_details';
import { FiFilter } from "react-icons/fi";
import { actionMyClient, ACTION_MY_CLIENT_SUCCESS } from './action';
import { actionMyTeamClient, ACTION_MY_TEAM_CLIENT_SUCCESS } from './action';
import { actionClientTeamMember, ACTION_CLIENT_TEAM_VERTICAL_SUCCESS } from './action';
import Swal from 'sweetalert2';

const Client = (props) => {

    const [clientData, setClientData] = useState([]);
    const [myClientCount, setMyClientCount] = useState(0);
    const [myTeamClientCount, setMyTeamClientCount] = useState(0);
    const [inactiveClientCount, setInactiveClientCount] = useState(0);
    const [allClientCount, setAllClientCount] = useState(0);
    
    const [inactive, setInactive] = useState(false);
    const [columns, setColumns] = useState([]);
    const [selectedTemplate, setselectedTemplate] = useState('LIST');
    const [downloadFileName, setDownloadFileName] = useState("My Clients");
    const [showRMName, setShowRMName] = useState(false);
    const [showTeamMeeting, setShowTeamMeeting] = useState(true);
    const [myTeamMembers, setMyTeamMembers] = useState([]);
    const [MeWithTeam, setMeWithTeam] = useState([]);
    const [myTeamVertical, setMyTeamVertical] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [visible, setVisible] = useState(false);
    const [tabType, setTabType] = useState(props?.location?.state?.tabType != undefined ? props?.location?.state?.tabType : 'my');

    const initialRender = () => {

        $("#progress-loader").removeClass("hidden");

        if (props?.location?.state?.tabType != null && props?.location?.state?.tabType != undefined) {
            setTabType(props?.location?.state?.tabType)
        }

        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }

        props.actionClientTeamMember({ user_id: props.auth.getUser().user_id });
        if (tabType != null && tabType != undefined && tabType === 'my') {
            props.actionMyClient({
                clientType: 'my',
                leadOrClient: "client",
                user_id: props.auth.getUser().user_id,
                vertical: "corporate",
                clientOf: "insurance",
                is_active: 'yes'
            })
        } else if (tabType === 'team') {
            props.actionMyTeamClient({
                clientType: tabType,
                leadOrClient: "client",
                user_id: myTeamMembers,
                vertical: "corporate",
                clientOf: "insurance",
                is_active: 'yes'
            })
        }
        else if (tabType === 'inactive') {
            setShowTeamMeeting(false);
            setShowRMName(false);
            setInactive(true)
            props.actionMyTeamClient({
                clientType: tabType,
                leadOrClient: "client",
                user_id: MeWithTeam,
                vertical: "corporate",
                clientOf: "insurance",
                is_active: 'no'
            })
        }
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (tabType != null && tabType != undefined && tabType === 'my') {
            $("#progress-loader").removeClass("hidden");
            props.actionMyClient({
                clientType: 'my',
                leadOrClient: "client",
                user_id: props.auth.getUser().user_id,
                vertical: "corporate",
                clientOf: "insurance",
                is_active: 'yes'
            })
        } else if (tabType === 'team') {
            $("#progress-loader").removeClass("hidden");
            props.actionMyTeamClient({
                clientType: tabType,
                leadOrClient: "client",
                user_id: myTeamMembers,
                vertical: "corporate",
                clientOf: "insurance",
                is_active: 'yes'
            })
        }
        else if (tabType === 'inactive') {
            $("#progress-loader").removeClass("hidden");
            setShowTeamMeeting(false);
            setShowRMName(false);
            setInactive(true)
            props.actionMyTeamClient({
                clientType: tabType,
                leadOrClient: "client",
                user_id: MeWithTeam,
                vertical: "corporate",
                clientOf: "insurance",
                is_active: 'no'
            })
        }
    }, [myTeamMembers])


    const onMyClientUpdate = () => {
        const { MyClient } = props;
        if (MyClient != null) {
            var MyClientResponse = Object.assign({}, MyClient);
            if (MyClientResponse.result === true) {
                setShowRMName(false);
                setShowTeamMeeting(true);
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "client_saiba_id", caption: "Cust Code" },
                    { dataField: "name", caption: "Customer Name" },
                    { dataField: "company_group_name", caption: "Group Name" },
                    { dataField: "email", caption: "Email" },
                    { dataField: "mobile", caption: "Mobile" },
                ])
                setClientData(MyClientResponse.response);
                setMyClientCount(MyClientResponse.response.length);
                setMyTeamClientCount(0);
                setInactiveClientCount(0)
                setAllClientCount(0)
                $("#progress-loader").addClass("hidden");

                setDownloadFileName("My Clients")
            } else {
                $("#progress-loader").addClass("hidden");
                setClientData([]);
                setShowTeamMeeting(true);
                switch (MyClientResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        setClientData([]);
                        setMyClientCount(0);
                        break;
                    default:
                }
            }
        }

    }
    useEffect(onMyClientUpdate, [props.MyClient]);

    const onMyTeamClientUpdate = () => {
        const { MyTeamClient } = props;
        if (MyTeamClient != null) {
            var MyTeamClientResponse = Object.assign({}, MyTeamClient);
            if (MyTeamClientResponse.result === true) {
                setColumns([
                    { dataField: "Action", caption: "Action", type: "button" },
                    { dataField: "client_saiba_id", caption: "Cust Code" },
                    { dataField: "full_name", caption: "RM Name" },
                    { dataField: "company_group_name", caption: "Company Group Name" },
                    { dataField: "name", caption: "Name" },
                    { dataField: "email", caption: "Email" },
                    { dataField: "mobile", caption: "Mobile" },
                ]);

                setClientData(MyTeamClientResponse.response);
                if (inactive === true) {
                    setShowRMName(false);
                    setShowTeamMeeting(true);
                    setMyTeamClientCount(0)
                    setAllClientCount(0)
                    setInactiveClientCount(MyTeamClientResponse.response.length);
                } else if (inactive === false && tabType==='all') {
                    setShowRMName(false);
                    setShowTeamMeeting(true);
                    setMyTeamClientCount(0)
                    setAllClientCount(MyTeamClientResponse.response.length);
                } 
                else {
                    setShowRMName(false);
                    setShowTeamMeeting(false);
                    setInactiveClientCount(0)
                    setAllClientCount(0)
                    setMyTeamClientCount(MyTeamClientResponse.response.length);
                }
                setMyClientCount(0);
                $("#progress-loader").addClass("hidden");

                setDownloadFileName("My Team Clients")
            } else {
                $("#progress-loader").addClass("hidden");
                setClientData([]);
                setShowTeamMeeting(true);
                switch (MyTeamClientResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        setClientData([]);
                        if (inactive === true) {
                            setInactiveClientCount(0)
                        } else {
                            setMyTeamClientCount(0);
                        }
                        break;
                    default:
                }
            }
        }

    }
    useEffect(onMyTeamClientUpdate, [props.MyTeamClient]);

    const onClientTeamMemberUpdate = () => {
        const { ClientTeamMember } = props;
        if (ClientTeamMember != null) {
            var ClientTeamMemberResponse = Object.assign({}, ClientTeamMember);
            if (ClientTeamMemberResponse.result === true) {
                setShowTeamMeeting(true)
                var myTeam = []
                var myTeamMember = []
                var MeWithTeam = []
                ClientTeamMemberResponse.response.map((item) => {
                    if (item.id != props.auth.getUser().user_id) {
                        myTeam.push(item);
                        myTeamMember.push(item.id);
                        setMyTeamVertical(myTeam);
                        setMyTeamMembers(myTeamMember);
                    }
                    MeWithTeam.push(item.id);
                    setMeWithTeam(MeWithTeam)
                })

            } else {
                setShowTeamMeeting(true)
                switch (ClientTeamMemberResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        toast.success("Your Login session has expired, Please try to Login again!");
                        break;
                    case ERROR:
                        setVisible(true);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onClientTeamMemberUpdate, [props.ClientTeamMember]);

    const renderGroupNameCell = (cell) => {
        if (cell?.data?.company_group_name != null) {
            return <a
                className='text-primary'
                onClick={() => {
                    props.history.push({
                        pathname: '/client/group-level-view',
                        state: { client_group_id: cell?.data?.client_group_id }
                    });
                }}
            ><u>{cell?.data?.company_group_name}</u></a>
        }
    }

    const renderCompanyNameCell = (cell) => {
        if (cell?.data?.name != null) {
            return <a
                className='text-primary'
                onClick={(() => {
                    if (cell?.data?.client_type.toLowerCase() === 'lead') {
                        props.history.push({
                            pathname: '/leads/view',
                            state: {
                                client_id: cell?.data?.id,
                            }
                        });
                    } else if (cell?.data?.client_type.toLowerCase() === 'client') {
                        props.history.push({
                            pathname: '/client/view',
                            state: {
                                client_id: cell?.data?.id,
                            }
                        });
                    }
                })}
            ><u>{cell?.data?.name}</u></a>
        }
    }

    const renderRMNameCell = (cell) => {
        if (cell?.data?.full_name != null) {
            return <a
                className='text-primary'
                onClick={() => {
                    props.history.push({
                        pathname: '/profile',
                        state: {
                            user_id: cell?.data?.created_by_id,
                        }
                    });
                }}
            ><u>{cell?.data?.full_name}</u></a>
        }
    }

    

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-8 col-lg-8 d-flex align-items-center">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        {
                            verifyPermission("VIEW_MY_CLIENT") ?

                                <li class="nav-item">
                                    <button
                                        onClick={() => {
                                            setInactive(false)
                                            $("#progress-loader").removeClass("hidden");
                                            setShowTeamMeeting(false);
                                            setShowRMName(false);
                                            setTabType('my')
                                            props.actionMyClient({
                                                clientType: "my",
                                                leadOrClient: "client",
                                                user_id: props.auth.getUser().user_id,
                                                vertical: "corporate",
                                                clientOf: "insurance",
                                                is_active: 'yes'
                                            });
                                            props.actionClientTeamMember({ user_id: props.auth.getUser().user_id });
                                        }}
                                        className={`btn ${tabType === 'my' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        My Clients {myClientCount > 0 ? `(` + myClientCount + `)` : ``}
                                    </button>
                                </li>
                                : <> </>

                        }
                        {
                            verifyPermission("VIEW_MY_TEAM_CLIENT") && verifyPermission("SHOW_TEAM") ?
                                <li class="nav-item ml-1">
                                    <button
                                        onClick={() => {
                                            setTabType('team')
                                            setInactive(false)
                                            if (showTeamMeeting != true) {
                                                if (showRMName === true) {
                                                    setShowRMName(false);
                                                } else {
                                                    setShowRMName(true);
                                                }
                                            }
                                            if (myTeamMembers != null && myTeamMembers.length > 0 && showTeamMeeting === true) {
                                                $("#progress-loader").removeClass("hidden");
                                                props.actionMyTeamClient({
                                                    leadOrClient: "client",
                                                    user_id: myTeamMembers,
                                                    vertical: "corporate",
                                                    clientOf: "insurance",
                                                    is_active: 'yes'
                                                })
                                            }
                                        }}
                                        className={`btn ${tabType === 'team' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        Team Clients <span className='mr-half'>{myTeamClientCount > 0 && tabType==='team' ? `(` + myTeamClientCount + `)` : ``}</span>
                                        <FiFilter size={16} />
                                    </button>
                                </li>
                                : <> </>

                        }
                        {
                            // verifyPermission("VIEW_MY_CLIENT") ?

                            <li class="nav-item ml-1">
                                <button
                                    onClick={() => {
                                        $("#progress-loader").removeClass("hidden");
                                        setTabType('inactive')
                                        // myTeamMembers.push(props.auth.getUser().user_id);
                                        setShowTeamMeeting(false);
                                        setShowRMName(false);
                                        setInactive(true)
                                        props.actionMyTeamClient({
                                            leadOrClient: "client",
                                            user_id: [...myTeamMembers, props.auth.getUser().user_id],
                                            vertical: "corporate",
                                            clientOf: "insurance",
                                            is_active: 'no'
                                        })
                                    }}
                                    className={`btn ${tabType === 'inactive' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                    Inactive Clients {inactiveClientCount > 0 ? `(` + inactiveClientCount + `)` : ``}
                                </button>
                            </li>
                            // : <> </>

                        }
                        {/* {
                            verifyPermission("VIEW_ALL_CLIENT") && verifyPermission("SHOW_TEAM") ? */}
                                <li class="nav-item ml-1">
                                    <button
                                        onClick={() => {
                                           

                                            $("#progress-loader").removeClass("hidden");
                                        setTabType('all')
                                        setShowTeamMeeting(false);
                                        setShowRMName(false);
                                        setInactive(false)
                                        props.actionMyTeamClient({
                                            leadOrClient: "client",
                                            user_id: [...myTeamMembers, props.auth.getUser().user_id],
                                            vertical: "corporate",
                                            clientOf: "insurance",
                                        })
                                        }}
                                        className={`btn ${tabType === 'all' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        All Clients <span className='mr-half'>{allClientCount > 0 ? `(` + allClientCount + `)` : ``}</span>
                                    </button>
                                </li>
                                {/* : <> </>

                        } */}
                    </ul>
                </div>
                <div className="content-header-right col-12 col-md-4 col-lg-4">
                    <ul class="nav nav-pills float-right">
                        {/* {
                            clientData != null && clientData.length > 0 ?
                                <li class="nav-item mr-1">
                                    <button className="btn btn-primary btn-md font-small-3 shadow round"
                                        onClick={(event) => {
                                            if (selectedTemplate === "LIST") {
                                                setselectedTemplate("GRID")
                                            } else {
                                                setselectedTemplate("LIST")
                                            }
                                        }}
                                    >
                                        {
                                            selectedTemplate === "LIST" ?
                                                <RiLayoutGridFill />
                                                : <BsTable />
                                        }

                                    </button>
                                </li>
                                : <></>
                        } */}
                        {
                            verifyPermission("CREATE_CLIENT") ?
                                <li class="nav-item">
                                    <button
                                        className="btn btn-primary btn-md px-1 font-small-3 shadow round"
                                        onClick={() => {
                                            props.history.push('/leads/add');
                                        }}
                                    >
                                        Create Client
                                    </button>
                                </li>
                                : <> </>
                        }

                    </ul>
                </div>
            </>
        )
    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="Action" type="buttons">
                        {
                            verifyPermission("VIEW_CLIENT_DETAILS") ?

                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: '/client/view',
                                                    state: {
                                                        client_id: params.data.id,
                                                        type: 'Client',
                                                        tab: selectedTemplate
                                                    }
                                                });
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>

                        }

                        {
                            verifyPermission("UPDATE_CLIENT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: '/client/edit',
                                                    state: {
                                                        client_id: params.data.id,
                                                        type: 'Client',
                                                        tab: selectedTemplate,
                                                        tabType: tabType,
                                                        visible: (props.auth.getUser().user_id === params.data.created_by_id || props.auth.getUser().user_id === 1) ? true : false
                                                    }
                                                });
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                : <> </>
                        }

                    </Column>
                );
            } else if (objColumn.dataField === "company_group_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderGroupNameCell}
                    ></Column>
                );
            } else if (objColumn.dataField === "full_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderRMNameCell}
                    ></Column>
                );
            } else if (objColumn.dataField === "name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCompanyNameCell}
                    ></Column>
                );
            }
            else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                    ></Column>
                );
            }
        });
        return arrColumns;
    };

    const renderListUI = () => {
        return (
            <DataGrid
                dataSource={clientData}
                columnAutoWidth={true}
                columnHidingEnabled={false}
                showBorders={true}
                export={{
                    fileName: downloadFileName,
                    enabled: clientData != null && clientData.length > 0 ? true : false,
                    allowExportSelectedData: false,
                    excelFilterEnabled: true,
                }}
                onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                        location: 'before'

                    })
                }}
            >
                <SearchPanel visible={true} />
                <FilterRow visible={true} />
                <HeaderFilter visible={true} />
                {renderColumns()}
                <Pager
                    visible={true}
                    showPageSizeSelector={false}
                    showNavigationButtons={true}
                    allowedPageSizes={[4, 6, 10]}
                />
                <Paging
                    defaultPageIndex={0}
                    defaultPageSize={10}
                    enabled={true}
                />
            </DataGrid>
        )
    }

    const renderGridUI = () => {
        var arrGridColumns = []
        if (clientData != null && clientData.length > 0) {
            clientData.map((client, index) => (
                arrGridColumns.push(
                    <div className="col-12 col-md-6 col-lg-6" key={index}>
                        <div className="card border">
                            <div className={`card-body px-0 py-1`}>
                                <div className="d-flex flex-row px-1">
                                    <div className="profile-img-container-sm bg-light rounded-circle mr-1 border">
                                        <h6 class="name-preffix-sm"> {String(client.name).substring(0, 2).toUpperCase()}</h6>
                                    </div>
                                    <div>
                                        <p className={`card-text-esc font-medium-1 mb-0 text-capitalize`}>{client.name}</p>
                                        <p className={`font-small-2 small mb-0`}>Group Name: {client.company_group_name}</p>
                                    </div>
                                    <div className={`ml-auto font-small-2`}>
                                        <div>
                                            <span>Mobile:</span> {client.mobile}
                                        </div>
                                        <div>
                                            <span>City: {client.country_name}</span>
                                        </div>
                                    </div>
                                </div>
                                <hr className={`my-half`} />
                                <div className="d-flex flex-row px-1">
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Email ID:</p>
                                        <p className={`font-small-2 small mb-0`}>{client.email}</p>
                                    </div>
                                    <div className={"flex-column w-50"}>
                                        <p className={`card-text-esc font-small-3 mb-0 text-capitalize`}>Address:</p>
                                        <p className={`font-small-2 small mb-0 card-text-esc`} title={client.address}>{client.address}</p>
                                    </div>
                                    <div className={"align-self-end d-inline-flex"}>
                                        {
                                            verifyPermission("UPDATE_CLIENT") ?
                                                props.auth.userId === client.created_by_id ?
                                                    <FiEdit size={14} className={`primary mr-half`} cursor="pointer"
                                                        onClick={() => {
                                                            props.history.push({
                                                                pathname: '/client/edit',
                                                                state: {
                                                                    client_id: client.id,
                                                                    type: 'Client',
                                                                    visible: true
                                                                }
                                                            });
                                                        }}
                                                    />
                                                    : <> </>

                                                : <></>
                                        }

                                        <FiEye size={14} className={`primary`} cursor="pointer"
                                            onClick={() => {
                                                props.history.push({
                                                    pathname: '/client/view',
                                                    state: {
                                                        client_id: client.id,
                                                        type: 'Client',
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )
            ))
        }
        return arrGridColumns
    }

    return (
        <>
            <div className="content-header row mb-1">
                {renderHeaderButtonUI()}
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                            {
                                selectedTemplate === "LIST" ?
                                    renderListUI()
                                    : <></>
                            }
                        </div>

                        {
                            selectedTemplate === "GRID" ?
                                renderGridUI()
                                : <></>
                        }
                    </div>
                </div>
            </div>
            <TeamMembers
                {...props}
                show={showRMName}
                myTeamVertical={myTeamVertical}
                myTeamMembers={userIds != null && userIds.length > 0 ? userIds : myTeamMembers}
                handleClose={(teamMember) => {
                    if (teamMember != null) {
                        setUserIds(teamMember);
                        props.actionMyTeamClient({
                            leadOrClient: "client",
                            user_id: teamMember,
                            vertical: "corporate",
                            clientOf: "insurance"
                        });
                    } else {
                        $("#progress-loader").addClass("hidden");
                    }
                    setShowRMName(false);
                }}
            />
        </>
    );
}


const mapStateToProps = (state) => {
    var MyClient = null;
    var MyTeamClient = null;
    var ClientTeamMember = null;

    if (
        state.ClientListReducer != null &&
        Object.keys(state.ClientListReducer).length > 0 &&
        state.ClientListReducer.constructor === Object
    ) {
        switch (state.ClientListReducer.tag) {
            case ACTION_MY_CLIENT_SUCCESS:
                MyClient = Object.assign({}, state.ClientListReducer.data);
                delete state.ClientListReducer;
                return { MyClient };
            case ACTION_MY_TEAM_CLIENT_SUCCESS:
                MyTeamClient = Object.assign({}, state.ClientListReducer.data);
                delete state.ClientListReducer;
                return { MyTeamClient };
            case ACTION_CLIENT_TEAM_VERTICAL_SUCCESS:
                ClientTeamMember = Object.assign({}, state.ClientListReducer.data);
                delete state.ClientListReducer;
                return { ClientTeamMember };
            default:

        }
    }
    return {
        MyClient,
        MyTeamClient,
        ClientTeamMember
    };

}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ actionMyClient, actionMyTeamClient, actionClientTeamMember }, dispatch)
}
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Client));