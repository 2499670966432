import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import { actionWellnessPoolList, ACTION_WELLNESS_POOL_LIST_SUCCESS } from "./action";
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import $ from "jquery";
import moment from "moment";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import EditWellnessAssignRM from "../../../../../../component/popups/edit_wellness_assign_rm";
import { toast } from "react-toastify";
import { verifyPermission } from "../../../../../../utils";
import { KEY_TOAST_MESSAGE, getData, removeData } from "../../../../../../utils/preferences";

const WellnessManagementPoolList = (props) => {
    const { match } = props;
    const [assignPoolCount, setAssignPoolCount] = useState(0);
    const [unassignPoolCount, setUnassignPoolCount] = useState(0);
    const [requestType, setRequestType] = useState('assigned');
    const [wellnessRequest, setWellnessRequest] = useState('Assigned');
    const [showAssignRM, setShowAssignRM] = useState(false);
    const [wellnessPool, setWellnessPool] = useState([]);
    const [wellnessId, setWellnessId] = useState(null);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        if (props.location?.state?.from === 'notification') {
            setRequestType('un-assigned')
            setWellnessRequest('Unassigned')
            props.actionWellnessPoolList({ user_type: 'un-assigned' })
        } else {
            setRequestType('assigned')
            setWellnessRequest('Assigned')
            props.actionWellnessPoolList({ user_type: 'assigned' });
        }
    }
    useEffect(initialRender, []);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button" },
        { dataField: "created_date", caption: "Created Date", visible: false },
        { dataField: "created_date_formated", caption: "Created Date", visible: true },
        { dataField: "client_name", caption: "Client Name", visible: true },
        { dataField: "address", caption: "Location Address", visible: true },
        { dataField: "assign_rm", caption: "Assign RM", visible: true },
        { dataField: "activity_date", caption: "Date of Activity", visible: false },
        { dataField: "activity_date_formated", caption: "Date of Activity", visible: true },
        { dataField: "approved_by", caption: "Approved By", visible: true },
        { dataField: "raised_by", caption: "Raised By", visible: true },
        { dataField: "actual_activity_date", caption: "Actual Date of Activity", visible: false },
        { dataField: "actual_activity_date_formated", caption: "Actual Date of Activity", visible: true },
        { dataField: "remarks", caption: "Remark", visible: true },
        { dataField: "status_name", caption: "Status", visible: true },
        { dataField: "rm_status_name", caption: "RM Status", visible: true }
    ]);

    const renderRMStatus = (cell) => {
        return <div>{cell.data.rm_status_name != null && cell.data.rm_status_name != "Not Defined" ? cell.data.rm_status_name : ''}</div>;
    }

    const renderStatusName = (cell) => {
        return <div>{cell.data.status_name != null && cell.data.status_name != "Not Defined" ? cell.data.status_name : ''}</div>;
    }

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.created_date_formated != null ? moment(cell?.data?.created_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActivityDate = (cell) => {
        return <div>{cell?.data?.activity_date_formated != null ? moment(cell?.data?.activity_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActualActivityDate = (cell) => {
        return <div>{cell?.data?.actual_activity_date_formated != null ? moment(cell?.data?.actual_activity_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const onWellnessPoolListUpdate = () => {
        const { WellnessPoolList } = props;
        if (WellnessPoolList != null) {
            setWellnessPool([]);
            var WellnessPoolListResponse = Object.assign({}, WellnessPoolList);
            if (WellnessPoolListResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                if (requestType == "un-assigned") {
                    setUnassignPoolCount(WellnessPoolListResponse.response.length);
                    setAssignPoolCount(0)
                    setColumns([
                        { dataField: "Action", caption: "Action", type: "button", visible: true },
                        { dataField: "created_date", caption: "Created Date", visible: false },
                        { dataField: "created_date_formated", caption: "Created Date", visible: true },
                        { dataField: "client_name", caption: "Client Name", visible: true },
                        { dataField: "address", caption: "Location Address", visible: true },
                        // { dataField: "assign_rm", caption: "Assign RM",visible:true },
                        { dataField: "activity_date", caption: "Date of Activity", visible: false },
                        { dataField: "activity_date_formated", caption: "Date of Activity", visible: true },
                        { dataField: "approved_by", caption: "Approved By", visible: true },
                        { dataField: "raised_by", caption: "Raised By", visible: true },
                        { dataField: "actual_activity_date", caption: "Actual Date of Activity", visible: false },
                        { dataField: "actual_activity_date_formated", caption: "Actual Date of Activity", visible: true },
                        { dataField: "remarks", caption: "Remark", visible: true },
                    ]);
                } else {
                    setAssignPoolCount(WellnessPoolListResponse.response.length);
                    setUnassignPoolCount(0);
                    setColumns([
                        { dataField: "Action", caption: "Action", type: "button" },
                        { dataField: "created_date", caption: "Created Date", visible: false },
                        { dataField: "created_date_formated", caption: "Created Date", visible: true },
                        { dataField: "client_name", caption: "Client Name", visible: true },
                        { dataField: "address", caption: "Location Address", visible: true },
                        { dataField: "assign_rm", caption: "Assign RM", visible: true },
                        { dataField: "activity_date", caption: "Date of Activity", visible: false },
                        { dataField: "activity_date_formated", caption: "Date of Activity", visible: true },
                        { dataField: "approved_by", caption: "Approved By", visible: true },
                        { dataField: "raised_by", caption: "Raised By", visible: true },
                        { dataField: "actual_activity_date", caption: "Actual Date of Activity", visible: false },
                        { dataField: "actual_activity_date_formated", caption: "Actual Date of Activity", visible: true },
                        { dataField: "remarks", caption: "Remark", visible: true },
                        { dataField: "status_name", caption: "Status", visible: true },
                        { dataField: "rm_status_name", caption: "RM Status", visible: true }
                    ]);
                }
                setWellnessPool(WellnessPoolListResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (WellnessPoolListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    case ERROR:
                        setAssignPoolCount(0);
                        setUnassignPoolCount(0);
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onWellnessPoolListUpdate, [props.WellnessPoolList])

    const renderHeaderButtonUI = () => {
        return (
            <>
                <div className="content-header-left col-12 col-md-6 col-lg-6 d-flex align-items-center">
                    <ul class="nav nav-pills d-none d-md-flex d-lg-flex">
                        {
                            verifyPermission("WELLNESS_POOL_ASSIGNED_REQUEST") ?
                                <li class="nav-item">
                                    <button
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setRequestType('assigned')
                                            setWellnessRequest('Assigned')
                                            props.actionWellnessPoolList({ user_type: 'assigned' });
                                        }}
                                        className={`btn ${requestType === 'assigned' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        Assigned Requests <span className='mr-half'>{assignPoolCount > 0 ? `(` + assignPoolCount + `)` : ``}</span>
                                    </button>
                                </li>
                                :
                                <></>
                        }
                        {
                            verifyPermission("WELLNESS_POOL_UNASSIGNED_REQUEST") ?
                                <li class="nav-items ml-1">
                                    <button
                                        onClick={() => {
                                            $("#progress-loader").removeClass("hidden");
                                            setRequestType('un-assigned')
                                            setWellnessRequest('Unassigned')
                                            props.actionWellnessPoolList({ user_type: 'un-assigned' });
                                        }}
                                        className={`btn ${requestType === 'un-assigned' ? 'btn-primary' : 'btn-light'} btn-lead btn-md px-1 font-small-3 shadow round `}>
                                        Unassigned Requests <span className='mr-half'>{unassignPoolCount > 0 ? `(` + unassignPoolCount + `)` : ``}</span>
                                    </button>
                                </li>
                                :
                                <></>
                        }
                    </ul>
                </div>

            </>
        )

    }

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            verifyPermission("WELLNESS_MANAGEMNT_POOL_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id, request_type: wellnessRequest, status_name: params?.data?.status } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("WELLNESS_MANAGEMNT_POOL_EDIT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className={`btn btn-icon btn-sm ${params.data.rm_status === null || params.data.rm_status === 'update' ? '' : 'hidden'}`}
                                            onClick={() => {
                                                if (requestType === 'assigned') {
                                                    props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                                } else {
                                                    setShowAssignRM(true)
                                                    setWellnessId(params.data.id);
                                                }
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "status_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStatusName}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "rm_status_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderRMStatus}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "created_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCreateDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "activity_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActivityDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "actual_activity_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActualActivityDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>

            <div>
                <div className="content-header row mb-1">
                    {renderHeaderButtonUI()}
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={wellnessPool}
                                    showBorders={true}
                                    export={{
                                        fileName: "Wellness Management Pool",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("created_date_formated", "visible", false)
                                        event.component.columnOption("created_date", "visible", true)
                                        event.component.columnOption("activity_date_formated", "visible", false)
                                        event.component.columnOption("activity_date", "visible", true)
                                        event.component.columnOption("actual_activity_date_formated", "visible", false)
                                        event.component.columnOption("actual_activity_date", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("created_date_formated", "visible", true)
                                        event.component.columnOption("created_date", "visible", false)
                                        event.component.columnOption("activity_date_formated", "visible", true)
                                        event.component.columnOption("activity_date", "visible", false)
                                        event.component.columnOption("actual_activity_date_formated", "visible", true)
                                        event.component.columnOption("actual_activity_date", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EditWellnessAssignRM
                show={showAssignRM}
                wellnessId={wellnessId}
                handleClose={(wellnessPool) => {
                    if (wellnessPool != null && wellnessPool.updated == true) {
                        toast.success("RM Assigned Successfully");
                        props.actionWellnessPoolList({
                            user_type: requestType
                        })
                    }
                    setShowAssignRM(false)
                }}
            />
        </>
    )

}
const mapStateToProps = (state) => {

    var WellnessPoolList = null;

    if (
        state.WellnessPoolListReducer != null &&
        Object.keys(state.WellnessPoolListReducer).length > 0 &&
        state.WellnessPoolListReducer.constructor === Object
    ) {
        switch (state.WellnessPoolListReducer.tag) {
            case ACTION_WELLNESS_POOL_LIST_SUCCESS:
                WellnessPoolList = Object.assign({}, state.WellnessPoolListReducer.data);
                delete state.WellnessPoolListReducer;
                return { WellnessPoolList };
            default:
        }
    }
    return {
        WellnessPoolList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionWellnessPoolList
        },
        dispatch
    );
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessManagementPoolList))
