import React, { useEffect, useState } from "react";
import ScreenTitle from '../../../../../../component/screen_title';
import { DateBox } from 'devextreme-react/date-box';
import { Controller, useForm } from "react-hook-form";
import dateFormat from "dateformat";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { checkFileExtension } from '../../../../../../utils';
import { ERROR, MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR } from '../../../../../../constants';
import Swal from 'sweetalert2';
import { FiUser } from 'react-icons/fi';
import { bindActionCreators } from "redux";
import { UNAUTHORIZED } from '../../../../../../constants';
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import $ from 'jquery';
import { toast } from 'react-toastify';
import moment from "moment";
import { ACTION_RISK_POOL_VIEW_SUCCESS, actionViewRiskManagmentPool } from "./action";
import { ACTION_RISK_POOL_CLIENT_TYPE_SUCCESS, actionRiskManagementClientType } from "./action";
import { ACTION_RISK_POOL_TYPE_OF_INSPECTION_SUCCESS, actionRiskManagementTypeOfInspection } from "./action";
import { ACTION_RISK_POOL_FINANCIAL_YEAR_SUCCESS, actionRiskManagementFinancialYear } from "./action";
import { ACTION_RISK_POOL_EDIT_SUCCESS, actionEditRiskManagmentPool } from "./action";
import { ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS, actionAssignEngineer } from "./action";
import { KEY_TOAST_MESSAGE, setData } from "../../../../../../utils/preferences";
import DocumentIframe from "../../../../../../component/popups/document_iframe";
import { downloadFile } from "../../../../../../utils";

const RiskPoolEdit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [postImage, setPostImage] = useState(null);
    const validExt = ['doc', 'docx', 'pptx', 'ppt', 'xlsx', 'xls', 'pdf', 'zip'];
    const [selectTypeOfInspection, setSelectTypeOfInspection] = useState(null);
    const [clientType, setClientType] = useState([]);
    const [typeOfInspection, setTypeOfInspection] = useState([]);
    const [financialYear, setFinancialYear] = useState([]);
    const [assignEngineer, setAssignEngineer] = useState([]);
    const [riskPool, setRiskPool] = useState(null);
    const [riskStatus, setRiskStatus] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        props.actionViewRiskManagmentPool({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (riskPool != null) {
            props.actionRiskManagementClientType();
            props.actionRiskManagementTypeOfInspection();
            props.actionRiskManagementFinancialYear();
            props.actionAssignEngineer();
        }
    }, [riskPool])

    const onViewRiskManagmentPoolUpdate = () => {
        const { ViewRiskManagmentPool } = props;
        var ViewRiskManagmentPoolResponse = Object.assign({}, ViewRiskManagmentPool);
        if (ViewRiskManagmentPoolResponse.result === true) {
            setValue('created_at', moment(ViewRiskManagmentPoolResponse.response.created_at).format("DD/MM/YYYY"));
            setValue('address', ViewRiskManagmentPoolResponse.response.address);
            setValue('occupancy', ViewRiskManagmentPoolResponse.response.occupancy);
            setValue('sum_insured', ViewRiskManagmentPoolResponse.response.sum_insured);
            setValue('inspection_date', ViewRiskManagmentPoolResponse.response.inspection_date);
            setValue('actual_date_inspection', ViewRiskManagmentPoolResponse.response.actual_date_inspection != null && ViewRiskManagmentPoolResponse.response.actual_date_inspection != 'null' ? ViewRiskManagmentPoolResponse.response.actual_date_inspection : '');
            setValue('remarks', ViewRiskManagmentPoolResponse.response.remarks);
            setValue('description', ViewRiskManagmentPoolResponse.response.description);
            setValue('engineer_status', ViewRiskManagmentPoolResponse.response.engineer_status != null ? ViewRiskManagmentPoolResponse.response.engineer_status : 'update');
            setValue('client_name', ViewRiskManagmentPoolResponse.response.client_name);
            setRiskPool(ViewRiskManagmentPoolResponse.response);
        } else {
            switch (ViewRiskManagmentPoolResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onViewRiskManagmentPoolUpdate, [props.ViewRiskManagmentPool]);

    useEffect(() => {
        if (clientType != null && clientType.length > 0) {
            if (riskPool != null) {
                setValue('client_type', riskPool.client_type);
            }
        }
    }, [clientType])

    const onRiskManagementClientTypeUpdate = () => {
        const { RiskManagementClientType } = props;
        var RiskManagementClientTypeResponse = Object.assign({}, RiskManagementClientType);
        if (RiskManagementClientTypeResponse.result === true) {
            setClientType(RiskManagementClientTypeResponse.response);
        } else {
            switch (RiskManagementClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRiskManagementClientTypeUpdate, [props.RiskManagementClientType]);

    useEffect(() => {
        if (typeOfInspection != null && typeOfInspection.length > 0) {
            if (riskPool != null) {
                setValue('inspection_id', riskPool.inspection_id);
            }
        }
    }, [typeOfInspection])

    const onRiskManagementTypeOfInspectionUpdate = () => {
        const { RiskManagementTypeOfInspection } = props;
        var RiskManagementTypeOfInspectionResponse = Object.assign({}, RiskManagementTypeOfInspection);
        if (RiskManagementTypeOfInspectionResponse.result === true) {
            setTypeOfInspection(RiskManagementTypeOfInspectionResponse.response);
        } else {
            switch (RiskManagementTypeOfInspectionResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRiskManagementTypeOfInspectionUpdate, [props.RiskManagementTypeOfInspection]);

    useEffect(() => {
        if (financialYear != null && financialYear.length > 0) {
            if (riskPool != null) {
                setValue('financial_year', riskPool.financial_year != null && riskPool.financial_year != 'null' ? riskPool.financial_year : '');
            }
        }
    }, [financialYear])

    const onRiskManagementFinancialYearUpdate = () => {
        const { RiskManagementFinancialYear } = props;
        var RiskManagementFinancialYearResponse = Object.assign({}, RiskManagementFinancialYear);
        if (RiskManagementFinancialYearResponse.result === true) {
            setFinancialYear(RiskManagementFinancialYearResponse.response);
        } else {
            switch (RiskManagementFinancialYearResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRiskManagementFinancialYearUpdate, [props.RiskManagementFinancialYear]);

    useEffect(() => {
        if (assignEngineer != null && assignEngineer.length > 0) {
            if (riskPool != null) {
                setValue('assign_engineer', riskPool.assign_engineer_id != null && riskPool.assign_engineer_id != 'null' ? riskPool.assign_engineer_id : '');
            }
        }
    }, [assignEngineer])

    const onAssignEngineerUpdate = () => {
        const { AssignEngineer } = props;
        var AssignEngineerResponse = Object.assign({}, AssignEngineer);
        if (AssignEngineerResponse.result === true) {
            setAssignEngineer(AssignEngineerResponse.response);
        } else {
            switch (AssignEngineerResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onAssignEngineerUpdate, [props.AssignEngineer]);

    const onEditRiskManagmentPoolUpdate = () => {
        const { EditRiskManagmentPool } = props;
        var EditRiskManagmentPoolResponse = Object.assign({}, EditRiskManagmentPool);
        $("#edit_risk_management").prop("disabled", false);
        if (EditRiskManagmentPoolResponse.result === true) {
            $("#risk-management-edit-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Risk Updated Successfully");
            if (props?.location?.state?.type === 'RISK_ASSIGNEE_NOTIFICATION' || props?.location?.state?.type === 'RISK_REQUEST_ASSIGN_BY_RISK_USER') {
                props.history.push({
                    pathname: '/notification'
                })
            } else {
                props.history.goBack();
            }
        }
        else {
            $("#risk-management-edit-loader").addClass("hidden");
            switch (EditRiskManagmentPoolResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    toast.error(EditRiskManagmentPoolResponse.message);
                default:
            }
        }
    }
    useEffect(onEditRiskManagmentPoolUpdate, [props.EditRiskManagmentPool]);

    const onSubmit = (data) => {
        $("#risk-management-edit-loader").removeClass("hidden");
        $("#edit_risk_management").prop("disabled", true);
        data.id = props.location.state.id;
        data.client_id = riskPool.client_id != null ? riskPool.client_id : '';
        data.occupancy = data.occupancy != null ? data.occupancy : '';
        data.sum_insured = data.sum_insured != null ? data.sum_insured : '';
        data.inspection_id = data.inspection_id != null ? data.inspection_id : '';
        data.inspection_date = data.inspection_date != null ? data.inspection_date : '';
        data.remarks = data.remarks != null ? data.remarks : '';
        data.actual_date_inspection = data.actual_date_inspection != null ? data.actual_date_inspection : '';
        data.financial_year = data.financial_year != null ? data.financial_year : '';
        data.engineer_status = data.engineer_status != null ? data.engineer_status : null;
        data.description = data.description != null ? data.description : '';
        if (data.engineer_status === null || data.engineer_status === undefined) {
            delete data.engineer_status
        }
        if (data.client_name === null || data.client_name === undefined) {
            delete data.client_name
        }
        if (data.created_at === null || data.created_at === undefined) {
            delete data.created_at
        }
        var files = [];
        if (postImage != null) {
            files.push({ 'name': 'upload_document', 'value': postImage });
        }
        delete data.upload_document;
        props.actionEditRiskManagmentPool(data, files);

    }

    return (
        <>
            <ScreenTitle title="Risk Assignment List Edit" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Created Date</label>
                                                    <input
                                                        id="created_at"
                                                        name="created_at"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("created_at")}
                                                    />
                                                    {errors.created_at && <ErrorLabel message={errors.created_at?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type</label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name</label>
                                                    <input
                                                        id="client_name"
                                                        name="client_name"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("client_name")}
                                                    />
                                                    {errors.client_name && <ErrorLabel message={errors.client_name?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        disabled={['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Occupancy&nbsp;<span className={`danger ${riskStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="occupancy"
                                                        name="occupancy"
                                                        type="text"
                                                        placeholder="Enter occupancy"
                                                        disabled={['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                        {...register('occupancy', {
                                                            required: riskStatus === 'close' ? 'Please Enter Occupancy' : false
                                                        })}
                                                    />
                                                    {errors.occupancy?.type && <ErrorLabel message={errors.occupancy?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Sum Insured&nbsp;<span className={`danger ${riskStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="sum_insured"
                                                        name="sum_insured"
                                                        type="text"
                                                        disabled={['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        placeholder="Enter sum insured"
                                                        {...register('sum_insured', {
                                                            required: riskStatus === 'close' ? 'Please Enter Sum Insured' : false
                                                        })}
                                                    />
                                                    {errors.sum_insured?.type && <ErrorLabel message={errors.sum_insured?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Inspection&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="inspection_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="inspection_id"
                                                                name="inspection_id"
                                                                dataSource={typeOfInspection}
                                                                allowFiltering={false}
                                                                placeholder="Select Type of Inspection"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                enabled={!['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectTypeOfInspection(event.itemData.name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: ' Please select type of inspection'
                                                        }}
                                                    />

                                                    {errors.inspection_id && <ErrorLabel message={errors.inspection_id?.message} />}
                                                </div>
                                            </div>
                                            {
                                                selectTypeOfInspection === 'Others' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="description"
                                                                name="description"
                                                                type="text"
                                                                placeholder="Enter description"
                                                                disabled={['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                                {...register('description',
                                                                    { required: 'Description is required' }
                                                                )}
                                                            />
                                                            {errors.description?.type && <ErrorLabel message={errors.description?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Inspection&nbsp;<span className={`danger ${riskStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <Controller
                                                        name="inspection_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="inspection_date"
                                                                name="inspection_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                disabled={['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: riskStatus === 'close' ? 'Please Select Inspection Date' : false
                                                        }}
                                                    />
                                                    {errors.inspection_date && <ErrorLabel message={errors.inspection_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Document</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="upload_document"
                                                            accept=".doc,.docx,.pptx,.ppt,.xlsx,.xls,.pdf,.zip"
                                                            name="upload_document"
                                                            disabled={['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                            onChange={(event) => {
                                                                if (event.target.files != null) {
                                                                    var fileSize = event.target.files[0].size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setPostImage(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    }
                                                                    else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload Word, PPT, Excel, PDF & ZIP files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="upload_document">
                                                            {
                                                                postImage != null ?
                                                                    postImage.name : "Choose file"
                                                            }
                                                        </label>
                                                        <div className="mt-1">
                                                            {
                                                                (riskPool != null && riskPool.risk_management_document != null) ?
                                                                    // <div id="action_risk_management_document">
                                                                    //     <a target="_blank" href={riskPool.risk_management_document}>Click Here to View</a>
                                                                    // </div>
                                                                    // : <></>
                                                                    <>
                                                                        <a className="primary" id="action_risk_management_document"
                                                                            onClick={() => {

                                                                                if (riskPool?.risk_management_document?.split('.').pop() == 'pdf' ||
                                                                                    riskPool?.risk_management_document?.split('.').pop() == 'PDF' ||
                                                                                    riskPool?.risk_management_document?.split('.').pop() == 'png' ||
                                                                                    riskPool?.risk_management_document?.split('.').pop() == 'jpg' ||
                                                                                    riskPool?.risk_management_document?.split('.').pop() == 'jpeg' ||
                                                                                    riskPool?.risk_management_document?.split('.').pop() == 'gif'
                                                                                ) {
                                                                                    setSelectedDocumentIndex(riskPool?.risk_management_document)
                                                                                    setShowDocumentDetails(true);
                                                                                    setExtension(riskPool?.risk_management_document?.split('.').pop())
                                                                                    setDocumentDetails(riskPool)
                                                                                } else {
                                                                                    downloadFile(riskPool?.risk_management_document);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Click Here to View
                                                                        </a>
                                                                        <a id="downloadLink" className="d-none"></a>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Assigned Engineer&nbsp;<span className={`danger ${riskStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <Controller
                                                        name="assign_engineer"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="assign_engineer"
                                                                name="assign_engineer"
                                                                dataSource={assignEngineer}
                                                                allowFiltering={false}
                                                                placeholder="Select Assigned Engineer"
                                                                value={field.value}
                                                                fields={{ text: 'full_name', value: 'user_id' }}
                                                                enabled={!['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.user_id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: riskStatus === 'close' ? 'Please Select Assigned Engineer' : false
                                                        }}
                                                    />

                                                    {errors.assign_engineer && <ErrorLabel message={errors.assign_engineer?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Assigned Engineer Status</label>
                                                    <Controller
                                                        name="engineer_status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="engineer_status"
                                                                name="engineer_status"
                                                                dataSource={[
                                                                    {
                                                                        "title": "Update",
                                                                        "value": "update"
                                                                    },
                                                                    {
                                                                        "title": "Cancel",
                                                                        "value": "cancel"
                                                                    },
                                                                    {
                                                                        "title": "Close",
                                                                        "value": "close"
                                                                    }
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Assigned Engineer Status"
                                                                value={field.value != null ? field.value : 'update'}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                enabled={!['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value);
                                                                        setRiskStatus(event.itemData.value);
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    {errors.engineer_status && <ErrorLabel message={errors.engineer_status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Approved By
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Actual Date of Inspection&nbsp;<span className={`danger ${riskStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <Controller
                                                        name="actual_date_inspection"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="actual_date_inspection"
                                                                name="actual_date_inspection"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                disabled={['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: riskStatus === 'close' ? 'Please Select Actual Date Inspection' : false
                                                        }}

                                                    />
                                                    {errors.actual_date_inspection && <ErrorLabel message={errors.actual_date_inspection?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remark&nbsp;<span className={`danger ${riskStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks"
                                                        name="remarks"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Remark"
                                                        disabled={['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                        {...register('remarks', {
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            },
                                                            required: riskStatus === 'close' ? 'Please Enter Remark' : false
                                                        })}
                                                    />
                                                    {errors.remarks?.type === 'required' && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Financial Year&nbsp;<span className={`danger ${riskStatus === 'close' ? '' : 'hidden'}`}>*</span></label>
                                                    <Controller
                                                        name="financial_year"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="financial_year"
                                                                name="financial_year"
                                                                dataSource={financialYear}
                                                                allowFiltering={false}
                                                                placeholder="Select Financial Year"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                enabled={!['cancel', 'close'].includes(riskPool?.engineer_status) ? true : false}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: riskStatus === 'close' ? 'Please Select Financial Year' : false
                                                        }}
                                                    />

                                                    {errors.financial_year && <ErrorLabel message={errors.financial_year?.message} />}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_risk_management"
                                            className={`btn btn-primary mr-1 shadow round text-uppercase ${!['cancel', 'close'].includes(riskPool?.engineer_status) ? '' : 'hidden'}`}
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="risk-management-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (props?.location?.state?.type === 'RISK_ASSIGNEE_NOTIFICATION' || props?.location?.state?.type === 'RISK_REQUEST_ASSIGN_BY_RISK_USER') {
                                                    props.history.push({
                                                        pathname: '/notification'
                                                    })
                                                } else {
                                                    props.history.goBack();
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <DocumentIframe
                {...props}
                show={showDocumentDetails}
                documentDetails={selectedDocumentIndex}
                detailsOfDocument={documentDetails}
                extension={extension}
                handleClose={() => {
                    setShowDocumentDetails(false);
                }}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    var ViewRiskManagmentPool = null;
    var RiskManagementClientType = null;
    var RiskManagementTypeOfInspection = null;
    var RiskManagementFinancialYear = null;
    var EditRiskManagmentPool = null;
    var AssignEngineer = null;

    if (
        state.EditRiskManagementPoolReducer != null &&
        Object.keys(state.EditRiskManagementPoolReducer).length > 0 &&
        state.EditRiskManagementPoolReducer.constructor === Object
    ) {
        switch (state.EditRiskManagementPoolReducer.tag) {
            case ACTION_RISK_POOL_VIEW_SUCCESS:
                ViewRiskManagmentPool = Object.assign({}, state.EditRiskManagementPoolReducer.data);
                delete state.EditRiskManagementPoolReducer;
                return { ViewRiskManagmentPool };
            case ACTION_RISK_POOL_CLIENT_TYPE_SUCCESS:
                RiskManagementClientType = Object.assign({}, state.EditRiskManagementPoolReducer.data);
                delete state.EditRiskManagementPoolReducer;
                return { RiskManagementClientType };
            case ACTION_RISK_POOL_TYPE_OF_INSPECTION_SUCCESS:
                RiskManagementTypeOfInspection = Object.assign({}, state.EditRiskManagementPoolReducer.data);
                delete state.EditRiskManagementPoolReducer;
                return { RiskManagementTypeOfInspection };
            case ACTION_RISK_POOL_FINANCIAL_YEAR_SUCCESS:
                RiskManagementFinancialYear = Object.assign({}, state.EditRiskManagementPoolReducer.data);
                delete state.EditRiskManagementPoolReducer;
                return { RiskManagementFinancialYear };
            case ACTION_RISK_POOL_EDIT_SUCCESS:
                EditRiskManagmentPool = Object.assign({}, state.EditRiskManagementPoolReducer.data);
                delete state.EditRiskManagementPoolReducer;
                return { EditRiskManagmentPool };
            case ACTION_MASTER_ASSIGN_ENGINEER_SUCCESS:
                AssignEngineer = Object.assign({}, state.EditRiskManagementPoolReducer.data);
                delete state.EditRiskManagementPoolReducer;
                return { AssignEngineer };
            default:
        }
    }
    return {
        ViewRiskManagmentPool,
        RiskManagementClientType,
        RiskManagementTypeOfInspection,
        RiskManagementFinancialYear,
        EditRiskManagmentPool,
        AssignEngineer
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewRiskManagmentPool,
        actionRiskManagementClientType,
        actionRiskManagementTypeOfInspection,
        actionRiskManagementFinancialYear,
        actionEditRiskManagmentPool,
        actionAssignEngineer
    }, dispatch)
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskPoolEdit))