import React, { useEffect, useState } from "react"
import ScreenTitle from '../../../../../../component/screen_title';
import { DateBox } from 'devextreme-react/date-box';
import { Controller, useForm } from "react-hook-form";
import dateFormat from "dateformat";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { UNAUTHORIZED } from "../../../../../../constants";
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { KEY_TOAST_MESSAGE, setData } from "../../../../../../utils/preferences";
import moment from "moment";
import $ from 'jquery';
import { actionViewWellnessRequest, ACTION_VIEW_WELLNESS_REQUEST_SUCCESS } from "./action";
import { actionEditWellnessManagementClientName, ACTION_EDIT_WELLNESS_CLIENT_NAME_SUCCESS } from "./action";
import { actionEditWellnessManagementClientType, ACTION_EDIT_WELLNESS_CLIENT_TYPE_SUCCESS } from "./action";
import { actionEditWellnessRequest, ACTION_EDIT_WELLNESS_REQUEST_SUCCESS } from "./action";

const WellnessManagementEdit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [clientType, setClientType] = useState([]);
    const [clientName, setClientName] = useState([]);
    const [viewNewRequest, SetViewNewRequest] = useState(null);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionViewWellnessRequest({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (viewNewRequest != null) {
            setValue('created_at', viewNewRequest?.created_at != null ? moment(viewNewRequest?.created_at).format("DD/MM/YYYY") : '');
            setValue('address', viewNewRequest?.address != null ? viewNewRequest?.address : '');
            setValue('activity_date', viewNewRequest?.activity_date != null ? viewNewRequest?.activity_date : '');
            setValue('remarks', viewNewRequest?.remarks != null ? viewNewRequest?.remarks : '');
            props.actionEditWellnessManagementClientType();
        }
    }, [viewNewRequest]);

    const onViewNewRequestUpdate = () => {
        const { ViewNewRequest } = props;
        if (ViewNewRequest != null) {
            var ViewNewRequestResponse = Object.assign({}, ViewNewRequest);
            if (ViewNewRequestResponse.result === true) {
                $("#progress-loader").addClass("hidden");
                SetViewNewRequest(ViewNewRequestResponse.response);
            } else {
                $("#progress-loader").addClass("hidden");
                switch (ViewNewRequestResponse.status) {
                    case UNAUTHORIZED:
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onViewNewRequestUpdate, [props.ViewNewRequest]);

    useEffect(() => {
        if (clientType != null && clientType.length > 0) {
            if (viewNewRequest != null && viewNewRequest.client_type != null) {
                setValue('client_type', viewNewRequest.client_type);
                props.actionEditWellnessManagementClientName({ client_type: viewNewRequest.client_type });
            }
        }
    }, [clientType])

    const onClientTypeUpdate = () => {
        const { ClientType } = props;
        var ClientTypeResponse = Object.assign({}, ClientType);
        if (ClientTypeResponse.result === true) {
            setClientType(ClientTypeResponse.response);
        } else {
            switch (ClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onClientTypeUpdate, [props.ClientType]);

    useEffect(() => {
        if (clientName != null && clientName.length > 0) {
            if (viewNewRequest != null && viewNewRequest.client_id != null) {
                setValue('client_id', viewNewRequest?.client_id);
            }
        }
    }, [clientName])

    const onClientNameUpdate = () => {
        const { ClientName } = props;
        var ClientNameResponse = Object.assign({}, ClientName);
        if (ClientNameResponse.result === true) {
            setClientName(ClientNameResponse.response);
        } else {
            switch (ClientNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onClientNameUpdate, [props.ClientName]);

    const onRequestEditUpdate = () => {
        const { RequestEdit } = props;
        var RequestEditResponse = Object.assign({}, RequestEdit);
        $("#edit_wellness_management").prop("disabled", false);
        if (RequestEditResponse.result === true) {
            $("#wellness-management-edit-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, RequestEditResponse.message);
            props.history.goBack();
        } else {
            $("#wellness-management-edit-loader").addClass("hidden");
            switch (RequestEditResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRequestEditUpdate, [props.RequestEdit]);

    const onSubmit = (data) => {
        $("#wellness-management-edit-loader").removeClass("hidden");
        $("#edit_wellness_management").prop("disabled", true);
        data.id = viewNewRequest?.id;
        props.actionEditWellnessRequest(data);

    }
    return (
        <>
            <ScreenTitle title="Edit Wellness Management" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Created Date</label>
                                                    <input
                                                        id="created_at"
                                                        name="created_at"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("created_at")}
                                                    />
                                                    {errors.created_at && <ErrorLabel message={errors.created_at?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type</label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                enabled={false}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: ' Please select client type'
                                                        }}
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name</label>
                                                    <Controller
                                                        name="client_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_id"
                                                                name="client_id"
                                                                dataSource={clientName}
                                                                allowFiltering={false}
                                                                placeholder="Select Client Name"
                                                                value={field.value}
                                                                enabled={false}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: viewNewRequest != null && viewNewRequest.client_id != null ? ' Please select client name' : false
                                                        }}
                                                    />

                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Activity</label>
                                                    <Controller
                                                        name="activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="activity_date"
                                                                name="activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.activity_date && <ErrorLabel message={errors.activity_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remark</label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks"
                                                        name="remarks"
                                                        type="text"
                                                        placeholder="Enter Remark"
                                                        {...register('remarks')}
                                                    />
                                                    {errors.remarks?.type && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_wellness_management"
                                            className="btn btn-primary mr-1 shadow round text-uppercase"
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="wellness-management-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                props.history.goBack();
                                            }}
                                        >
                                            Cancel
                                        </button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
const mapStateToProps = (state) => {
    var ViewNewRequest = null;
    var ClientType = null;
    var ClientName = null;
    var RequestEdit = null;

    if (
        state.editWellnessManagementReducer != null &&
        Object.keys(state.editWellnessManagementReducer).length > 0 &&
        state.editWellnessManagementReducer.constructor === Object
    ) {
        switch (state.editWellnessManagementReducer.tag) {
            case ACTION_VIEW_WELLNESS_REQUEST_SUCCESS:
                ViewNewRequest = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { ViewNewRequest };
            case ACTION_EDIT_WELLNESS_CLIENT_TYPE_SUCCESS:
                ClientType = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { ClientType };
            case ACTION_EDIT_WELLNESS_CLIENT_NAME_SUCCESS:
                ClientName = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { ClientName };
            case ACTION_EDIT_WELLNESS_REQUEST_SUCCESS:
                RequestEdit = Object.assign({}, state.editWellnessManagementReducer.data);
                delete state.editWellnessManagementReducer;
                return { RequestEdit };
            default:
        }
    }
    return {
        ViewNewRequest,
        ClientType,
        ClientName,
        RequestEdit,

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewWellnessRequest, actionEditWellnessManagementClientType, actionEditWellnessManagementClientName, actionEditWellnessRequest
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessManagementEdit))
