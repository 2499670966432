import React, { useEffect, useState } from "react";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
    Button,
    Column,
    FilterRow,
    HeaderFilter,
    Pager,
    Paging,
    SearchPanel,
} from "devextreme-react/data-grid";
import { FiEdit, FiEye } from "react-icons/fi";
import { UNAUTHORIZED } from "../../../../../../constants";
import $ from "jquery";
import moment from "moment";
import { actionWellnessApprovalList, ACTION_WELLNESS_APPROVAL_LIST_SUCCESS } from "./action";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { verifyPermission } from "../../../../../../utils";
import { KEY_TOAST_MESSAGE, getData, removeData } from "../../../../../../utils/preferences";
import { toast } from "react-toastify";

const WellnessManagementApprovalsList = (props) => {
    const { match } = props;

    const [wellnessManagementApprovalList, setWellnessManagementApprovalList] = useState([]);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        if (getData(KEY_TOAST_MESSAGE) != null) {
            toast.success(getData(KEY_TOAST_MESSAGE));
            removeData(KEY_TOAST_MESSAGE);
        }
        props.actionWellnessApprovalList({ user_type: "manager" });
    }
    useEffect(initialRender, []);

    const [columns, setColumns] = useState([
        { dataField: "Action", caption: "Action", type: "button", visible: true },
        { dataField: "created_date", caption: "Created Date", visible: false },
        { dataField: "created_date_formated", caption: "Created Date", visible: true },
        { dataField: "client_name", caption: "Client Name", visible: true },
        { dataField: "address", caption: "Location Address", visible: true },
        { dataField: "activity_date", caption: "Date of activity", visible: false },
        { dataField: "activity_date_formated", caption: "Date of activity", visible: true },
        { dataField: "approved_by", caption: "Approved By", visible: true },
        { dataField: "actual_activity_date", caption: "Actual Date of activity", visible: false },
        { dataField: "actual_activity_date_formated", caption: "Actual Date of activity", visible: true },
        { dataField: "remarks", caption: "Remark", visible: true },
        { dataField: "status_name", caption: "Status", visible: true },
        { dataField: "rm_status_name", caption: "RM Status", visible: true }
    ]);

    const renderApprovedBy = (cell) => {
        return <div>{cell.data.status != null && cell.data.status != "pending" ? cell.data.approved_by : ''}</div>
    }

    const renderRMStatus = (cell) => {
        return <div>{cell.data.rm_status_name != null && cell.data.rm_status_name != "Not Defined" ? cell.data.rm_status_name : ''}</div>;
    }

    const renderStatusName = (cell) => {
        return <div>{cell.data.status_name != null && cell.data.status_name != "Not Defined" ? cell.data.status_name : ''}</div>;
    }

    const renderCreateDate = (cell) => {
        return <div>{cell?.data?.created_date_formated != null ? moment(cell?.data?.created_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActivityDate = (cell) => {
        return <div>{cell?.data?.activity_date_formated != null ? moment(cell?.data?.activity_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const renderActualActivityDate = (cell) => {
        return <div>{cell?.data?.actual_activity_date_formated != null ? moment(cell?.data?.actual_activity_date_formated).format('DD/MM/YYYY') : ''}</div>
    }

    const onWellnessApprovalListUpdate = () => {
        const { WellnessApprovalList } = props;
        if (WellnessApprovalList != null) {
            var WellnessApprovalListResponse = Object.assign({}, WellnessApprovalList);
            if (WellnessApprovalListResponse.result === true) {
                setWellnessManagementApprovalList(WellnessApprovalListResponse.response);
                $("#progress-loader").addClass("hidden");
            } else {
                $("#progress-loader").addClass("hidden");
                switch (WellnessApprovalListResponse.status) {
                    case UNAUTHORIZED:
                        props.auth.logout();
                        break;
                    default:
                }
            }
        }
    }
    useEffect(onWellnessApprovalListUpdate, [props.WellnessApprovalList])

    const renderColumns = () => {
        var arrColumns = [];
        columns.forEach((objColumn, indexColumn) => {
            if (objColumn.dataField === "Action") {
                arrColumns.push(
                    <Column dataField="action" type="buttons">
                        {
                            verifyPermission("WELLNESS_MANAGEMNT_APPROVAL_VIEW") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className="btn btn-icon btn-sm float-left"
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/view`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEye size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                        {
                            verifyPermission("WELLNESS_MANAGEMNT_APPROVAL_EDIT") ?
                                <Button
                                    render={(params) => (
                                        <button
                                            className={`btn btn-icon btn-sm ${['update', 'pending'].includes(params.data.status) ? '' : 'hidden'} `}
                                            onClick={() => {
                                                props.history.push({ pathname: `${match.url}/edit`, state: { id: params.data.id } })
                                            }}
                                        >
                                            <FiEdit size={16} className="primary" />
                                        </button>
                                    )}
                                />
                                :
                                <></>
                        }
                    </Column>
                );
            } else if (objColumn.dataField === "approved_by") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderApprovedBy}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "status_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderStatusName}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "rm_status_name") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderRMStatus}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "created_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderCreateDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "activity_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActivityDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else if (objColumn.dataField === "actual_activity_date_formated") {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        cellRender={renderActualActivityDate}
                        visible={objColumn.visible}
                    ></Column>
                );
            } else {
                arrColumns.push(
                    <Column
                        dataField={objColumn.dataField}
                        caption={objColumn.caption}
                        visible={objColumn.visible}
                    ></Column>
                )
            }
        });
        return arrColumns;
    };

    return (
        <>
            <div>
                <div className="content-header row mb-1">
                    <div className="content-header-left col-12 col-md-6 col-lg-6">
                        <h3 className="content-header-title text-capitalize mb-0">Wellness Management Approvals {wellnessManagementApprovalList.length === 0 ? '' : `(${wellnessManagementApprovalList.length})`}</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-lg-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <DataGrid
                                    dataSource={wellnessManagementApprovalList}
                                    showBorders={true}
                                    export={{
                                        fileName: "Wellness Management Approval",
                                        enabled: true,
                                        allowExportSelectedData: false,
                                        excelFilterEnabled: true,
                                    }}
                                    onExporting={(event) => {
                                        event.component.beginUpdate();
                                        event.component.columnOption("created_date_formated", "visible", false)
                                        event.component.columnOption("created_date", "visible", true)
                                        event.component.columnOption("activity_date_formated", "visible", false)
                                        event.component.columnOption("activity_date", "visible", true)
                                        event.component.columnOption("actual_activity_date_formated", "visible", false)
                                        event.component.columnOption("actual_activity_date", "visible", true)
                                    }}
                                    onExported={(event) => {
                                        event.component.columnOption("created_date_formated", "visible", true)
                                        event.component.columnOption("created_date", "visible", false)
                                        event.component.columnOption("activity_date_formated", "visible", true)
                                        event.component.columnOption("activity_date", "visible", false)
                                        event.component.columnOption("actual_activity_date_formated", "visible", true)
                                        event.component.columnOption("actual_activity_date", "visible", false)
                                        event.component.endUpdate();
                                    }}
                                >
                                    <SearchPanel visible={true} />
                                    <FilterRow visible={true} />
                                    <HeaderFilter visible={true} />
                                    {renderColumns()}
                                    <Pager
                                        visible={true}
                                        showPageSizeSelector={false}
                                        showNavigationButtons={true}
                                        allowedPageSizes={[4, 6, 10]}
                                    />
                                    <Paging
                                        defaultPageIndex={0}
                                        defaultPageSize={10}
                                        enabled={true}
                                    />
                                </DataGrid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
const mapStateToProps = (state) => {

    var WellnessApprovalList = null;

    if (
        state.WellnessApprovalListReducer != null &&
        Object.keys(state.WellnessApprovalListReducer).length > 0 &&
        state.WellnessApprovalListReducer.constructor === Object
    ) {
        switch (state.WellnessApprovalListReducer.tag) {
            case ACTION_WELLNESS_APPROVAL_LIST_SUCCESS:
                WellnessApprovalList = Object.assign({}, state.WellnessApprovalListReducer.data);
                delete state.WellnessApprovalListReducer;
                return { WellnessApprovalList };
            default:
        }
    }
    return {
        WellnessApprovalList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            actionWellnessApprovalList
        },
        dispatch
    );
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessManagementApprovalsList))