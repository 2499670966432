import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DateBox } from "devextreme-react/date-box";
import { TagBox } from "devextreme-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ERROR, UNAUTHORIZED } from "../../../constants";
import dateFormat from "dateformat";
import {
  actionPolicyGroup,
  ACTION_MASTER_POLICY_SUCCESS,
  actionOpportunityQuestionsList,
  ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS,
  actionGetOpportunityQuestions,
  ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS,
} from "./action";
import {
  actionInsuranceCompanyGroup,
  ACTION_MASTER_INSURANCE_COMPANY_SUCCESS,
} from "./action";
import {
  actionProbabilityGroup,
  ACTION_MASTER_PROBABILITY_SUCCESS,
} from "./action";
import {
  actionGetAssignProductRM,
  ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS,
} from "./action";
import { actionGetBrokerName, ACTION_GET_BROKER_NAME_SUCCESS } from "./action";
import { actionSelectCustomer, ACTION_CLIENT_NAME_SUCCESS } from "./action";
import { actionMeetingType, ACTION_API_CLIENT_TYPE_SUCCESS } from "./action";
import { random } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import { ToWords } from "to-words";
import ArrayStore from "devextreme/data/array_store";
import Swal from "sweetalert2";
import { FiTrash } from "react-icons/fi";

const PolicyDetailModal = props => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      client_remark: "",
      department_name: "",
      opportunityQuestion: [],
      product_specialist_id: 0,
    },
  });
  const { fields: opportunityQuestionFields } = useFieldArray({
    control,
    name: "opportunityQuestion",
  });

  const [policyGroup, setPolicyGroup] = useState([]);
  // const [expiringDate, setExpiringDate] = useState(null);
  const [proposalItemId, setProposalItemId] = useState(null);
  const [insuranceCompanyGroup, setInsuranceCompanyGroup] = useState([]);
  const [getAssignProductRM, setGetAssignProductRM] = useState([]);
  const [getAssignRM, setGetAssignRM] = useState(0);
  const [policyStartDate, setPolicyStartDate] = useState();
  const [policyExpiryDate, setPolicyExpiryDate] = useState();
  const [probabilityGroup, setProbabilityGroup] = useState([]);
  const [insurerCompany, setInsurerCompany] = useState("");
  const [policy, setPolicy] = useState("");
  const [department, setDepartment] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [isProductSpecialist, setIsProductSpecialist] = useState(1);
  const [probability, setProbability] = useState(null);
  const [arrSystemProbability, setArrSystenProbability] = useState([]);
  const [isOpportunityQuestion, setIsOpportunityQuestion] = useState(false);
  const [opportunityType, setOpportunityType] = useState(null);
  const [intermediaryType, setIntermediaryType] = useState(null);
  const [getBrokerName, setGetBrokerName] = useState([]);
  const [stateRfq, setStateRfq] = useState(false);
  const [meetingType, setMeetingType] = useState();
  const [customer, setCustomer] = useState();
  const [selectedBroker, setSelectedBroker] = useState([]);
  const [selectedSubProducts, setSelectedSubProducts] = useState([]);
  const [selectedInsurer, setSelectedInsurer] = useState([]);
  const [meetingTypeId, setMeetingTypeId] = useState(null);
  const [customerPolicyId, setCustomerPolicyId] = useState(0);
  const [customerCompanyId, setCustomerCompanyId] = useState(null);
  const [numberInWords, setNumberInWords] = useState(0);
  const [assignRMId, setAssignRMId] = useState(null);
  const watchExpectedPremium = watch("expected_premium");
  const watchDependents = watch("no_of_dependants");
  const watchEmployees = watch("no_of_employees");
  const watchPolicyStartDate = watch("start_date");
  const watchPolicyExpiryDate = watch("expiring_date");
  const [isPolicyExpiryWithin90Days, setIsPolicyExpiryWithin90Days] =
    useState(0);
    const subProductDataArr = [
      { name: "Base Policy", id: "Base Policy" },
      { name: "TopUp Policy", id: "TopUp Policy" },
      { name: "Parent Base Policy", id: "Parent Base Policy" },
      { name: "Parent TopUp Policy", id: "Parent TopUp Policy" }
  ];
  const initialRender = () => {
    $("#save-policy-details").prop("disabled", false);

    if (props.show === true) {
      setAssignRMId(props?.assignRMId != null ? props?.assignRMId : "");
      setProposalItemId(props.data != null ? props.data.id : "");
      props.actionPolicyGroup({ status: "ACTIVE", orderBy: "name" });
      // props.actionInsuranceCompanyGroup({ status: 'ACTIVE', department_id: departmentId });
      props.actionProbabilityGroup();
      props.actionMeetingType({});
      props.actionGetBrokerName();
      $("#root").addClass("modal-open");
      $("#root").append('<div class="modal-backdrop fade show"></div>');
      if (props.data !== null && props.data !== undefined) {
        $("#policy-details-form-loader").addClass("hidden");
        setProbability(props?.data?.probability);
        setValue(
          "start_date",
          props.data !== null &&
            props.data.start_date != null &&
            props.data.start_date != "0000-00-00 00:00:00"
            ? props.data.start_date
            : null
        );
        setValue(
          "policy_for_short_period",
          props.data !== null && props.data.policy_for_short_period != null
            ? props.data.policy_for_short_period
            : ""
        );
        setValue(
          "sub_product",
          props.data !== null && props.data.sub_product != null
            ? props.data.sub_product
            : ""
        );
        setValue(
          "line_of_business",
          props.data !== null && props.data.line_of_business != null
            ? props.data.line_of_business
            : ""
        );
        setValue(
          "employer_employee_relationship",
          props.data !== null &&
            props.data.employer_employee_relationship != null
            ? props.data.employer_employee_relationship
            : ""
        );
        setValue(
          "no_of_dependants",
          props.data !== null && props.data.no_of_dependants != null
            ? props.data.no_of_dependants
            : ""
        );
        setValue(
          "no_of_employees",
          props.data !== null && props.data.no_of_employees != null
            ? props.data.no_of_employees
            : ""
        );
        setValue(
          "total_lives",
          props.data !== null && props.data.total_lives != null
            ? props.data.total_lives
            : ""
        );
        if (
          Array.isArray(props?.data?.brokers) &&
          props.data.brokers.length > 0
        ) {
          const oppBrokerIds = props.data.brokers.map(item => item.id);
          setValue("broker_ids", oppBrokerIds.length > 0 ? oppBrokerIds : []);
        } else if (
          Array.isArray(props?.data?.broker_ids) &&
          props.data.broker_ids.length > 0
        ) {
          setValue("broker_ids", props.data.broker_ids);
        } else {
          setValue("broker_ids", []);
        }
        setValue(
          "expected_premium",
          props.data !== null && props.data.expected_premium != null
            ? props.data.expected_premium
            : ""
        );
        setValue(
          "expiring_date",
          props.data !== null &&
            props.data.lead_expiry_date != null &&
            props.data.lead_expiry_date != "0000-00-00 00:00:00"
            ? props.data.lead_expiry_date
            : null
        );
        setValue(
          "probability",
          props.data !== null && props.data.probability != null
            ? props.data.probability
            : ""
        );
        setValue(
          "client_remark",
          props.data !== null && props.data.client_remark != null
            ? props.data.client_remark
            : ""
        );
        setValue(
          "department_name",
          props.data !== null && props.data.department_name != null
            ? props.data.department_name
            : ""
        );
        setValue(
          "is_product_specialist_required",
          props.data !== null &&
            props.data.is_product_specialist_required != null &&
            props.data.is_product_specialist_required === 1
            ? true
            : false
        );
        setValue(
          "product_specialist_id",
          props.data !== null && props.data.product_specialist_id != null
            ? props.data.product_specialist_id
            : 0
        );
        setValue(
          "risk_advisory_activity_done",
          props.data !== null && props.data.risk_advisory_activity_done != null
            ? props.data.risk_advisory_activity_done
            : ""
        );
        setValue(
          "exclusive_mandate_received",
          props.data !== null && props.data.exclusive_mandate_received != null
            ? props.data.exclusive_mandate_received
            : ""
        );
        setValue(
          "product_specialist_involved",
          props.data !== null && props.data.product_specialist_involved != null
            ? props.data.product_specialist_involved
            : ""
        );
        setValue(
          "oppurtunity_type",
          props.data !== null && props.data.oppurtunity_type != null
            ? props.data.oppurtunity_type
            : ""
        );
        setValue(
          "intermediary_type",
          props.data !== null && props?.data?.intermediary_type != null
            ? props.data?.intermediary_type
            : ""
        );
        setValue(
          "intermediary_value",
          props.data !== null && props.data.intermediary_value != null
            ? props.data.intermediary_value
            : ""
        );
        setIntermediaryType(props?.data?.intermediary_type);
        var arrData = [];
        if (
          props.data != null &&
          props.data.opportunity_questions != null &&
          props.data.opportunity_questions != undefined &&
          props.data.opportunity_questions.length > 0
        ) {
          arrSystemProbability?.forEach(element => {
            var value = props.data.opportunity_questions.find(
              item => item.id === element.id
            );
            var questionValue = "";
            if (value != null && value != undefined && value != "") {
              questionValue = value.question_value;
              setIsOpportunityQuestion(true);
            } else {
              setIsOpportunityQuestion(false);
            }
            arrData.push({
              id: element.id,
              question: element.question,
              question_value: questionValue,
              yes_value: element.yes_value,
              no_value: element.no_value,
            });
          });
          setValue("opportunityQuestion", arrData);
          setArrSystenProbability(arrData);
        } else {
          props.actionOpportunityQuestionsList({});
        }
      } else {
        reset();
        setValue("probability", null);
        setValue("department_name", "");
        setValue("product_specialist_id", 0);
        setSelectedBroker([]);
        setSelectedInsurer([]);
        setValue("broker_ids", null);
        setValue("insurer_ids", null);
        setInsuranceCompanyGroup([]);
        setGetBrokerName([]);
        setIntermediaryType(null);
      }
    } else {
      reset();
      setValue("probability", null);
      setPolicy("");
      $("#policy-details-form-loader").addClass("hidden");
      $("#root").removeClass("modal-open");
      $(".modal-backdrop").remove();
      setPolicyGroup([]);
      setValue("meeting_type", null);
      setValue("client_id", null);
      setInsuranceCompanyGroup([]);
      setProbabilityGroup([]);
      setGetBrokerName([]);
      setSelectedBroker([]);
      setSelectedInsurer([]);
      setValue("broker_ids", null);
      setValue("insurer_ids", null);
      setValue("expected_premium", "");
      setValue("expiring_date", "");
      setValue("policy_type_id", null);
      setValue("oppurtunity_type", null);
      setDepartment(null);
      setDepartmentId(null);
      setProbability(null);
      setOpportunityType(null);
      setIntermediaryType(null);
    }
  };
  useEffect(initialRender, [props.show]);

  useEffect(() => {
    if (watchPolicyStartDate && watchPolicyExpiryDate) {
      const startDate = moment(watchPolicyStartDate, "YYYY-MM-DD"); // Ensure correct format
      const endDate = moment(watchPolicyExpiryDate, "YYYY-MM-DD"); // Ensure correct format
      const diffDays = endDate.diff(startDate, "days");
      console.log("====================", diffDays);
      if (diffDays <= 365) {
        setValue("policy_for_short_period", "Yes");
      } else {
        setValue("policy_for_short_period", "No");
      }
    }
  }, [watchPolicyStartDate, watchPolicyExpiryDate, setValue]);

  useEffect(() => {
    setValue(
      "intermediary_type",
      intermediaryType != null ? intermediaryType : ""
    );
  }, [intermediaryType]);

  useEffect(() => {
    if (policy !== "Group Mediclaim Policy") {
      setValue("sub_product", null);
      setValue("line_of_business", null);
      setValue("employer_employee_relationship", null);
      setValue("no_of_dependants", null);
      setValue("no_of_employees", null);
      setValue("total_lives", null);
    }
  }, [policy]);

  useEffect(() => {
    if (watchDependents || watchEmployees) {
      const noOfLives = Number(watchDependents) + Number(watchEmployees);
      setValue("total_lives", noOfLives);
    }
  }, [watchDependents, watchEmployees]);

  useEffect(() => {
    if (policyGroup != null && policyGroup.length > 0) {
      if (props.data !== null) {
        setValue(
          "policy_type_id",
          props.data != null ? props.data.policy_type_id : ""
        );
      }
    }
  }, [policyGroup]);

  useEffect(() => {
    if (watchExpectedPremium != null) {
      const isNumeric = /^[0-9]*$/.test(watchExpectedPremium);
      if (isNumeric) {
        const numericValue = parseFloat(watchExpectedPremium || "0");
        const toWords = new ToWords();
        const words = toWords.convert(numericValue);
        setNumberInWords(words);
      }
    } else {
      setNumberInWords("");
    }
  }, [watchExpectedPremium]);

  const onMasterPolicyGroupUpdate = () => {
    const { PolicyGroup } = props;
    var PolicyGroupResponse = Object.assign({}, PolicyGroup);
    if (PolicyGroupResponse.result === true) {
      setPolicyGroup(PolicyGroupResponse.response);
    } else {
      switch (PolicyGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(PolicyGroupResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterPolicyGroupUpdate, [props.PolicyGroup]);

  useEffect(() => {
    if (departmentId != null) {
      props.actionGetAssignProductRM({
        department_id: departmentId,
        assigned_rm_id: assignRMId,
      });
      props.actionInsuranceCompanyGroup({
        status: "ACTIVE",
        department_id: departmentId,
      });
    }
  }, [departmentId]);

  useEffect(() => {
    if (getAssignProductRM != null && getAssignProductRM.length > 0) {
      if (props.data != null) {
        if (props?.data?.policy_type_id != getValues("policy_type_id")) {
          setValue("product_specialist_id", 0);
        } else {
          setValue("product_specialist_id", props?.data?.product_specialist_id);
        }
      }
    }
  }, [getAssignProductRM]);

  const onAssignProductRMUpdate = () => {
    const { GetAssignProductRM } = props;
    var GetAssignProductRMResponse = Object.assign({}, GetAssignProductRM);
    if (GetAssignProductRMResponse.result === true) {
      var arrAssignRM = Object.assign([], GetAssignProductRMResponse.response);

      var user = {
        user_id: 0,
        full_name: "None",
      };

      arrAssignRM.unshift(user);

      setGetAssignProductRM(arrAssignRM);
    } else {
      switch (GetAssignProductRMResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          setGetAssignProductRM([{ user_id: 0, full_name: "None" }]);
        // toast.error(GetAssignProductRMResponse.message);
        default:
      }
    }
  };
  useEffect(onAssignProductRMUpdate, [props.GetAssignProductRM]);

  const onMasterInsuranceCompanyGroupUpdate = () => {
    const { InsuranceCompanyGroup } = props;
    var InsuranceCompanyGroupResponse = Object.assign(
      {},
      InsuranceCompanyGroup
    );
    if (InsuranceCompanyGroupResponse.result === true) {
      setInsuranceCompanyGroup(InsuranceCompanyGroupResponse.response);
      if (
        Array.isArray(props?.data?.insurers) &&
        props.data.insurers.length > 0
      ) {
        const oppInsurerIds = props.data.insurers.map(item => item.id);
        setValue("insurer_ids", oppInsurerIds.length > 0 ? oppInsurerIds : []);
      } else if (
        Array.isArray(props?.data?.insurer_ids) &&
        props.data.insurer_ids.length > 0
      ) {
        setValue("insurer_ids", props.data.insurer_ids);
      } else {
        setValue("insurer_ids", []);
      }
      // setValue("insurer_ids", props.data != null ? props.data.insurer_ids : "");
    } else {
      switch (InsuranceCompanyGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(InsuranceCompanyGroupResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterInsuranceCompanyGroupUpdate, [props.InsuranceCompanyGroup]);

  useEffect(() => {
    if (probabilityGroup != null && probabilityGroup.length > 0) {
      if (props.data !== null) {
        setValue(
          "probability",
          props.data.probability != null ? Number(props.data.probability) : ""
        );
      }
    }
  }, [probabilityGroup]);

  const onMasterProbabilityGroupUpdate = () => {
    const { ProbabilityGroup } = props;
    var ProbabilityGroupResponse = Object.assign({}, ProbabilityGroup);
    if (ProbabilityGroupResponse.result === true) {
      setProbabilityGroup(ProbabilityGroupResponse.response);
    } else {
      switch (ProbabilityGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProbabilityGroupResponse.message);
        default:
      }
    }
  };
  useEffect(onMasterProbabilityGroupUpdate, [props.ProbabilityGroup]);

  const onOpportunityQuestionListUpdate = () => {
    const { OpportunityQuestionList } = props;
    var OpportunityQuestionListResponse = Object.assign(
      {},
      OpportunityQuestionList
    );
    if (OpportunityQuestionListResponse.result === true) {
      var opportunityQuestionArr = OpportunityQuestionListResponse.response.map(
        item => ({
          ...item,
          question_value: "",
        })
      );
      setValue("opportunityQuestion", opportunityQuestionArr);
      setArrSystenProbability(opportunityQuestionArr);
      if (
        props?.data?.id != null &&
        props?.data?.id != "" &&
        props?.data?.probability >= 50
      ) {
        props.actionGetOpportunityQuestions({ id: props.data.id });
      }
    } else {
      switch (OpportunityQuestionListResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onOpportunityQuestionListUpdate, [props.OpportunityQuestionList]);

  const onGetOpportunityQuestionUpdate = () => {
    const { GetOpportunityQuestion } = props;
    var GetOpportunityQuestionResponse = Object.assign(
      {},
      GetOpportunityQuestion
    );
    if (GetOpportunityQuestionResponse.result === true) {
      var arrData = [];
      if (
        GetOpportunityQuestionResponse.response.opportunity_questions != null &&
        GetOpportunityQuestionResponse.response.opportunity_questions.length >
          0 &&
        arrSystemProbability != null &&
        arrSystemProbability.length > 0
      ) {
        arrSystemProbability?.forEach(element => {
          var value =
            GetOpportunityQuestionResponse.response.opportunity_questions.find(
              item => item.id === element.id
            );
          var questionValue = "";
          if (value != null && value != undefined) {
            questionValue = value.question_value;
            setIsOpportunityQuestion(false);
          } else {
            setIsOpportunityQuestion(true);
          }

          arrData.push({
            id: element.id,
            question: element.question,
            question_value: questionValue,
          });
        });
        setValue("opportunityQuestion", arrData);
        setArrSystenProbability(arrData);
      }
    } else {
      switch (GetOpportunityQuestionResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onGetOpportunityQuestionUpdate, [props.GetOpportunityQuestion]);

  useEffect(() => {
    if (
      Array.isArray(props?.data?.insurers) &&
      props.data.insurers.length > 0
    ) {
      const oppInsurerIds = props.data.insurers.map(item => item.id);
      setValue("insurer_ids", oppInsurerIds.length > 0 ? oppInsurerIds : []);
    }
  }, [insuranceCompanyGroup, props?.data?.insurers]);

  const onGetBrokerNameUpdate = () => {
    const { GetBrokerName } = props;
    var GetBrokerNameResponse = Object.assign({}, GetBrokerName);
    if (GetBrokerNameResponse.result === true) {
      setGetBrokerName(GetBrokerNameResponse.response);
    } else {
      switch (GetBrokerNameResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(GetBrokerNameResponse.message);
        default:
      }
    }
  };
  useEffect(onGetBrokerNameUpdate, [props.GetBrokerName]);

  const onMeetingTypeUpdate = () => {
    const { MeetingType } = props;
    var MeetingTypeResponse = Object.assign({}, MeetingType);
    if (MeetingTypeResponse.result === true) {
      setMeetingType(MeetingTypeResponse.response);
    } else {
      switch (MeetingTypeResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onMeetingTypeUpdate, [props.MeetingType]);

  // Call On Change of Meeting With To Get The Customer List **Select Customer**
  const onSelectCustomerUpdate = () => {
    const { Customers } = props;
    var CustomersResponse = Object.assign({}, Customers);
    if (CustomersResponse.result === true) {
      setCustomer(CustomersResponse.response);
    } else {
      switch (CustomersResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onSelectCustomerUpdate, [props.Customers]);

  const onSubmit = data => {
    var arrData = [];
    data.opportunityQuestion?.forEach(element => {
      if (element.question_value === undefined) {
        var value = arrSystemProbability.find(item => item.id === element.id);
        var questionValue = "";
        if (value != null && value != undefined) {
          //questionValue = value.question_value
          arrData.push({
            id: element.id,
            question: element.question,
            question_value: value.question_value,
            yes_value: element.yes_value,
            no_value: element.no_value,
          });
        } else {
          arrData.push({
            id: element.id,
            question: element.question,
            question_value: element.question_value,
            yes_value: element.yes_value,
            no_value: element.no_value,
          });
        }
      } else {
        arrData.push({
          id: element.id,
          question: element.question,
          question_value: element.question_value,
          yes_value: element.yes_value,
          no_value: element.no_value,
        });
      }
    });

    var newPolicy = {};
    if (props.data != null && props.data.hasOwnProperty("id")) {
      newPolicy.id = props.data.id;
    }

    if (props.opportunityPool === true) {
      newPolicy.client_id = data.client_id;
    }
    newPolicy.department_id = departmentId;
    newPolicy.department_name = department;
    newPolicy.policy_type_id = data.policy_type_id;
    newPolicy.policy_type = policy;

    newPolicy.product_specialist_id = data.product_specialist_id;
    newPolicy.expected_premium = data.expected_premium;
    newPolicy.probability = data.probability;
    newPolicy.status = "open";
    newPolicy.client_remark = data.client_remark;
    newPolicy.is_product_specialist_required = isProductSpecialist;
    newPolicy.risk_advisory_activity_done = data.risk_advisory_activity_done;
    newPolicy.exclusive_mandate_received = data.exclusive_mandate_received;
    newPolicy.product_specialist_involved = data.product_specialist_involved;
    newPolicy.opportunity_questions =
      arrData != null && arrData.length > 0
        ? arrData
        : data.opportunityQuestion != null
        ? data.opportunityQuestion
        : arrSystemProbability;
    newPolicy.oppurtunity_type = data.oppurtunity_type;
    newPolicy.insurer_name = insurerCompany;
    newPolicy.insurer_ids = selectedInsurer;
    newPolicy.expiring_date =
      data.expiring_date != null ? data.expiring_date : null;
    newPolicy.lead_expiry_date =
      data.expiring_date != null ? data.expiring_date : null;
    newPolicy.start_date = data?.start_date != null ? data?.start_date : null;
    newPolicy.intermediary_type = data.intermediary_type;
    newPolicy.sub_product = data?.sub_product;
    newPolicy.line_of_business = data?.line_of_business;
    newPolicy.employer_employee_relationship =
      data?.employer_employee_relationship;
    newPolicy.no_of_employees = data?.no_of_employees;
    newPolicy.no_of_dependants = data?.no_of_dependants;
    newPolicy.total_lives = data?.total_lives;
    newPolicy.policy_for_short_period = data?.policy_for_short_period;
    newPolicy.sub_products = selectedSubProducts
    // if (data.oppurtunity_type === "Fresh") {
    //   delete data.intermediary_type;
    //   delete data.intermediary_value;
    // } else {
    if (data.intermediary_type === "Broker") {
      newPolicy.broker_ids = selectedBroker;
    } else {
      newPolicy.intermediary_value = data.intermediary_value;
    }
    // }
    $("#policy-details-form-loader").removeClass("hidden");
    $("#save-policy-details").prop("disabled", true);
    props.handleClose(newPolicy, stateRfq);
    setValue("department_name", "");
    setSelectedSubProducts([])
  };

  return (
    <div
      className="modal fade show"
      id="default"
      role="dialog"
      aria-labelledby="myModalLabel1"
      aria-hidden="true"
      style={{ display: `${props.show === true ? "block" : "none"}` }}
    >
      <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="media-middle mt-1">
              <h4 className="modal-title" id="myModalLabel1">
                {proposalItemId ? "Edit" : "Add "} Opportunity Details
              </h4>
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                props.handleClose();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ overflow: "visible" }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <form
                  noValidate
                  autoComplete="Off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-body">
                    <div className="row">
                      {props.opportunityPool === true ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Client Type&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <Controller
                                name="meeting_type"
                                className="form-control"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={meetingType}
                                    fields={{ text: "title", value: "value" }}
                                    allowFiltering={false}
                                    placeholder="Select Client Type"
                                    value={field.value}
                                    change={event => {
                                      if (event.itemData != null) {
                                        // Reset Customer
                                        setCustomerCompanyId(null);
                                        setCustomerPolicyId(0);
                                        setCustomer([]);
                                        // EOC : Reset Customer

                                        field.onChange(event.itemData.value);
                                        setMeetingTypeId(event.itemData.value);

                                        if (event.itemData.value === "lead") {
                                          props.actionSelectCustomer({
                                            client_type: "lead",
                                          });
                                        } else {
                                          props.actionSelectCustomer({
                                            client_type: "client",
                                          });
                                        }
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required: "Client Type is required",
                                }}
                              />
                              {errors.meeting_type && (
                                <ErrorLabel
                                  message={errors.meeting_type?.message}
                                />
                              )}
                            </div>
                          </div>
                          <div
                            className="col-12 col-md-4 col-lg-4"
                            id="customer-field"
                          >
                            <div className="form-group">
                              <label className="label-control">
                                Client Name&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <Controller
                                name="client_id"
                                className="form-control"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={customer}
                                    fields={{ text: "name", value: "id" }}
                                    allowFiltering={true}
                                    filterType={"Contains"}
                                    placeholder="Select Customer"
                                    value={field.value}
                                    change={event => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.id);
                                        setCustomerCompanyId(event.itemData.id);
                                        // AssignRM API
                                        setAssignRMId(
                                          event.itemData.created_by
                                        );
                                        // props.actionGetAssignProductRM({ department_id: departmentId, assigned_rm_id: event.itemData.created_by })
                                        // setCustomerPolicyId(event.itemData.id)
                                        // props.actionPreviousMeeting({ client_id: event.itemData.id });
                                        // props.actionCustomerMeetingContact({ client_id: event.itemData.id })
                                        // props.actionCustomerMeetingPolicy({ client_id: event.itemData.id })
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required: "Customer is required",
                                }}
                              />
                              {errors.client_id && (
                                <ErrorLabel
                                  message={errors.client_id?.message}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {props?.data?.oppurtunity_type === "Renewal" ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Control Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="control_no"
                                name="control_no"
                                value={
                                  props?.data != null
                                    ? props.data?.control_no
                                    : ""
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Policy Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="policy_no"
                                name="policy_no"
                                value={
                                  props?.data != null
                                    ? props.data?.policy_no
                                    : ""
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Opportunity Type&nbsp;
                            <span className="danger">*</span>
                          </label>
                          <Controller
                            name="oppurtunity_type"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={[
                                  {
                                    id: "Fresh",
                                    name: "Fresh",
                                  },
                                  {
                                    id: "Market Roll Over",
                                    name: "Market Roll Over",
                                  },
                                ]}
                                id="oppurtunity_type"
                                name="oppurtunity_type"
                                fields={{ text: "name", value: "id" }}
                                allowFiltering={false}
                                value={field.value}
                                placeholder="Select Opportunity Type"
                                change={event => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.id);
                                    setOpportunityType(event.itemData.id);
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Please Select Opportunity Type",
                            }}
                          />
                          {errors.oppurtunity_type && (
                            <ErrorLabel
                              message={errors.oppurtunity_type?.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            {" "}
                            Policy Type&nbsp;<span className="danger">*</span>
                          </label>
                          <Controller
                            name="policy_type_id"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={policyGroup}
                                id="policy_type_id"
                                name="policy_type_id"
                                fields={{ text: "name", value: "id" }}
                                allowFiltering={true}
                                filterType={"Contains"}
                                value={field.value}
                                placeholder="Select Policy Type"
                                change={event => {
                                  if (event.itemData != null) {
                                    // Set Policy Name So That We Can Use That Name To Show In Front Page
                                    setPolicy(event.itemData.name);
                                    setDepartment(
                                      event.itemData.department_name
                                    );
                                    setDepartmentId(
                                      event.itemData.department_id
                                    );
                                    field.onChange(event.itemData.id);
                                    setValue("product_specialist_id", 0);
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Please Select Policy",
                            }}
                          />
                          {errors.policy_type_id && (
                            <ErrorLabel
                              message={errors.policy_type_id?.message}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">Department</label>
                          <input
                            type="text"
                            className="form-control"
                            id="department_name"
                            name="department_name"
                            value={department != null ? department : ""}
                            disabled={true}
                            placeholder="Enter Department"
                          />
                        </div>
                      </div>
                      {/* {opportunityType != null &&
                      opportunityType === "Market Roll Over" ? (
                        <> */}
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Intermediary Type&nbsp;
                            <span className="danger">*</span>
                          </label>
                          <Controller
                            name="intermediary_type"
                            control={control}
                            defaultValue={intermediaryType}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={[
                                  {
                                    name: "Agent",
                                  },
                                  {
                                    name: "Broker",
                                  },
                                  {
                                    name: "Direct",
                                  },
                                  {
                                    name: "Banca",
                                  },
                                  {
                                    name: "Others",
                                  },
                                  {
                                    name: "No Information",
                                  },
                                ]}
                                id="intermediary_type"
                                name="intermediary_type"
                                fields={{ text: "name", value: "name" }}
                                allowFiltering={true}
                                filterType={"Contains"}
                                value={field.value || intermediaryType}
                                placeholder="Select Intermediary Type"
                                change={event => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.name);
                                    setIntermediaryType(event.itemData.name);
                                    // setValue("intermediary_value", "");
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "Please Select",
                            }}
                          />
                          {errors.intermediary_type && (
                            <ErrorLabel
                              message={errors.intermediary_type?.message}
                            />
                          )}
                        </div>
                      </div>
                      {intermediaryType != null &&
                      intermediaryType != "Broker" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Intermediary Details{" "}
                              <span className="danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="intermediary_value"
                              name="intermediary_value"
                              placeholder="Enter Intermediary Details"
                              {...register("intermediary_value", {
                                required: "Intermediary Details are required",
                              })}
                            />
                            {errors.intermediary_value && (
                              <ErrorLabel
                                message={errors.intermediary_value?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {intermediaryType != null &&
                      intermediaryType === "Broker" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Name of Broker in Competition&nbsp;
                              <span
                                className={
                                  opportunityType === "Fresh"
                                    ? "danger"
                                    : "hidden"
                                }
                              >
                                *
                              </span>
                            </label>
                            <Controller
                              name="broker_ids"
                              control={control}
                              render={({ field }) => (
                                <TagBox
                                  {...field}
                                  dataSource={
                                    new ArrayStore({
                                      data: getBrokerName,
                                      key: "broker_ids",
                                    })
                                  }
                                  dropDownOptions={{
                                    onInitialized: () => {
                                      console.log("initial");
                                    },
                                    onContentReady: () => {
                                      console.log("content ready");
                                    },
                                    onShowing: () => {
                                      console.log("showing");
                                    },
                                    onShown: () => {
                                      console.log("shown");
                                    },
                                  }}
                                  deferRendering={true}
                                  displayExpr="name"
                                  placeholder="Select Broker's name"
                                  valueExpr="id"
                                  searchEnabled={true}
                                  searchMode="contains"
                                  acceptCustomValue={false}
                                  maxDisplayedTags={4}
                                  showSelectionControls={true}
                                  showMultiTagOnly={false}
                                  className="form-control"
                                  stylingMode="underlined"
                                  value={
                                    field.value != null &&
                                    field.value?.length > 0
                                      ? field.value
                                      : selectedBroker
                                  }
                                  onValueChanged={({ value, event }) => {
                                    if (
                                      (value != null || value !== undefined) &&
                                      value?.length > 0
                                    ) {
                                      setSelectedBroker(value);
                                      field.onChange(value);
                                    } else {
                                      if (
                                        selectedBroker != null &&
                                        selectedBroker.length > 0
                                      ) {
                                        field.onChange("");
                                        setSelectedBroker([]);
                                      }
                                    }
                                    if (event != null) {
                                      if (
                                        $(event.currentTarget).hasClass(
                                          "dx-clear-button-area"
                                        )
                                      ) {
                                        field.onChange("");
                                        setSelectedBroker([]);
                                      }
                                    }
                                  }}
                                  showClearButton={true}
                                />
                              )}
                              rules={{
                                required:
                                  opportunityType != "Fresh"
                                    ? ""
                                    : "Please Select",
                              }}
                            />
                            {errors.broker_ids && (
                              <ErrorLabel
                                message={errors.broker_ids?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* </>
                      ) : (
                        <></>
                      )} */}
                      <div
                        className={`col-12 col-md-4 col-lg-4 ${
                          opportunityType != "Fresh" ? "" : "hidden"
                        }`}
                      >
                        <div className="form-group">
                          <label className="label-control">
                            Name of Existing Insurer
                          </label>
                          <Controller
                            name="insurer_ids"
                            control={control}
                            render={({ field }) => (
                              <TagBox
                                {...field}
                                dataSource={
                                  new ArrayStore({
                                    data: insuranceCompanyGroup,
                                    key: "insurer_ids",
                                  })
                                }
                                dropDownOptions={{
                                  onInitialized: () => {
                                    console.log("initial");
                                  },
                                  onContentReady: () => {
                                    console.log("content ready");
                                  },
                                  onShowing: () => {
                                    console.log("showing");
                                  },
                                  onShown: () => {
                                    console.log("shown");
                                  },
                                }}
                                deferRendering={true}
                                displayExpr="name"
                                placeholder="Select Insurer Company"
                                valueExpr="id"
                                searchEnabled={true}
                                searchMode="contains"
                                acceptCustomValue={false}
                                maxDisplayedTags={4}
                                showSelectionControls={true}
                                showMultiTagOnly={false}
                                className="form-control"
                                stylingMode="underlined"
                                value={
                                  field.value != null && field.value?.length > 0
                                    ? field.value
                                    : selectedInsurer
                                }
                                onValueChanged={({ value, event }) => {
                                  if (
                                    (value != null || value !== undefined) &&
                                    value?.length > 0
                                  ) {
                                    setSelectedInsurer(value);
                                    field.onChange(value);
                                  } else {
                                    if (
                                      selectedInsurer != null &&
                                      selectedInsurer.length > 0
                                    ) {
                                      field.onChange("");
                                      setSelectedInsurer([]);
                                    }
                                  }
                                  if (event != null) {
                                    if (
                                      $(event.currentTarget).hasClass(
                                        "dx-clear-button-area"
                                      )
                                    ) {
                                      field.onChange("");
                                      setSelectedInsurer([]);
                                    }
                                  }
                                }}
                                showClearButton={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            Expected Premium&nbsp;
                            <span
                              className={`danger ${
                                opportunityType === "Market Roll Over"
                                  ? ""
                                  : "hidden"
                              }`}
                            >
                              *
                            </span>
                          </label>
                          <input
                            min={0}
                            className="form-control"
                            id="expected_premium"
                            name="expected_premium"
                            onKeyPress={event => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onPaste={event => {
                              const pastedText =
                                event.clipboardData.getData("text/plain");
                              const textWithoutCommas = pastedText.replace(
                                /,/g,
                                ""
                              );
                              if (!/^\d+$/.test(textWithoutCommas)) {
                                event.preventDefault();
                              } else {
                                document.execCommand(
                                  "insertText",
                                  false,
                                  textWithoutCommas
                                );
                                event.preventDefault();
                              }
                            }}
                            placeholder="Enter Expected Premium"
                            {...register("expected_premium", {
                              required:
                                opportunityType === "Market Roll Over"
                                  ? "Expected Premium is required"
                                  : "",
                            })}
                          />
                          <div className="font-small-2 text-success mt-half">
                            {numberInWords != "Zero" ? numberInWords : ""}
                          </div>
                          {errors.expected_premium && (
                            <ErrorLabel
                              message={errors.expected_premium?.message}
                            />
                          )}
                        </div>
                      </div>
                      {opportunityType != null && opportunityType != "Fresh" ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Expiring Policy End Date &nbsp;
                                <span
                                  className={`danger ${
                                    opportunityType === "Market Roll Over"
                                      ? ""
                                      : "hidden"
                                  }`}
                                >
                                  *
                                </span>
                              </label>
                              <Controller
                                name="expiring_date"
                                control={control}
                                render={({ field }) => (
                                  <DateBox
                                    calendarOptions={{
                                      showTodayButton: true,
                                    }}
                                    {...field}
                                    id="end_expiring_date"
                                    name="expiring_date"
                                    // min={moment(new Date())
                                    //   .subtract(2, "M")
                                    //   .format("YYYY-MM-DD")}
                                    stylingMode="underlined"
                                    className="form-control"
                                    pickerType="calendar"
                                    type="date"
                                    value={field.value}
                                    acceptCustomValue={false}
                                    displayFormat="dd/MM/yyyy"
                                    // onValueChange={(value) => {
                                    //   field.onChange(
                                    //     dateFormat(value, "yyyy-mm-dd")
                                    //   );
                                    //   setIsPolicyExpiryWithin90Days(moment(value, "YYYY-MM-DD").diff(moment(), 'days') <= 90? 1:0);
                                    // }}

                                    onValueChange={value => {
                                      const selectedDate = moment(value);
                                      if (
                                        policyStartDate &&
                                        selectedDate.isBefore(policyStartDate)
                                      ) {
                                        Swal.fire({
                                          title: "Error",
                                          text: "End date must be greater than Start date",
                                          icon: "error",
                                        }).then(success => {});
                                      } else {
                                        field.onChange(
                                          dateFormat(value, "yyyy-mm-dd")
                                        );
                                        const defaultStartDate = moment(value)
                                          .subtract(1, "years")
                                          .add(1, "days")
                                          .format("YYYY-MM-DD");
                                        setValue(
                                          "start_date",
                                          defaultStartDate
                                        );
                                        setPolicyExpiryDate(
                                          moment(defaultStartDate)
                                        );
                                        setIsPolicyExpiryWithin90Days(
                                          moment(value, "YYYY-MM-DD").diff(
                                            moment(),
                                            "days"
                                          ) <= 90
                                            ? 1
                                            : 0
                                        );
                                        setPolicyExpiryDate(selectedDate);
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required:
                                    opportunityType === "Market Roll Over"
                                      ? "Expiring End Date is required"
                                      : "",
                                }}
                              />
                              {errors.expiring_date && (
                                <ErrorLabel
                                  message={errors.expiring_date?.message}
                                />
                              )}
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Expiring Policy Start Date &nbsp;
                                <span
                                  className={`danger ${
                                    opportunityType === "Market Roll Over"
                                      ? ""
                                      : "hidden"
                                  }`}
                                >
                                  *
                                </span>
                              </label>
                              <Controller
                                name="start_date"
                                control={control}
                                render={({ field }) => (
                                  <DateBox
                                    calendarOptions={{
                                      showTodayButton: true,
                                    }}
                                    {...field}
                                    id="start_date"
                                    name="start_date"
                                    max={moment(new Date()).format(
                                      "YYYY-MM-DD"
                                    )}
                                    stylingMode="underlined"
                                    className="form-control"
                                    pickerType="calendar"
                                    type="date"
                                    value={field?.value}
                                    acceptCustomValue={false}
                                    displayFormat="dd/MM/yyyy"
                                    // onValueChange={(value) => {
                                    //   field.onChange(
                                    //     dateFormat(value, "yyyy-mm-dd")
                                    //   );
                                    //   setValue("expiring_date", moment(value)
                                    //   .add(1, "years")
                                    //   .subtract(1, "days")
                                    //   .format("YYYY-MM-DD"));
                                    // }}
                                    onValueChange={value => {
                                      if (!value) {
                                        field.onChange(null);
                                        setValue("expiring_date", null);
                                        setPolicyExpiryDate(null);
                                        setIsPolicyExpiryWithin90Days(0);
                                        return;
                                      }
                                      const selectedPolicyStartDate =
                                        moment(value);
                                      if (
                                        policyExpiryDate &&
                                        selectedPolicyStartDate.isAfter(
                                          policyExpiryDate
                                        )
                                      ) {
                                        setPolicyExpiryDate(null);
                                        Swal.fire({
                                          title: "Error",
                                          text: "Start date can't be greater than End date",
                                          icon: "error",
                                        }).then(success => {});
                                      } else {
                                        field.onChange(
                                          dateFormat(value, "yyyy-mm-dd")
                                        );
                                        setPolicyStartDate(
                                          selectedPolicyStartDate
                                        );

                                        // setIsPolicyExpiryWithin90Days(
                                        //   moment(
                                        //     defaultExpiryDate,
                                        //     "YYYY-MM-DD"
                                        //   ).diff(moment(), "days") <= 90
                                        //     ? 1
                                        //     : 0
                                        // );
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required:
                                    opportunityType === "Market Roll Over"
                                      ? "Expiring Start Date is required"
                                      : "",
                                }}
                              />
                              {errors.start_date && (
                                <ErrorLabel
                                  message={errors.start_date?.message}
                                />
                              )}
                            </div>
                          </div>

                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Policy for Short Period
                              </label>
                              <Controller
                                name="policy_for_short_period"
                                control={control}
                                defaultValue="No"
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={[
                                      {
                                        name: "Yes",
                                        value: "Yes",
                                      },
                                      {
                                        name: "No",
                                        value: "No",
                                      },
                                    ]}
                                    id="policy_for_short_period"
                                    name="policy_for_short_period"
                                    fields={{ text: "name", value: "value" }}
                                    value={field.value}
                                    enabled={false}
                                    placeholder="Select"
                                    change={event => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.value);
                                      }
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </>
                      ) : opportunityType != null &&
                        opportunityType === "Fresh" ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Expected Closure Date&nbsp;
                              <span className="danger">*</span>
                            </label>
                            <Controller
                              name="expiring_date"
                              control={control}
                              render={({ field }) => (
                                <DateBox
                                  calendarOptions={{
                                    showTodayButton: true,
                                  }}
                                  {...field}
                                  id="expiring_date"
                                  name="expiring_date"
                                  stylingMode="underlined"
                                  min={moment()
                                    .startOf("month")
                                    .format("YYYY-MM-DD")}
                                  className="form-control"
                                  pickerType="calendar"
                                  type="date"
                                  value={field.value}
                                  acceptCustomValue={false}
                                  displayFormat="dd/MM/yyyy"
                                  showClearButton={true}
                                  onValueChange={value => {
                                    field.onChange(
                                      dateFormat(value, "yyyy-mm-dd")
                                    );
                                    setIsPolicyExpiryWithin90Days(
                                      moment(value, "YYYY-MM-DD").diff(
                                        moment(),
                                        "days"
                                      ) <= 90
                                        ? 1
                                        : 0
                                    );
                                  }}
                                />
                              )}
                              rules={{
                                required:
                                  opportunityType === "Fresh"
                                    ? "Expected Closure Date is required"
                                    : "",
                              }}
                            />
                            {errors.expiring_date && (
                              <ErrorLabel
                                message={errors.expiring_date?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {/* {expiringDate && (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Proposal Month
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              readOnly
                              value={CalculateProposalMonth(
                                getValues("expiring_date")
                              )}
                            />
                          </div>
                        </div>
                      )} */}
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">
                            RM Probability (in %)&nbsp;
                            <span className="danger">*</span>
                          </label>
                          <Controller
                            name="probability"
                            control={control}
                            render={({ field }) => (
                              <DropDownListComponent
                                {...field}
                                dataSource={probabilityGroup}
                                id="probability"
                                name="probability"
                                fields={{ text: "title", value: "value" }}
                                allowFiltering={false}
                                placeholder="Select Probability"
                                value={field.value}
                                change={event => {
                                  if (event.itemData != null) {
                                    field.onChange(event.itemData.value);
                                    setProbability(event.itemData.value);
                                  }
                                }}
                              />
                            )}
                            rules={{
                              required: "RM Probability is required",
                            }}
                          />
                          {errors.probability && (
                            <ErrorLabel message={errors.probability?.message} />
                          )}
                        </div>
                      </div>
                      {probability != null &&
                      Number(probability) >= 50 &&
                      opportunityQuestionFields != null &&
                      opportunityQuestionFields.length > 0 ? (
                        opportunityQuestionFields.map((item, index) => (
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                {item.question}
                                <span
                                  className={`danger ${
                                    isOpportunityQuestion != true
                                      ? ""
                                      : "hidden"
                                  }`}
                                >
                                  *
                                </span>
                              </label>
                              <Controller
                                name={`opportunityQuestion[${index}].question_value`}
                                id={`opportunityQuestion[${index}].question_value`}
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={[
                                      {
                                        title: "Yes",
                                        value: "Yes",
                                      },
                                      {
                                        title: "No",
                                        value: "No",
                                      },
                                    ]}
                                    fields={{ text: "title", value: "value" }}
                                    value={item.question_value}
                                    placeholder="Select"
                                    change={event => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.value);
                                      }
                                    }}
                                  />
                                )}
                                rules={{
                                  required:
                                    isOpportunityQuestion != true
                                      ? true
                                      : false,
                                }}
                              />
                              {errors.opportunityQuestion?.[index]
                                ?.question_value && (
                                <ErrorLabel message={"Please Select details"} />
                              )}
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                      {/* <div className="col-12 col-md-4 col-lg-4 pt-1 mb-0 pb-0">
                        <div className="form-group">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              name="is_product_specialist_required"
                              id="is_product_specialist_required"
                              disabled={true}
                              // disabled={props.opportunityPool === true ? true : false}
                              defaultChecked={
                                isProductSpecialist === 1 ? true : false
                              }
                              checked={isProductSpecialist === 1 ? true : false}
                              {...register("is_product_specialist_required")}
                              onChange={(event) => {
                                if (event.target.checked === true) {
                                  setIsProductSpecialist(1);
                                } else {
                                  setIsProductSpecialist(0);
                                }
                              }}
                            />
                            <label
                              class="custom-control-label"
                              for="is_product_specialist_required"
                            >
                              Is Product Specialist Required?
                            </label>
                          </div>
                        </div>
                      </div> */}
                      {policy != null &&
                      policy != "" &&
                      isProductSpecialist != null &&
                      isProductSpecialist === 1 ? (
                        <div className="col-12 col-md-4 col-lg-4">
                          <div className="form-group">
                            <label className="label-control">
                              Assign Product / UnderWriter&nbsp;
                              <span className="danger">*</span>
                            </label>
                            <Controller
                              name="product_specialist_id"
                              control={control}
                              render={({ field }) => (
                                <DropDownListComponent
                                  {...field}
                                  dataSource={getAssignProductRM}
                                  id="product_specialist_id"
                                  name="product_specialist_id"
                                  fields={{
                                    text: "full_name",
                                    value: "user_id",
                                  }}
                                  allowFiltering={true}
                                  filterType={"Contains"}
                                  popupHeight={"200px"}
                                  placeholder="Please Select"
                                  value={field.value}
                                  change={event => {
                                    if (event.itemData != null) {
                                      setGetAssignRM(event.itemData.title);
                                      field.onChange(event.itemData.user_id);
                                    }
                                  }}
                                />
                              )}
                              rules={{
                                required: "Please select",
                              }}
                            />
                            {errors.product_specialist_id && (
                              <ErrorLabel
                                message={errors.product_specialist_id?.message}
                              />
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {policy === "Group Mediclaim Policy" ? (
                        <>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Sub Product
                              </label>
                              {/* <Controller
                                name="sub_product"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={[
                                      {
                                        name: "Base Policy",
                                        id: "Base Policy",
                                      },
                                      {
                                        name: "TopUp Policy",
                                        id: "TopUp Policy",
                                      },
                                    ]}
                                    id="sub_product"
                                    name="sub_product"
                                    fields={{ text: "name", value: "id" }}
                                    value={field.value}
                                    placeholder="Select Sub Product"
                                    change={event => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.id);
                                      }
                                    }}
                                  />
                                )}
                              /> */}
                               <Controller
                              name="sub_products"
                              control={control}
                              render={({ field }) => (
                                <TagBox
                                  {...field}
                                  // dataSource={
                                  //   new ArrayStore({
                                  //     data: getBrokerName,
                                  //     key: "broker_ids",
                                  //   })
                                  // }
                                  dataSource={subProductDataArr}
                                  dropDownOptions={{
                                    onInitialized: () => {
                                      console.log("initial");
                                    },
                                    onContentReady: () => {
                                      console.log("content ready");
                                    },
                                    onShowing: () => {
                                      console.log("showing");
                                    },
                                    onShown: () => {
                                      console.log("shown");
                                    },
                                  }}
                                  deferRendering={true}
                                  displayExpr="name"
                                  placeholder="Select Sub Proudcts"
                                  valueExpr="id"
                                  searchEnabled={true}
                                  searchMode="contains"
                                  acceptCustomValue={false}
                                  maxDisplayedTags={4}
                                  showSelectionControls={true}
                                  showMultiTagOnly={false}
                                  className="form-control"
                                  stylingMode="underlined"
                                  value={
                                    field.value != null &&
                                    field.value?.length > 0
                                      ? field.value
                                      : selectedSubProducts
                                  }
                                  onValueChanged={({ value, event }) => {
                                    if (
                                      (value != null || value !== undefined) &&
                                      value?.length > 0
                                    ) {
                                      setSelectedSubProducts(value);
                                      field.onChange(value);
                                    } else {
                                      if (
                                        selectedSubProducts != null &&
                                        selectedSubProducts.length > 0
                                      ) {
                                        field.onChange("");
                                        setSelectedSubProducts([]);
                                      }
                                    }
                                    if (event != null) {
                                      if (
                                        $(event.currentTarget).hasClass(
                                          "dx-clear-button-area"
                                        )
                                      ) {
                                        field.onChange("");
                                        setSelectedSubProducts([]);
                                      }
                                    }
                                  }}
                                  showClearButton={true}
                                />
                              )}
                              // rules={{
                              //   required:
                              //     opportunityType != "Fresh"
                              //       ? ""
                              //       : "Please Select",
                              // }}
                            />
                            {/* {errors.broker_ids && (
                              <ErrorLabel
                                message={errors.broker_ids?.message}
                              />
                            )} */}
                            </div>
                          </div>
                          {/* <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Line of Business&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                id="line_of_business"
                                name="line_of_business"
                                placeholder="Enter Line of Business"
                                {...register("line_of_business", {
                                  required: "Line of Business is required",
                                })}
                              />
                              {errors.line_of_business && (
                                <ErrorLabel
                                  message={errors.line_of_business?.message}
                                />
                              )}
                            </div>
                          </div> */}
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Employer Employee Relationship
                              </label>
                              <Controller
                                name="employer_employee_relationship"
                                control={control}
                                render={({ field }) => (
                                  <DropDownListComponent
                                    {...field}
                                    dataSource={[
                                      {
                                        name: "Yes",
                                        value: "Yes",
                                      },
                                      {
                                        name: "No",
                                        value: "No",
                                      },
                                    ]}
                                    id="employer_employee_relationship"
                                    name="employer_employee_relationship"
                                    fields={{ text: "name", value: "value" }}
                                    value={field.value}
                                    placeholder="Select"
                                    change={event => {
                                      if (event.itemData != null) {
                                        field.onChange(event.itemData.value);
                                      }
                                    }}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          {/* <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                No. of Employees&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="no_of_employees"
                                name="no_of_employees"
                                placeholder="Enter"
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                {...register("no_of_employees", {
                                  required: "No of Employees",
                                })}
                              />
                              {errors.no_of_employees && (
                                <ErrorLabel
                                  message={errors.no_of_employees?.message}
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                No. of Dependents
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="no_of_dependants"
                                name="no_of_dependants"
                                placeholder="Enter"
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                {...register("no_of_dependants")}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-4 col-lg-4">
                            <div className="form-group">
                              <label className="label-control">
                                Total Lives&nbsp;
                                <span className="danger">*</span>
                              </label>
                              <input
                                // type="number"
                                className="form-control"
                                id="total_lives"
                                name="total_lives"
                                placeholder="Enter"
                                disabled={true}
                                {...register("total_lives", {})}
                              />
                            </div>
                          </div> */}
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="col-12 col-md-4 col-lg-4">
                        <div className="form-group">
                          <label className="label-control">Remark</label>
                          <textarea
                            className="form-control"
                            id="client_remark"
                            name="client_remark"
                            placeholder="Enter Remark"
                            maxLength={250}
                            {...register("client_remark")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12 border-bottom mb-2">
                      <h5>Document Upload</h5>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                      <div className="form-group">
                        <label className="label-control">
                          Document Type
                          <span className="danger">*</span>
                        </label>
                        <Controller
                          name="document_type"
                          className="form-control"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={[
                                {
                                  value: "Policy Document",
                                  title: "Policy Document",
                                },
                                {
                                  value: "Claim Document",
                                  title: "Claim Document",
                                },
                                {
                                  value: "Other Document",
                                  title: "Other Document",
                                },
                              ]}
                              fields={{ text: "title", value: "value" }}
                              allowFiltering={false}
                              placeholder="Select Document Type"
                              value={field.value}
                            />
                          )}
                          rules={{
                            required: "Document Type is required",
                          }}
                        />
                        {errors.meeting_type && (
                          <ErrorLabel message={errors.meeting_type?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                   <p>
                          Upload File
                          <span className="danger">*</span>
                        </p>
                    <div>
                          <input
                            type="file"
                            class="custom-file-input"
                            id="inputGroupFile04"
                          />
                          <label
                            class="custom-file-label"
                            for="inputGroupFile04"
                          >
                            Choose file
                          </label>
                          </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <button
                            class="btn btn-primary"
                            type="button"
                          >
                            Upload
                          </button>
                    </div>
                    <div className="col-md-4">
                        <b>Policy Documents</b>
                      <div className="card mt-1">
                        <div className="card-body d-flex py-1">
                          <div>1.</div>
                          <div className="w-75 flex-fill my-auto">File 1</div>
                          <div className="border-left-1 flex-fill text-right">
                            <button className="btn btn-primary btn-sm">
                              <FiTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-1">
                        <div className="card-body d-flex py-1">
                          <div>2.</div>
                          <div className="w-75 flex-fill my-auto">File 2</div>
                          <div className="border-left-1 flex-fill text-right">
                            <button className="btn btn-primary btn-sm">
                              <FiTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-1">
                        <div className="card-body d-flex py-1">
                          <div>3.</div>
                          <div className="w-75 flex-fill my-auto">File 3</div>
                          <div className="border-left-1 flex-fill text-right">
                            <button className="btn btn-primary btn-sm">
                              <FiTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-1 py-1">
                        <h6 className="text-center mb-0">No Document Uploaded</h6>
                      </div>
                    </div>
                    <div className="col-md-4">
                        <b>Claim Documents</b>
                      <div className="card mt-1">
                        <div className="card-body d-flex py-1">
                          <div>1.</div>
                          <div className="w-75 flex-fill my-auto">File 1</div>
                          <div className="border-left-1 flex-fill text-right">
                            <button className="btn btn-primary btn-sm">
                              <FiTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-1">
                        <div className="card-body d-flex py-1">
                          <div>2.</div>
                          <div className="w-75 flex-fill my-auto">File 2</div>
                          <div className="border-left-1 flex-fill text-right">
                            <button className="btn btn-primary btn-sm">
                              <FiTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-1">
                        <div className="card-body d-flex py-1">
                          <div>3.</div>
                          <div className="w-75 flex-fill my-auto">File 3</div>
                          <div className="border-left-1 flex-fill text-right">
                            <button className="btn btn-primary btn-sm">
                              <FiTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-1 py-1">
                        <h6 className="text-center mb-0">No Document Uploaded</h6>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <b>Other Document Upload</b>
                      <div className="card mt-1">
                        <div className="card-body d-flex py-1">
                          <div>1.</div>
                          <div className="w-75 flex-fill my-auto">File 1</div>
                          <div className="border-left-1 flex-fill text-right">
                            <button className="btn btn-primary btn-sm">
                              <FiTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-1">
                        <div className="card-body d-flex py-1">
                          <div>2.</div>
                          <div className="w-75 flex-fill my-auto">File 2</div>
                          <div className="border-left-1 flex-fill text-right">
                            <button className="btn btn-primary btn-sm">
                              <FiTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-1">
                        <div className="card-body d-flex py-1">
                          <div>3.</div>
                          <div className="w-75 flex-fill my-auto">File 3</div>
                          <div className="border-left-1 flex-fill text-right">
                            <button className="btn btn-primary btn-sm">
                              <FiTrash />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-1 py-1">
                        <h6 className="text-center mb-0">No Document Uploaded</h6>
                      </div>
                    </div>
                  </div>
 */}
                  <div className="form-actions border-0 mt-0 pt-0 float-none">
                    <button
                      id="save-policy-details"
                      className="btn btn-primary mr-1 shadow round text-uppercase"
                      type="submit"
                      onClick={() => {
                        setStateRfq(false);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        Submit
                        <span
                          id="policy-details-form-loader"
                          className="spinner-border spinner-border-sm ml-half hidden"
                        ></span>
                      </div>
                    </button>
                    <button
                      id="send-for-rfq"
                      className={`btn btn-primary mr-1 shadow round text-uppercase ${
                        !isPolicyExpiryWithin90Days ? "d-none" : ""
                      }`}
                      type="submit"
                      onClick={() => {
                        setStateRfq(true);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        Proceed For RFQ
                        <span
                          id="send-for-rfq-loader"
                          className="spinner-border spinner-border-sm ml-half hidden"
                        ></span>
                      </div>
                    </button>
                    <button
                      className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                      onClick={event => {
                        event.preventDefault();
                        props.handleClose();
                        setValue("department_name", "");
                        setStateRfq(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>

                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  var PolicyGroup = null;
  var InsuranceCompanyGroup = null;
  var ProbabilityGroup = null;
  var OpportunityQuestionList = null;
  var GetOpportunityQuestion = null;
  var GetAssignProductRM = null;
  var GetBrokerName = null;
  var MeetingType = null;
  var Customers = null;

  if (
    state.LeadCreatePolicyDetailsReducer != null &&
    Object.keys(state.LeadCreatePolicyDetailsReducer).length > 0 &&
    state.LeadCreatePolicyDetailsReducer.constructor === Object
  ) {
    switch (state.LeadCreatePolicyDetailsReducer.tag) {
      case ACTION_MASTER_POLICY_SUCCESS:
        PolicyGroup = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { PolicyGroup };
      case ACTION_MASTER_INSURANCE_COMPANY_SUCCESS:
        InsuranceCompanyGroup = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { InsuranceCompanyGroup };
      case ACTION_MASTER_PROBABILITY_SUCCESS:
        ProbabilityGroup = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { ProbabilityGroup };
      case ACTION_OPPORTUNITY_QUESTIONS_LIST_SUCCESS:
        OpportunityQuestionList = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { OpportunityQuestionList };
      case ACTION_GET_OPPORTUNITY_QUESTIONS_SUCCESS:
        GetOpportunityQuestion = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { GetOpportunityQuestion };
      case ACTION_GET_ASSIGN_PRODUCT_RM_SUCCESS:
        GetAssignProductRM = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { GetAssignProductRM };
      case ACTION_GET_BROKER_NAME_SUCCESS:
        GetBrokerName = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { GetBrokerName };
      case ACTION_API_CLIENT_TYPE_SUCCESS:
        MeetingType = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { MeetingType };
      case ACTION_CLIENT_NAME_SUCCESS:
        Customers = Object.assign(
          {},
          state.LeadCreatePolicyDetailsReducer.data
        );
        delete state.LeadCreatePolicyDetailsReducer;
        return { Customers };
      default:
    }
  }
  return {
    PolicyGroup,
    InsuranceCompanyGroup,
    ProbabilityGroup,
    OpportunityQuestionList,
    GetOpportunityQuestion,
    GetAssignProductRM,
    GetBrokerName,
    MeetingType,
    Customers,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      actionPolicyGroup,
      actionInsuranceCompanyGroup,
      actionProbabilityGroup,
      actionOpportunityQuestionsList,
      actionGetOpportunityQuestions,
      actionGetAssignProductRM,
      actionGetBrokerName,
      actionSelectCustomer,
      actionMeetingType,
    },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(PolicyDetailModal)
);
