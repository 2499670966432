import React, { useEffect, useState } from "react";
import { withAuth } from "react-auth-guard";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  removeData,
  getData,
  KEY_TOAST_MESSAGE,
  setData,
} from "../../../../../utils/preferences";
import RequestHistory from "../../../../../component/widgets/request_history";
import {
  actionProposalPoolView,
  ACTION_PROPOSALPOOL_VIEW_SUCCESS,
} from "./action";
import {
  actionOpportunityPoolQuestion,
  ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS,
} from "./action";
import dateFormat from "dateformat";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ERROR, UNAUTHORIZED } from "../../../../../constants";
import { DataGrid } from "devextreme-react";
import { Column, Pager, Paging } from "devextreme-react/data-grid";
import moment from "moment";
import _ from "lodash";
import HTMLReactParser from "html-react-parser";
import { downloadFile, CalculateProposalMonth, linkify } from "../../../../../utils";
import DocumentIframe from "../../../../../component/popups/document_iframe";
import { FiTrash2 } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Controller } from "react-hook-form";
import { AiFillFileText } from "react-icons/ai";

const ProposalSettingsView = (props) => {
  const [proposalPoolViewDetails, setProposalPoolViewDetails] = useState();
  const [showOpportunityPoolQuestions, setShowOpportunityPoolQuestions] =
    useState([]);
  const [getProposalPlacement, setGetProposalPlacement] = useState([]);
  const [getBrokerNameList, setGetBrokerNameList] = useState([]);
  const [getInsurerNameList, setGetInsurerNameList] = useState([]);
  const [closureType, setClosureType] = useState(null);
  const [detailsData, setDetailsData] = useState(null);
  const [competitionIntermediaryType, setCompetitionIntermediaryType] =
    useState(null);
  const [quoteStatus, setQuoteStatus] = useState(null);
  const [proposalLostReason, setProposalLostReason] = useState(null);
  const [grossPremium, setGrossPremium] = useState(0);
  const [totalPremium, setTotalPremium] = useState(0);
  const [pincSharePremium, setPincSharePremium] = useState(0);
  const [showDocumentDetails, setShowDocumentDetails] = useState(false);
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null);
  const [extension, setExtension] = useState(null);
  const [getOppInsurerNameList, setGetOppInsurerNameList] = useState([]);
  const [getOppBrokerNameList, setGetOppBrokerNameList] = useState([]);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [totalSharePercent, setTotalSharePercent] = useState();
  const [totalSharePremiumValue, setTotalSharePremiumValue] = useState();
  const [pincGrossPremiumAmount, setPincGrossPremiumAmount] = useState(null);
  const [totalGrossPremiumValue, setTotalGrossPremiumValue] = useState();
  const [noOfLives, setNoOfLives] = useState(null);

  const initialRender = () => {
    if (getData(KEY_TOAST_MESSAGE) != null) {
      toast.success(getData(KEY_TOAST_MESSAGE));
      removeData(KEY_TOAST_MESSAGE);
    }

    setDetailsData(JSON.parse(localStorage.getItem("detailsData")));
  };
  useEffect(initialRender, []);

  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    if (detailsData != null) {
      props.actionOpportunityPoolQuestion({ id: detailsData.id });
      props.actionProposalPoolView({
        policy_id: detailsData.id,
        opportunity_status: detailsData.opportunity_status,
        closure_type: detailsData.closure_type,
      });
    }
  }, [detailsData]);

  const columns = [
    { dataField: "insurer_name", caption: "Insurer Name", visible: true },
    { dataField: "branch_short_name", caption: "Branch Name", visible: true },
    { dataField: "status", caption: "Current Status", visible: true },
    { dataField: "quote_amount", caption: "Quote Amt (Net)", visible: true },
    {
      dataField: "quote_request_date",
      caption: "Quote Request Date",
      visible: true,
    },
    {
      dataField: "quote_received_date",
      caption: "Quote Receive Date",
      visible: true,
    },
    { dataField: "remark", caption: "Remarks", visible: true },
  ];

  const columnsInsurer = [
    // { dataField: "id", caption: "ID" },
    { dataField: "name", caption: "Insurer Name", visible: true },
  ];

  const columnsBroking = [
    // { dataField: "id", caption: "ID", visible: true },
    { dataField: "name", caption: "Broker Name", visible: true },
  ];

  const renderColumns = () => {
    var arrColumns = [];
    columns.forEach((objColumn, indexColumn) => {
      arrColumns.push(
        <Column
          dataField={objColumn.dataField}
          caption={objColumn.caption}
        ></Column>
      );
    });
    return arrColumns;
  };

  const renderBrokerColumns = () => {
    var arrColumns = [];
    columnsBroking.forEach((objColumn, indexColumn) => {
      arrColumns.push(
        <Column
          dataField={objColumn.dataField}
          caption={objColumn.caption}
        ></Column>
      );
    });
    return arrColumns;
  };

  const renderInsurerColumns = () => {
    var arrColumns = [];
    columnsInsurer.forEach((objColumn, indexColumn) => {
      arrColumns.push(
        <Column
          dataField={objColumn.dataField}
          caption={objColumn.caption}
        ></Column>
      );
    });
    return arrColumns;
  };

  const renderPlacementRemark = (opportunity_remark) => {
    try {
      var formatText = opportunity_remark;
      if (
        proposalPoolViewDetails != null &&
        proposalPoolViewDetails.opportunity_remark != null
      ) {
        formatText = linkify(formatText);
      }
    } catch (e) {
      console.log("Format dashboad Text");
      console.log(e);
    }
    try {
      return HTMLReactParser(
        String(formatText)
          .substring(0, formatText.length)
          .trim()
          .replaceAll("\n", "<br>")
      );
    } catch (e) {
      return <>{formatText}</>;
    }
  };

  const onOpportunityPoolQuestionUpdate = () => {
    const { OpportunityPoolQuestion } = props;
    var OpportunityPoolQuestionResponse = Object.assign(
      {},
      OpportunityPoolQuestion
    );
    if (OpportunityPoolQuestionResponse.result === true) {
      setShowOpportunityPoolQuestions(OpportunityPoolQuestionResponse.response);
    } else {
      switch (OpportunityPoolQuestionResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onOpportunityPoolQuestionUpdate, [props.OpportunityPoolQuestion]);

  useEffect(() => {
    if (questionList != null && questionList.length > 0) {
      questionList.forEach((item, index) => {
        if (
          proposalPoolViewDetails?.closure?.premium_amount != null &&
          item?.share != null
        ) {
          const sharePremiumAmount =
            (Number(proposalPoolViewDetails?.closure?.premium_amount) *
              Number(item.share)) /
            100;
          if (item.share_premium_amount !== sharePremiumAmount.toFixed(2)) {
            const tempMobileArray = [...questionList];
            const objMobile = { ...tempMobileArray[index] };
            objMobile.share_premium_amount = sharePremiumAmount.toFixed(2);
            tempMobileArray[index] = objMobile;
            setQuestionList(tempMobileArray);
          }
        }
      });
      const totalShare = questionList.reduce((total, item) => {
        return total + (item.share ? Number(item.share) : 0);
      }, 0);
      setTotalSharePercent(totalShare);

      const SharePremiumAmount = questionList.reduce((total, item) => {
        return (
          total +
          (item.share_premium_amount ? Number(item.share_premium_amount) : 0)
        );
      }, 0);
      setTotalSharePremiumValue(SharePremiumAmount);

      const grossPremiumValue = questionList.reduce((total, item) => {
        return total + (item.gross_premium ? Number(item.gross_premium) : 0);
      }, 0);
      setTotalGrossPremiumValue(grossPremiumValue);
    } else {
      setTotalSharePercent(0);
      setTotalSharePremiumValue(0);
      setTotalGrossPremiumValue(0);
    }
  }, [questionList]);

  useEffect(() => {
    if (questionList != null && questionList.length > 0) {
      questionList.forEach((item, index) => {
        if (
          proposalPoolViewDetails?.closure?.premium_amount != null &&
          item?.share != null &&
          proposalPoolViewDetails?.closure?.cgst != null &&
          proposalPoolViewDetails?.closure?.cgst != "" &&
          proposalPoolViewDetails?.closure?.sgst != null &&
          proposalPoolViewDetails?.closure?.sgst != ""
        ) {
          const sharePremiumAmount =
            (Number(proposalPoolViewDetails?.closure?.premium_amount) *
              Number(item.share)) /
            100;
          const cgstAmount =
            (Number(sharePremiumAmount) *
              Number(proposalPoolViewDetails?.closure?.cgst)) /
            100;
          const sgstAmount =
            (Number(sharePremiumAmount) *
              Number(proposalPoolViewDetails?.closure?.sgst)) /
            100;
          const grossPremium = (
            Number(sharePremiumAmount) +
            cgstAmount +
            sgstAmount
          ).toFixed(2);

          // not NaN
          if (!isNaN(grossPremium) && item.gross_premium !== grossPremium) {
            const updatedQuestionList = [...questionList];
            const updatedItem = { ...updatedQuestionList[index] };
            updatedItem.gross_premium = grossPremium;
            updatedQuestionList[index] = updatedItem;
            setQuestionList(updatedQuestionList);
          }
        }
      });
    }
  }, [questionList]);

  const onProposalPoolViewUpdate = () => {
    const { ProposalPoolView } = props;
    var ProposalPoolViewResponse = Object.assign({}, ProposalPoolView);
    if (ProposalPoolViewResponse.result === true) {
      if (ProposalPoolViewResponse?.response?.detail?.no_of_dependants || ProposalPoolViewResponse?.response?.detail?.no_of_employees) {
        const noOfLives = Number(ProposalPoolViewResponse?.response?.detail?.no_of_dependants) + Number(ProposalPoolViewResponse?.response?.detail?.no_of_employees)
        setNoOfLives(noOfLives)
      }
      setGetProposalPlacement(
        ProposalPoolViewResponse?.response?.detail?.opportunity_insurers
      );
      setProposalPoolViewDetails(ProposalPoolViewResponse.response?.detail);
      setGetBrokerNameList(
        ProposalPoolViewResponse.response?.detail?.closure
          ?.opportunity_co_broker_success_detail_list
      );
      setGetInsurerNameList(
        ProposalPoolViewResponse.response?.detail?.closure
          ?.opportunity_co_insurer_success_detail_list
      );

      setGetOppInsurerNameList(ProposalPoolViewResponse.response?.detail?.insurers);
      setGetOppBrokerNameList(ProposalPoolViewResponse.response?.detail?.brokers);
      setQuoteStatus(
        ProposalPoolViewResponse.response?.detail?.opportunity_status
      );
      setClosureType(
        ProposalPoolViewResponse.response?.detail?.closure_type != null
          ? ProposalPoolViewResponse.response?.detail?.closure_type
          : ""
      );
      if (
        ProposalPoolViewResponse.response?.detail?.opportunity_status ===
        "Proposal Success"
      ) {
        if (
          ProposalPoolViewResponse.response?.detail?.closure_type ===
          "co_broking"
        ) {
          setQuestionList(
            ProposalPoolViewResponse.response?.detail?.closure
              ?.opportunity_broker_success_detail_list
          );
        } else if (
          ProposalPoolViewResponse.response?.detail?.closure_type ===
          "co_insurer"
        ) {
          setQuestionList(
            ProposalPoolViewResponse.response?.detail?.closure
              ?.opportunity_insurer_success_detail_list
          );
        }
      }
      if (
        ProposalPoolViewResponse.response?.detail?.closure?.cgst != null &&
        ProposalPoolViewResponse.response?.detail?.closure?.sgst != null
      ) {
        var cgstAmount =
          (Number(
            ProposalPoolViewResponse.response?.detail?.closure?.premium_amount
          ) *
            Number(ProposalPoolViewResponse.response?.detail?.closure?.cgst)) /
          100;
        var sgstAmount =
          (Number(
            ProposalPoolViewResponse.response?.detail?.closure?.premium_amount
          ) *
            Number(ProposalPoolViewResponse.response?.detail?.closure?.sgst)) /
          100;

        var grossPremiumAmount =
          Number(
            ProposalPoolViewResponse.response?.detail?.closure?.premium_amount
          ) +
          cgstAmount +
          sgstAmount;
      }
      setGrossPremium(grossPremiumAmount);

      var totalPremium = 0;
      if (
        ProposalPoolViewResponse.response?.detail?.closure?.net_amount !=
        null &&
        ProposalPoolViewResponse.response?.detail?.closure?.gst_amount != null
      ) {
        totalPremium =
          Number(
            ProposalPoolViewResponse.response?.detail?.closure?.net_amount
          ) +
          Number(
            ProposalPoolViewResponse.response?.detail?.closure?.gst_amount
          );
      }
      setTotalPremium(totalPremium);

      var pincSharePremium = 0;
      if (
        ProposalPoolViewResponse.response?.detail?.closure?.premium_amount !=
        null &&
        ProposalPoolViewResponse.response?.detail?.closure?.pinc_share != null
      ) {
        pincSharePremium =
          (Number(
            ProposalPoolViewResponse.response?.detail?.closure?.premium_amount
          ) *
            Number(
              ProposalPoolViewResponse.response?.detail?.closure?.pinc_share
            )) /
          100;
      }
      setPincSharePremium(pincSharePremium);
    } else {
      switch (ProposalPoolViewResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        case ERROR:
          toast.error(ProposalPoolViewResponse.message);
        default:
      }
    }
  };
  useEffect(onProposalPoolViewUpdate, [props.ProposalPoolView]);

  useEffect(() => {
    if (
      proposalPoolViewDetails != null &&
      proposalPoolViewDetails?.closure?.cgst != null &&
      proposalPoolViewDetails?.closure?.sgst != null
    ) {
      var cgstAmount =
        (Number(pincSharePremium) *
          Number(proposalPoolViewDetails?.closure?.cgst)) /
        100;
      var sgstAmount =
        (Number(pincSharePremium) *
          Number(proposalPoolViewDetails?.closure?.sgst)) /
        100;
      var pincGrossPremiumAmount =
        Number(pincSharePremium) + cgstAmount + sgstAmount;
      setPincGrossPremiumAmount(pincGrossPremiumAmount);
    }
  }, [pincSharePremium]);

  return (
    <section>
      <div>
        <div className="content-header row mb-1">
          <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
            <h5 className="content-header-title text-capitalize mb-0">
              Opportunity Pool View
            </h5>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Opportunity ID
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails?.id != null
                    ? proposalPoolViewDetails?.id
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Client Type
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails?.client_type != null
                    ? _.capitalize(proposalPoolViewDetails?.client_type)
                    : "-"}
                </h4>
              </div>
              {detailsData?.oppurtunity_type === "Renewal" ? (
                <>
                  <div className="col-12 col-md-6 col-lg-6">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Control Number
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null && detailsData.control_no != null
                        ? detailsData.control_no
                        : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Policy Number
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null && detailsData?.policy_no != null
                        ? detailsData?.policy_no
                        : "-"}
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className="col-12 col-md-6 col-lg-6">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Opportunity Type
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.oppurtunity_type != null
                    ? detailsData.oppurtunity_type
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Company Name
                </h6>
                <h4
                  className="font-medium-3 text-bold-700 text-justify text-primary cursor-pointer"
                  onClick={() => {
                    if (proposalPoolViewDetails.client_type === "lead") {
                      props.history.push({
                        pathname: "/leads/view",
                        state: {
                          client_id: proposalPoolViewDetails?.client_id,
                        },
                      });
                    } else if (
                      proposalPoolViewDetails.client_type === "client"
                    ) {
                      props.history.push({
                        pathname: "/client/view",
                        state: {
                          client_id: proposalPoolViewDetails?.client_id,
                        },
                      });
                    }
                  }}
                >
                  {detailsData != null && detailsData.customer_name != null
                    ? detailsData.customer_name
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Group Name
                </h6>
                <h4
                  className="font-medium-1 text-bold-700 text-justify text-primary cursor-pointer"
                  onClick={() => {
                    props.history.push({
                      pathname: "/client/group-level-view",
                      state: {
                        client_group_id:
                          proposalPoolViewDetails?.client_group_id,
                      },
                    });
                  }}
                >
                  {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails.company_group_name != null
                    ? proposalPoolViewDetails.company_group_name
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Policy Type
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData?.policy_type != null
                    ? detailsData?.policy_type
                    : "-"}
                </h4>
              </div>
              {/* {detailsData != null &&
              detailsData.oppurtunity_type != null &&
              detailsData.oppurtunity_type != "Fresh" ? (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Insurance Company Name
                  </h6>
                  <h4 className="font-medium-1 text-bold-700 text-justify">
                    {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails.insurer_short_name != null
                      ? proposalPoolViewDetails.insurer_short_name
                      : "-"}
                  </h4>
                </div>
              ) : (
                <></>
              )} */}
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Department
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.department_name != null
                    ? detailsData.department_name
                    : "-"}
                </h4>
              </div>
              {/* {detailsData != null &&
              detailsData.oppurtunity_type != null &&
              detailsData.oppurtunity_type != "Fresh" ? ( */}
              <>
                <div className="col-12 col-md-6 col-lg-6">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Intermediary Type
                  </h6>
                  <h4 className="font-medium-1 text-bold-700 text-justify">
                    {detailsData != null &&
                      detailsData.intermediary_type != null
                      ? detailsData.intermediary_type
                      : "-"}
                  </h4>
                </div>
                {
                  detailsData?.intermediary_type != "Broker" ?
                    <div className="col-12 col-md-6 col-lg-6">
                      <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                        Intermediary Details</h6>
                      <h4 className="font-medium-1 text-bold-700 text-justify">
                        {detailsData != null &&
                          detailsData.intermediary_value != null
                          ? detailsData.intermediary_value
                          : "-"}
                      </h4>
                    </div>
                    :
                    <></>
                }

              </>
              {detailsData?.oppurtunity_type != null && detailsData?.oppurtunity_type != "Fresh" ? (
                <div className="col-12 col-md-6 col-lg-6 mb-1 mt-2">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Existing Insurer Company
                  </h6>
                  {getOppInsurerNameList != null &&
                    getOppInsurerNameList.length > 0 ? (
                    <div className="row">
                      <div className="col-12 col-lg-12 col-md-12">
                        <DataGrid
                          dataSource={getOppInsurerNameList}
                          sorting={false}
                          showBorders={true}
                          columnAutoWidth={true}
                        >
                          {renderInsurerColumns()}
                          <Pager
                            visible={true}
                            showPageSizeSelector={false}
                            showNavigationButtons={true}
                            allowedPageSizes={[4, 6, 10]}
                          />
                          <Paging
                            defaultPageIndex={0}
                            defaultPageSize={10}
                            enabled={true}
                          />
                        </DataGrid>
                      </div>
                    </div>
                  ) : (
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      -
                    </h4>
                  )}
                </div>)
                :
                <></>
              }
              {
                detailsData?.intermediary_type === "Broker" ?
                  <div className="col-12 col-md-6 col-lg-6 mb-1 mt-2">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Name of Broker in Competition
                    </h6>
                    {getOppBrokerNameList != null &&
                      getOppBrokerNameList.length > 0 ? (
                      <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                          <DataGrid
                            dataSource={getOppBrokerNameList}
                            showBorders={true}
                            sorting={false}
                            columnAutoWidth={true}
                          >
                            {renderBrokerColumns()}
                            <Pager
                              visible={true}
                              showPageSizeSelector={false}
                              showNavigationButtons={true}
                              allowedPageSizes={[4, 6, 10]}
                            />
                            <Paging
                              defaultPageIndex={0}
                              defaultPageSize={10}
                              enabled={true}
                            />
                          </DataGrid>
                        </div>
                      </div>
                    ) : (
                      <h4 className="font-medium-3 text-bold-700 text-justify">
                        -
                      </h4>
                    )}
                  </div>
                  :
                  <></>
              }

              {/* ) : (
                <></>
              )} */}
              {props.proposalType === "assigned" ? (
                <div className="col-12 col-md-6 col-lg-6">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Assigned Specialist
                  </h6>
                  <h4 className="font-medium-1 text-bold-700 text-justify">
                    {detailsData != null &&
                      detailsData.product_specialist_name != null
                      ? detailsData.product_specialist_name
                      : "-"}
                  </h4>
                </div>
              ) : (
                <></>
              )}
              {detailsData != null &&
                detailsData.oppurtunity_type != null &&
                detailsData.oppurtunity_type != "Fresh" ? (
                <>
                  <div className="col-12 col-md-6 col-lg-6 mb-1 mt-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Expiring Policy Start Date
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null && detailsData.start_date != null
                        ?
                        moment(detailsData.start_date).format("DD/MM/yyyy")
                        : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mb-1 mt-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Expiring Policy End Date
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null && detailsData.expiring_date != null
                        ? moment(detailsData.expiring_date).format("DD/MM/yyyy")
                        : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Policy for Short Period
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null && detailsData.policy_for_short_period != null
                        ? detailsData.policy_for_short_period
                        : "-"}
                    </h4>
                  </div>
                </>
              ) : (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Expected Closure Date
                  </h6>
                  <h4 className="font-medium-1 text-bold-700 text-justify">
                    {detailsData != null && detailsData.expiring_date != null
                      ? detailsData.expiring_date
                      : "-"}
                  </h4>
                </div>
              )}
              {/* {detailsData?.expiring_date && (
                <div className="col-12 col-md-6 mb-1">
                  <div className="form-group">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Proposal Month
                    </h6>
                    {detailsData?.expiring_date ? CalculateProposalMonth(moment(detailsData?.expiring_date, 'DD/MM/YYYY')):'-'}
                  </div>
                </div>
              )} */}
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Expected Premium
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails.expected_premium != null ? (
                    <div>
                      <span>&#8377;</span>{" "}
                      {proposalPoolViewDetails.expected_premium}{" "}
                    </div>
                  ) : (
                    "-"
                  )}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Probability
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.probability != null
                    ? detailsData.probability + "%"
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Created By
                </h6>
                <h4 className="font-medium-3 text-bold-700 text-justify">
                  {detailsData != null && detailsData.created_by != null
                    ? detailsData.created_by
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Create Date
                </h6>
                <h4 className="font-medium-3 text-bold-700 text-justify">
                  {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails?.created_at != null
                    ? proposalPoolViewDetails?.created_at
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Assigned RM
                </h6>
                <h4
                  className="font-medium-1 text-bold-700 text-justify text-primary cursor-pointer"
                  onClick={() => {
                    props.history.push({
                      pathname: "/profile",
                      state: {
                        user_id: proposalPoolViewDetails?.assigned_rm_id,
                      },
                    });
                  }}
                >
                  {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails.assigned_rm_name != null
                    ? proposalPoolViewDetails.assigned_rm_name
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Assign Product / UnderWriter
                </h6>
                <h4
                  className="font-medium-1 text-bold-700 text-justify text-primary cursor-pointer"
                  onClick={() => {
                    props.history.push({
                      pathname: "/profile",
                      state: {
                        user_id: proposalPoolViewDetails?.product_specialist_id,
                      },
                    });
                  }}
                >
                  {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails.ps_name != null
                    ? proposalPoolViewDetails.ps_name
                    : "-"}
                </h4>
              </div>

              {detailsData?.policy_type === 'Group Mediclaim Policy' ?
                <>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Sub Product
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null && detailsData.sub_product != null
                        ? detailsData.sub_product
                        : "-"}
                    </h4>
                  </div>
                  {/* <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                 Line of Business
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.line_of_business != null
                    ? detailsData.line_of_business
                    : "-"}
                </h4>
              </div> */}
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Employer Employee Relationship
                    </h6>
                    <h4 className="font-medium-1 text-bold-700 text-justify">
                      {detailsData != null && detailsData.employer_employee_relationship != null
                        ? detailsData.employer_employee_relationship
                        : "-"}
                    </h4>
                  </div>
                  {/* <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
             No. of Employees
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.no_of_employees != null
                    ? detailsData.no_of_employees
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
             No. of Dependents
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {detailsData != null && detailsData.no_of_dependants != null
                    ? detailsData.no_of_dependants
                    : "-"}
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
             Total Lives
                </h6>
                <h4 className="font-medium-1 text-bold-700 text-justify">
                  {noOfLives != null 
                    ? noOfLives
                    : "-"}
                </h4>
              </div> */}
                </>
                :
                <></>
              }

              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  RM Remark
                </h6>
                <h4 className="font-medium-3 text-bold-700 text-justify" style={{ whiteSpace: 'pre-wrap' }}>
                  {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails?.client_remark != null &&
                    proposalPoolViewDetails?.client_remark != "null" &&
                    proposalPoolViewDetails?.client_remark != "undefined"
                    ? proposalPoolViewDetails?.client_remark
                    : "-"}
                </h4>
              </div>
            </div>
            {showOpportunityPoolQuestions.opportunity_questions != null &&
              showOpportunityPoolQuestions.opportunity_questions.length > 0 ? (
              <>
                <div className="col-12 col-md-12 col-lg-12 pl-0">
                  <h4 className="modal-title" id="myModalLabel1">
                    More Information
                  </h4>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1 mt-1 d-flex">
                      System Probability
                    </h6>
                    <h4 className="font-small-2 text-bold-700 text-justify d-flex">
                      {showOpportunityPoolQuestions.total_probability != null
                        ? showOpportunityPoolQuestions.total_probability
                        : "-"}
                    </h4>
                  </div>
                  {showOpportunityPoolQuestions.opportunity_questions != null &&
                    showOpportunityPoolQuestions.opportunity_questions.length >
                    0 ? (
                    showOpportunityPoolQuestions.opportunity_questions.map(
                      (item, index) => (
                        <div className="col-12 col-md-6 col-lg-6">
                          <h6 className="font-small-2 text-uppercase blue-grey darken-1 mt-1 d-flex">
                            {item.question}
                          </h6>
                          <h4 className="font-small-2 text-bold-700 text-justify mt-1 d-flex">
                            {item.question_value}
                          </h4>
                        </div>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
            {getProposalPlacement != null && getProposalPlacement.length > 0 ? (
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12">
                  <DataGrid
                    dataSource={getProposalPlacement}
                    showBorders={true}
                    // sorting={false}
                    columnAutoWidth={true}
                  >
                    {renderColumns()}
                    <Pager
                      visible={true}
                      showPageSizeSelector={false}
                      showNavigationButtons={true}
                      allowedPageSizes={[4, 6, 10]}
                    />
                    <Paging
                      defaultPageIndex={0}
                      defaultPageSize={10}
                      enabled={true}
                    />
                  </DataGrid>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-1">
                <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                  Quote Status
                </h6>
                <h4 className="font-medium-3 text-bold-700 text-justify">
                  {proposalPoolViewDetails != null &&
                    proposalPoolViewDetails?.opportunity_status != null
                    ? proposalPoolViewDetails?.opportunity_status
                    : "-"}
                </h4>
              </div>
              {/* Proposal Rejected */}
              {quoteStatus === "Quote Rejected" ? (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Rejection Reason
                  </h6>
                  <h4 className="font-medium-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails != null &&
                      proposalPoolViewDetails?.opportunity_sub_status != null
                      ? proposalPoolViewDetails?.opportunity_sub_status
                      : "-"}
                  </h4>
                </div>
              ) : (
                <></>
              )}

              {/* Proposal Lost */}
              {quoteStatus === "Proposal Lost" ? (
                <>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Reason
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.opportunity_sub_status != null
                        ? proposalPoolViewDetails?.opportunity_sub_status
                        : "-"}
                    </h4>
                  </div>
                  {proposalLostReason === "Others" ? (
                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                      <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                        Other Reason
                      </h6>
                      <h4 className="font-medium-3 text-bold-700 text-justify">
                        {proposalPoolViewDetails != null &&
                          proposalPoolViewDetails?.created_at != null
                          ? proposalPoolViewDetails?.created_at
                          : "-"}
                      </h4>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Competition Intermediary Type
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.intermediary_type !=
                        null
                        ? proposalPoolViewDetails?.closure?.intermediary_type
                        : "-"}
                    </h4>
                  </div>

                  {competitionIntermediaryType === "Broker" ? (
                    <>
                      <div className="col-12 col-md-6 col-lg-6 mb-1">
                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                          Broker's name
                        </h6>
                        <h4 className="font-medium-3 text-bold-700 text-justify">
                          {proposalPoolViewDetails != null &&
                            proposalPoolViewDetails?.closure?.intermediary_name !=
                            null
                            ? proposalPoolViewDetails?.closure
                              ?.intermediary_name
                            : "-"}
                        </h4>
                      </div>
                    </>
                  ) : (
                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                      <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                        Intermediary Name
                      </h6>
                      <h4 className="font-medium-3 text-bold-700 text-justify">
                        {proposalPoolViewDetails != null &&
                          proposalPoolViewDetails?.closure?.intermediary_name !=
                          null
                          ? proposalPoolViewDetails?.closure?.intermediary_name
                          : "-"}
                      </h4>
                    </div>
                  )}

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Insurer Name
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.name != null
                        ? proposalPoolViewDetails?.closure?.name
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Net Amount
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.net_amount != null
                        ? proposalPoolViewDetails?.closure?.net_amount
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      GST Amount
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.gst_amount != null
                        ? proposalPoolViewDetails?.closure?.gst_amount
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Total Premium
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {totalPremium != null ? totalPremium.toFixed(2) : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Payment Sent to Insurer
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure
                          ?.payment_sent_to_insurer != null
                        ? proposalPoolViewDetails?.closure
                          ?.payment_sent_to_insurer
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Client Responsive
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.client_response != null
                        ? proposalPoolViewDetails?.closure?.client_response
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Sales Remarks
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.sales_remark != null &&
                        proposalPoolViewDetails?.closure?.sales_remark != "null"
                        ? proposalPoolViewDetails?.closure?.sales_remark
                        : "-"}
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* Proposal Success */}
              {quoteStatus === "Proposal Success" ? (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Closure Type
                  </h6>
                  <h4 className="font-medium-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails != null &&
                      proposalPoolViewDetails?.closure_type_formated != null
                      ? _.capitalize(
                        proposalPoolViewDetails?.closure_type_formated
                      )
                      : "-"}
                  </h4>
                </div>
              ) : (
                <></>
              )}
              {quoteStatus === "Proposal Success" &&
                closureType === "standard" ? (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Insurer Business booked with
                  </h6>
                  <h4 className="font-medium-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails != null &&
                      proposalPoolViewDetails?.closure?.name != null
                      ? proposalPoolViewDetails?.closure?.name
                      : "-"}
                  </h4>
                </div>
              ) : (
                <></>
              )}

              {/* START COMMENTED FOR CO BROKING / CO INSURER */}
              {quoteStatus === "Proposal Success" &&
                closureType != null &&
                closureType === "co_broking" ? (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                    Insurer Business booked with
                  </h6>
                  <h4 className="font-medium-3 text-bold-700 text-justify">
                    {proposalPoolViewDetails != null &&
                      proposalPoolViewDetails?.closure?.name != null
                      ? proposalPoolViewDetails?.closure?.name
                      : "-"}
                  </h4>
                </div>
              ) : (
                <></>
              )}
              {/* {
                                quoteStatus === 'Proposal Success' && closureType === 'co_insurer' ?
                                    <div className="col-12 col-md-6 col-lg-6 mb-1">
                                        <h6 className="font-small-2 text-uppercase blue-grey darken-1">Insurer Company</h6>
                                        <h4 className="font-medium-3 text-bold-700 text-justify">
                                            {proposalPoolViewDetails != null && proposalPoolViewDetails?.insurer_company != null ? proposalPoolViewDetails?.insurer_company : '-'}
                                        </h4>
                                    </div>
                                    :
                                    <></>
                            } */}
              {/* START COMMENTED FOR CO BROKING / CO INSURER */}

              {quoteStatus === "Proposal Success" && closureType != null ? (
                <>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Premium Amount (Quote Amt (Net)
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.premium_amount != null
                        ? proposalPoolViewDetails?.closure?.premium_amount
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      CGST %
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.cgst != null
                        ? proposalPoolViewDetails?.closure?.cgst
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      SGST %
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.sgst != null
                        ? proposalPoolViewDetails?.closure?.sgst
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Gross Premium
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {grossPremium != null ? grossPremium.toFixed(2) : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Installment
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.instalment != null
                        ? proposalPoolViewDetails?.closure?.instalment
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Business Closure Date
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.business_closure_date !=
                        null
                        ? proposalPoolViewDetails?.closure
                          ?.business_closure_date
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Policy Start Date
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.policy_start_date !=
                        null
                        ? proposalPoolViewDetails?.closure?.policy_start_date
                        : "-"}
                    </h4>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Policy End Date
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.end_date != null &&
                        proposalPoolViewDetails?.end_date != "0000-00-00 00:00:00"
                        ? moment(proposalPoolViewDetails?.end_date).format(
                          "DD/MM/yyyy"
                        )
                        : "-"}
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* START COMMENTED FOR CO BROKING / CO INSURER */}
              {quoteStatus === "Proposal Success" &&
                closureType != null &&
                closureType === "co_insurer_n_co_broking" ? (
                <>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      PINC Share %
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.closure?.pinc_share != null
                        ? proposalPoolViewDetails?.closure?.pinc_share
                        : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      PINC Share Premium
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {pincSharePremium != null
                        ? pincSharePremium.toFixed(2)
                        : "-"}
                    </h4>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      PINC Gross Premium
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {pincGrossPremiumAmount != null
                        ? pincGrossPremiumAmount.toFixed(2)
                        : "-"}
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="row">
              {quoteStatus === "Proposal Success" &&
                closureType != null &&
                closureType === "co_insurer_n_co_broking" ? (
                <>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Insurer Company
                    </h6>
                    {getInsurerNameList != null &&
                      getInsurerNameList.length > 0 ? (
                      <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                          <DataGrid
                            dataSource={getInsurerNameList}
                            sorting={false}
                            showBorders={true}
                            columnAutoWidth={true}
                          >
                            {renderInsurerColumns()}
                            <Pager
                              visible={true}
                              showPageSizeSelector={false}
                              showNavigationButtons={true}
                              allowedPageSizes={[4, 6, 10]}
                            />
                            <Paging
                              defaultPageIndex={0}
                              defaultPageSize={10}
                              enabled={true}
                            />
                          </DataGrid>
                        </div>
                      </div>
                    ) : (
                      <h4 className="font-medium-3 text-bold-700 text-justify">
                        -
                      </h4>
                    )}
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Broker Name
                    </h6>
                    {getBrokerNameList != null &&
                      getBrokerNameList.length > 0 ? (
                      <div className="row">
                        <div className="col-12 col-lg-12 col-md-12">
                          <DataGrid
                            dataSource={getBrokerNameList}
                            showBorders={true}
                            sorting={false}
                            columnAutoWidth={true}
                          >
                            {renderBrokerColumns()}
                            <Pager
                              visible={true}
                              showPageSizeSelector={false}
                              showNavigationButtons={true}
                              allowedPageSizes={[4, 6, 10]}
                            />
                            <Paging
                              defaultPageIndex={0}
                              defaultPageSize={10}
                              enabled={true}
                            />
                          </DataGrid>
                        </div>
                      </div>
                    ) : (
                      <h4 className="font-medium-3 text-bold-700 text-justify">
                        -
                      </h4>
                    )}
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mb-1">
                    <h6 className="font-small-2 text-uppercase blue-grey darken-1">
                      Remarks
                    </h6>
                    <h4 className="font-medium-3 text-bold-700 text-justify">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.remarks != null
                        ? proposalPoolViewDetails?.remarks
                        : "-"}
                    </h4>
                  </div>
                </>
              ) : (
                <></>
              )}
              {/* END COMMENTED FOR CO BROKING / CO INSURER */}
              {proposalPoolViewDetails?.product_specialist_id != 0 &&
                proposalPoolViewDetails?.product_specialist_id != "" &&
                proposalPoolViewDetails?.product_specialist_id != null ? (
                <div className="col-12 col-md-6 col-lg-6 mb-1">
                  <h6 className="font-small-2 text-uppercase blue-grey darken-1" onClick={() => console.log(proposalPoolViewDetails)}>
                    Placement Remark
                  </h6>
                  <h4 className="font-medium-3 text-bold-700 text-justify" style={{ whiteSpace: 'pre-wrap' }}>
                    {proposalPoolViewDetails != null &&
                      proposalPoolViewDetails?.opportunity_remark != null &&
                      proposalPoolViewDetails?.opportunity_remark != "null" &&
                      proposalPoolViewDetails?.opportunity_remark != "undefined"
                      ? renderPlacementRemark(
                        proposalPoolViewDetails?.opportunity_remark
                      )
                      : "-"}
                    {/* {proposalPoolViewDetails != null && proposalPoolViewDetails?.opportunity_remark != null ? proposalPoolViewDetails?.opportunity_remark : '-'} */}
                  </h4>
                </div>
              ) : (
                <></>
              )}
              {quoteStatus === "Proposal Lost" ||
                quoteStatus === "Proposal Success" ? (
                <>
                  <div className="col-12 col-md-12 col-lg-12 mb-1">
                    <h4 className="text-uppercase font-small-3 mb-0">
                      Document
                    </h4>
                    <div className="mt-1 row">
                      {proposalPoolViewDetails != null &&
                        proposalPoolViewDetails?.documents != null &&
                        proposalPoolViewDetails?.documents.length > 0 ? (
                        proposalPoolViewDetails.documents.map(
                          (objLink, index) => (
                            <>
                              <div className="col-12 col-md-4 col-xl-3">
                                <div className="card shadow bg-light rounded mb-1 ">
                                  <div className="card-body d-flex justify-content-between py-1">
                                    {/* <a target="_blank" href={objLink.document} download="Meeting Document">Document {index + 1}</a> */}
                                    <a
                                      className="primary"
                                      onClick={() => {
                                        if (
                                          objLink.full_url?.split(".").pop() ==
                                          "pdf" ||
                                          objLink.full_url?.split(".").pop() ==
                                          "PDF" ||
                                          objLink.full_url?.split(".").pop() ==
                                          "png" ||
                                          objLink.full_url?.split(".").pop() ==
                                          "jpg" ||
                                          objLink.full_url?.split(".").pop() ==
                                          "jpeg" ||
                                          objLink.full_url?.split(".").pop() ==
                                          "gif"
                                        ) {
                                          setSelectedDocumentIndex(
                                            objLink.full_url
                                          );
                                          setShowDocumentDetails(true);
                                          setExtension(
                                            objLink.full_url?.split(".").pop()
                                          );
                                          setDocumentDetails(objLink);
                                        } else {
                                          downloadFile(objLink.full_url);
                                        }
                                      }}
                                    >
                                      {/* Document {index + 1} */}
                                      {objLink.actual_uploaded_file_name}
                                    </a>
                                    {/* <span className='cursor-pointer'>  <FiTrash2 size={16} className="primary" /></span> */}
                                    <a id="downloadLink" className="d-none"></a>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        )
                      ) : (
                        <h4 className="font-medium-3 text-bold-700 text-justify pl-1">
                          -
                        </h4>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {quoteStatus != null &&
                quoteStatus === "Proposal Success" &&
                closureType != null &&
                (closureType === "co_insurer" || closureType === "co_broking") ? (
                <>
                  <div className="col-12 col-md-12 col-lg-12 ">
                    <div class="accordion" id="co-insurer-accordion">
                      <div
                        data-toggle="collapse"
                        data-target="#co-insurer-details-card"
                        aria-expanded="true"
                        aria-controls="co-insurer-details-card"
                      >
                        <h4 className="d-flex align-items-center justify-content-between border-bottom-black">
                          <span>
                            {" "}
                            {closureType != null && closureType === "co_insurer"
                              ? "CoInsurer Details"
                              : closureType != null &&
                                closureType === "co_broking"
                                ? "CoBroking Details"
                                : ""}{" "}
                          </span>
                          <button type="button" className="btn">
                            <IoIosArrowDown cursor="pointer" size={18} />
                          </button>
                        </h4>
                      </div>

                      <div
                        id="co-insurer-details-card"
                        class="collapse container show"
                        aria-labelledby="headingOne"
                        data-parent="#co-insurer-accordion"
                      >
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            {/* <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}> */}
                            <div className="form-body">
                              {/* <div className="form-section"> */}
                              {questionList != null &&
                                questionList.length > 0 ? (
                                questionList.map((item, index) => (
                                  <>
                                    <div className="border-bottom-primary px-0 py-1">
                                      <div className="row">
                                        {closureType != null &&
                                          closureType === "co_insurer" ? (
                                          <div className="col-12 col-md-5 col-lg-5">
                                            <div className="form-group mb-0">
                                              <label className="label-control">
                                                Insurance Company&nbsp;
                                                <span className="danger">
                                                  *
                                                </span>
                                              </label>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                                {item?.name != null
                                                  ? String(item?.name)
                                                  : "--"}
                                              </h4>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="col-12 col-md-5 col-lg-5">
                                            <div className="form-group mb-0">
                                              <label className="label-control">
                                                Broker Name&nbsp;
                                                <span className="danger">
                                                  *
                                                </span>
                                              </label>
                                              <h4 className="font-small-3 text-bold-700 text-justify">
                                                {item?.name != null
                                                  ? String(item?.name)
                                                  : "--"}
                                              </h4>
                                            </div>
                                          </div>
                                        )}
                                        <div className="col-12 col-md-1 col-lg-1">
                                          <div className="form-group mb-0">
                                            <label className="label-control">
                                              Type&nbsp;
                                              <span className="danger">*</span>
                                            </label>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                              {item?.type != null
                                                ? _.capitalize(item?.type)
                                                : "--"}
                                            </h4>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-2 col-lg-2">
                                          <div className="form-group mb-0">
                                            <label className="label-control">
                                              Share %&nbsp;
                                              <span className="danger">*</span>
                                            </label>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                              {item?.share != null
                                                ? String(item?.share)
                                                : "--"}
                                            </h4>
                                          </div>
                                        </div>

                                        <div className="col-12 col-md-2 col-lg-2">
                                          <div className="form-group mb-0">
                                            <label className="label-control">
                                              Share Premium
                                            </label>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                              {item?.share_premium_amount !=
                                                null
                                                ? String(
                                                  item?.share_premium_amount
                                                )
                                                : "--"}
                                            </h4>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-2 col-lg-2">
                                          <div className="form-group mb-0">
                                            <label className="label-control">
                                              Gross Premium
                                            </label>
                                            <h4 className="font-small-3 text-bold-700 text-justify">
                                              {item?.gross_premium != null
                                                ? String(item?.gross_premium)
                                                : "--"}
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))
                              ) : (
                                <></>
                              )}
                              {/* Total */}
                              <div className="row mt-2">
                                <div className="col-12 col-md-4 col-lg-4">
                                  <label className="label-control">
                                    Total Share
                                    <b className="ml-1">
                                      {Number(totalSharePercent).toFixed(2)} %
                                    </b>
                                  </label>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <label className="label-control">
                                    Total Share Premium Amount
                                    <b className="ml-1">
                                      {Number(totalSharePremiumValue).toFixed(
                                        2
                                      )}
                                    </b>
                                  </label>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                  <label className="label-control">
                                    {" "}
                                    Total Gross Premium
                                    <b className="ml-1">
                                      {Number(totalGrossPremiumValue).toFixed(
                                        2
                                      )}
                                    </b>
                                  </label>
                                </div>
                              </div>
                              {/* Total End */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="content-header row mb-1">
          <div className="content-header-left col-12 col-md-6 col-lg-6 my-auto">
            <h5 className="content-header-title text-capitalize mb-0">
              Documents
            </h5>
          </div>
        </div>
        {proposalPoolViewDetails != null &&
          proposalPoolViewDetails?.erp_rfq_policy_documents != null &&
          proposalPoolViewDetails?.erp_rfq_policy_documents.length > 0 ?
          <>
            <div class="card-header primary mb-1">
              <p class="card-title lead">Policy Documents</p>
            </div>
            <div className="row">
              {
                proposalPoolViewDetails.erp_rfq_policy_documents.map((document, indexDocument) => (
                  <div className="col-12 col-lg-4 col-md-8" key={`document-${indexDocument}`}>
                    <div className="card rounded shadow">
                      <div className="card-body">
                        <div className="d-flex flex-row align-items-center">
                          <div className="flex-column">
                            <h1 className="font-medium-1 text-black card-text-esc">{document?.actual_uploaded_file_name}</h1>
                          </div>
                          <div className="d-flex align-items-center flex-column ml-auto">
                            <p className="mb-0"><AiFillFileText size={40} className="primary" /></p>
                            {
                              document.full_url != null ?
                                <>
                                  <a className="primary"
                                    onClick={() => {
                                      if (document?.full_url?.split('.').pop() == 'pdf' ||
                                        document?.full_url?.split('.').pop() == 'PDF' ||
                                        document?.full_url?.split('.').pop() == 'png' ||
                                        document?.full_url?.split('.').pop() == 'jpg' ||
                                        document?.full_url?.split('.').pop() == 'jpeg' ||
                                        document?.full_url?.split('.').pop() == 'gif'
                                      ) {
                                        setSelectedDocumentIndex(document?.full_url)
                                        setShowDocumentDetails(true);
                                        setExtension(document?.full_url.split('.').pop())
                                        setDocumentDetails(document)
                                      } else {
                                        downloadFile(document?.full_url);
                                      }
                                    }}
                                  >
                                    Open
                                  </a>
                                  <a id="downloadLink" className="d-none"></a>
                                </>
                                :
                                <></>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </> : <></>}
        {proposalPoolViewDetails != null &&
          proposalPoolViewDetails?.erp_rfq_claim_documents != null &&
          proposalPoolViewDetails?.erp_rfq_claim_documents.length > 0 ?
          <>
            <div class="card-header primary mb-1">
              <p class="card-title lead">Claim Documents</p>
            </div>
            <div className="row">
              {
                proposalPoolViewDetails.erp_rfq_claim_documents.map((document, indexDocument) => (
                  <div className="col-12 col-lg-4 col-md-8" key={`document-${indexDocument}`}>
                    <div className="card rounded shadow">
                      <div className="card-body">
                        <div className="d-flex flex-row align-items-center">
                          <div className="flex-column">
                            <h1 className="font-medium-1 text-black card-text-esc">{document?.actual_uploaded_file_name}</h1>
                          </div>
                          <div className="d-flex align-items-center flex-column ml-auto">
                            <p className="mb-0"><AiFillFileText size={40} className="primary" /></p>
                            {
                              document.full_url != null ?
                                <>
                                  <a className="primary"
                                    onClick={() => {
                                      if (document?.full_url?.split('.').pop() == 'pdf' ||
                                        document?.full_url?.split('.').pop() == 'PDF' ||
                                        document?.full_url?.split('.').pop() == 'png' ||
                                        document?.full_url?.split('.').pop() == 'jpg' ||
                                        document?.full_url?.split('.').pop() == 'jpeg' ||
                                        document?.full_url?.split('.').pop() == 'gif'
                                      ) {
                                        setSelectedDocumentIndex(document?.full_url)
                                        setShowDocumentDetails(true);
                                        setExtension(document?.full_url.split('.').pop())
                                        setDocumentDetails(document)
                                      } else {
                                        downloadFile(document?.full_url);
                                      }
                                    }}
                                  >
                                    Open
                                  </a>
                                  <a id="downloadLink" className="d-none"></a>
                                </>
                                :
                                <></>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </> : <></>}
        {proposalPoolViewDetails != null &&
          proposalPoolViewDetails?.erp_rfq_other_documents != null &&
          proposalPoolViewDetails?.erp_rfq_other_documents.length > 0 ?
          <>
            <div class="card-header primary mb-1">
              <p class="card-title lead">Other Documents</p>
            </div>
            <div className="row">
              {
                proposalPoolViewDetails.erp_rfq_other_documents.map((document, indexDocument) => (
                  <div className="col-12 col-lg-4 col-md-8" key={`document-${indexDocument}`}>
                    <div className="card rounded shadow">
                      <div className="card-body">
                        <div className="d-flex flex-row align-items-center">
                          <div className="flex-column">
                            <h1 className="font-medium-1 text-black card-text-esc">{document?.actual_uploaded_file_name}</h1>
                          </div>
                          <div className="d-flex align-items-center flex-column ml-auto">
                            <p className="mb-0"><AiFillFileText size={40} className="primary" /></p>
                            {
                              document.full_url != null ?
                                <>
                                  <a className="primary"
                                    onClick={() => {
                                      if (document?.full_url?.split('.').pop() == 'pdf' ||
                                        document?.full_url?.split('.').pop() == 'PDF' ||
                                        document?.full_url?.split('.').pop() == 'png' ||
                                        document?.full_url?.split('.').pop() == 'jpg' ||
                                        document?.full_url?.split('.').pop() == 'jpeg' ||
                                        document?.full_url?.split('.').pop() == 'gif'
                                      ) {
                                        setSelectedDocumentIndex(document?.full_url)
                                        setShowDocumentDetails(true);
                                        setExtension(document?.full_url.split('.').pop())
                                        setDocumentDetails(document)
                                      } else {
                                        downloadFile(document?.full_url);
                                      }
                                    }}
                                  >
                                    Open
                                  </a>
                                  <a id="downloadLink" className="d-none"></a>
                                </>
                                :
                                <></>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </> : <></>}
        <div className="form-actions border-0 mt-0 mb-4 pt-0 float-none">
          <button
            className="btn btn-outline-secondary mr-1 shadow round text-uppercase mt-1"
            onClick={(event) => {
              event.preventDefault();
              // props.history.push({
              //     pathname: '/proposal_pool',
              //     state: {
              //         proposalType: props.location.state.type,
              //     }
              // });
              window.close();
              // props.history.goBack();
            }}
          >
            Back
          </button>
        </div>
      </div>
      <DocumentIframe
        {...props}
        show={showDocumentDetails}
        documentDetails={selectedDocumentIndex}
        detailsOfDocument={documentDetails}
        extension={extension}
        handleClose={() => {
          setShowDocumentDetails(false);
        }}
      />
    </section>
  );
};

const mapStateToProps = (state) => {
  var ProposalPoolView = null;
  var OpportunityPoolQuestion = null;
  if (
    state.ProposalPoolViewReducer != null &&
    Object.keys(state.ProposalPoolViewReducer).length > 0 &&
    state.ProposalPoolViewReducer.constructor === Object
  ) {
    switch (state.ProposalPoolViewReducer.tag) {
      case ACTION_PROPOSALPOOL_VIEW_SUCCESS:
        ProposalPoolView = Object.assign(
          {},
          state.ProposalPoolViewReducer.data
        );
        delete state.ProposalPoolViewReducer;
        return { ProposalPoolView };
      case ACTION_OPPORTUNITY_POOL_QUESTION_SUCCESS:
        OpportunityPoolQuestion = Object.assign(
          {},
          state.ProposalPoolViewReducer.data
        );
        delete state.ProposalPoolViewReducer;
        return { OpportunityPoolQuestion };
      default:
    }
  }
  return {
    ProposalPoolView,
    OpportunityPoolQuestion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { actionProposalPoolView, actionOpportunityPoolQuestion },
    dispatch
  );
};
export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(ProposalSettingsView)
);
