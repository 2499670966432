import {  API_OPPORTUNITY_DOCUMENT_UPLOAD, API_PROPOSALPOOL_VIEW } from '../../../api/constants';
import Api from '../../../api';
import { fetchSuccess, loading } from '../../../utils/action.util';

export const ACTION_DOCUMENT_UPLOAD_LOADING = 'ACTION_DOCUMENT_UPLOAD_LOADING';
export const ACTION_DOCUMENT_UPLOAD_SUCCESS = 'ACTION_DOCUMENT_UPLOAD_SUCCESS';

export const ACTION_PROPOSAL_VIEW_LOADING = 'ACTION_PROPOSAL_VIEW_LOADING';
export const ACTION_PROPOSAL_VIEW_SUCCESS = 'ACTION_PROPOSAL_VIEW_SUCCESS';

export function actionDocumentUpload(params,files) {
    return (dispatch, getState) =>
        Api.multipart(API_OPPORTUNITY_DOCUMENT_UPLOAD, params, files).then((res) => {
            const data = res;
            dispatch(fetchSuccess(ACTION_DOCUMENT_UPLOAD_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_DOCUMENT_UPLOAD_LOADING, { loading: false, data }));
        });
}

export function actionProposalView(params) {
    return (dispatch, getState) =>
        Api.post(API_PROPOSALPOOL_VIEW, params).then((res) => {
            const data = res;
            // PRINT('ACTION RESPONSE', data)
            dispatch(fetchSuccess(ACTION_PROPOSAL_VIEW_SUCCESS, { data }));
        }).catch((reason) => {
            /** Handle Error */
            var data = {
                message: ''
            }
            dispatch(loading(ACTION_PROPOSAL_VIEW_LOADING, { loading: false, data }));
        });
}