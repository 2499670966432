/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import $ from "jquery";
import ErrorLabel from "../error_label";
import { FiUser } from "react-icons/fi";
import {
  DropDownListComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withAuth } from "react-auth-guard";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import {
  actionGetActivity,
  ACTION_GET_ACTIVITY_SUCCESS,
} from "../../../routes/app/auth/lead/create/components/corporate/action";
import {
  actionNewCompanyGroup,
  ACTION_MASTER_NEW_COMPANY_GROUP_SUCCESS,
} from "./action";
import {
  actionNewCustomerCategory,
  ACTION_MASTER_NEW_CUSTOMER_CATEGORY_SUCCESS,
} from "./action";
import {
  actionNewIndustrialSegment,
  ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_SUCCESS,
} from "./action";
import {
  actionNewFormOfOrganization,
  ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_SUCCESS,
} from "./action";
import {
  actionNewLeadSource,
  ACTION_MASTER_NEW_LEAD_SOURCE_SUCCESS,
} from "./action";
import {
  actionNewAssignRM,
  ACTION_MASTER_NEW_ASSIGN_RM_SUCCESS,
} from "./action";
import {
  actionNewStates,
  ACTION_MASTER_NEW_LEAD_STATES_SUCCESS,
} from "./action";
import {
  actionNewCities,
  ACTION_MASTER_NEW_LEAD_CITIES_SUCCESS,
} from "./action";
import { actionAddNewCompany, ACTION_ADD_NEW_COMPANY_SUCCESS } from "./action";
import {
  actionGetClientDetails,
  ACTION_GET_CORPORATE_CLIENT_DETAILS_SUCCESS,
} from "./action";
import { actionEditCompany, ACTION_EDIT_COMPANY_SUCCESS } from "./action";
import {
  PRIMARY_COLOR,
  UNAUTHORIZED,
  VALIDATION_ERROR,
} from "../../../constants";

const AddNewCompany = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      multi_branch: 1,
    },
  });

  const [newCompanyGroup, setNewCompanyGroup] = useState([]);
  const [newCustomerCategory, setNewCustomerCategory] = useState([]);
  const [newMasterStates, setNewMasterStates] = useState([]);
  const [newMasterCities, setNewMasterCities] = useState([]);
  const additionalCheck = props?.additionalCheck;
  const [newIndustrialSegment, setNewIndustrialSegment] = useState([]);
  const [newFormOfOrganization, setNewFormOfOrganization] = useState([]);
  const [newLeadSource, setNewLeadSource] = useState({});
  const [newAssignRM, setNewAssignRM] = useState({});
  const [clientDetails, setClientDetails] = useState(null);
  const [clientRedquiredFields, setClientRedquiredFields] = useState(false);
  const [activity, setActivity] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const watchDay = watch("contact_dob_date");
  const watchMonth = watch("contact_dob_month");
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleDateString("default", { month: "long" })
  );

  const initialRender = () => {
    if (props.show === true) {
      // reset({
      //     company_group_name: '',
      //     year_of_passing: '',
      //     institute_name: ''
      // })

      $("#root").addClass("modal-open");
      $("#root").append('<div class="modal-backdrop fade show"></div>');
      if (props.clientId != null) {
        props.actionGetClientDetails({ id: props.clientId });
        setClientRedquiredFields(true);
      } else {
        props.actionNewCompanyGroup();
        props.actionNewCustomerCategory();
        props.actionNewStates();
        props.actionNewIndustrialSegment();
        props.actionNewFormOfOrganization();
        props.actionNewLeadSource();
        props.actionNewAssignRM({ user_id: props.auth.getUser().id });
        if (
          props.hasOwnProperty("validateClient") &&
          props.validateClient === true
        ) {
          setClientRedquiredFields(true);
        } else {
          setClientRedquiredFields(false);
        }
      }
    } else {
      $("#root").removeClass("modal-open");
      $(".modal-backdrop").remove();
      reset();
    }
    $(
      "input[type='radio'][name='customer_group_type'][value='Existing Customer Group']"
    ).prop("checked", true);
  };
  useEffect(initialRender, [props.show]);

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
      years.push({
        value: i.toString(),
        text: i.toString(),
      });
    }
    return years;
  };

  const generateMonthOptions = () => {
    const months = [];
    for (let i = 0; i < 12; i++) {
      const monthNumber = i + 1;
      const monthName = new Date(selectedYear, i, 1).toLocaleDateString(
        "default",
        { month: "long" }
      );
      months.push({ value: monthNumber.toString(), text: monthName });
    }
    return months;
  };

  const generateDayOptions = () => {
    let daysInMonth;
    const currentMonth = new Date().toLocaleDateString("default", {
      month: "long",
    });
    if (
      selectedMonth === currentMonth &&
      selectedYear === new Date().getFullYear()
    ) {
      daysInMonth = new Date(
        selectedYear,
        new Date(Date.parse(selectedMonth + " 1, " + selectedYear)).getMonth() +
          1,
        0
      ).getDate();
    } else {
      daysInMonth = new Date(
        selectedYear,
        new Date(Date.parse(selectedMonth + " 1, " + selectedYear)).getMonth() +
          1,
        0
      ).getDate();
    }

    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({ value: i.toString(), text: i.toString() });
    }
    return days;
  };

  useEffect(() => {
    if (clientDetails != null) {
      // alert(JSON.stringify(clientDetails))
      props.actionNewCompanyGroup();
      props.actionNewCustomerCategory();
      props.actionNewStates();
      props.actionNewIndustrialSegment();
      props.actionNewFormOfOrganization();
      props.actionNewLeadSource();
      props.actionNewAssignRM({ user_id: props.auth.getUser().id });
    }
  }, [clientDetails]);

  const onMasterNewCompanyGroupUpdate = () => {
    const { NewCompanyGroup } = props;
    var NewCompanyGroupResponse = Object.assign({}, NewCompanyGroup);
    if (NewCompanyGroupResponse.result === true) {
      setNewCompanyGroup(NewCompanyGroupResponse.response);
    } else {
      switch (NewCompanyGroupResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onMasterNewCompanyGroupUpdate, [props.NewCompanyGroup]);

  const onMasterNewCustomerCategoryUpdate = () => {
    const { NewCustomerCategory } = props;
    var NewCustomerCategoryResponse = Object.assign({}, NewCustomerCategory);
    if (NewCustomerCategoryResponse.result === true) {
      setNewCustomerCategory(NewCustomerCategoryResponse.response);
    } else {
      switch (NewCustomerCategoryResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onMasterNewCustomerCategoryUpdate, [props.NewCustomerCategory]);

  useEffect(() => {
    if (
      props.clientId != null &&
      newCustomerCategory != null &&
      newCustomerCategory.length > 0 &&
      (props.convertedToCustomer === true || additionalCheck)
    ) {
      setValue(
        "category",
        clientDetails.category != null ? clientDetails.category : ""
      );
    }
  }, [newCustomerCategory]);

  const onMasterNewStatesUpdate = () => {
    const { NewStates } = props;
    var NewStatesResponse = Object.assign({}, NewStates);
    if (NewStatesResponse.result === true) {
      setNewMasterStates(NewStatesResponse.response);
    } else {
      switch (NewStatesResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onMasterNewStatesUpdate, [props.NewStates]);

  useEffect(() => {
    if (
      props.clientId != null &&
      newMasterStates != null &&
      newMasterStates.length > 0
    ) {
      if (props.convertedToCustomer === true || additionalCheck) {
        setValue("state", clientDetails.state_id);
      }
      props.actionNewCities({
        state_ids:
          clientDetails.state_id != null ? [clientDetails.state_id] : "",
      });
    }
  }, [newMasterStates]);

  const onMasterNewCitiesUpdate = () => {
    const { NewCities } = props;
    var NewCitiesResponse = Object.assign({}, NewCities);
    if (NewCitiesResponse.result === true) {
      setNewMasterCities(NewCitiesResponse.response);
    } else {
      switch (NewCitiesResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onMasterNewCitiesUpdate, [props.NewCities]);

  useEffect(() => {
    if (
      props.clientId != null &&
      newMasterCities != null &&
      newMasterCities.length > 0 &&
      (props.convertedToCustomer === true || additionalCheck)
    ) {
      setValue(
        "city_id",
        clientDetails.city_id != null ? clientDetails.city_id : ""
      );
    }
  }, [newMasterCities]);

  const onMasterNewIndustrialSegmentUpdate = () => {
    const { NewIndustrialSegment } = props;
    var NewIndustrialSegmentResponse = Object.assign({}, NewIndustrialSegment);
    if (NewIndustrialSegmentResponse.result === true) {
      setNewIndustrialSegment(NewIndustrialSegmentResponse.response);
    } else {
      switch (NewIndustrialSegmentResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onMasterNewIndustrialSegmentUpdate, [props.NewIndustrialSegment]);

  useEffect(() => {
    if (
      props.clientId != null &&
      newIndustrialSegment != null &&
      newIndustrialSegment.length > 0 &&
      (props.convertedToCustomer === true || additionalCheck)
    ) {
      setValue(
        "industrial_segment_id",
        clientDetails.industrial_segment_id != null
          ? clientDetails.industrial_segment_id
          : ""
      );
    }
  }, [newIndustrialSegment]);

  const onMasterNewFormofOrganizationUpdate = () => {
    const { NewFormofOrganization } = props;
    var NewFormofOrganizationResponse = Object.assign(
      {},
      NewFormofOrganization
    );
    if (NewFormofOrganizationResponse.result === true) {
      setNewFormOfOrganization(NewFormofOrganizationResponse.response);
    } else {
      switch (NewFormofOrganizationResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onMasterNewFormofOrganizationUpdate, [props.NewFormofOrganization]);

  useEffect(() => {
    if (
      props.clientId != null &&
      newFormOfOrganization != null &&
      newFormOfOrganization.length > 0 &&
      (props.convertedToCustomer === true || additionalCheck)
    ) {
      setValue(
        "form_of_organization_id",
        clientDetails.form_of_organization_id != null
          ? clientDetails.form_of_organization_id
          : ""
      );
    }
  }, [newFormOfOrganization]);

  const onMasterNewLeadSourceUpdate = () => {
    const { NewLeadSource } = props;
    var NewLeadSourceResponse = Object.assign({}, NewLeadSource);
    if (NewLeadSourceResponse.result === true) {
      setNewLeadSource(NewLeadSourceResponse.response);
    } else {
      switch (NewLeadSourceResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onMasterNewLeadSourceUpdate, [props.NewLeadSource]);

  useEffect(() => {
    if (
      props.clientId != null &&
      newLeadSource != null &&
      newLeadSource.length > 0 &&
      (props.convertedToCustomer === true || additionalCheck)
    ) {
      setValue(
        "lead_source_id",
        clientDetails.lead_source_id != null ? clientDetails.lead_source_id : ""
      );
    }
  }, [newLeadSource]);

  const onMasterNewAssignRMUpdate = () => {
    const { NewAssignRM } = props;
    var NewAssignRMResponse = Object.assign({}, NewAssignRM);
    if (NewAssignRMResponse.result === true) {
      var arrAssignRM = Object.assign([], NewAssignRMResponse.response);

      setNewAssignRM(arrAssignRM);
    } else {
      switch (NewAssignRMResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onMasterNewAssignRMUpdate, [props.NewAssignRM]);

  useEffect(() => {
    if (
      props.clientId != null &&
      newAssignRM != null &&
      newAssignRM.length > 0 &&
      (props.convertedToCustomer === true || additionalCheck)
    ) {
      setValue(
        "created_by",
        clientDetails.created_by != null ? clientDetails.created_by : ""
      );
    }
  }, [newAssignRM]);

  const onActivityUpdate = () => {
    const { GetActivity } = props;
    var GetActivityResponse = Object.assign({}, GetActivity);
    if (GetActivityResponse.result === true) {
      setActivity(GetActivityResponse.response);
    } else {
      switch (GetActivityResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          toast.success(
            "Your Login session has expired, Please try to Login again!"
          );
          break;
        default:
      }
    }
  };
  useEffect(onActivityUpdate, [props.GetActivity]);

  useEffect(() => {
    if (activity != null && activity.length > 0) {
      if (clientDetails != null) {
        setValue(
          "industrial_segment_activity_id",
          clientDetails?.industrial_segment_activity_id
        );
      }
    }
  }, [activity]);

  const onAddNewCompanyUpdate = () => {
    const { AddNewCompany } = props;
    if (AddNewCompany != null) {
      var AddNewCompanyResponse = Object.assign({}, AddNewCompany);
      $("#save-new-company").prop("disabled", false);
      if (AddNewCompanyResponse.result === true) {
        $("#new-company-form-loader").addClass("hidden");
        // setAddNewCompany(AddNewCompanyResponse.response);
        props.handleClose(AddNewCompanyResponse.response);
      } else {
        $("#new-company-form-loader").addClass("hidden");
        switch (AddNewCompanyResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          case VALIDATION_ERROR:
            var strMessage = "";

            if (AddNewCompanyResponse.message.hasOwnProperty("name")) {
              strMessage += AddNewCompanyResponse.message.name + "<br>";
            }
            if (AddNewCompanyResponse.message.hasOwnProperty("email")) {
              strMessage += AddNewCompanyResponse.message.email + "<br>";
            }
            if (AddNewCompanyResponse.message.hasOwnProperty("mobile")) {
              strMessage += AddNewCompanyResponse.message.mobile + "<br>";
            }

            if (strMessage.length > 0) {
              Swal.fire({
                title: "Error",
                icon: "error",
                html: strMessage,
                confirmButtonColor: PRIMARY_COLOR,
              }).then((result) => {});
            }
            break;
          default:
        }
      }
    }
  };
  useEffect(onAddNewCompanyUpdate, [props.AddNewCompany]);

  const onEditCompanyUpdate = () => {
    const { EditCompany } = props;
    var EditCompanyResponse = Object.assign({}, EditCompany);
    if (EditCompanyResponse.result === true) {
      $("#new-company-form-loader").addClass("hidden");
      $("#save-new-company").prop("disabled", false);
      props.handleClose(EditCompanyResponse.response, additionalCheck);
    } else {
      switch (EditCompanyResponse.status) {
        case UNAUTHORIZED:
          props.auth.logout();
          break;
        default:
      }
    }
  };
  useEffect(onEditCompanyUpdate, [props.EditCompany]);

  const onClientDetailsUpdate = () => {
    const { ClientDetails } = props;
    if (ClientDetails != null) {
      var ClientDetailsResponse = Object.assign({}, ClientDetails);
      if (ClientDetailsResponse.result === true) {
        setClientDetails(ClientDetailsResponse.response);
        if (props.convertedToCustomer === true || additionalCheck) {
          setValue("id", ClientDetailsResponse.response.id);
          setValue("name", ClientDetailsResponse.response.company_name);
          setValue("address", ClientDetailsResponse.response.address);
          setValue("pincode", ClientDetailsResponse.response.pincode);
          setValue("email", ClientDetailsResponse.response.email);
          setValue("mobile", ClientDetailsResponse.response.mobile);
          setValue("pan", ClientDetailsResponse.response.pan);
          setValue("landline", ClientDetailsResponse.response.landline);
          setValue("website", ClientDetailsResponse.response.website);
        }
        if (ClientDetailsResponse.response.primary_contact !== null) {
          setValue(
            "contact_name",
            ClientDetailsResponse.response.primary_contact.contact_name
          );
          setValue(
            "contact_designation",
            ClientDetailsResponse.response.primary_contact.contact_designation
          );
          setValue(
            "contact_phone",
            ClientDetailsResponse.response.primary_contact.contact_phone
          );
          setValue(
            "contact_email",
            ClientDetailsResponse.response.primary_contact.contact_email
          );
          setValue(
            "contact_dob_date",
            ClientDetailsResponse.response.contact_dob_date
              ? String(ClientDetailsResponse.response.contact_dob_date)
              : ""
          );
          setValue(
            "contact_dob_month",
            ClientDetailsResponse.response.contact_dob_month
              ? String(ClientDetailsResponse.response.contact_dob_month)
              : ""
          );
          setValue(
            "contact_dob_year",
            ClientDetailsResponse.response.contact_dob_year
              ? String(ClientDetailsResponse.response.contact_dob_year)
              : ""
          );
        }
        if (props.convertedToCustomer === true || additionalCheck) {
          setValue(
            "contact_details",
            ClientDetailsResponse.response.contact_details
          );
          // setValue('policy_details', ClientDetailsResponse.response.policy_details);
          setValue(
            "company_group_name",
            ClientDetailsResponse.response.company_group_name
          );
          setValue(
            "multi_branch",
            ClientDetailsResponse.response.multi_branch === "No" ? 0 : 1
          );
          setValue("category", ClientDetailsResponse.response.category);
        }
      } else {
        switch (ClientDetailsResponse.status) {
          case UNAUTHORIZED:
            props.auth.logout();
            break;
          default:
        }
      }
    }
  };
  useEffect(onClientDetailsUpdate, [props.ClientDetails]);

  const onSubmit = (data) => {
    data.vertical = "corporate";
    data.client_of = "insurance";
    data.clientOrLead = "lead";

    if (props.hasOwnProperty("createClient") && props?.createClient == true) {
      data.clientOrLead = "client";
    }
    if (
      props.clientId != null &&
      (props.convertedToCustomer === true || additionalCheck)
    ) {
      data.primary_contact = {
        id:
          clientDetails.primary_contact !== null
            ? clientDetails.primary_contact.id
            : null,
        contact_name: getValues("contact_name"),
        contact_designation: getValues("contact_designation"),
        contact_phone: getValues("contact_phone"),
        contact_email: getValues("contact_email"),
      };
      data.id = props.clientId;
      /**
       * API : /api/client/edit
       * METHOD : POST
       * Description : Edit Client Record
       * INPUT : Client Object
       */
      $("#new-company-form-loader").removeClass("hidden");
      $("#save-new-company").prop("disabled", true);
      props.actionEditCompany(data);
    } else {
      data.primary_contact = {
        contact_name: getValues("contact_name"),
        contact_designation: getValues("contact_designation"),
        contact_phone: getValues("contact_phone"),
        contact_email: getValues("contact_email"),
      };
      /**
       * API : /api/client/create
       * METHOD : POST
       * Description : Add Client Record
       * INPUT : Client Object
       */
      $("#new-company-form-loader").removeClass("hidden");
      $("#save-new-company").prop("disabled", true);
      props.actionAddNewCompany(data);
    }
  };

  return (
    <>
      <div
        className="modal fade show"
        id="default"
        role="dialog"
        aria-labelledby="add-new-company"
        aria-hidden="true"
        style={{ display: `${props.show === true ? "block" : "none"}` }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="media-middle mt-1">
                <h4 className="modal-title" id="add-new-company">
                  {additionalCheck ? "Update Lead Details" : "Add New Company"}
                </h4>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  props.handleClose();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                noValidate
                autoComplete="Off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-body">
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                      <h4 className="d-flex align-items-center form-section my-2 black">
                        <HiOutlineOfficeBuilding size={16} />
                        &nbsp;Company Information
                      </h4>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">
                          Company Group Name&nbsp;
                          <span className="danger">*</span>
                        </label>
                        <Controller
                          name="company_group_name"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <AutoCompleteComponent
                              {...field}
                              dataSource={newCompanyGroup}
                              fields={{ value: "name" }}
                              value={field.value}
                              placeholder="Select Company Group Name"
                              change={(event) => {
                                if (event?.itemData != null) {
                                  field.onChange(event?.itemData?.name);
                                }
                              }}
                            />
                          )}
                          rules={{
                            required: "Please Select Company Group Name",
                          }}
                        />
                        {errors.company_group_name && (
                          <ErrorLabel
                            message={errors.company_group_name?.message}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <fieldset className="form-group">
                        <label className="label-control">
                          Company Name&nbsp;<span className="danger">*</span>
                        </label>
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <input
                              {...field}
                              className="form-control"
                              placeholder="Enter Company Name"
                              value={field.value}
                              onChange={(event) =>
                                field.onChange(event.target.value)
                              }
                            />
                          )}
                          rules={{
                            required: "Please Enter Company Name",
                          }}
                        />
                        {errors.name && (
                          <ErrorLabel message={errors.name?.message} />
                        )}
                      </fieldset>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <label className="label-control">Address</label>
                        <textarea
                          className="form-control"
                          id="address"
                          name="address"
                          placeholder="Enter Address"
                          rows="3"
                          {...register("address")}
                        />
                        {errors.address && (
                          <ErrorLabel message={errors.address?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">
                          Customer Category
                        </label>
                        <Controller
                          name="category"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={newCustomerCategory}
                              fields={{ text: "title", value: "value" }}
                              allowFiltering={true}
                              placeholder="Select Customer Category"
                              value={field.value}
                              change={(event) => {
                                if (event?.itemData != null) {
                                  field.onChange(event?.itemData?.value);
                                }
                              }}
                            />
                          )}
                          rules={{
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Select Customer Category"
                                : false,
                          }}
                        />
                        {errors.category && (
                          <ErrorLabel message={errors.category?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Email ID</label>
                        <input
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter Email ID"
                          {...register("email", {
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Enter Email ID"
                                : false,
                            pattern: {
                              value:
                                /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/i,
                              message: "Please enter valid Email Id",
                            },
                          })}
                        />
                        {errors.email && (
                          <ErrorLabel message={errors.email?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">State</label>
                        <Controller
                          name="state"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={newMasterStates}
                              fields={{ text: "name", value: "id" }}
                              allowFiltering={true}
                              placeholder="Select State"
                              value={field.value}
                              change={(event) => {
                                if (event?.itemData != null) {
                                  field.onChange(event?.itemData.id);
                                  props.actionNewCities({
                                    state_ids: [event?.itemData?.id],
                                  });
                                }
                              }}
                            />
                          )}
                          rules={{
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Select State"
                                : false,
                          }}
                        />
                        {errors.state && (
                          <ErrorLabel message={errors.state?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">City</label>
                        <Controller
                          name="city_id"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={newMasterCities}
                              fields={{ text: "name", value: "id" }}
                              allowFiltering={true}
                              placeholder="Select City"
                              value={field.value}
                              change={(event) =>
                                field.onChange(event?.itemData?.id)
                              }
                            />
                          )}
                          rules={{
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Select City"
                                : false,
                          }}
                        />
                        {errors.city_id && (
                          <ErrorLabel message={errors.city_id?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Pincode</label>
                        <input
                          className="form-control"
                          id="pincode"
                          name="pincode"
                          maxLength={6}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Enter Pincode"
                          {...register("pincode", {
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Enter Pincode"
                                : false,
                            pattern: {
                              value: /^\d{6}$/,
                              message: "Please enter 6 digit valid PIN Code",
                            },
                          })}
                        />
                        {errors.pincode && (
                          <ErrorLabel message={errors.pincode?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Mobile</label>
                        <input
                          className="form-control"
                          id="mobile"
                          name="mobile"
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Enter Your Mobile Number"
                          {...register("mobile", {
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Enter Mobile"
                                : false,
                            pattern: {
                              value: /^[6789]\d{9}$/,
                              message:
                                "Please enter 10 digit valid Mobile Number",
                            },
                          })}
                        />
                        {errors.mobile && (
                          <ErrorLabel message={errors.mobile?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Pan No</label>
                        <input
                          className="form-control"
                          id="pan"
                          name="pan"
                          maxLength={10}
                          placeholder="Enter pan No"
                          {...register("pan", {
                            pattern: {
                              value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                              message: "Please Enter valid Pan No",
                            },
                          })}
                        />
                        {errors.pan && (
                          <ErrorLabel message={errors.pan?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Landline</label>
                        <input
                          className="form-control"
                          id="landline"
                          name="landline"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          minLength={8}
                          maxLength={13}
                          placeholder="Enter Landline"
                          {...register("landline", {
                            minLength: {
                              value: 8,
                              message:
                                "Please enter min 8 and max 13 digit valid Landline no",
                            },
                          })}
                        />
                        {errors.landline && (
                          <ErrorLabel message={errors.landline?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">
                          Industrial Segment
                        </label>
                        <Controller
                          name="industrial_segment_id"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={newIndustrialSegment}
                              fields={{ text: "name", value: "id" }}
                              allowFiltering={true}
                              placeholder="Select Industrial Segment"
                              value={field.value}
                              change={(event) => {
                                const { id } = event?.itemData || {};
                                if (id !== undefined) {
                                  field.onChange(id);
                                  props.actionGetActivity({ id: id });
                                }
                              }}
                            />
                          )}
                          rules={{
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Enter Industrial Segment"
                                : false,
                          }}
                        />
                        {errors.industrial_segment_id && (
                          <ErrorLabel
                            message={errors.industrial_segment_id?.message}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Activity</label>
                        <Controller
                          name="industrial_segment_activity_id"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={activity}
                              id="industrial_segment_activity_id"
                              name="industrial_segment_activity_id"
                              fields={{ text: "name", value: "id" }}
                              allowFiltering={true}
                              placeholder="Select Activity"
                              value={field.value}
                              change={(event) =>
                                field.onChange(event?.itemData?.id)
                              }
                            />
                          )}
                          rules={{
                            required: additionalCheck
                              ? "Please Select Activity"
                              : false,
                          }}
                        />
                        {errors.industrial_segment_activity_id && (
                          <ErrorLabel
                            message={
                              errors.industrial_segment_activity_id?.message
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Website</label>
                        <input
                          className="form-control"
                          id="website"
                          name="website"
                          placeholder="Enter Website"
                          {...register("website")}
                        />
                        {errors.website && (
                          <ErrorLabel message={errors.website?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">
                          Form of Oraganization
                        </label>
                        <Controller
                          name="form_of_organization_id"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={newFormOfOrganization}
                              fields={{ text: "name", value: "id" }}
                              allowFiltering={true}
                              placeholder="Select Form of Oraganization"
                              value={field.value}
                              change={(event) =>
                                field.onChange(event?.itemData?.id)
                              }
                            />
                          )}
                          rules={{
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Select Form Of Organization"
                                : false,
                          }}
                        />
                        {errors.form_of_organization_id && (
                          <ErrorLabel
                            message={errors.form_of_organization_id?.message}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Multi Branch</label>
                        <Controller
                          name="multi_branch"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={[
                                {
                                  name: "Yes",
                                  value: 1,
                                },
                                {
                                  name: "No",
                                  value: 0,
                                },
                              ]}
                              allowFiltering={false}
                              fields={{ text: "name", value: "value" }}
                              placeholder="Select Multi Branch"
                              value={field.value}
                              change={(event) => {
                                field.onChange(event?.itemData?.value);
                              }}
                            />
                          )}
                          rules={{
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Select Multi Branch"
                                : false,
                          }}
                        />
                        {errors.multi_branch && (
                          <ErrorLabel message={errors.multi_branch?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Lead Source</label>
                        <Controller
                          name="lead_source_id"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={newLeadSource}
                              fields={{ text: "name", value: "id" }}
                              allowFiltering={true}
                              placeholder="Select Lead Source"
                              value={field.value}
                              change={(event) =>
                                field.onChange(event?.itemData?.id)
                              }
                            />
                          )}
                          rules={{
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Select Lead Source"
                                : false,
                          }}
                        />
                        {errors.lead_source_id && (
                          <ErrorLabel
                            message={errors.lead_source_id?.message}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">
                          Assign RM&nbsp;<span className="danger">*</span>
                        </label>
                        <Controller
                          name="created_by"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              dataSource={newAssignRM}
                              fields={{ text: "full_name", value: "user_id" }}
                              allowFiltering={true}
                              placeholder="Select Assign RM"
                              value={field.value}
                              change={(event) => {
                                if (event?.itemData != null) {
                                  field.onChange(event?.itemData?.user_id);
                                }
                              }}
                            />
                          )}
                          rules={{
                            required: "Please Select Assign RM",
                          }}
                        />
                        {errors.created_by && (
                          <ErrorLabel message={errors.created_by?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                      <h4 className="d-flex align-items-center form-section mb-2 black">
                        <FiUser size={16} />
                        &nbsp;Primary Contact
                      </h4>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Contact Name</label>
                        <input
                          className="form-control"
                          id="contact_name"
                          name="contact_name"
                          placeholder="Enter Contact Name"
                          {...register("contact_name", {
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Enter Contact Name"
                                : false,
                          })}
                        />
                        {errors.contact_name && (
                          <ErrorLabel message={errors.contact_name?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">
                          Contact Designation
                        </label>
                        <input
                          className="form-control"
                          id="contact_designation"
                          name="contact_designation"
                          placeholder="Enter Contact Designation"
                          {...register("contact_designation", {
                            required:
                              (clientRedquiredFields &&
                                props.convertedToCustomer === true) ||
                              additionalCheck
                                ? "Please Enter Contact Designation"
                                : false,
                          })}
                        />
                        {errors.contact_designation && (
                          <ErrorLabel
                            message={errors.contact_designation?.message}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Contact Phone</label>
                        <input
                          className="form-control"
                          id="contact_phone"
                          name="contact_phone"
                          maxLength={10}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          placeholder="Enter Contact Phone"
                          {...register("contact_phone", {
                            pattern: {
                              value: /^[6789]\d{9}$/,
                              message:
                                "Please enter 10 digit valid Contact Phone",
                            },
                            required: additionalCheck
                              ? "Please Enter Contact Phone"
                              : false,
                          })}
                        />
                        {errors.contact_phone && (
                          <ErrorLabel message={errors.contact_phone?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <label className="label-control">Contact Email</label>
                        <input
                          className="form-control"
                          id="contact_email"
                          name="contact_email"
                          placeholder="Enter Contact Email"
                          {...register("contact_email", {
                            pattern: {
                              value:
                                /^[0-9a-z]+(?:\.[0-9a-z]+)*@[a-z0-9]{2,}(?:\.[a-z]{2,})+$/i,
                              message: "Please enter valid Email Id",
                            },
                            required: additionalCheck
                              ? "Please Enter Contact Email"
                              : false,
                          })}
                        />
                        {errors.contact_email && (
                          <ErrorLabel message={errors.contact_email?.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label className="label-control">Day</label>
                        <Controller
                          name="contact_dob_date"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              id="contact_dob_date"
                              name="contact_dob_date"
                              dataSource={generateDayOptions()}
                              allowFiltering={false}
                              placeholder="Select Day"
                              value={field.value}
                              fields={{ text: "text", value: "value" }}
                              change={(event) => {
                                if (event?.itemData != null) {
                                  field.onChange(event?.itemData?.value);
                                }
                              }}
                            />
                          )}
                          rules={{
                            required:
                              additionalCheck || watchMonth
                                ? "Please Select Day"
                                : false,
                          }}
                        />
                        {errors.contact_dob_date && (
                          <ErrorLabel
                            message={errors.contact_dob_date?.message}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label className="label-control">Month</label>
                        <Controller
                          name="contact_dob_month"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              id="contact_dob_month"
                              name="contact_dob_month"
                              dataSource={generateMonthOptions()}
                              allowFiltering={false}
                              placeholder="Select Month"
                              value={field.value}
                              fields={{ text: "text", value: "value" }}
                              change={(event) => {
                                if (event?.itemData != null) {
                                  field.onChange(event?.itemData?.value);
                                  setSelectedMonth(
                                    Number(event?.itemData?.value)
                                  );
                                }
                              }}
                            />
                          )}
                          rules={{
                            required:
                              additionalCheck || watchDay
                                ? "Please Select Month"
                                : false,
                          }}
                        />
                        {errors.contact_dob_month && (
                          <ErrorLabel
                            message={errors.contact_dob_month?.message}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <label className="label-control">Year</label>
                        <Controller
                          name="contact_dob_year"
                          control={control}
                          render={({ field }) => (
                            <DropDownListComponent
                              {...field}
                              id="contact_dob_year"
                              name="contact_dob_year"
                              dataSource={generateYearOptions()}
                              allowFiltering={false}
                              placeholder="Select Year"
                              value={field.value}
                              fields={{ text: "text", value: "value" }}
                              change={(event) => {
                                if (event?.itemData != null) {
                                  field.onChange(event?.itemData?.value);
                                  setSelectedYear(event?.itemData?.value);
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-actions border-0">
                  <button
                    id="save-new-company"
                    className="btn btn-primary round text-uppercase shadow mr-1 mb-1 mb-md-0 mb-lg-0"
                    type="submit"
                  >
                    <div className="d-flex align-items-center">
                      {props.convertedToCustomer == false
                        ? additionalCheck
                          ? "Submit and Proceed For RFQ"
                          : "Save New Company"
                        : "Convert to Customer"}
                      <span
                        id="new-company-form-loader"
                        className="spinner-border spinner-border-sm ml-half hidden"
                      ></span>
                    </div>
                  </button>
                  <button
                    className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                    onClick={(event) => {
                      props.handleClose();
                      event.preventDefault();
                    }}
                  >
                    {" "}
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  var NewCompanyGroup = null;
  var NewCustomerCategory = null;
  var NewIndustrialSegment = null;
  var NewFormofOrganization = null;
  var NewLeadSource = null;
  var NewAssignRM = null;
  var NewStates = null;
  var NewCities = null;
  var AddNewCompany = null;
  var ClientDetails = null;
  var EditCompany = null;
  var GetActivity = null;

  if (
    state.CustomerAddNewCustomerReducer != null &&
    Object.keys(state.CustomerAddNewCustomerReducer).length > 0 &&
    state.CustomerAddNewCustomerReducer.constructor === Object
  ) {
    switch (state.CustomerAddNewCustomerReducer.tag) {
      case ACTION_MASTER_NEW_COMPANY_GROUP_SUCCESS:
        NewCompanyGroup = Object.assign(
          {},
          state.CustomerAddNewCustomerReducer.data
        );
        delete state.CustomerAddNewCustomerReducer;
        return { NewCompanyGroup };
      case ACTION_MASTER_NEW_CUSTOMER_CATEGORY_SUCCESS:
        NewCustomerCategory = Object.assign(
          {},
          state.CustomerAddNewCustomerReducer.data
        );
        delete state.CustomerAddNewCustomerReducer;
        return { NewCustomerCategory };
      case ACTION_MASTER_NEW_INDUSTRIAL_SEGMENT_SUCCESS:
        NewIndustrialSegment = Object.assign(
          {},
          state.CustomerAddNewCustomerReducer.data
        );
        delete state.CustomerAddNewCustomerReducer;
        return { NewIndustrialSegment };
      case ACTION_MASTER_NEW_FORM_OF_ORGANIZATION_SUCCESS:
        NewFormofOrganization = Object.assign(
          {},
          state.CustomerAddNewCustomerReducer.data
        );
        delete state.CustomerAddNewCustomerReducer;
        return { NewFormofOrganization };
      case ACTION_MASTER_NEW_LEAD_SOURCE_SUCCESS:
        NewLeadSource = Object.assign(
          {},
          state.CustomerAddNewCustomerReducer.data
        );
        delete state.CustomerAddNewCustomerReducer;
        return { NewLeadSource };
      case ACTION_MASTER_NEW_ASSIGN_RM_SUCCESS:
        NewAssignRM = Object.assign(
          {},
          state.CustomerAddNewCustomerReducer.data
        );
        delete state.CustomerAddNewCustomerReducer;
        return { NewAssignRM };
      case ACTION_MASTER_NEW_LEAD_STATES_SUCCESS:
        NewStates = Object.assign({}, state.CustomerAddNewCustomerReducer.data);
        delete state.CustomerAddNewCustomerReducer;
        return { NewStates };
      case ACTION_MASTER_NEW_LEAD_CITIES_SUCCESS:
        NewCities = Object.assign({}, state.CustomerAddNewCustomerReducer.data);
        delete state.CustomerAddNewCustomerReducer;
        return { NewCities };
      case ACTION_ADD_NEW_COMPANY_SUCCESS:
        AddNewCompany = Object.assign(
          {},
          state.CustomerAddNewCustomerReducer.data
        );
        delete state.CustomerAddNewCustomerReducer;
        return { AddNewCompany };
      case ACTION_GET_CORPORATE_CLIENT_DETAILS_SUCCESS:
        ClientDetails = Object.assign(
          {},
          state.CustomerAddNewCustomerReducer.data
        );
        delete state.CustomerAddNewCustomerReducer;
        return { ClientDetails };
      case ACTION_EDIT_COMPANY_SUCCESS:
        EditCompany = Object.assign(
          {},
          state.CustomerAddNewCustomerReducer.data
        );
        delete state.CustomerAddNewCustomerReducer;
        return { EditCompany };
      default:
    }
  }
  if (
    state.LeadCreateCorporateReducer != null &&
    Object.keys(state.LeadCreateCorporateReducer).length > 0 &&
    state.LeadCreateCorporateReducer.constructor === Object
  ) {
    switch (state.LeadCreateCorporateReducer.tag) {
      case ACTION_GET_ACTIVITY_SUCCESS:
        GetActivity = Object.assign({}, state.LeadCreateCorporateReducer.data);
        delete state.LeadCreateCorporateReducer;
        return { GetActivity };
      default:
    }
  }
  return {
    NewCompanyGroup,
    NewCustomerCategory,
    NewIndustrialSegment,
    NewFormofOrganization,
    NewLeadSource,
    NewAssignRM,
    NewStates,
    NewCities,
    AddNewCompany,
    ClientDetails,
    EditCompany,
    GetActivity,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionNewCompanyGroup,
      actionNewCustomerCategory,
      actionNewIndustrialSegment,
      actionNewFormOfOrganization,
      actionNewLeadSource,
      actionNewAssignRM,
      actionNewStates,
      actionNewCities,
      actionAddNewCompany,
      actionGetClientDetails,
      actionEditCompany,
      actionGetActivity,
    },
    dispatch
  );
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(AddNewCompany)
);
