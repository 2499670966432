import React, { useEffect, useState } from "react";
import ScreenTitle from '../../../../../../component/screen_title';
import { DateBox } from 'devextreme-react/date-box';
import { Controller, useForm } from "react-hook-form";
import dateFormat from "dateformat";
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { checkFileExtension } from '../../../../../../utils';
import { MEETING_FILE_ERROR_MESSAGE, MEETING_FILE_SIZE, PRIMARY_COLOR, UNAUTHORIZED, ERROR } from '../../../../../../constants';
import Swal from 'sweetalert2';
import { FiUser } from 'react-icons/fi';
import { bindActionCreators } from "redux";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import $ from 'jquery';
import { toast } from 'react-toastify';
import { KEY_TOAST_MESSAGE, setData } from "../../../../../../utils/preferences";
import { actionRiskManagementClientType, ACTION_RISK_APPROVAL_CLIENT_TYPE_SUCCESS } from './action';
import { actionRiskManagementClientName, ACTION_RISK_APPROVAL_CLIENT_NAME_SUCCESS } from './action';
import { actionRiskManagementTypeOfInspection, ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_SUCCESS } from './action';
import { actionRiskManagementFinancialYear, ACTION_RISK_APPROVAL_FINANCIAL_YEAR_SUCCESS } from './action';
import { actionViewRiskManagmentApproval, ACTION_RISK_APPROVAL_VIEW_SUCCESS } from './action';
import { actionEditRiskManagmentApproval, ACTION_RISK_APPROVAL_EDIT_SUCCESS } from './action';
import moment from "moment";
import DocumentIframe from "../../../../../../component/popups/document_iframe";
import { downloadFile } from "../../../../../../utils";

const RiskManagementApprovolEdit = (props) => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [postImage, setPostImage] = useState(null);
    const validExt = ['doc', 'docx', 'pptx', 'ppt', 'xlsx', 'xls', 'pdf', 'zip'];
    const [selectTypeOfInspection, setSelectTypeOfInspection] = useState(null);
    const [clientName, setClientName] = useState([]);
    const [clientType, setClientType] = useState([]);
    const [riskApproval, setRiskApproval] = useState(null);
    const [typeOfInspection, setTypeOfInspection] = useState([]);
    const [financialYear, setFinancialYear] = useState([]);
    const [riskStatus, setRiskStatus] = useState(null);
    const [showDocumentDetails, setShowDocumentDetails] = useState(false)
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null)
    const [extension, setExtension] = useState(null)
    const [documentDetails, setDocumentDetails] = useState(null)

    const initialRender = () => {
        props.actionViewRiskManagmentApproval({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (riskApproval != null) {
            props.actionRiskManagementTypeOfInspection();
            props.actionRiskManagementClientType();
            props.actionRiskManagementFinancialYear();
        }
    }, [riskApproval]);

    const onViewRiskManagmentApprovalUpdate = () => {
        const { ViewRiskManagmentApproval } = props;
        var ViewRiskManagmentApprovalResponse = Object.assign({}, ViewRiskManagmentApproval);
        if (ViewRiskManagmentApprovalResponse.result === true) {
            setValue('created_at', moment(ViewRiskManagmentApprovalResponse.response.created_at).format("DD/MM/YYYY"));
            setValue('address', ViewRiskManagmentApprovalResponse.response.address);
            setValue('occupancy', ViewRiskManagmentApprovalResponse.response.occupancy != null && ViewRiskManagmentApprovalResponse.response.occupancy != 'null' ? ViewRiskManagmentApprovalResponse.response.occupancy : '');
            setValue('sum_insured', ViewRiskManagmentApprovalResponse.response.sum_insured);
            setValue('inspection_date', ViewRiskManagmentApprovalResponse.response.inspection_date != null ? ViewRiskManagmentApprovalResponse.response.inspection_date : '');
            setValue('actual_date_inspection', ViewRiskManagmentApprovalResponse.response.actual_date_inspection != null ? ViewRiskManagmentApprovalResponse.response.actual_date_inspection : '');
            setValue('description', ViewRiskManagmentApprovalResponse.response.description);
            setValue('status', ViewRiskManagmentApprovalResponse.response.status);
            setValue('remarks', ViewRiskManagmentApprovalResponse.response.remarks);
            setValue('reason', ViewRiskManagmentApprovalResponse.response.reason);
            setRiskApproval(ViewRiskManagmentApprovalResponse.response);
        } else {
            switch (ViewRiskManagmentApprovalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onViewRiskManagmentApprovalUpdate, [props.ViewRiskManagmentApproval]);

    useEffect(() => {
        if (clientType != null && clientType.length > 0) {
            if (riskApproval != null) {
                setValue('client_type', riskApproval.client_type);
            }
        }
    }, [clientType])

    const onRiskManagementClientTypeUpdate = () => {
        const { RiskManagementClientType } = props;
        var RiskManagementClientTypeResponse = Object.assign({}, RiskManagementClientType);
        if (RiskManagementClientTypeResponse.result === true) {
            setClientType(RiskManagementClientTypeResponse.response);
        } else {
            switch (RiskManagementClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRiskManagementClientTypeUpdate, [props.RiskManagementClientType]);

    useEffect(() => {
        if (clientName != null && clientName.length > 0) {
            if (riskApproval != null) {
                setValue('client_id', riskApproval.client_id);
            }
        }
    }, [clientName])

    const onRiskManagementClientNameUpdate = () => {
        const { RiskManagementClientName } = props;
        var RiskManagementClientNameResponse = Object.assign({}, RiskManagementClientName);
        if (RiskManagementClientNameResponse.result === true) {
            setClientName(RiskManagementClientNameResponse.response);
        } else {
            switch (RiskManagementClientNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRiskManagementClientNameUpdate, [props.RiskManagementClientName]);

    useEffect(() => {
        if (typeOfInspection != null && typeOfInspection.length > 0) {
            if (riskApproval != null) {
                setValue('inspection_id', riskApproval.inspection_id);
            }
        }
    }, [typeOfInspection])

    const onRiskManagementTypeOfInspectionUpdate = () => {
        const { RiskManagementTypeOfInspection } = props;
        var RiskManagementTypeOfInspectionResponse = Object.assign({}, RiskManagementTypeOfInspection);
        if (RiskManagementTypeOfInspectionResponse.result === true) {
            setTypeOfInspection(RiskManagementTypeOfInspectionResponse.response);
        } else {
            switch (RiskManagementTypeOfInspectionResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRiskManagementTypeOfInspectionUpdate, [props.RiskManagementTypeOfInspection]);

    useEffect(() => {
        if (financialYear != null && financialYear.length > 0) {
            if (riskApproval != null) {
                setValue('financial_year', riskApproval.financial_year);
            }
        }
    }, [financialYear])

    const onRiskManagementFinancialYearUpdate = () => {
        const { RiskManagementFinancialYear } = props;
        var RiskManagementFinancialYearResponse = Object.assign({}, RiskManagementFinancialYear);
        if (RiskManagementFinancialYearResponse.result === true) {
            setFinancialYear(RiskManagementFinancialYearResponse.response);
        } else {
            switch (RiskManagementFinancialYearResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onRiskManagementFinancialYearUpdate, [props.RiskManagementFinancialYear]);

    const onEditRiskManagmentApprovalUpdate = () => {
        const { EditRiskManagmentApproval } = props;
        var EditRiskManagmentApprovalResponse = Object.assign({}, EditRiskManagmentApproval);
        $("#edit_risk_management").prop("disabled", false);
        if (EditRiskManagmentApprovalResponse.result === true) {
            $("#risk-management-edit-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Risk Updated Successfully");
            if (props?.location?.state?.type === 'ADD_RISK_REQUEST') {
                props.history.push({
                    pathname: '/notification'
                })
            } else {
                props.history.goBack();
            }
        }
        else {
            $("#risk-management-edit-loader").addClass("hidden");
            switch (EditRiskManagmentApprovalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    toast.error(EditRiskManagmentApprovalResponse.message);
                default:
            }
        }
    }
    useEffect(onEditRiskManagmentApprovalUpdate, [props.EditRiskManagmentApproval]);

    const onSubmit = (data) => {
        var files = [];
        if (postImage != null) {
            files.push({ 'name': 'upload_document', 'value': postImage });
        }
        delete data.upload_document;
        data.id = props.location.state.id;
        data.client_id = Number(data.client_id);
        data.occupancy = data.occupancy != null ? data.occupancy : '';
        data.sum_insured = data.sum_insured != null ? data.sum_insured : '';
        data.inspection_id = data.inspection_id != null ? data.inspection_id : '';
        data.inspection_date = data.inspection_date != null ? data.inspection_date : '';
        data.remarks = data.remarks != null ? data.remarks : '';
        data.actual_date_inspection = data.actual_date_inspection != null ? data.actual_date_inspection : '';
        data.financial_year = data.financial_year != null ? data.financial_year : '';
        data.status = data.status != null ? data.status : null;
        data.description = data.description != null ? data.description : '';
        data.reason = data.reason != null ? data.reason : '';
        if (data.status === null || data.status === undefined) {
            delete data.status
        }
        if (data.created_at === null || data.created_at === undefined) {
            delete data.created_at
        }
        $("#risk-management-edit-loader").removeClass("hidden");
        $("#edit_risk_management").prop("disabled", true);
        props.actionEditRiskManagmentApproval(data, files);
    }

    return (
        <>
            <ScreenTitle title="Edit Risk Management" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Created Date</label>
                                                    <input
                                                        id="created_at"
                                                        name="created_at"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("created_at")}
                                                    />
                                                    {errors.created_at && <ErrorLabel message={errors.created_at?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type</label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        props.actionRiskManagementClientName({ client_type: event.itemData.value });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name</label>
                                                    <Controller
                                                        name="client_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_id"
                                                                name="client_id"
                                                                dataSource={clientName}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client Name"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                    />

                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        disabled={!['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Occupancy</label>
                                                    <input
                                                        className="form-control"
                                                        id="occupancy"
                                                        name="occupancy"
                                                        type="text"
                                                        disabled={!['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                        placeholder="Enter occupancy"
                                                        {...register('occupancy')}
                                                    />
                                                    {errors.occupancy?.type && <ErrorLabel message={errors.occupancy?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Sum Insured </label>
                                                    <input
                                                        className="form-control"
                                                        id="sum_insured"
                                                        name="sum_insured"
                                                        type="text"
                                                        disabled={!['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        placeholder="Enter sum insured"
                                                        {...register('sum_insured')}
                                                    />
                                                    {errors.sum_insured?.type && <ErrorLabel message={errors.sum_insured?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Type of Inspection&nbsp;<span className="danger">*</span></label>
                                                    <Controller
                                                        name="inspection_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="inspection_id"
                                                                name="inspection_id"
                                                                dataSource={typeOfInspection}
                                                                allowFiltering={false}
                                                                placeholder="Select Type of Inspection"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                enabled={['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                        setSelectTypeOfInspection(event.itemData.name)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        rules={{
                                                            required: ' Please select type of inspection'
                                                        }}
                                                    />

                                                    {errors.inspection_id && <ErrorLabel message={errors.inspection_id?.message} />}
                                                </div>
                                            </div>
                                            {
                                                selectTypeOfInspection === 'Others' ?
                                                    <div className="col-12 col-md-6 col-lg-6">
                                                        <div className="form-group">
                                                            <label className="label-control">Description&nbsp;<span className="danger">*</span></label>
                                                            <input
                                                                className="form-control"
                                                                id="description"
                                                                name="description"
                                                                type="text"
                                                                placeholder="Enter description"
                                                                disabled={!['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                                {...register('description',
                                                                    { required: 'Description is required' }
                                                                )}
                                                            />
                                                            {errors.description?.type && <ErrorLabel message={errors.description?.message} />}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Inspection</label>
                                                    <Controller
                                                        name="inspection_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="inspection_date"
                                                                name="inspection_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                disabled={!['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.inspection_date && <ErrorLabel message={errors.inspection_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <fieldset class="form-group">
                                                    <label for="basicInputFile">Document</label>
                                                    <div class="custom-file">
                                                        <input
                                                            type="file"
                                                            class="custom-file-input"
                                                            id="upload_document"
                                                            accept=".doc,.docx,.pptx,.ppt,.xlsx,.xls,.pdf,.zip"
                                                            name="upload_document"
                                                            disabled={!['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                            onChange={(event) => {
                                                                if (
                                                                    event.target.files
                                                                ) {

                                                                    var fileSize = event.target.files[0].size;

                                                                    var ext = (event.target.files[0].name).split('.').pop();

                                                                    if (checkFileExtension(ext, validExt)) {

                                                                        if (Math.round(fileSize / 1024) <= MEETING_FILE_SIZE) {
                                                                            setPostImage(event.target.files[0]);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: 'Error',
                                                                                icon: 'error',
                                                                                html: MEETING_FILE_ERROR_MESSAGE,
                                                                                confirmButtonColor: PRIMARY_COLOR
                                                                            }).then((result) => {

                                                                            });
                                                                        }
                                                                    }
                                                                    else {
                                                                        Swal.fire({
                                                                            title: 'Upload Error',
                                                                            text: 'You can only upload Word, PPT, Excel, PDF & ZIP files',
                                                                            icon: 'error'
                                                                        }).then(success => {

                                                                        })
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label class="custom-file-label" for="upload_document">
                                                            {
                                                                postImage != null ?
                                                                    postImage.name : "Choose file"
                                                            }
                                                        </label>
                                                        <div className="mt-1">
                                                            {
                                                                (riskApproval != null && riskApproval.risk_management_document != null) ?
                                                                    // <div id="action_risk_management_document">
                                                                    //     <a target="_blank" href={riskApproval?.risk_management_document}>Click Here to View</a>
                                                                    // </div>
                                                                    // : <></>

                                                                    <>
                                                                        <a className="primary" id="action_risk_management_document"
                                                                            onClick={() => {

                                                                                if (riskApproval?.risk_management_document?.split('.').pop() == 'pdf' ||
                                                                                    riskApproval?.risk_management_document?.split('.').pop() == 'PDF' ||
                                                                                    riskApproval?.risk_management_document?.split('.').pop() == 'png' ||
                                                                                    riskApproval?.risk_management_document?.split('.').pop() == 'jpg' ||
                                                                                    riskApproval?.risk_management_document?.split('.').pop() == 'jpeg' ||
                                                                                    riskApproval?.risk_management_document?.split('.').pop() == 'gif'
                                                                                ) {
                                                                                    setSelectedDocumentIndex(riskApproval?.risk_management_document)
                                                                                    setShowDocumentDetails(true);
                                                                                    setExtension(riskApproval?.risk_management_document?.split('.').pop())
                                                                                    setDocumentDetails(riskApproval)
                                                                                } else {
                                                                                    downloadFile(riskApproval?.risk_management_document);
                                                                                }
                                                                            }}
                                                                        >
                                                                            Click Here to View
                                                                        </a>
                                                                        <a id="downloadLink" className="d-none"></a>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Approved By
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Actual Date of Inspection</label>
                                                    <Controller
                                                        name="actual_date_inspection"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="actual_date_inspection"
                                                                name="actual_date_inspection"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                disabled={!['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.actual_date_inspection && <ErrorLabel message={errors.actual_date_inspection?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remark</label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks"
                                                        name="remarks"
                                                        type="text"
                                                        maxLength={250}
                                                        disabled={!['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                        placeholder="Enter Remark"
                                                        {...register('remarks', {
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.remarks?.type === 'required' && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Financial Year</label>
                                                    <Controller
                                                        name="financial_year"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="financial_year"
                                                                name="financial_year"
                                                                dataSource={financialYear}
                                                                allowFiltering={false}
                                                                placeholder="Select Financial Year"
                                                                value={field.value}
                                                                enabled={['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    {errors.financial_year && <ErrorLabel message={errors.financial_year?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Your Action</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        text: 'Approve',
                                                                        value: 'approve'
                                                                    },
                                                                    {
                                                                        text: 'Reject',
                                                                        value: 'reject'
                                                                    }
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                value={field.value}
                                                                enabled={['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        setRiskStatus(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                    />

                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reason&nbsp;<span className={`danger ${riskStatus != null && riskStatus === 'reject' ? '' : 'hidden'}`}>*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="reason"
                                                        name="reason"
                                                        type="text"
                                                        placeholder="Enter Reason"
                                                        disabled={!['update', 'pending'].includes(riskApproval?.status) ? true : false}
                                                        {...register('reason', {
                                                            required: riskStatus != null && riskStatus === 'reject' ? 'Please Enter Reason' : false
                                                        })}
                                                    />
                                                    {errors.reason && <ErrorLabel message={errors.reason?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        <button
                                            id="edit_risk_management"
                                            className={`btn btn-primary mr-1 shadow round text-uppercase ${['update', 'pending'].includes(riskApproval?.status) ? '' : 'hidden'}`}
                                            type="submit"
                                        >

                                            <div className="d-flex align-items-center">
                                                Submit
                                                <span id="risk-management-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                            </div>
                                        </button>
                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (props?.location?.state?.type === 'ADD_RISK_REQUEST') {
                                                    props.history.push({
                                                        pathname: '/notification'
                                                    })
                                                } else {
                                                    props.history.goBack();
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <DocumentIframe
                    {...props}
                    show={showDocumentDetails}
                    documentDetails={selectedDocumentIndex}
                    detailsOfDocument={documentDetails}
                    extension={extension}
                    handleClose={() => {
                        setShowDocumentDetails(false);
                    }}
                />
            </section>
        </>
    )
}

const mapStateToProps = (state) => {
    var ViewRiskManagmentApproval = null;
    var RiskManagementClientType = null;
    var RiskManagementClientName = null;
    var RiskManagementTypeOfInspection = null;
    var RiskManagementFinancialYear = null;
    var EditRiskManagmentApproval = null;

    if (
        state.EditRiskManagementApprovalReducer != null &&
        Object.keys(state.EditRiskManagementApprovalReducer).length > 0 &&
        state.EditRiskManagementApprovalReducer.constructor === Object
    ) {
        switch (state.EditRiskManagementApprovalReducer.tag) {
            case ACTION_RISK_APPROVAL_VIEW_SUCCESS:
                ViewRiskManagmentApproval = Object.assign({}, state.EditRiskManagementApprovalReducer.data);
                delete state.EditRiskManagementApprovalReducer;
                return { ViewRiskManagmentApproval };
            case ACTION_RISK_APPROVAL_CLIENT_TYPE_SUCCESS:
                RiskManagementClientType = Object.assign({}, state.EditRiskManagementApprovalReducer.data);
                delete state.EditRiskManagementApprovalReducer;
                return { RiskManagementClientType };
            case ACTION_RISK_APPROVAL_CLIENT_NAME_SUCCESS:
                RiskManagementClientName = Object.assign({}, state.EditRiskManagementApprovalReducer.data);
                delete state.EditRiskManagementApprovalReducer;
                return { RiskManagementClientName };
            case ACTION_RISK_APPROVAL_TYPE_OF_INSPECTION_SUCCESS:
                RiskManagementTypeOfInspection = Object.assign({}, state.EditRiskManagementApprovalReducer.data);
                delete state.EditRiskManagementApprovalReducer;
                return { RiskManagementTypeOfInspection };
            case ACTION_RISK_APPROVAL_FINANCIAL_YEAR_SUCCESS:
                RiskManagementFinancialYear = Object.assign({}, state.EditRiskManagementApprovalReducer.data);
                delete state.EditRiskManagementApprovalReducer;
                return { RiskManagementFinancialYear };
            case ACTION_RISK_APPROVAL_EDIT_SUCCESS:
                EditRiskManagmentApproval = Object.assign({}, state.EditRiskManagementApprovalReducer.data);
                delete state.EditRiskManagementApprovalReducer;
                return { EditRiskManagmentApproval };
            default:
        }
    }
    return {
        ViewRiskManagmentApproval,
        RiskManagementClientType,
        RiskManagementClientName,
        RiskManagementTypeOfInspection,
        RiskManagementFinancialYear,
        EditRiskManagmentApproval
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewRiskManagmentApproval,
        actionRiskManagementClientType,
        actionRiskManagementClientName,
        actionRiskManagementTypeOfInspection,
        actionRiskManagementFinancialYear,
        actionEditRiskManagmentApproval
    }, dispatch)
};
export default withAuth(connect(mapStateToProps, mapDispatchToProps)(RiskManagementApprovolEdit))
