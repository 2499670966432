import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { ERROR, UNAUTHORIZED } from "../../../../../../constants";
import { DateBox } from 'devextreme-react/date-box';
import { Controller, useForm } from "react-hook-form";
import dateFormat from "dateformat";
import ScreenTitle from '../../../../../../component/screen_title';
import ErrorLabel from "../../../../../../component/form/error_label";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { FiUser } from 'react-icons/fi';
import $ from 'jquery';
import { KEY_TOAST_MESSAGE, setData } from "../../../../../../utils/preferences";
import { withAuth } from "react-auth-guard";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { actionViewWellnessApproval, ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS } from "./action";
import { actionEditWellnessApprovalClientType, ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS } from "./action";
import { actionEditWellnessApprovalClientName, ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS } from "./action";
import { actionEditWellnessApproval, ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS } from "./action";

const WellnessApprovalEdit = (props) => {

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const [clientType, setClientType] = useState([]);
    const [clientName, setClientName] = useState([]);
    const [wellnessApproval, setWellnessApproval] = useState(null);
    const [wellnessStatus, setWellnessStatus] = useState(null);

    const initialRender = () => {
        $("#progress-loader").removeClass("hidden");
        props.actionViewWellnessApproval({ id: props.location.state.id });
    }
    useEffect(initialRender, []);

    useEffect(() => {
        if (wellnessApproval != null) {
            setValue('created_at', wellnessApproval?.created_at != null ? moment(wellnessApproval?.created_at).format("DD/MM/YYYY") : '');
            setValue('address', wellnessApproval?.address != null ? wellnessApproval?.address : '');
            setValue('activity_date', wellnessApproval?.activity_date != null ? wellnessApproval?.activity_date : '');
            setValue('actual_activity_date', wellnessApproval?.actual_activity_date != null ? wellnessApproval?.actual_activity_date : '');
            setValue('remarks', wellnessApproval?.remarks != null ? wellnessApproval?.remarks : '');
            setValue('reason', wellnessApproval?.reason != null ? wellnessApproval?.reason : '');
            setValue('status', wellnessApproval?.status != null ? wellnessApproval?.status : '');
            setValue('reason', wellnessApproval?.reason != null ? wellnessApproval?.reason : '');
            props.actionEditWellnessApprovalClientType();
        }
    }, [wellnessApproval])

    const onViewWellnessManagmentApprovalUpdate = () => {
        const { ViewWellnessManagmentApproval } = props;
        var ViewWellnessManagmentApprovalResponse = Object.assign({}, ViewWellnessManagmentApproval);
        if (ViewWellnessManagmentApprovalResponse.result === true) {
            $("#progress-loader").addClass("hidden");
            setWellnessApproval(ViewWellnessManagmentApprovalResponse.response);
        } else {
            $("#progress-loader").addClass("hidden");
            switch (ViewWellnessManagmentApprovalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onViewWellnessManagmentApprovalUpdate, [props.ViewWellnessManagmentApproval]);


    useEffect(() => {
        if (clientType != null && clientType.length > 0) {
            if (wellnessApproval != null) {
                setValue('client_type', wellnessApproval.client_type);
            }
        }
    }, [clientType])

    const onwellnessApprovalClientTypeUpdate = () => {
        const { WellnessApprovalClientType } = props;
        var WellnessApprovalClientTypeResponse = Object.assign({}, WellnessApprovalClientType);
        if (WellnessApprovalClientTypeResponse.result === true) {
            setClientType(WellnessApprovalClientTypeResponse.response);
        } else {
            switch (WellnessApprovalClientTypeResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onwellnessApprovalClientTypeUpdate, [props.WellnessApprovalClientType]);

    useEffect(() => {
        if (clientName != null && clientName.length > 0) {
            if (wellnessApproval != null) {
                setValue('client_id', wellnessApproval.client_id);
            }
        }
    }, [clientName])

    const onWellnessApprovalClientNameUpdate = () => {
        const { WellnessApprovalClientName } = props;
        var WellnessApprovalClientNameResponse = Object.assign({}, WellnessApprovalClientName);
        if (WellnessApprovalClientNameResponse.result === true) {
            setClientName(WellnessApprovalClientNameResponse.response);
        } else {
            switch (WellnessApprovalClientNameResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                default:
            }
        }
    }
    useEffect(onWellnessApprovalClientNameUpdate, [props.WellnessApprovalClientName]);

    const onEditWellnessApprovalUpdate = () => {
        const { EditWellnessApproval } = props;
        var EditWellnessApprovalResponse = Object.assign({}, EditWellnessApproval);
        $("#edit_wellness_approval").prop("disabled", false);
        if (EditWellnessApprovalResponse.result === true) {
            $("#wellness-approval-edit-loader").addClass("hidden");
            setData(KEY_TOAST_MESSAGE, "Wellness Updated Successfully");
            if (props?.location?.state?.type === 'ADD_WELLNESS_REQUEST') {
                props.history.push({
                    pathname: '/notification'
                })
            } else {
                props.history.goBack();
            }
        }
        else {
            $("#wellness-approval-edit-loader").addClass("hidden");
            switch (EditWellnessApprovalResponse.status) {
                case UNAUTHORIZED:
                    props.auth.logout()
                    break;
                case ERROR:
                    toast.error(EditWellnessApprovalResponse.message);
                default:
            }
        }
    }
    useEffect(onEditWellnessApprovalUpdate, [props.EditWellnessApproval]);


    const onSubmit = (data) => {
        $("#wellness-approval-edit-loader").removeClass("hidden");
        $("#edit_wellness_approval").prop("disabled", true);
        data.id = wellnessApproval?.id;
        if (data.created_at === null || data.created_at === undefined) {
            delete data.created_at;
        }
        props.actionEditWellnessApproval(data);

    }

    return (
        <>
            <ScreenTitle title="Wellness Approval Edit" />
            <section>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <form noValidate autoComplete="Off" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-body">
                                        <div className="row">
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Created Date</label>
                                                    <input
                                                        id="created_at"
                                                        name="created_at"
                                                        className="form-control"
                                                        type="text"
                                                        disabled={true}
                                                        {...register("created_at")}
                                                    />
                                                    {errors.created_at && <ErrorLabel message={errors.created_at?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Type</label>
                                                    <Controller
                                                        name="client_type"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_type"
                                                                name="client_type"
                                                                dataSource={clientType}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client Type"
                                                                value={field.value}
                                                                fields={{ text: 'title', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        props.actionEditWellnessApprovalClientName({ client_type: event.itemData.value });
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    {errors.client_type && <ErrorLabel message={errors.client_type?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Client Name</label>
                                                    <Controller
                                                        name="client_id"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="client_id"
                                                                name="client_id"
                                                                dataSource={clientName}
                                                                allowFiltering={false}
                                                                enabled={false}
                                                                placeholder="Select Client Name"
                                                                value={field.value}
                                                                fields={{ text: 'name', value: 'id' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.id)
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                    />

                                                    {errors.client_id && <ErrorLabel message={errors.client_id?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Location Address&nbsp;<span className="danger">*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        maxLength={250}
                                                        placeholder="Enter Location Address"
                                                        disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                        {...register('address', {
                                                            required: 'Location Address is required',
                                                            maxLength:
                                                            {
                                                                value: 250,
                                                                message: "Please enter 250 charcter only"
                                                            }
                                                        })}
                                                    />
                                                    {errors.address?.type === 'required' && <ErrorLabel message={errors.address?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Date of Activity</label>
                                                    <Controller
                                                        name="activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="activity_date"
                                                                name="activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                                value={field.value}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.activity_date && <ErrorLabel message={errors.activity_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Remark</label>
                                                    <input
                                                        className="form-control"
                                                        id="remarks"
                                                        name="remarks"
                                                        type="text"
                                                        placeholder="Enter Remark"
                                                        disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                        {...register('remarks')}
                                                    />
                                                    {errors.remarks && <ErrorLabel message={errors.remarks?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12">
                                                <h4 className="d-flex align-items-center form-section mb-2 black">
                                                    <FiUser size={16} />&nbsp;Approved By
                                                </h4>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Actual Date of Activity</label>
                                                    <Controller
                                                        name="actual_activity_date"
                                                        className="form-control"
                                                        control={control}
                                                        defaultValue=""
                                                        render={({ field }) =>
                                                            <DateBox
                                                                id="actual_activity_date"
                                                                name="actual_activity_date"
                                                                defaultValue={new Date()}
                                                                calendarOptions={{
                                                                    showTodayButton: true,
                                                                }}
                                                                stylingMode="underlined"
                                                                className="form-control"
                                                                pickerType="calendar"
                                                                type="date"
                                                                displayFormat="dd/MM/yyyy"
                                                                showClearButton={true}
                                                                acceptCustomValue={false}
                                                                value={field.value}
                                                                disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                                onValueChange={(value) => {
                                                                    field.onChange(dateFormat(value, 'yyyy-mm-dd'));
                                                                }}
                                                            />
                                                        }

                                                    />
                                                    {errors.actual_activity_date && <ErrorLabel message={errors.actual_activity_date?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Status</label>
                                                    <Controller
                                                        name="status"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <DropDownListComponent
                                                                {...field}
                                                                id="status"
                                                                name="status"
                                                                dataSource={[
                                                                    {
                                                                        text: 'Approve',
                                                                        value: 'approve'
                                                                    },
                                                                    {
                                                                        text: 'Reject',
                                                                        value: 'reject'
                                                                    }
                                                                ]}
                                                                allowFiltering={false}
                                                                placeholder="Select Status"
                                                                enabled={['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                                value={field.value}
                                                                fields={{ text: 'text', value: 'value' }}
                                                                change={(event) => {
                                                                    if (event.itemData != null) {
                                                                        field.onChange(event.itemData.value)
                                                                        setWellnessStatus(event.itemData.value)
                                                                    }
                                                                }}
                                                            />
                                                        }

                                                    />

                                                    {errors.status && <ErrorLabel message={errors.status?.message} />}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="label-control">Reason&nbsp;<span className={`danger ${wellnessStatus != null && wellnessStatus === 'reject' ? '' : 'hidden'}`}>*</span></label>
                                                    <input
                                                        className="form-control"
                                                        id="reason"
                                                        name="reason"
                                                        type="text"
                                                        placeholder="Enter Reason"
                                                        disabled={!['update', 'pending'].includes(wellnessApproval?.status) ? true : false}
                                                        {...register('reason', {
                                                            required: wellnessStatus != null && wellnessStatus === 'reject' ? 'Please Enter Reason' : false
                                                        })}
                                                    />
                                                    {errors.reason && <ErrorLabel message={errors.reason?.message} />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions border-0 float-none ">
                                        {
                                            wellnessApproval?.status === 'pending' || wellnessApproval?.status === 'update' ?
                                                <button
                                                    id="edit_wellness_approval"
                                                    className={`btn btn-primary mr-1 shadow round text-uppercase ${['update', 'pending'].includes(wellnessApproval?.status) ? '' : 'hidden'}`}
                                                    type="submit"
                                                >

                                                    <div className="d-flex align-items-center">
                                                        Submit
                                                        <span id="wellness-approval-edit-loader" className="spinner-border spinner-border-sm ml-half hidden"></span>
                                                    </div>
                                                </button>
                                                :
                                                <></>
                                        }

                                        <button
                                            className="btn btn-outline-secondary mr-1 shadow round text-uppercase"
                                            onClick={(event) => {
                                                event.preventDefault();
                                                if (props?.location?.state?.type === 'ADD_WELLNESS_REQUEST') {
                                                    props.history.push({
                                                        pathname: '/notification'
                                                    })
                                                } else {
                                                    props.history.goBack();
                                                }
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
const mapStateToProps = (state) => {
    var ViewWellnessManagmentApproval = null;
    var WellnessApprovalClientType = null;
    var WellnessApprovalClientName = null;
    var EditWellnessApproval = null;

    if (
        state.EditWellnessApprovalReducer != null &&
        Object.keys(state.EditWellnessApprovalReducer).length > 0 &&
        state.EditWellnessApprovalReducer.constructor === Object
    ) {
        switch (state.EditWellnessApprovalReducer.tag) {
            case ACTION_VIEW_WELLNESS_APPROVAL_SUCCESS:
                ViewWellnessManagmentApproval = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { ViewWellnessManagmentApproval };
            case ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_TYPE_SUCCESS:
                WellnessApprovalClientType = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { WellnessApprovalClientType };
            case ACTION_EDIT_WELLNESS_APPROVAL_CLIENT_NAME_SUCCESS:
                WellnessApprovalClientName = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { WellnessApprovalClientName };
            case ACTION_WELLNESS_APPROVAL_EDIT_SUCCESS:
                EditWellnessApproval = Object.assign({}, state.EditWellnessApprovalReducer.data);
                delete state.EditWellnessApprovalReducer;
                return { EditWellnessApproval };
            default:
        }
    }
    return {
        ViewWellnessManagmentApproval,
        WellnessApprovalClientType,
        WellnessApprovalClientName,
        EditWellnessApproval,

    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        actionViewWellnessApproval, actionEditWellnessApprovalClientType, actionEditWellnessApprovalClientName, actionEditWellnessApproval
    }, dispatch)
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(WellnessApprovalEdit))