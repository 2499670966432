import createReducer from "../../../../../../reducers/createReducer";
import { ACTION_RISK_MANAGEMENT_CLIENT_TYPE_SUCCESS, ACTION_RISK_MANAGEMENT_REQUEST_ADD_SUCCESS } from "./action";
import { ACTION_RISK_MANAGEMENT_CLIENT_NAME_SUCCESS, ACTION_ASSIGN_ENGINEER_SUCCESS, ACTION_RISK_MASTER_CUSTOMER_TYPE_SUCCESS } from "./action";
import { ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_SUCCESS } from "./action";

const INITIAL_STATE = {
    data: [],
    loading: false,
}

const addRiskManagementReducer = createReducer(INITIAL_STATE, {
    [ACTION_RISK_MANAGEMENT_CLIENT_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_MANAGEMENT_CLIENT_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_MANAGEMENT_CLIENT_NAME_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_MANAGEMENT_CLIENT_NAME_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_MANAGEMENT_TYPE_OF_INSPECTION_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_ASSIGN_ENGINEER_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_ASSIGN_ENGINEER_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_MASTER_CUSTOMER_TYPE_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_MASTER_CUSTOMER_TYPE_SUCCESS,
                loading: false,
            }
        );
    },
    [ACTION_RISK_MANAGEMENT_REQUEST_ADD_SUCCESS](state, action) {
        return Object.assign(
            {},
            {
                ...state,
                data: action.payload.data,
                tag: ACTION_RISK_MANAGEMENT_REQUEST_ADD_SUCCESS,
                loading: false,
            }
        );
    },
}
);
export default addRiskManagementReducer;
